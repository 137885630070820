import styled from "styled-components";

export const TableHeader = styled.div`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 100px repeat(2, minmax(100px, 200px));
  margin: 20px 0;
  place-items: center;
  padding: 0 12px;

  & p {
    margin: 0;
  }

  & > div {
    width: 100%;
  }
`;
