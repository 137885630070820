import React, { useState } from "react";
import { Expand } from "./components/Expand";

import { PatientTableBody, PatientTableContainer, PatientTableHeader } from "./RecordTable.styles";
import { ArrowPatientTableIcon } from "./icons";

export const RecordTable = ({ record, recordActive, setRecordActive, t }) => {
  const [showBody, setShowBody] = useState(record === recordActive);
  const handleToggle = () => setShowBody((prev) => !prev);

  return (
    <PatientTableContainer active={record === recordActive} onClick={() => setRecordActive(record)}>
      <PatientTableHeader onClick={handleToggle}>
        <div className="header--info">
          <p>{record.dateStr}</p>
          <p>
            {t("exercises")}: {record.total}
          </p>
        </div>

        <div className={`_icon ${showBody ? "_active" : ""}`}>
          <ArrowPatientTableIcon />
        </div>
      </PatientTableHeader>
      {showBody && (
        <PatientTableBody>
          <Expand data={record.data} t={t} />
        </PatientTableBody>
      )}
    </PatientTableContainer>
  );
};
