import React from 'react';
import { Grid } from '@material-ui/core';
import TimePicker from '../timePicker';
import Counter from '../counter';
import { configInit } from './utils';
import ImgBipeMarch from '../../assets/img/bloques/bipedestacion-marcha.svg';
import { withTranslation } from 'react-i18next'

var frecuencia, ancho_pulso, limite_intensidad
class March extends React.Component {
    constructor() {
        super();
        this.state = {
            channels: [
                { ch: 1, name: "", index: 100 },
                { ch: 2, name: "", index: 100 },
                { ch: 3, name: "", index: 100 },
                { ch: 4, name: "", index: 100 },
                { ch: 5, name: "", index: 100 },
                { ch: 6, name: "", index: 100 }
            ],
            configs: configInit(),
            contraccion: null,
            series: null,
            pausa_contraccion: null,
            pausa_series: null,
            frecuencia: 35,
            ancho_pulso: 200,
            repeticiones: 0
        }
    }

    values() {
        const { configs, name, description, contraccion, series, pausa_contraccion, pausa_series, repeticiones } = this.state;
        if (this.props.values !== undefined) {
            const chs = this.state.channels.map(e => e.index !== 100 ? true : false)
            this.props.values({
                channels: chs,
                configs: configs,
                name: name,
                description: description,
                contraccion: contraccion,
                series: series,
                pausa_series: pausa_series,
                pausa_contraccion: pausa_contraccion,
                frecuencia: frecuencia,
                ancho_pulso: ancho_pulso,
                limite_intensidad: limite_intensidad,
                config_channels: this.state.channels.filter(e => e.index !== 100),
                repeticiones: repeticiones
            });
        }
    }

    componentDidUpdate() {
        this.values();
    }

    componentDidMount() {

        if (this.props.data.params && Object.keys(this.props.data.params).length !== 0) {
            const { contraccion, pausa_contraccion, pausa_series, series, repeticiones } = this.props.data.params;
            this.setState({
                contraccion,
                pausa_contraccion,
                pausa_series,
                series,
                repeticiones: repeticiones
            })
        }
    }

    render() {
        const { t } = this.props;
        return (
            <Grid container direction="row" justifyContent="center">
                <Grid item xs={12} md={4} className="pl-4">
                    <h6 className="title-calendar mb-3">{t('selectedMuscle')}</h6>
                    <Grid item xs={12} container direction="row" justifyContent="center">
                        <img src={ImgBipeMarch} className="img-fluid" alt="BipeMarch"></img>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={8} className="px-4">
                    <p className="title-calendar mb-3">{t('adjustments')}</p>
                    <Grid container direction="row">
                        <Grid item xs={12} md={4} className="pr-1">
                            <div className="border-tf py-2 px-3">
                                <p className="fz-10 color-sub-text m-0">{t('series')}</p>
                                <Counter data={this.state.series} title="0 - 50" min={0} max={50} values={value => this.setState({ series: value })} />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4} className="px-1">
                            <div className="border-tf py-2 px-3">
                                <p className="fz-10 color-sub-text m-0">{t('pauseSeries')}</p>
                                <TimePicker data={this.state.pausa_series} minutes={true} seconds={true} values={value => this.setState({ pausa_series: value })} />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4} className="pl-1">
                            <div className="border-tf py-2 px-3">
                                <p className="fz-10 color-sub-text m-0">{t('repeat')}</p>
                                <Counter data={this.state.repeticiones} title="" min={0} max={100} values={value => this.setState({ repeticiones: value })} />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" className="mt-2">
                        <Grid item xs={12} md={4} className="pr-1">
                            <div className="border-tf py-2 px-3">
                                <p className="fz-10 color-sub-text m-0">{t('timeContract')}</p>
                                <TimePicker data={this.state.contraccion} seconds={true} values={value => this.setState({ contraccion: value })} />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4} className="pl-1">
                            <div className="border-tf py-2 px-3">
                                <p className="fz-10 color-sub-text m-0">{t('pauseContract')}</p>
                                <TimePicker data={this.state.pausa_contraccion} seconds={true} values={value => this.setState({ pausa_contraccion: value })} />
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default withTranslation('sessionStudio')(March);