import React from "react";
import { useTranslation } from "react-i18next";

import { RadioInput } from "./RadioInput";

import { v4 as uuidv4 } from "uuid";
import { SET_VARS_DATA } from "../../reducer/evaluationsActions";

export const RadioInputsComp = ({
  state,
  dispatch,
  type,
  editingId,
  questionId,
  setTypeRenderData,
  stored,
  typeRenderData,
  onDrop,
  'data-index': index,
}) => {
  const { t } = useTranslation("evaluations");
  const { varsData } = state;

  const handleAddInput = () =>
    setTypeRenderData((prev) => [...prev, { id: uuidv4(), name: "" }]);

  const onChange = (e, index) => {
    const newArray = JSON.parse(JSON.stringify([...typeRenderData]));
    newArray[index] = {
      ...newArray[index],
      name: e.target.value,
    };

    setTypeRenderData(newArray);
  };

  const onRemove = (id) =>
    setTypeRenderData(typeRenderData.filter((el) => el.id !== id));

  // - - -
  // Editing methods
  // - - -
  const onChangeEditing = (id, e) => {
    const newDataArr = [...varsData];
    const index = newDataArr.findIndex((el) => el.id === questionId);

    const index2 = newDataArr[index].data.findIndex((el) => el.id === id);

    if (index2 >= 0) {
      newDataArr[index].data[index2] = {
        ...newDataArr[index].data[index2],
        name: e.target.value,
      };
    }

    dispatch({ type: SET_VARS_DATA, payload: newDataArr });
  };

  const onRemoveEditing = (id) => {
    const newDataArr = [...varsData];
    const index = newDataArr.findIndex((el) => el.id === questionId);

    newDataArr[index].data = newDataArr[index].data.filter((e) => e.id !== id);

    dispatch({ type: SET_VARS_DATA, payload: newDataArr });
  };

  const handleAddEditingInput = () => {
    const newDataArr = [...varsData];
    const index = newDataArr.findIndex((el) => el.id === questionId);

    if (index >= 0) {
      newDataArr[index].data = [
        ...newDataArr[index].data,
        { id: uuidv4(), name: "" },
      ];
    }

    dispatch({ type: SET_VARS_DATA, payload: newDataArr });
  };

  if (stored && type === "bool") {
    return (
      <div className="__inputs" onDrop={onDrop} data-index={index}>
        {typeRenderData.map((element, i) => (
          <RadioInput
            key={i}
            index={i + 1}
            type={type}
            stored
            isActive
            placeholder={`${t("option")} ${i + 1}`}
            value={element.name}
            onChange={(e) => onChange(e, i)}
            onRemove={() => onRemoveEditing(element.id)}
          />
        ))}
      </div>
    );
  }

  if (stored && editingId === questionId) {
    return (
      <>
        <div className="__inputs" onDrop={onDrop} data-index={index}>
          {[...JSON.parse(JSON.stringify(typeRenderData))].map((element, i) => (
            <RadioInput
              key={i}
              index={i + 1}
              type={type}
              id={element.id || null}
              stored
              editingId={editingId}
              isActive
              placeholder={`${t("option")} ${i + 1}`}
              value={element.name}
              onChange={(e) => onChangeEditing(element.id, e)}
              onRemove={() => onRemoveEditing(element.id)}
            />
          ))}
          <RadioInput
            index={typeRenderData.length + 1}
            type={type}
            onClick={handleAddEditingInput}
          />
        </div>
      </>
    );
  }

  if (stored) {
    return (
      <div className="__inputs" onDrop={onDrop} data-index={index} style={{pointerEvents: 'none'}}>
        {typeRenderData.map((element, i) => (
          <RadioInput
            key={i}
            index={i + 1}
            type={type}
            stored
            isActive
            placeholder={`${t("option")} ${i + 1}`}
            value={element.name}
            onChange={(e) => onChange(e, i)}
            onRemove={() => onRemoveEditing(element.id)}
          />
        ))}
      </div>
    );
  }

  return (
    <>
      <div className="__inputs" onDrop={onDrop} data-index={index}>
        {typeRenderData.map((element, i) => (
          <RadioInput
            key={i}
            index={i + 1}
            type={type}
            isActive
            placeholder={`${t("option")} ${i + 1}`}
            value={element.name}
            onChange={(e) => onChange(e, i)}
            onRemove={() => onRemove(element.id)}
          />
        ))}
        <RadioInput
          index={typeRenderData.length + 1}
          type={type}
          onClick={handleAddInput}
        />
      </div>
    </>
  );
};
