export const RESET_DATA = "[] Reset to initial state."
export const GET_DATA_ADDRESS = "[Address] set fields to complete address."

export const SET_EDIT = "[] Set edit fields."

export const OPEN_MODAL = "[] Open modal."

export const SET_FIELD = "[] Set fields."

export const ADD_SPECIALITY = "[] Add Speciality."
export const REMOVE_SPECIALITY = "[] Remove Speciality."

export const POSTING_DATA = "[] Is posting data boolean."