import React from "react";
import "../../../assets/img/icomoon/style.css";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  CardSensor,
  CardTens,
  CardSecuencial,
  CardRowing,
  CardNeuro,
  CardCardio,
  CardVideos,
  CardGeneral,
  CardMarcha,
  CardBike,
  CardFlexibilidad,
  CardFiles,
  CardAdvertencia,
  CardDescanso,
  CardBipedestacion,
  CardWalkfes,
  CardBiofeedback,
  CardCicloergometro,
  CardTreadmill,
  CardEvaluation,
} from "./cards";
import { PaddingContainer } from "../sessionTherapist.styles";

const SessionsDetail = (props) => {
  const { t } = useTranslation("protocols");

  const renderCard = (e, i) => {
    switch (e.mode.toLowerCase()) {
      case "sensor":
        return <CardSensor key={i} data={e} />;
      case "graphic":
      case "secuencial":
        return <CardSecuencial key={i} data={e} />;
      case "march":
        return <CardMarcha key={i} data={e} />;
      case "tens":
        return <CardTens key={i} data={e} />;
      case "records":
        return <CardFiles key={i} data={e} />;
      case "warnings":
        return <CardAdvertencia key={i} data={e} />;
      case "pause":
        return <CardDescanso key={i} data={e} />;
      case "bipedestacion":
        return <CardBipedestacion key={i} data={e} />;
      case "flexibility":
        return <CardFlexibilidad key={i} data={e} />;
      case "bike":
        return <CardBike key={i} data={e} />;
      case "rowing":
        return <CardRowing key={i} data={e} />;
      case "neuromodulation":
        return <CardNeuro key={i} data={e} />;
      case "cardiovascular":
        return <CardCardio key={i} data={e} />;
      case "video":
        return <CardVideos key={i} data={e} />;
      case "general":
        return <CardGeneral key={i} data={e} />;
      case "biofeedback":
        return <CardBiofeedback key={i} data={e} />;
      case "walkfes":
        return <CardWalkfes key={i} data={e} />;
      case "cicloergometro":
        return <CardCicloergometro key={i} data={e} />;
      case "treadmill":
        return <CardTreadmill key={i} data={e} />;
      case "evaluations":
        return <CardEvaluation key={i} data={e} />;

      default:
        return (
          <Grid
            key={i}
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            className="card-records mb-3"
          >
            <Grid item xs={3} container direction="row" justifyContent="center" alignItems="center">
              <div className="icon mx-auto">
                <span className={e.icon}></span>
              </div>
            </Grid>
            <Grid item xs={8}>
              <h6 className="my-3 fz-16">{e.mode}</h6>
              <h6 className="mb-2 fz-16 color-primario">{e.name}</h6>
              <h6 className="m-0 color-primario">{t("desc")}</h6>
              <hr className="my-1"></hr>
              <p className="m-0 fz-14 color-sub-text mb-4">{e.desc}</p>
            </Grid>
          </Grid>
        );
    }
  };

  return (
    <Grid className=" pb-2 pt-3 ">
      {props.trainings.length !== 0 && (
        <PaddingContainer>{props.trainings.map((e, i) => renderCard(e, i))}</PaddingContainer>
      )}
    </Grid>
  );
};

export default SessionsDetail;
