import React from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import Geocode from "react-geocode";
import { TextField } from "@material-ui/core";

import { InputBorder } from "trainfes-components-library";

// cambiar apikey tambien en index.html
Geocode.setApiKey(process.env.REACT_APP_MAPS_APIKEY);
Geocode.setLanguage("en");
Geocode.setRegion("es");

const Places = React.forwardRef((props, ref) => {
	const getParsingData = (result) => {
		return new Promise((resolve) => {
			let addressComplete = result.formatted_address || "";
			let address = null;
			let number = null;
			let provincia = null;
			let commune = null;
			let country = null;
			let pc = null;
			let region = null;
			result.address_components.forEach((e) => {
				e.types.forEach((r) => {
					if (r === "route") address = e.long_name;
					else if (r === "street_number") number = e.long_name;
					else if (r === "administrative_area_level_3") commune = e.long_name;
					else if (r === "administrative_area_level_2") provincia = e.long_name;
					else if (r === "administrative_area_level_1") region = e.long_name;
					else if (r === "country") country = e.long_name;
					else if (r === "postal_code") pc = e.long_name;
				});
			});

			let data_address = {
				address,
				number,
				region,
				commune,
				country,
				pc,
				provincia,
				state: commune ? commune : provincia,
				addressComplete,
			};

			resolve(data_address);
		});
	};

	const getResults = (address) => {
		return new Promise((resolve, reject) => {
			Geocode.fromAddress(address).then(
				(response) => resolve(response.results[0]),
				(error) => reject(error)
			);
		});
	};

	const handleSelect = async (addrs) => {
		getParsingData(await getResults(addrs))
			.then((latLng) => {
				if (props.onChangeCoords !== undefined) props.onChangeCoords(latLng);
			})
			.catch((err) => console.log(err));
	};

	const setAddress = (str) => {
		if (props.onChangeAddress !== undefined) props.onChangeAddress(str);
	};

	return (
		<div disabled={props.disabled} ref={ref}>
			<PlacesAutocomplete
				value={props.value}
				onChange={props.onChange ? props.onChange : (c) => setAddress(c)}
				onSelect={props.onChange ? props.onChange : handleSelect}
				disabled={props.disabled}
			>
				{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
					<div
						style={{ position: "relative" }}
						className="w-100"
						disabled={props.disabled}
					>
						{/*<form autocomplete="off" className="w-100">*/}
						<div className="relative w-100">
							{/*  TODO: Cambiar solamente por componente InputBorder */}
							{/*  TODO: Borrar propiedad newInput  */}
							{props.newInput ? (
								<InputBorder
									label={props.label}
									id="outlined-margin-dense4"
									disabled={props.disabled}
									{...getInputProps({
										placeholder: props.placeholder,
									})}
								/>
							) : (
								<TextField
									className="w-100 mb-4"
									label={props.label}
									id="outlined-margin-dense4"
									margin="dense"
									variant="outlined"
									disabled={props.disabled}
									{...getInputProps({
										placeholder: props.placeholder,
									})}
								/>
							)}
						</div>
						{/*</form>*/}

						<div
							className="autocomplete-dropdown-container"
							style={{
								marginBottom: 30,
								position: "absolute",
								top: 60,
								background: "#FAFAFA",
								zIndex: 9999999,
							}}
						>
							{loading && <div>Cargando...</div>}
							{suggestions.map((suggestion, i) => {
								const className = suggestion.active
									? "suggestion-item--active"
									: "suggestion-item";
								const style = suggestion.active
									? {
										backgroundColor: "#fafafa",
										cursor: "pointer",
										padding: 10,
									}
									: {
										backgroundColor: "#eee",
										cursor: "pointer",
										padding: 10,
									};
								return (
									<div
										key={i}
										{...getSuggestionItemProps(suggestion, {
											className,
											style,
										})}
									>
										<span>{suggestion.description}</span>
									</div>
								);
							})}
						</div>
					</div>
				)}
			</PlacesAutocomplete>
		</div>
	);
});

Places.defaultProps = {
	value: "",
	label: "Dirección",
	placeholder: "Dirección ...",
	disabled: false,
};

Places.displayName = "Places";

export default Places;
