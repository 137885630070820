import styled from "styled-components";

export const PreviewContainer = styled.section`
    background-color: #F3F6FD;
    height: calc(100vh - 45px);
    overflow: auto;
`;

export const ModalPreviewContainer = styled.div`
    background-color: white;
    max-width: 700px;
    border-radius: 4px;
    width: 100%;
    margin: auto;
    padding: 20px 15px 15px 15px;
    min-height: calc(100vh - 45px);
`;