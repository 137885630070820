import React from "react";
import styled from "styled-components";

export const ImageCard = ({ data, onClick, selectedImage, t }) => {
  const active = () => {
    if (!selectedImage || !data.image) return false;
    return selectedImage.image === data.image;
  };

  return (
    <Container onClick={onClick} active={active()}>
      <Image image={data.image} />
      <NameContainer>
        <Name>
          {data.name} {data.position === "left" && t("left_abr")}
          {data.position === "right" && t("right_abr")}
        </Name>
      </NameContainer>
    </Container>
  );
};

const NameContainer = styled.div`
  height: 24px;
  display: grid;
  place-items: center;
`;

const Name = styled.span`
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  text-transform: capitalize;
  color: #281b65;
  display: flex;
  justify-content: center;
  line-height: 1;
`;

const Image = styled.div`
  background: url(${({ image }) => image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  max-height: 85px;
  width: 100%;
`;

const Container = styled.div`
  background: ${({ active }) => (active ? "#97b8ff" : "#fff")};
  border-radius: 4px;
  cursor: pointer;
  height: 120px;
  padding: 8px;
  width: 100px;
`;
