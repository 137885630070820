import React, { Suspense } from "react";
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import * as serviceWorker from "./services/serviceWorker";
import Login from "./layouts/login";
import StaticSite from "./layouts/staticSite";
import NoMobile from "./layouts/noMobile";
import { Middleware, MiddlewareTokenParams } from "./lib/router";
import Layout from "./layouts/layout";
import LayoutTeleton from "./layouts/orgs/teleton/teleton.layout";
import { getToken, closeSession } from "./lib/router";
import axios from "axios";
import endpoint from "./services/endpoint";
import { baseTheme } from "./mui_custom_theme.js";
import { ThemeProvider } from '@mui/material/styles';

import { CookiesProvider } from "react-cookie";
import { Provider } from "react-redux";
import { store } from "./store";
import "./services/i18n";
import Qualification from "./layouts/qualification";

import CacheBuster from "react-cache-buster";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "react-notifications-component/dist/theme.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";

axios.interceptors.request.use(
  async (config) => {
    config.baseURL = endpoint;
    config.headers.Authorization = `Bearer ${getToken()}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 403) closeSession();
    return Promise.reject(error.response);
  }
);

// Create a client
const queryClient = new QueryClient();

// TODO: Get the version dinamically.
const App = () => {
  return (
    <CacheBuster
      currentVersion={"1.1.4"}
      isEnabled={true} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      // loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
    >
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <div style={{ position: "relative" }}>
            <Suspense fallback="">
              <CookiesProvider>
                <ThemeProvider theme={baseTheme}>
                  {/* <ReactNotification /> */}
                  <BrowserRouter getUserConfirmation={() => { }}>
                    <Switch>
                      <Route exact path="/login" component={Login}></Route>
                      <Route exact path="/agenda" component={StaticSite}></Route>
                      <Route exact path="/noMobile" component={NoMobile}></Route>
                      <Route
                        exact
                        path="/calificar"
                        component={Qualification}
                      ></Route>
                      <Route
                        path="/o"
                        component={() => MiddlewareTokenParams(LayoutTeleton)}
                      />
                      <Route path="/" component={() => Middleware(Layout)} />
                    </Switch>
                  </BrowserRouter>
                </ThemeProvider>
              </CookiesProvider>
            </Suspense>
          </div>
        </QueryClientProvider>
      </Provider>
    </CacheBuster>
  );
};

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
