import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Drawer, Button, List, ListItem } from '@material-ui/core';
import '../assets/img/icomoon/style.css';
import { Link } from 'react-router-dom'



const useStyles = makeStyles({
  list: {
    width: 75,
  },
  fullList: {
    width: 'auto',
  },
});

export default function TemporaryDrawer() {
  const classes = useStyles();
  const [state, setState] = React.useState({

    left: false

  });

  const toggleDrawer = (anchor, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = anchor => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
        <List>
            <ListItem>
                <ul className="nav flex-column ml-3">
                    <li className="nav-item mt-100">
                        <Link to="/createPatient" style={{ textDecoration: "none" }}>
                        <span className="icon-patient color-primario icon-menu"></span>
                        </Link>
                    </li>
                    <li className="nav-item mt-100">
                        <Link to="/therapist" style={{ textDecoration: "none" }}>
                        <span className="icon-training color-primario icon-menu"></span>
                        </Link>
                    </li>
                </ul>
            </ListItem>
        </List>
    </div>
  );

  return (
    <div>
      {['left'].map(anchor => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button>
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
