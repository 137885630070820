import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { connect } from '../../store'
import { useTranslation } from 'react-i18next';
import PublishIcon from '@material-ui/icons/Publish';
import './style.css'

const UploadFiles = (props) => {
    const { t } = useTranslation('global')
    const [files, setFiles] = useState([]);

    const upload = (data) => {
        if (props.formats) {
            if ( !props.formats.includes(data.type) ) {
                return props.notify({ type: "error", title: "Error", text: t("error.accepted_formats") });
            }
        }

        let f = Object.assign([], files);
        data['id'] = Math.random().toString();
        f.push(data);
        setFiles(f);
    }

    const removeFile = item => {
        let d = Object.assign([], files);
        d.splice(d.findIndex(e => e.id === item.id), 1);
        setFiles(d);
    }

    useEffect(() => {
        if (props.onChange !== undefined) props.onChange(files);
    }, [files])

    return (
        <Grid container direction="row">
            <p className="title-calendar mb-3">{props.title == null ? t('uploadFiles.title') : props.title}</p>
            <Grid container className="head-table">
                <Grid item xs={10} container direction="row" justifyContent="flex-start" alignItems="center" className="pl-4">
                    <p className="fz-14 color-sub-text m-0">{t('select_files')}</p>
                </Grid>
                <Grid item xs={2} container direction="row" justifyContent="center" alignItems="center">
                    <input type="file" style={{ display: "none" }} id="ioop" onChange={(c) => upload(c.target.files[0])} accept={ props.accept }/>
                    <label htmlFor="ioop" className="bg-purple text-white btn-upFiles" >
                        <PublishIcon />
                    </label>
                </Grid>
            </Grid>
            <Grid container direction="row" className="">
                {files.map((e, i) => {
                    return (
                        <Grid container className="border-table-records py-2" key={i}>
                            <Grid className="px-4" item xs={12} md={10} container direction="row" justifyContent="flex-start" alignItems="center">
                                {e.name}
                            </Grid>
                            <Grid item xs={12} md={2} container direction="row" justifyContent="center" alignItems="center">
                                <button className="btn-carrusel d-flex align-items-center justify-content-center" onClick={() => removeFile(e)}><span className="icon-trash"></span></button>
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
        </Grid>

    )

}

UploadFiles.defaultProps = {
    title: null,
}

export default connect(UploadFiles);