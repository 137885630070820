import React from "react";
import styled from "styled-components";
import { CheckIcon, CrossTableIcon } from "../../../../assets/icons";

export const TableSpasms = ({ t }) => {
  return (
    <TableContainer>
      <Row>
        <CellHeader background="#F8F8F8"></CellHeader>
        <CellHeader>{t("tableSpasms.level")}</CellHeader>
        <CellHeader>{t("tableSpasms.detention")}</CellHeader>
        <CellHeader>{t("tableSpasms.recovery")}</CellHeader>
        <CellHeader>{t("tableSpasms.back")}</CellHeader>
        <CellHeader>{t("tableSpasms.restart")}</CellHeader>
      </Row>
      <Row>
        <Cell background="#EFECFC" fz="9px">
          {t("tableSpasms.minus")}
        </Cell>
        <Cell>0</Cell>
        <Cell>
          <CrossTableIcon />
        </Cell>
        <Cell>
          <CrossTableIcon />
        </Cell>
        <Cell>
          <CrossTableIcon />
        </Cell>
        <Cell>
          <CrossTableIcon />
        </Cell>
      </Row>

      <Row>
        <Cell background="#E4DEFC"></Cell>
        <Cell>1</Cell>
        <Cell>5</Cell>
        <Cell>2</Cell>
        <Cell>
          <CrossTableIcon />
        </Cell>
        <Cell>
          <CrossTableIcon />
        </Cell>
      </Row>

      <Row>
        <Cell background="#DAD2FB"></Cell>
        <Cell>2</Cell>
        <Cell>4</Cell>
        <Cell>3</Cell>
        <Cell>
          <CrossTableIcon />
        </Cell>
        <Cell>
          <CrossTableIcon />
        </Cell>
      </Row>

      <Row>
        <Cell background="#CFC4FB"></Cell>
        <Cell>3</Cell>
        <Cell>3</Cell>
        <Cell>4</Cell>
        <Cell>
          <CheckIcon className="__blue" />
        </Cell>
        <Cell>
          <CrossTableIcon />
        </Cell>
      </Row>

      <Row>
        <Cell background="#C3B4FD"></Cell>
        <Cell>4</Cell>
        <Cell>2</Cell>
        <Cell>5</Cell>
        <Cell>
          <CheckIcon className="__blue" />
        </Cell>
        <Cell>
          <CrossTableIcon />
        </Cell>
      </Row>

      <Row>
        <Cell background="#AF9CF9">{t("tableSpasms.maxim")}</Cell>
        <Cell>5</Cell>
        <Cell>1</Cell>
        <Cell>5</Cell>
        <Cell>
          <CheckIcon className="__blue" />
        </Cell>
        <Cell>
          <CheckIcon className="__blue" />
        </Cell>
      </Row>
    </TableContainer>
  );
};

const TableContainer = styled.div`
  padding-bottom: 20px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  background: #f8f8f8;
  width: fit-content;
`;

const Cell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  width: 90px;
  font-size: ${({ fz = "10px" }) => fz};
  height: ${({ h = "43px" }) => h};
  text-align: center;
  font-weight: ${({ fw = "500" }) => fw};
  background-color: ${({ background = "transparent" }) => background};
  border: 1px solid rgba(241, 241, 241, 0.7);

  & > svg {
    fill: #fdad34;
  }

  & > .__blue {
    fill: #281b65;
  }
`;

const CellHeader = styled(Cell)`
  font-size: 12px;
  height: 65px;
  font-weight: 900;
  background-color: ${({ background = "#EFECFC" }) => background};
`;
