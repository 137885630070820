import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "./style.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const PDFThumnail = (props) => {
  const [, setNumPages] = useState(null);
  const [pageNumber] = useState(1);
  const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);
  const onDocumentLoadError = (err) => console.log("Err ->", err);

  return (
    <div className={`${props.className} file-container`}>
      <Document
        file={props.src}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={onDocumentLoadError}
      >
        <Page pageNumber={pageNumber} />
      </Document>
    </div>
  );
};

PDFThumnail.defaultProps = {
  className: "",
};
