import React, { Component } from "react";
import TimePicker from "../timePicker";
import { withTranslation } from "react-i18next";

import { Container, Row, Col } from "trainfes-components-library";

import { TextField } from '@mui/material';

import * as M from "./styles/Modos.styles";

var currentFile = null;
var currentName = null;

var self;
class Pause extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      description: "",
      tiempo_trabajo: null,
      files: [],
      video: "",
      type: true,
      channels: [],

      technical_name: "",
      public_name: "",
    };
    this.currentName = React.createRef();
  }

  values() {
    let data = Object.assign({}, this.state);
    data["config_channels"] = this.state.channels.filter((e) => e.index !== 100);
    if (this.props.values !== undefined) this.props.values(data);
  }

  componentDidUpdate() {
    this.values();
  }

  componentDidMount() {
    self = this;
    if (this.props.data.params && Object.keys(this.props.data.params).length !== 0) {
      const { name, description, tiempo_trabajo, technical_name, public_name } =
        this.props.data.params;
      this.setState({
        name,
        description,
        tiempo_trabajo,
      });

      if (name && !technical_name) {
        this.setState({
          technical_name: name,
        });
      }

      if (technical_name) {
        this.setState({
          technical_name,
        });
      }

      if (public_name) {
        this.setState({
          public_name,
        });
      }
    }
  }

  removeFile(item) {
    this.state.files.splice(
      this.state.files.findIndex(
        (i) => i.name === item.name && i.size === item.size && i.lastModified === item.lastModified
      ),
      1
    );
    this.setState({ files: this.state.files });
  }

  pushFile() {
    const item = {};
    let name = this.currentName.current.querySelector("input");
    if (currentFile) {
      item["_id"] = Math.random().toString(36).slice(-8);
      item["file"] = currentFile;
      item["name"] = currentName;
      name.value = "";
      this.setState({
        files: [...this.state.files, item],
      });
    }
  }

  setPublicName(e) {
    self.setState({ public_name: e });
  }

  setTechnicalName(e) {
    self.setState({ technical_name: e });
  }

  setDescription(e) {
    self.setState({ description: e });
  }

  render() {
    const { t } = this.props;
    return (
      <M.ScreenContainer>
        <Container>
          <Row cols={12}>
            <Col xs={12}>
              <M.RecordsVideoForm>
                <div>
                  <TextField
                    fullWidth
                    required
                    label={t("tech_name")}
                    value={this.state.technical_name}
                    onChange={({ target }) => this.setTechnicalName(target.value)}
                  />
                  <TextField
                    fullWidth
                    label={t("public_name")}
                    value={this.state.public_name}
                    onChange={({ target }) => this.setPublicName(target.value)}
                  />
                </div>
                <TextField
                  label={t("description")}
                  fullWidth
                  multiline
                  rows={4}
                  value={this.state.description}
                  onChange={({ target }) => this.setDescription(target.value)}
                />
              </M.RecordsVideoForm>
            </Col>
          </Row>

          <Row cols={12} style={{ marginTop: 48 }}>
            <Col xs={6}>
              <M.Box padding={"10px 15px 0px 15px"} style={{ width: 335 }}>
                <M.GridTitle>{t("timePause")}</M.GridTitle>
                <M.SliderContainer margin="0">
                  <TimePicker
                    data={this.state.tiempo_trabajo}
                    seconds={true}
                    minutes={true}
                    values={(value) => this.setState({ tiempo_trabajo: value })}
                  />
                </M.SliderContainer>
              </M.Box>
            </Col>
          </Row>
        </Container>
      </M.ScreenContainer>
    );
  }
}

export default withTranslation("sessionStudio")(Pause);
