import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Axios from "axios";

import { Container, Row, Col, Dropdown } from "trainfes-components-library";
import { TextField } from '@mui/material';

import * as M from "./styles/Modos.styles";

var self;
class evaluations extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      description: "",
      evaluaciones: [],
      evaluationCurrent: null,

      public_name: "",
      technical_name: "",
    };
    this.currentName = React.createRef();
  }

  values() {
    let data = Object.assign({}, this.state);
    if (this.props.values !== undefined) this.props.values(data);
  }

  componentDidMount() {
    self = this;
    this.getEvaluations();
    this.values();
    if (this.props.data.params && Object.keys(this.props.data.params).length !== 0) {
      const { name, description, evaluationCurrent, technical_name, public_name } =
        this.props.data.params;
      this.setState({
        name,
        description,
        evaluationCurrent,
      });

      if (name && !technical_name) {
        this.setState({
          technical_name: name,
        });
      }

      if (technical_name) {
        this.setState({
          technical_name,
        });
      }

      if (public_name) {
        this.setState({
          public_name,
        });
      }
    }
  }

  componentDidUpdate() {
    this.values();
  }

  getEvaluations() {
    Axios.get(`/evaluations`)
      .then((response) => {
        this.setState({
          evaluaciones: response.data.data.map((e) => {
            e["label"] = e.name;
            e["value"] = e.name;
            return e;
          }),
        });
      })
      .catch((err) => console.log(err));
  }

  GetType(str) {
    switch (str) {
      case "string":
        return "Texto";
      case "number":
        return "Numero";
      case "textarea":
        return "Observación";
      case "bool":
        return "Si/No";

      default:
        return "Texto";
    }
  }

  setPublicName(e) {
    self.setState({ public_name: e });
  }

  setTechnicalName(e) {
    self.setState({ technical_name: e });
  }

  setDescription(e) {
    self.setState({ description: e });
  }

  render() {
    const { t } = this.props;

    console.log(this.state.evaluationCurrent);

    const currentValueOption =
      this.state.evaluationCurrent &&
      this.state.evaluaciones.filter(
        (item) => item.value === this.state.evaluationCurrent.value
      )[0];

    return (
      <M.ScreenContainer>
        <Container>
          <Row cols={12}>
            <Col xs={6}>
              <M.SequentialForm>
              <TextField
                  fullWidth
                  required
                  label={t("tech_name")}
                  value={this.state.technical_name}
                  onChange={({ target }) => this.setTechnicalName(target.value)}
                />
                <TextField
                  fullWidth
                  label={t("public_name")}
                  value={this.state.public_name}
                  onChange={({ target }) => this.setPublicName(target.value)}
                />
                <TextField
                  label={t("description")}
                  fullWidth
                  multiline
                  rows={4}
                  value={this.state.description}
                  onChange={({ target }) => this.setDescription(target.value)}
                />
              </M.SequentialForm>
            </Col>

            <Col xs={6}>
              <Dropdown
                placeholder={
                  <>
                    {t("select_evaluations")}
                    <span className="__red">*</span>
                  </>
                }
                value={currentValueOption}
                onChange={(c) => this.setState({ evaluationCurrent: c })}
                options={this.state.evaluaciones}
              />

              {this.state.evaluationCurrent == null ? null : ( // <p style={{ fontSize: 12, textAlign: "center" }}>{t("evaluation_not_selected")}</p>
                <div style={{ marginTop: 18, marginLeft: 10 }}>
                  <div>
                    <p>
                      <strong>{t("name")}:</strong> {this.state.evaluationCurrent.name}
                    </p>
                  </div>
                  <div>
                    <p>
                      <strong>{t("description")}:</strong> {this.state.evaluationCurrent.desc}
                    </p>
                  </div>
                  <div>
                    <p>
                      <strong>{t("instructions")}:</strong>{" "}
                      {this.state.evaluationCurrent.instructions || "-"}
                    </p>
                  </div>
                  <div>
                    <p>
                      <strong>{t("category")}:</strong> {this.state.evaluationCurrent.cat.name}
                    </p>
                  </div>
                  <div>
                    <p style={{ marginBottom: 0 }}>
                      <strong>{t("variables")}:</strong>
                    </p>
                    <ul>
                      {this.state.evaluationCurrent &&
                        this.state.evaluationCurrent.variables.map((e, i) => {
                          return (
                            <li key={i}>
                              {e.name} ({t("type")}:{this.GetType(e.type)})
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </M.ScreenContainer>
    );
  }
}

export default withTranslation("sessionStudio")(evaluations);
