import styled from "styled-components";
import { SequentialForm } from "../styles/Modos.styles";

export const BipedestacionForm = styled(SequentialForm)`
  margin-bottom: 26px;
`;

export const Params = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 13px;
  margin-bottom: 45px;
`;
