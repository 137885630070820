import React from "react";
import { ModalFullscreen } from "trainfes-components-library";

import * as S from "./ModalExtended.styles";
import { ModalCloseIcon } from "../../assets/icons";

export default function FullScreenDialog({
  title,
  btnText,
  btnPreviewText,
  callback,
  open,
  setOpen,
  closedSave = false,
  children,
  onClose,
  preview,
  handleClickPreview
}) {
  const handleClickSave = () => {
    if (typeof callback === 'function') {
      callback();
    }

    if (closedSave) {
      setOpen(false);
    }
  };

  return (
    <ModalFullscreen isOpen={open} handleClose={onClose}>
      <S.Toolbar>
        <S.Icon onClick={onClose}>
          <ModalCloseIcon />
        </S.Icon>
        <S.Title>{title}</S.Title>
        {preview && <S.SaveButton onClick={handleClickPreview}>{btnPreviewText}</S.SaveButton>}
        <S.SaveButton onClick={handleClickSave}>{btnText}</S.SaveButton>
      </S.Toolbar>
      {children}
    </ModalFullscreen>
  );
}
