import React, { useEffect, useReducer } from "react";
import { connect } from "../../store";
import { useTranslation } from "react-i18next";

import { 
  Container, 
  Row, 
  Col, 
  Button,
  ButtonCircle,
  Modal,
  Dropdown,
  SearchBar,
  TableServices 
} from "trainfes-components-library"

import * as Hook from "../../hooks";

import { CLEAR_STATE, EDITING_STATE, initialState, reducer, SET_FIELDS, SET_OPEN_MODAL } from "./reducer";
import { formValidation } from "./subsidiary.utils";

import CardLayoutPlatform from "../../components/CardLayoutPlatform";
import { ModalNewSubsidiary } from "./components/ModalNewSubsidiary";

import * as S from "./subsidiary.styles"
import { TableHeader } from "../ServicesMaintainerPage/specificServices.styles";
import { filterValidation } from "../../lib/textValidation";
import { Delete, Edit } from "../../assets/icons/pages";

const Subsidiary = (props) => {
  const { t } = useTranslation("maintainers");
  
  const [state, dispatch] = useReducer(reducer, initialState);

  const { subsidiariesQuery, addNewSubsidiary, editSubsidiary, deleteSubsidiary } = Hook.useGetSubsidiaries()

  const handleModals = ( modal, value ) => dispatch({ type: SET_OPEN_MODAL, payload: { modal, value }})

  const onCreateSubsidiary = () => {
    if ( state.isPosting ) return;
    dispatch({ type: SET_FIELDS, payload: { field: "isPosting", value: true } })

    if ( !formValidation( state, t, props.notify ) ) return dispatch({ type: SET_FIELDS, payload: { field: "isPosting", value: false } })

    let data = {
      name:           state.name,
      address:        state.address,
      address2:       state.address2,
      country:        state.country,
      comune:         state.commune,
      region:         state.region,
      email:          state.email,
      phone1:         state.phone_one,
      phone2:         state.phone_two,
      phone3:         state.phone_three,
      specialities:   state.specialities.map((e) => e._id),
      representative: state.representative,
    };

    if ( state.isEditing ) return onEdit( state.idCurrent, data )
    
    try {
      addNewSubsidiary( data )
      
      dispatch({ type: CLEAR_STATE  })
      
      return props.notify({
        type: "success",
        title: t("subsidiary.subsidiary"),
        text: t("notify.success"),
      });
    } catch (error) {
      return props.notify({ type: "error", title: t("subsidiary.subsidiary"), text: t("notify.error") });
    }
  };

  const onEdit = ( id, data ) => {
    if ( state.isPosting ) return;
    dispatch({ type: SET_FIELDS, payload: { field: "isPosting", value: true } })
    
    try {
      editSubsidiary( { id, data: { ...data, representative: data.representative.value || data.representative } } )
      
      dispatch({ type: CLEAR_STATE  })

      return props.notify({
        type: "success",
        title: t("subsidiary.subsidiary"),
        text: t("notify.success"),
      });
    } catch (error) {
      return props.notify({ type: "error", title: t("subsidiary.subsidiary"), text: t("notify.error") });
    }
  }

  const onDeleteSubsidiary = () => {
    if ( state.isPosting ) return;
    dispatch({ type: SET_FIELDS, payload: { field: "isPosting", value: true } })

    try {
      deleteSubsidiary( state.idCurrent )

      dispatch({ type: CLEAR_STATE })

      return props.notify({
        type: "success",
        title: t("subsidiary.subsidiary"),
        text: t("notify.success"),
      });
      
    } catch (error) {
      return props.notify({ type: "error", title: t("subsidiary.subsidiary"), text: t("notify.error") })
    }
  }


  const handleOpenDelete = ( id ) => {
    dispatch({ type: SET_FIELDS, payload: { field: "idCurrent", value: id } })
    dispatch({ type: SET_FIELDS, payload: { field: "removeModal", value: true } })
  }

  const columns = [
    { key: "name",      text: t("subsidiary.subsidiary"),     alignContent: "center", alignTitle: "center" },
    { key: "encargado", text: t("subsidiary.representative"), alignContent: "center", alignTitle: "center" },
    { key: "country",   text: t("subsidiary.country"),        alignContent: "center", alignTitle: "center" },
    { key: "region",    text: t("subsidiary.region"),         alignContent: "center", alignTitle: "center" },
    { key: "comune",    text: t("subsidiary.commune"),        alignContent: "center", alignTitle: "center" },
    { key: "email",     text: t("subsidiary.mail"),           alignContent: "center", alignTitle: "center" },
    // { key: "phone1",    text: t("subsidiary.phone_1"),        alignContent: "center", alignTitle: "center" },
    {
      key: "controls", text: t("maintainer.actions"), type: "custom", alignContent: "center", alignTitle: "center",
      elem: (payload) => (
        <S.IconsContainer>
          <Edit onClick={ () => dispatch({ type: EDITING_STATE, payload }) } />
          <Delete onClick={ () => handleOpenDelete( payload._id ) } />
        </S.IconsContainer>
      ),
    },
  ];

  const filterOptions = [
    { label: t("subsidiary.subsidiary"),      value: "name",      key: "name"       },
    { label: t("subsidiary.representative"),  value: "encargado", key: "encargado"  },
    { label: t("subsidiary.country"),         value: "country",   key: "country"    },
    { label: t("subsidiary.region"),          value: "region",    key: "region"     },
    { label: t("subsidiary.commune"),         value: "comune",    key: "commune"    },
    { label: t("subsidiary.mail"),            value: "email",     key: "mail"       },
    // { label: t("subsidiary.phone_1"),         value: "phone1",    key: "phone_1"    },
  ];

  useEffect(() => {
    handleFilter();
  }, [ state.inputFilter, subsidiariesQuery.data ]);

  const handleFilter = () => {
    let data = Object.assign( [], subsidiariesQuery.data );

    if (state.inputFilter === "") {
      return dispatch({
        type: SET_FIELDS,
        payload: {
          field: "filteredSubsidiaries",
          value: data.reverse(),
        },
      });
    }
    
    return dispatch({
            type: SET_FIELDS,
            payload: {
              field: "filteredSubsidiaries",
              value: data.reverse().filter((e) => {
                return filterValidation( e[ state.dropdownFilter ] ).includes( filterValidation( state.inputFilter ) )
              } ),
            },
          })
  }

  if (subsidiariesQuery.error) return "An error has occurred: " + subsidiariesQuery.error.message;

  return (
    <>
    <Container>
          <Row cols={12}>
            <Col xs={12}>
              <CardLayoutPlatform
                noPaddingBottom
                title={ t("subsidiary.title") }
                loading={ subsidiariesQuery.isLoading }
                floatButton={
                  <ButtonCircle onClick={ () => handleModals( "newSubsidiaryModal", true ) } />
                }
              >
                <TableHeader>
                  <p>{t("maintainer.search_by")}</p>
                  <Dropdown
                    height={38}
                    options={filterOptions}
                    value={ filterOptions.filter( (e) => e.value === state.dropdownFilter)[0] }
                    onChange={(e) =>
                      dispatch({ type: SET_FIELDS, payload: { field: "dropdownFilter", value: e.value } })
                    }
                  />
                  <SearchBar
                    borderRadius="6px"
                    height="38px"
                    placeholder={t("maintainer.search")}
                    onChange={(e) =>
                      dispatch({ type: SET_FIELDS, payload: { field: "inputFilter", value: e.target.value } })
                    }
                  />
                </TableHeader>
              
                <TableServices
                  data={ state.filteredSubsidiaries }
                  columns={ columns }
                  searchKeys="title mode"
                  autoHeight={false}
                  perPage={10}
                  pagination={true}
                  filterSelect={false}
                  filterInput={false}
                  perPageSelect={false}
                  textEmptyData="Sin datos para mostrar"
                />
              </CardLayoutPlatform>
            </Col>
          </Row>
    </Container>

    <Modal
      title={
        state.isEditing
          ? t("subsidiary.edit_subsidiary")
          : t("subsidiary.new_subsidiary")
      }
      isOpen={state.newSubsidiaryModal}
      buttons={
        <>
          <Button
            type="ghost"
            label={t("maintainer.cancel")}
            onClick={ () => dispatch({ type: CLEAR_STATE }) }
          />
          <Button
            label={t("maintainer.save")}
            onClick={ () => onCreateSubsidiary() }
            disabled={ state.isPosting }
          />
        </>
      }
    >
      <ModalNewSubsidiary t={t} state={state} dispatch={dispatch} />
    </Modal>

    <Modal
      title={ t("maintainer.really_want_to_delete") }
      isOpen={ state.removeModal }
      buttons={
        <>
          <Button
            type="ghost"
            label={t("maintainer.cancel")}
            onClick={ () => dispatch({ type: CLEAR_STATE }) }
          />
          <Button
            label={t("maintainer.remove")}
            onClick={ () => onDeleteSubsidiary() }
            disabled={ state.isPosting }
          />
        </>
      }
    >
    </Modal>
    </>
  );
};

export default connect(Subsidiary);
