export const toInt = (obj = { hours: 0, minutes: 0, seconds: 0 }) => {
  let { hours, minutes, seconds } = obj;
  return hours * 3600 + minutes * 60 + seconds;
};

export const hoursToSeconds = (h) => {
  if (h === 0) return 0;
  else return parseInt(h) * 3600;
};

export const minutesToSeconds = (m) => {
  if (m === 0) return 0;
  else return parseInt(m) * 60;
};

// * Calcular segundos totales de formato obj {hours: 0 , minutes:0, seconds: 0}
export const getSeconds = (state) => {
  return state.seconds + state.minutes * 60 + state.hours * 3600 + 0;
};

// * Calcular segundos totales de formato str '00:00:00'
export const getSecondsFromString = (elem) => {
  const arr = elem.split(":");
  return getSeconds({ hours: Number(arr[0]), minutes: Number(arr[1]), seconds: Number(arr[2]) });
};

// * Transforma total de segundos a formato '00:00:00'
export const secondsToDate = (seconds) => {
  // return !isNaN(seconds) ? new Date(seconds * 1000).toISOString().slice(10, 19) : "00:00:00";
  return !isNaN(seconds) ? (Math.floor(seconds / 3600)) + ":" + ("0" + Math.floor(seconds / 60) % 60).slice(-2) + ":" + ("0" + seconds % 60).slice(-2) : "00:00:00";
};



// * Genera validación en modo secuencial. Para canales cuales tengan pulsos que se sobrepongan.
export const overlapingValidation = ( array ) => {
  if (array.length <= 1) return

  const newFormatArray = array.map((item) => {
    const newObject = { start: item.pos, end: (item.pos + item.seconds) }
    return newObject
  })

  return newFormatArray.some((item) => {
    let tempArray = newFormatArray.filter((elem) => elem !== item )
    return tempArray.some((position) => (item.start >= position.start) && (item.start < position.end) )
  })
}