import React from 'react'
import styled from 'styled-components'

import ColorPicker from "../../../components/color";

import { InputBorder, Textarea, Checkbox } from "trainfes-components-library"

import { SET_FIELDS } from '../reducer';

export const ModalNewSpeciality = ({ t, state, dispatch }) => {

    const setField = ( field, value ) => {
        dispatch({ type: SET_FIELDS, payload: { field, value } })
    }

    return (
        <Container>
            <br />
            <InputBorder 
                placeholder={ t("specialties.specialty") }
                value={ state.name }
                onChange={ ( e ) => setField( "name", e.target.value ) }
            />

            <ColorContainer>
                <p>Color</p>
                <ColorPicker color={ state.color } onChange={ ( v ) => setField( "color", v )} />
            </ColorContainer>

            <DescriptionTitle>{ t('maintainer.desc') }</DescriptionTitle>

            <Textarea 
                placeholder={""} 
                value={ state.description }
                onChange={ ( e ) => setField( "description", e.target.value ) }
            ></Textarea>

            <CheckboxContainer>
                <Checkbox id="color" name="color" checked={ state.isMedic } onChange={ (e) => setField( "isMedic", e.target.checked) }/>
                <label htmlFor="color" className='__label'>{ t('specialties.referrer_interconsultation') }</label>
            </CheckboxContainer>
        </Container>
    )
}


const Container = styled.div``;

const ColorContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: 25px 0;

    & > p {
        margin: 0;
        font-size: 14px;
        color: #281B65;
    }
`;

const CheckboxContainer = styled.div`
    & > p {
        margin: 0;
    }

    & > .__label {
        margin: 0 0 20px 40px;
        cursor: pointer;
    }
`;

const DescriptionTitle = styled.p`
    margin: 0;
    font-weight: 500;
    font-size: 12px;
    color: #281B65;
`;