import styled from "styled-components";

export const CardExercise = styled.div`
  & > .card-records {
    width: 100%;
    height: auto;
    background: #ffffff;
    box-shadow: none;
    border-radius: 4px;
    cursor: pointer;
    overflow: hidden;
    border: 1px solid #ced4da;
  }

  & > .card-records:hover {
    box-shadow: none;
    border: 1px solid #a89ed5;
  }

  & > .card-records p:nth-child(2) {
    color: #979797;
  }
`;

export const PaddingContainer = styled.div`
  padding-bottom: 150px;
`;

export const SearchContainer = styled.div`
    margin: 14px 14px 0 14px;
`;

export const NoItemsDisplay = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 400px;
  width: 100%;

  & > p {
    font-size: 14px;
    color: #281B65;
  }
`;

export const DropdownContainer = styled.div`
    margin: 18px 0 34px 0;
`;