import styled from "styled-components";
import { LazyLoadImage } from "react-lazy-load-image-component";

export const Container = styled.div`
  width: 200px;
  height: 200px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    border: 1px solid #6d59c9;
    filter: drop-shadow(15px 10px 30px #e7ecf4);
  }
`;

export const CardStyled = styled.div`
  position: relative;
  background: #ffffff;
  border-radius: 4px;
  border: ${({ active }) =>
    active ? "0.5px solid #6d59c9" : "0.5px solid #ced4da"};
  cursor: pointer;
  height: 200px;
  width: 200px;
  filter: ${({ active }) =>
    active ? "drop-shadow(15px 10px 30px #e7ecf4)" : "none"};

  &:hover,
  &:active {
    filter: drop-shadow(15px 10px 30px #e7ecf4);
    border: 0.5px solid #6d59c9;

    & > .__addRemoveButton,
    & > .__videoDuration {
      opacity: 1;
    }
  }

  & > .__addRemoveButton,
  & > .__videoDuration {
    opacity: 0;

    transition: opacity cubic-bezier(0.075, 0.82, 0.165, 1);

    &:hover {
      opacity: 1;
    }
  }
`;

export const Image = styled(LazyLoadImage)`
  border-radius: 4px 4px 0 0;
  object-fit: ${({ type }) => (type === 0 ? "cover" : "contain")};
`;

export const Info = styled.div`
  display: grid;
  grid-template-columns: 1fr 50px;
  place-items: center;

  height: 45px;
  border-top: 0.5px solid #ced4da;
`;

export const FileName = styled.p`
  color: #182024;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.850962px;
  margin: 0;
  padding: 0 0 0 14px;
  place-self: center flex-start;
`;

export const DeleteButton = styled.div`
  background-color: #fdad34;
  border-radius: 50%;
  cursor: pointer;
  display: grid;
  height: 21px;
  place-items: center;
  width: 21px;

  &:hover,
  &:active {
    background-color: #281b65;
  }
`;
