import React, { useState, useEffect } from "react";

import {
  CardStyled,
  DeleteButton,
  FileName,
  Image,
  Info,
} from "./FileCard.styles";

import ImgTest from "../../../../assets/img/test/img-test.png";
import styled from "styled-components";

import VideoPlayer from "../../../../components/player";

import { Document, Page, pdfjs } from "react-pdf";
import { Modal, Button } from "trainfes-components-library";
import { GarbageIcon, PlusIcon } from "../../../../assets/icons";

import LinesEllipsis from "react-lines-ellipsis";
import "react-lazy-load-image-component/src/effects/blur.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const FileCard = ({
  file,
  t,
  list,
  name = "Filename",
  img = ImgTest,
  type,
  videoDuration,
  onClick,
  scrollPosition,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [cover, setCover] = useState();
  const [imageBackground, setImageBackground] = useState(null);

  const getVideoCover = (file, seekTo = 0.0) => {
    return new Promise((resolve, reject) => {
      const videoPlayer = document.createElement("video");
      videoPlayer.setAttribute("src", URL.createObjectURL(file));
      videoPlayer.load();
      videoPlayer.addEventListener("error", (ex) =>
        reject("error when loading video file", ex)
      );
      videoPlayer.addEventListener("loadedmetadata", () => {
        if (videoPlayer.duration < seekTo) return reject("video is too short.");
        setTimeout(() => (videoPlayer.currentTime = seekTo), 200);
        videoPlayer.addEventListener("seeked", () => {
          const canvas = document.createElement("canvas");
          canvas.width = videoPlayer.videoWidth;
          canvas.height = videoPlayer.videoHeight;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
          ctx.canvas.toBlob((blob) => resolve(blob), "image/jpeg", 0.75);
        });
      });
    });
  };

  const getCoverFile = async (file) => {
    try {
      const cover = await getVideoCover(file, 1.5);
      setCover(cover);
      var urlCreator = window.URL || window.webkitURL;
      var imageUrl = urlCreator.createObjectURL(cover);
      setImageBackground(imageUrl);
    } catch (ex) {
      console.log("ERROR: ", ex);
    }
  };

  useEffect(() => {
    if (type === 0) getCoverFile(file);

    if (type === 2) setImageBackground(file.src);
  }, []);

  // eslint-disable-next-line no-unused-vars
  const [numPages, setNumPages] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const [showModal, setShowModal] = useState(false);

  const buttonClick = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  return (
    <>
      <CardStyled
        onClick={type !== 0 ? onClick : undefined}
        active={list.some((e) => e._id === file._id)}
      >
        {type === 0 && (
          <VideoDurationStyled className="__videoDuration">
            {videoDuration}
          </VideoDurationStyled>
        )}

        <AddRemoveButton className="__addRemoveButton">
          {list.some((e) => e._id === file._id) ? (
            <GarbageIcon />
          ) : (
            <PlusIcon />
          )}
        </AddRemoveButton>

        {type === 2 ? (
          <DocumentStyled
            file={file.src}
            onLoadError={console.error}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page pageNumber={pageNumber} />
          </DocumentStyled>
        ) : (
          <Image
            effect="blur"
            height={150}
            width={"100%"}
            scrollPosition={scrollPosition}
            onClick={type === 0 ? onClick : undefined}
            type={type}
            src={imageBackground || img}
          />
        )}

        <Info>
          <FileName onClick={() => true}>
            {
              <LinesEllipsis
                text={name}
                maxLine="2"
                ellipsis="..."
                trimRight
                basedOn="letters"
              />
            }
          </FileName>
          {type === 0 && (
            <Dots onClick={buttonClick}>
              <div className="__dot"></div>
              <div className="__dot"></div>
              <div className="__dot"></div>
            </Dots>
          )}
        </Info>
      </CardStyled>
      {type === 0 && (
        <Modal
          title={file.name}
          isOpen={showModal}
          handleClose={() => setShowModal(false)}
          buttons={
            <>
              <Button label={t("close")} onClick={() => setShowModal(false)} />
            </>
          }
        >
          <VideoPlayer
            provider={file.provider}
            src={file.src}
            cover={file.cover}
          />
        </Modal>
      )}
    </>
  );
};

const AddRemoveButton = styled.div`
  pointer-events: none;
  width: 45px;
  height: 45px;
  background: #281b65;
  position: absolute;

  top: calc(50% - (45px / 2));
  left: 50%;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;

  transform: translate(-50%, -50%);
  border-radius: 50%;

  & > svg {
    fill: white;
    width: 18px;
    height: 18px;
  }
`;

const VideoDurationStyled = styled.div`
  align-items: center;
  background: #281b65;
  border-radius: 0 4px 0 0;
  color: white;
  display: flex;
  font-size: 9px;
  height: 16px;
  justify-content: center;
  padding-top: 1px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 65.5px;
`;

const DocumentStyled = styled(Document)`
  height: 150px;
  overflow: hidden;
`;

export const Dots = styled(DeleteButton)`
  display: flex;
  justify-content: center;
  align-items: center;

  & > .__dot {
    width: 3px;
    height: 3px;
    background-color: white;
    margin: 0 1px;
  }
`;
