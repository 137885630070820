import styled from "styled-components";
import { SequentialForm } from "../styles/Modos.styles";

export const Params = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 13px;
`;

export const MusclesContainer = styled.div`
  margin-top: 63px;
`;

export const BikeForm = styled(SequentialForm)`
  margin-bottom: 33px;
`;

export const TabsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 22px;

  & > div {
    margin-right: 3px;
  }
`;

export const TableContainer = styled.div``;
