import React from "react";
import { Title } from "../../../../styles/Modos.styles";
import * as S from "../../TableMuscles.styles";

export const TableMuscles = ({ t }) => {
  const tableHeaders = [t("muscles"), 1, 2];
  const tableData = [
    {
      muscle: t("quadriceps"),
      1: <S.Check className="__blue" />,
      2: <S.Check className="__blue" />,
    },

    {
      muscle: t("hamstring"),
      1: <S.Check />,
      2: <S.Check />,
    },
    {
      muscle: t("tibialis_anterior"),
      1: <S.Check />,
      2: <S.Check />,
    },
  ];

  // const tableDataTwo = [
  //   {
  //     muscle: t("left_quadriceps"),
  //     1: <S.Check className="__blue" />,
  //     2: <S.Check className="__blue" />,
  //   },

  //   {
  //     muscle: t("left_hamstring"),
  //     1: <S.Check />,
  //     2: <S.Check />,
  //   },
  //   {
  //     muscle: t("left_anterior_tibial"),
  //     1: <S.Check />,
  //     2: <S.Check />,
  //   },
  // ];

  return (
    <>
      <Title>
        {t("muscles_to_work")} <span className="__red">*</span>
      </Title>
      <S.TableFlex>
        <S.Table>
          <S.RowHeader>
            {tableHeaders.map((header, i) => (
              <div key={i} className={`${header === 1 ? "__dark-bg" : null}`}>
                {header}
              </div>
            ))}
          </S.RowHeader>
          {tableData.map((row, i) => (
            <S.Row key={i}>
              <div>{row.muscle}</div>
              <div>{row[1]}</div>
              <div>{row[2]}</div>
            </S.Row>
          ))}
        </S.Table>
        {/* <S.Table>
          <S.RowHeader>
            {tableHeaders.map((header, i) => (
              <div key={i} className={`${header === 1 ? "__dark-bg" : null}`}>
                {header === 1 && 3}
                {header === 2 && 4}
                {header !== 1 && header !== 2 ? header : null}
              </div>
            ))}
          </S.RowHeader>
          {tableDataTwo.map((row, i) => (
            <S.Row key={i}>
              <div>{row.muscle}</div>
              <div>{row[1]}</div>
              <div>{row[2]}</div>
            </S.Row>
          ))}
        </S.Table> */}
      </S.TableFlex>
      <S.InfoText>
        <p>*{t("conf_suggested")}</p>
        <p>*{t("conf_optional")}</p>
      </S.InfoText>
      <S.ReferenceText>
        <strong>{t("this_table_is_not_intended")},</strong> {t("it_is_a_reference")}
      </S.ReferenceText>
    </>
  );
};
