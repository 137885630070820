import './style.css';

const generateXAxis = (m = [100, 90, 80, 70, 60, 50, 40, 30, 20, 10, 0]) => {
    let container = document.querySelector('#chart .xLineContainer');
    container.innerHTML = "";
    let o = 0;
    let t = 0;
    let f = mediaQuery(m.length - 1);

    while (o < m.length) {
        createDivP(t, m[o]);
        t = t + f;
        o++;
    }
}

const createDivP = (x, value) => {
    let container = document.querySelector('#chart .xLineContainer');
    let p = document.createElement('p');
    p.style.position = "absolute";
    p.style.top = (x) + "px";
    p.style.right = "10px";
    p.style.textAlign = "right";
    p.style.margin = "0";
    p.style.padding = "0";
    p.className = "value-y";
    p.innerText = value;
    container.appendChild(p);
}

const createPolygon = (e) => {
    let s = document.getElementById('contSvg');
    let svgNS = s.namespaceURI;
    let v = document.getElementById('v');
    let poly = document.createElementNS(svgNS, 'polygon');
    poly.setAttributeNS(null, "points", `${e.x},0 ${e.x},${e.last} ${e.w + e.x},${e.h} ${e.w + e.x},0`);
    poly.setAttribute("fill", e.color);
    v.appendChild(poly);
    // let pos = e.last > e.h ? e.last : e.h;
    createRectLabel({ title: e.title, opt: e, alt: 515 });
}

const createRect = (e) => {
    let s = document.getElementById('contSvg');
    let svgNS = s.namespaceURI;
    let v = document.getElementById('v');
    let rect = document.createElementNS(svgNS, 'rect');
    rect.setAttribute("x", e.x);
    rect.setAttribute("y", e.y);
    rect.setAttribute("width", e.w);
    rect.setAttribute("height", e.h);
    rect.setAttribute("fill", e.color);
    v.appendChild(rect);
    createRectLabel({ title: e.title, opt: e, alt: 515 });
}

const createRectLabel = (e) => {
    let date = new Date();
    let id = 'text' + date.getTime();
    let s = document.getElementById('contSvg');
    let svgNS = s.namespaceURI;
    let v = document.getElementById('y');
    let t = document.createElementNS(svgNS, 'text');
    t.setAttributeNS(null, "x", e.opt.x + (e.opt.w / 2));
    t.setAttributeNS(null, "y", e.alt); //"420"
    t.setAttributeNS(null, "dominant-baseline", "middle");
    t.setAttributeNS(null, "text-anchor", "middle");
    t.setAttributeNS(null, "id", id);
    t.setAttributeNS(null, "fill", "#FDAD34");
    t.textContent = e.title;
    v.appendChild(t);
}

const createLineUmbral = (y, w) => {
    let s = document.getElementById('contSvg');
    let svgNS = s.namespaceURI;
    let v = document.getElementById('v');
    let rect = document.createElementNS(svgNS, 'rect');
    rect.setAttribute("x", 0);
    rect.setAttribute("y", y);
    rect.setAttribute("width", w);
    rect.setAttribute("height", "1px");
    rect.setAttribute("fill", "#FF0000");
    v.appendChild(rect);
}

const createData = (data = [], umbral = 0, axisY = [100, 90, 80, 70, 60, 50, 40, 30, 20, 10, 0]) => {
    const min = 0;
    let i = 0;
    let d = [];
    // let line = [];
    // line.push(rect['h']);
    let mq = mediaQuery(axisY.length - 1) / 10;
    while (i < data.length) {
        let rect = {}
        let px = 10;
        if (i === 0) rect['x'] = min;
        else rect['x'] = d[i - 1]['x'] + data[i - 1][1] * px;
        rect['y'] = 0;
        rect['w'] = data[i][1] * px;
        rect['h'] = data[i][2] * mq;
        if (i === 0) rect['last'] = 0;
        else rect['last'] = d[i - 1]['h'];
        rect['color'] = data[i][3] !== undefined && data[i][3].color !== undefined ? data[i][3].color : "#281B65";
        rect['type'] = data[i][3] !== undefined && data[i][3].type !== undefined ? data[i][3].type : 0;
        rect['title'] = data[i][0];
        d.push(rect);
        if (rect['type'] === 0) createRect(rect);
        else createPolygon(rect);
        i++;
    }

    let s = document.getElementById('contSvg');
    const reducer = (previousValue, currentValue) => previousValue + currentValue.w;
    let w = d.reduce(reducer, 0)
    let p = parentWidth(s.parentNode) - 30;
    let width = w < p ? p : w;
    s.setAttribute("width", width);

    createLineUmbral(umbral * mq, width);
}

const parentWidth = (elem) => {
    return elem.parentElement.clientWidth;
}

const mediaQuery = (count = 10) => {
    return 490 / count;
}


const generateChart = (obj) => {
    let arrDefault = [];
    document.getElementById('v').innerHTML = "";
    document.getElementById('x').innerHTML = "";
    document.getElementById('y').innerHTML = "";
    generateXAxis(obj.axisY);
    createData(obj.data !== undefined ? obj.data : arrDefault, obj.umbral !== undefined ? obj.umbral : 0, obj.axisY);

    var box = document.getElementById('boxChart');
    box.scrollLeft = box.scrollWidth;


}

export default generateChart;