import React, { Component } from 'react';
import { Grid } from '@material-ui/core';

class Help extends Component{
    constructor(){
        super();
        this.state = {
            type:"chat",
            channels:[]
        }
    }

    values(){
        let data = Object.assign({},this.state);
        data['config_channels'] = this.state.channels.filter(e => e.index !== 100);
        if(this.props.values !== undefined) this.props.values(this.state.type);
    }

    componentDidUpdate(){
        this.values();
    }

    render (){
        return (
            <Grid container direction="row" justifyContent="center"> 
                <Grid item xs={12} md={4}>
                    <p className="title-calendar mb-2">Iniciar</p>
                    <Grid item xs={12} container direction="row" className="border-tf rounded p-3">
                        <Grid item xs={4} container direction="row" justifyContent="space-around" alignItems="center">
                            <div className="d-flex justify-content-center d-flex align-items-center mx-auto">
                                <input type="radio" id="check1" name="alert" defaultChecked={true} onChange={value => value ? this.setState({type:'chat'}):null}></input>
                                <label htmlFor="check1" className="radio-alert"><span className="icon-sms"></span></label>
                            </div>
                        </Grid>
                        <Grid item xs={4} container direction="row" justifyContent="center" alignItems="center">
                            <div className="d-flex justify-content-center d-flex align-items-center mx-auto">
                                <input type="radio" id="check2" name="alert" onChange={value => value ? this.setState({type:'sms'}):null}></input>
                                <label htmlFor="check2" className="radio-alert"><span className="icon-help"></span></label>
                            </div>
                        </Grid>
                        <Grid item xs={4} container direction="row" justifyContent="center" alignItems="center">
                            <div className="d-flex justify-content-center d-flex align-items-center mx-auto">
                                <input type="radio" id="check3" name="alert" onChange={value => value ? this.setState({type:'call'}):null}></input>
                                <label htmlFor="check3" className="radio-alert"><span className="icon-phone"></span></label>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}


export default Help;