import styled from "styled-components";

export const DatePicketInputContainer = styled.div`
  .DatePicker {
    z-index: inherit !important;
  }

  position: relative;
  /* z-index: 20; */

  & > .borde-label {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    transition: 0.2s;
    font-size: 14px;
    pointer-events: none;
    color: #9aa2a8;
    font-weight: 300;

    display: flex;
    align-items: center;
  }

  .my-custom-input-class {
    &:focus + .borde-label,
    &:valid + .borde-label {
      font-size: 14px;
      top: 0;
      background-color: #fff;
      padding: 0 5px;
      border-radius: 4px;
    }

    &::placeholder {
      color: #9aa2a8;
      font-weight: 300;
    }
  }

  & > .top-0 {
    font-size: 14px;
    top: 0;
    background-color: #fff;
    padding: 0 5px;
    border-radius: 4px;
  }

  & > svg {
    position: absolute;
    top: 50%;
    bottom: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    pointer-events: none;
  }
`;

export const DatePickerContainer = styled.div`
  position: relative;
  .DatePicker {
    z-index: initial !important;
  }

  .DatePicker__calendarContainer {
    z-index: 30000;
  }

  .custom-calendar {
    font-family: "Roboto", sans-serif;
    color: #3f3356;
    font-size: 8px;
    box-shadow: 15px 10px 30px #e7ecf4 !important;
    font-weight: 600;
  }

  .Calendar__day.-weekend:not(.-selected):not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween) {
    color: #ffa26b;
  }

  .Calendar__section {
    padding: 0.5em 2.5em 0;
  }

  .Calendar__monthSelectorItemText,
  .Calendar__yearSelectorText {
    font-size: 14px;
  }
`;

const InputBorderStyled = styled.input`
  /* width: calc(250px - 15px - 2px); */
  background-color: #fff;
  border-radius: 4px;
  border: 0.5px solid #9aa2a8;
  box-sizing: border-box;
  height: calc(100% - 2px);
  outline: none;
  padding: 0 15px;
  width: ${({ width }) => width};

  &:focus + .borde-label,
  &:valid + .borde-label {
    font-size: 14px;
    top: 0;
    background-color: #fff;
    padding: 0 5px;
    border-radius: 4px;
  }

  &:focus {
    border: none;
    border: 1px solid #5943c1;
  }

  &:focus + .borde-label {
    border: none;
    color: #281b65;
  }

  &:hover {
    border: 0.5px solid #281b65;
  }
`;

export const DatePickerInput = styled(InputBorderStyled)`
  padding: 0 45px 0 16px;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;
