import styled from "styled-components";


export const ContainerStyled = styled.div`
    width: 100%;
    margin: 0 auto;
    max-width: ${props => props.width};
`
ContainerStyled.defaultProps = {
    width: '100%'
}

export const RowStyled = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding-left: ${props => `calc(${props.gap}px / -2)`};
    padding-right: ${props => `calc(${props.gap}px / -2)`};
    justify-content: ${props => props && props.justifyContent ? `${props.justifyContent}` : "initial"};
    align-items: ${props => props && props.alignItems ? `${props.alignItems}` : "initial"};
`

RowStyled.defaultProps = { 
    gap: 24,
    cols: 12
}

export const ColStyled = styled.div`
    width:100%;
    padding-left: ${props => `calc(${props.gap}px / 2)`};
    padding-right: ${props => `calc(${props.gap}px / 2)`};
    margin-bottom: ${props => `${props.gap_bottom}px`};
    justify-content: ${props => props && props.justifyContent ? `${props.justifyContent}` : "initial"};
    align-items: ${props => props && props.alignItems ? `${props.alignItems}` : "initial"};

    flex: ${props => `calc((100% / ${props.cols}) * ${props.xs ? props.xs : props.cols})`};
    max-width: ${props => `calc((100% / ${props.cols}) * ${props.xs ? props.xs : props.cols})`};

    @media screen and (min-width: 578px) {
        flex: ${props => `calc((100% / ${props.cols}) * ${props.sm ? props.sm : props.xs})`};
        max-width: ${props => `calc((100% / ${props.cols}) * ${props.sm ? props.sm : props.xs})`};
    }

    @media screen and (min-width: 991px) {
        flex: ${props => `calc((100% / ${props.cols}) * ${props.md ? props.md : props.sm ? props.sm : props.xs})`};
        max-width: ${props => `calc((100% / ${props.cols}) * ${props.md ? props.md : props.sm ? props.sm : props.xs})`};
    }

    @media screen and (min-width: 1025px) {
        flex: ${props => `calc((100% / ${props.cols}) * ${props.xl ? props.xl : props.md ? props.md : props.sm ? props.sm : props.xs})`};
        max-width: ${props => `calc((100% / ${props.cols}) * ${props.xl ? props.xl : props.md ? props.md : props.sm ? props.sm : props.xs})`};
    }
`