import styled, { css } from 'styled-components'

export const ProgressBar = styled.div`
    border-radius: 3px;
    height: 25px;
    background-color: #FAFAFA;
    background-image: none;
    background-repeat: repeat-x;
    overflow: hidden;
    line-height: 0;
    font-size: .75rem;
    display: flex;
    width: 100%;
`

export const ProgressBarItem = styled.div`
    background: ${props => props && props.bg};
    width: ${props => props && props.width};
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    transition: width .6s ease;
`

export const IconEvaluation = styled.span`
    color: ${props => props && props.disabled ? "#BBBBBB" : "#fdad34"};
    font-size: 18px;
    padding-right: 0.25rem!important;
`

export const PlanningContainer = styled.div`
    border: 0.5px solid #9CB2CD;
    border-radius: 3px;
    padding: 8px;
    background: white;
    border-left: 4px solid ${props => props && props.statusColor};
    & p{
        margin:0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
`

export const ItemContainer = styled.div`
    width: 100%;
    display: inline-flex;
    padding: 8px;
    & p{
        margin:0;
    }

    ${props => props && props.center ? css`justify-content: center;` : ``}
`

export const HeaderContainer = styled.div`
    width: 100%;
    display: inline-flex;
    padding: 8px;
    & p{margin:0;}
`

export const Header = styled.div`
    background: #EEF2FF;
    font-weight: 600;
    color: #281b65;
    font-size: 14px;
    padding: 10px 0;
`

export const Accordeon = styled.div`
    height: 100%;
    padding: 5px 0;
    max-height: ${props => props && props.active ? `${props.heightOpen}px` : "50px"};
    overflow: hidden;
    transition: max-height .6s linear;
    margin: 15px 0;
`

export const ArrowContainer = styled.div`
    & svg{
        rotate: ${props => props && props.active ? "180deg" : "360deg"};
        transition: max-height .6s linear;
    }
`

export const TitlePlans = styled.div`
    background: rgba(208,201,255,.19);
    font-weight: 600;
    color: #281b65;
    font-size: 14px;
    padding: 10px;
    margin: 8px 0;
`

export const ItemDetail = styled.div`
    margin-bottom:15px;
`

export const ArrowSVG = styled.div`
    display:flex;
    justify-content: center;
    align-items:center;
    & svg{
        margin-right: 10px;
        width: 10px;
        height: 10px;
    }
`

export const InfoEventModal = styled.div`
    height: 40px;
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-direction: column;
    & h4{
        font-size: 16px;
        font-weight: 700;
        margin: 0;
    }

    & p { margin:0; }
`

export const HourInfoModal = styled.div`
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%!important;

    & p{
        font-size: 14px;
        color: #3c4043;
        line-height:20px;
        margin:0;
    }

    & p.suc, a.suc {
        font-size: 12px;
        color: #70757a;
        line-height:20px;
        margin:0;
    }

    & p.bold {
        font-weight: 700;
    }

    & .date{
        font-weight: 600;
    }

    & span.space{
        margin-right: 15px;
    }

    & .bZaNel{
        width:100%;
    }

    & .iUTKsX{
        width:100%;
    }
`

export const IconModal = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction: column;
    height: 100%;
`

export const BarSeparateInfo = styled.div`
    height: 20px;
`

export const SidebarContent = styled.div`
  height: calc(100% - 84px);
  display: grid;
  grid-template-rows: 1fr 45px;

  & > .form > p {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;

    color: #182024;

    margin: 0 0 4px 0;

    &:last-child {
      margin: 30px 0 4px 0;
    }
  }

  & > .form > label {
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 1px;
    color: #182024;
  }

  & > .form > .mrgn-top-30 {
    margin-top: 30px;
  }
`;
