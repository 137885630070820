import React, { Component } from "react";
import { Checkbox, FormGroup, FormControlLabel } from "@material-ui/core";
import TimePicker from "../timePicker";
import Counter from "../counter";
import { withTranslation } from "react-i18next";
import { TableBioFeedback } from "../tableBiofeedback";
import ChartTF from "../chartTf";

import {
  SliderMulticolor,
  Container,
  Row,
  Col,
  Tabs,
  Tooltip,
  Button,
  Modal,
  Slider,
} from "trainfes-components-library";

import { TextField } from '@mui/material';

import * as M from "./styles/Modos.styles";
import * as C from "./styles/configbutton.styles";
import { getSeconds, secondsToDate } from "../../lib/modes.helpers";
import { ConfigIcon } from "../../assets/icons";

const axisNormal = [100, 90, 80, 70, 60, 50, 40, 30, 20, 10, 0];
const axisIMU = [
  180, 170, 160, 150, 140, 130, 120, 110, 100, 90, 80, 70, 60, 50, 40, 30, 20, 10, 0,
];

var self;
class Biofeedback extends Component {
  constructor() {
    super();
    this.state = {
      select: 0,
      data: [],
      frecuencia_cardiaca_min: 60,
      frecuencia_cardiaca_max: 140,
      sensor: "IMU",
      umbral: 0,
      fes: false,
      pausa: { hours: 0, minutes: 0, seconds: 0 },
      series: 1,
      duracion: { hours: 0, minutes: 0, seconds: 0 },
      objetivo: 0,
      typeData: 0,

      activeTab: 0,

      // Temp -> temporal para configuración de modal.
      frecuenciaTemp: 35,
      ancho_pulsoTemp: 200,

      frecuencia: 35,
      ancho_pulso: 200,

      work_intensity: 0,
      public_name: "",
      technical_name: "",
      description: "",

      time: "00:00:00",
    };
  }

  values() {
    if (this.props.values !== undefined) {
      this.props.values({
        ...this.state,
        frecuency_min: this.state.frecuencia_cardiaca_min,
        frecuency_max: this.state.frecuencia_cardiaca_max,
        sensor: this.state.sensor,
        umbral: this.state.umbral,
        modo: {
          value: this.state.type,
          name: this.state.type === 0 ? "Sobre umbral" : "Asistido",
        },
        type: {
          value: this.state.typeData,
          name: this.state.typeData === 0 ? "Estandar" : "Perfil",
        },
        data: this.state.data,
        fes: this.state.fes,
        series: this.state.series,
        pausa: this.state.pausa,
      });
    }
  }

  componentDidUpdate() {
    this.values();
  }

  componentDidMount() {
    self = this;
    this.values();
    if (this.props.data.params && Object.keys(this.props.data.params).length !== 0) {
      let params = this.props.data.params;
      this.setState(
        {
          data: params.data,
          frecuencia_cardiaca_min: params.frecuency_min,
          frecuencia_cardiaca_max: params.frecuency_max,
          sensor: params.sensor,
          umbral: params.umbral,
          fes: params.fes,
          pausa: params.pausa,
          series: params.series,
          technical_name: params.technical_name || "",
          public_name: params.public_name || "",
          description: params.description || "",
          time: params.time || "00:00:00",
          work_intensity: params.work_intensity || 0,
        },
        () => this.setState({ time: this.getTotalTime() })
      );

      if (params.frecuencia) {
        this.setState({ frecuencia: params.frecuencia, frecuenciaTemp: params.frecuencia });
      }

      if (params.ancho_pulso) {
        this.setState({ ancho_pulso: params.ancho_pulso, ancho_pulsoTemp: params.ancho_pulso });
      }
    }
  }

  selectType(val) {
    this.setState({ sensor: val });
  }

  getTimeToStr(obj, h = false) {
    const { hours, minutes, seconds } = obj;
    return `${h ? (hours < 10 ? "0" + hours + ":" : hours + ":") : ""} ${
      minutes < 10 ? "0" + minutes : minutes
    }:${seconds < 10 ? "0" + seconds : seconds} `;
  }

  appendData() {
    let { duracion, objetivo, typeData } = this.state;

    let d = Object.assign([], this.state.data);
    let id = Math.random().toString(36).slice(-8);
    let o = { duracion, objetivo, typeData, id };
    d.push(o);
    this.setState({ data: d }, () => this.getTotalTime());
  }

  removeItem(pos) {
    let d = Object.assign([], this.state.data);
    d.splice(
      d.findIndex((e) => e.id === pos),
      1
    );
    this.setState({ data: d }, () => this.getTotalTime());
  }

  toInt(obj = { hours: 0, minutes: 0, seconds: 0 }) {
    let { hours, minutes, seconds } = obj;
    return hours * 3600 + minutes * 60 + seconds;
  }

  transformArrData(data) {
    return data.map((e) => {
      return [
        this.parseTimeString(e.duracion.minutes, e.duracion.seconds),
        this.toInt(e.duracion),
        e.objetivo,
        { color: "#281B65", type: parseInt(e.typeData) },
      ];
    });
  }

  parseTimeString(m, s) {
    let str = "";
    if (m > 0) str += m + "m ";
    if (s > 0) str += s + "s";
    return str;
  }

  handleActiveTab(val) {
    self.setState({ activeTab: val });
  }

  setPublicName(e) {
    self.setState({ public_name: e });
  }

  setTechnicalName(e) {
    self.setState({ technical_name: e });
  }

  setDescription(e) {
    self.setState({ description: e });
  }

  getTotalTime() {
    const pausaSeconds = getSeconds(this.state.pausa) * (this.state.series - 1);
    let totalSeconds = pausaSeconds;

    this.state.data.forEach((e) => (totalSeconds += getSeconds(e.duracion) * this.state.series));

    self.setState({ time: secondsToDate(totalSeconds) });
    return secondsToDate(totalSeconds);
  }

  setShowConfigModal(bool) {
    self.setState({ showConfigModal: bool });
  }

  setFrecuencyTemp(value) {
    self.setState({ frecuenciaTemp: value });
  }

  setPulseWidthTemp(value) {
    self.setState({ ancho_pulsoTemp: value });
  }

  handleConfigSubmit() {
    self.setState({
      ancho_pulso: self.state.ancho_pulsoTemp,
      frecuencia: self.state.frecuenciaTemp,
    });
    self.setShowConfigModal(false);
  }

  render() {
    const { t } = this.props;

    const tabsObject = [
      {
        id: 0,
        title: this.props.t("graphic"),
      },
      {
        id: 1,
        title: this.props.t("information"),
      },
    ];

    const tabsRender = (key) => {
      switch (key) {
        case 0:
          return (
            <div className="grafic my-4">
              <ChartTF
                data={{
                  data: this.transformArrData(this.state.data),
                  yLabel: t("threshold"),
                  xLabel: t("duration"),
                  umbral: this.state.umbral,
                  axisY: this.state.sensor === "IMU" ? axisIMU : axisNormal,
                }}
              />
            </div>
          );
        case 1:
          return (
            <div>
              <TableBioFeedback data={this.state.data} remove={(v) => this.removeItem(v)} />
            </div>
          );
        default:
          break;
      }
    };

    return (
      <M.ScreenContainer>
        <Container>
          <Row cols={12}>
            <Col xs={6}>
              <Tabs
                data={tabsObject}
                activeTab={this.state.activeTab}
                setActiveTab={this.handleActiveTab}
              />
              {tabsRender(this.state.activeTab)}
            </Col>
            <Col xs={6}>
              <M.SequentialForm style={{ marginBottom: 0 }}>
                <TextField
                  fullWidth
                  required
                  label={t("tech_name")}
                  value={this.state.technical_name}
                  onChange={({ target }) => this.setTechnicalName(target.value)}
                />
                <TextField
                  fullWidth
                  label={t("public_name")}
                  value={this.state.public_name}
                  onChange={({ target }) => this.setPublicName(target.value)}
                />
                <TextField
                  label={t("description")}
                  fullWidth
                  multiline
                  rows={4}
                  value={this.state.description}
                  onChange={({ target }) => this.setDescription(target.value)}
                />
              </M.SequentialForm>

              <C.ConfigTimeContainer style={{ margin: "15px 0" }}>
                <M.TimeContainer>
                  <M.TimeTotal>{t("total_time")}:</M.TimeTotal>
                  <M.Box padding={"10px"}>
                    <M.TimeText>{this.state.time}</M.TimeText>
                  </M.Box>
                </M.TimeContainer>
                <C.ConfigButton onClick={() => this.setShowConfigModal(true)}>
                  <ConfigIcon />
                </C.ConfigButton>
              </C.ConfigTimeContainer>

              <Row cols={12} gap={0} gap_bottom={10}>
                <Col xs={4} style={{ paddingRight: 7 }}>
                  <M.Box padding={"10px"} className="__centerCheckbox">
                    <FormGroup>
                      <FormControlLabel
                        className="m-0 pr-3"
                        control={
                          <Checkbox
                            disabled={true}
                            onChange={(v) => this.setState({ fes: v.target.checked })}
                          />
                        }
                        label={t("will_use_FES")}
                      />
                    </FormGroup>
                  </M.Box>
                </Col>

                <Col xs={8} style={{ paddingLeft: 7 }}>
                  <M.Box padding={"10px 15px 0px 15px"}>
                    <M.GridTitle>{t("work_intensity")} (0 - 100%)</M.GridTitle>

                    <div className="box--tooltip">
                      <Tooltip
                        label={
                          <>
                            <strong>{t("by_FC")}</strong> {t("it_is_required_to_know_the_age")}
                          </>
                        }
                      />
                    </div>

                    <M.SliderIndicator>{this.state.work_intensity}</M.SliderIndicator>
                    <M.SliderContainer>
                      <SliderMulticolor
                        title={t("spasm")}
                        min={0}
                        step={1}
                        max={100}
                        value={this.state.work_intensity}
                        onChange={(value) => this.setState({ work_intensity: value })}
                      />
                    </M.SliderContainer>
                  </M.Box>
                </Col>

                <Col xs={4} style={{ paddingRight: 7 }}>
                  <M.Box padding={"10px 15px 0px 15px"}>
                    <M.GridTitle>{t("threshold")}</M.GridTitle>
                    <Counter
                      data={this.state.umbral}
                      title="0 - 180"
                      min={0}
                      max={180}
                      values={(value) => this.setState({ umbral: value })}
                    />
                  </M.Box>
                </Col>

                <Col xs={4} style={{ paddingLeft: 7, paddingRight: 7 }}>
                  <M.Box padding={"10px 15px 0px 15px"}>
                    <M.GridTitle>{t("series")}</M.GridTitle>
                    <Counter
                      data={this.state.series}
                      title="1 - 100"
                      min={1}
                      max={100}
                      values={(value) =>
                        this.setState({ series: value }, () => this.getTotalTime())
                      }
                    />
                  </M.Box>
                </Col>

                <Col xs={4} style={{ paddingLeft: 7 }}>
                  <M.Box padding={"10px 15px 0px 15px"}>
                    <M.GridTitle>{t("pauseSeries")}</M.GridTitle>
                    <TimePicker
                      data={this.state.pausa}
                      minutes={true}
                      seconds={true}
                      values={(value) => this.setState({ pausa: value }, () => this.getTotalTime())}
                    />
                  </M.Box>
                </Col>

                <Col xs={12}>
                  <M.BlueBg>
                    <Row cols={12} gap={0}>
                      <Col xs={4} style={{ paddingRight: 7 }}>
                        <M.Box padding={"10px 15px 0px 15px"}>
                          <M.GridTitle>{t("type")}</M.GridTitle>
                          <select
                            style={{ height: 35, padding: "0px 8px", cursor: "pointer" }}
                            className="form-control my-3 d-block w-100"
                            onChange={(v) => this.setState({ typeData: parseInt(v.target.value) })}
                          >
                            <option value={0}>{t("step")}</option>
                            <option value={1}>{t("ramp")}</option>
                          </select>
                        </M.Box>
                      </Col>

                      <Col xs={4} style={{ paddingLeft: 7, paddingRight: 7 }}>
                        <M.Box padding={"10px 15px 0px 15px"}>
                          <M.GridTitle>{t("step_ramp_duration")}</M.GridTitle>
                          <TimePicker
                            data={this.state.duracion}
                            minutes={true}
                            seconds={true}
                            values={(value) =>
                              this.setState({ duracion: value }, () => this.getTotalTime())
                            }
                          />
                        </M.Box>
                      </Col>

                      <Col xs={4} style={{ paddingLeft: 7 }}>
                        <M.Box padding={"10px 15px 0px 15px"}>
                          <M.GridTitle>{t("step_ramp_target")}</M.GridTitle>
                          <Counter
                            data={this.state.objetivo}
                            title={`0 - ${this.state.sensor === "IMU" ? 180 : 100}`}
                            min={0}
                            max={this.state.sensor === "IMU" ? 180 : 100}
                            values={(value) =>
                              this.setState({ objetivo: value }, () => this.getTotalTime())
                            }
                          />
                        </M.Box>
                      </Col>

                      {/* TODO: cambiar boton por styledcomponents o por libreria */}
                      <button
                        className="mt-2 btn btn-tf-xs w-100"
                        onClick={() => {
                          this.appendData();
                          this.getTotalTime();
                        }}
                        disabled={
                          JSON.stringify(this.state.duracion) ===
                          JSON.stringify({
                            hours: 0,
                            minutes: 0,
                            seconds: 0,
                          })
                        }
                      >
                        {t("add")}
                      </button>
                    </Row>
                  </M.BlueBg>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Modal
          isOpen={this.state.showConfigModal}
          buttons={
            <>
              <Button
                type="ghost"
                width="142px"
                label={t("cancel")}
                onClick={() => {
                  this.setFrecuencyTemp(this.state.frecuencia);
                  this.setPulseWidthTemp(this.state.ancho_pulso);
                  this.setShowConfigModal(false);
                }}
              />
              <Button width="142px" label={t("save")} onClick={this.handleConfigSubmit} />
            </>
          }
        >
          <C.ModalConfigContainer>
            <M.Box padding={"10px 15px 0px 15px"}>
              <M.GridTitle>{t("frecuency")} (HZ)</M.GridTitle>
              <M.SliderIndicator>{this.state.frecuenciaTemp || 0}</M.SliderIndicator>
              <M.SliderContainer>
                <Slider
                  step={1}
                  value={this.state.frecuenciaTemp}
                  min={5}
                  max={60}
                  onChange={(value) => this.setFrecuencyTemp(value)}
                />
              </M.SliderContainer>
            </M.Box>

            <M.Box padding={"10px 15px 0px 15px"}>
              <M.GridTitle>{t("pulse_width")} (US)</M.GridTitle>
              <M.SliderIndicator>{this.state.ancho_pulsoTemp || 0}</M.SliderIndicator>
              <M.SliderContainer>
                <Slider
                  step={1}
                  value={this.state.ancho_pulsoTemp}
                  min={20}
                  max={400}
                  onChange={(value) => this.setPulseWidthTemp(value)}
                />
              </M.SliderContainer>
            </M.Box>
          </C.ModalConfigContainer>
        </Modal>
      </M.ScreenContainer>
    );
  }
}

export default withTranslation("sessionStudio")(Biofeedback);
