import React, { useState, useEffect } from "react";
import Rutas from "../routes/routes";
import { useTranslation } from "react-i18next";

// Componentes
import Navbar from "./navbar.js";
import Sidebar from "./sidebar";
import RouterPrompt from "../components/prompt/index";
import { on, send } from "../services/socket";
import { getUserId, getToken } from "../lib/router";
import { withRouter } from "react-router-dom";
import Notifications from "../components/notifications/index";
import { connect } from "../store";
import axios from "axios";
import NoMobile from "./noMobile";
// import endpoint from "../endpoint";

const Layout = (props) => {
  const [openAlert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [id, setId] = useState("");
  const { t } = useTranslation("global");
  // const [done, setDone] = useState(null);
  // const [reject, setReject] = useState(null);

  useEffect(() => {
    const user = getUserId();
    props.setUserProfile(user.user);
  }, [getToken()]);

  const done = () => {
    setOpenAlert(false);
    send("app:connect:done", { body: { message: "success request" } });
    props.history.push(`/displayTraining/${id}`);
  };

  const reject = () => {
    setOpenAlert(false);
    send("app:connect:reject", { body: { message: "fail request" } });
  };

  useEffect(() => {
    const user = getUserId();
    props.setUserProfile(user.user);
    on("app_message", (response) => {
      if (response.req.key === user.userId) {
        if ("INIT_PLANNING" === response.data.type) {
          setOpenAlert(true);
          setMessage(response.data.body.message);
          setId(response.data.header.session);
          // setDone(() => {
          //     setOpenAlert(false);
          //     send('app:connect:done', { body: { message: "success request" } });
          //     props.history.push(`/displayTraining/${response.data.header.session}`)
          // });

          // setReject(() => {
          //     setOpenAlert(false);
          //     send('app:connect:reject', { body: { message: "fail request" } });
          // });
        }
      }
    });

    send("chat:get_rooms", { id: user.userId });

    on("chat:get_rooms", (body, status) => {
      if (status === 200) props.setRooms(body);
    });

    axios
      .get("/media/files")
      .then((res) => props.setFiles(res.data.data))
      .catch((err) => console.log(err));

    axios
      .get("/media/videos")
      .then((res) => props.setVideos(res.data.data))
      .catch((err) => console.log(err));

    axios
      .get("/media/images")
      .then((res) => props.setImages(res.data.data))
      .catch((err) => console.log(err));

    axios
      .get("/categories/media")
      .then((res) => props.setCategoriesMedia(res.data.data))
      .catch((err) => console.log(err));

    axios
      .get("/device/media")
      .then((res) => props.setDeviceMedia(res.data.data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <div style={{ position: "relative" }}>
      <div className="container-fluid" style={{ zIndex: 10000 }}>
        <div className="mobile">
          <NoMobile />
        </div>
        <div className="row scroll-row no-mobile">
          <div className="col p-0 sidebar-trainfes">
            <Sidebar />
          </div>
          <div className="col sidebar-ml-75 p-0">
            <div className="col-md-12 mx-auto p-0">
              <Navbar />
            </div>
            <Rutas />
          </div>
        </div>
      </div>
      {openAlert ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            position: "fixed",
            bottom: 0,
            top: 0,
            letf: 0,
            right: 0,
            height: "100%",
            width: "100%",
            background: "rgba(0,0,0,0.5)",
            zIndex: 999999999999,
          }}
        >
          <div
            style={{
              maxWidth: "450px",
              height: "300px",
              width: "100%",
              background: "#f0f0f0",
              borderRadius: "5px",
              padding: "60px 20px",
            }}
          >
            <div className="text-center">
              <svg
                width="40"
                height="67"
                viewBox="0 0 40 67"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M31.6304 0.390986L26.3209 33.2971L7.62126 16.298C7.57323 16.2381 7.50558 16.1938 7.42819 16.1717C7.35081 16.1497 7.26775 16.1509 7.19119 16.1753C7.02376 16.2251 6.92261 16.3634 6.88524 16.5912L0.00554976 60.2487C-0.00458948 60.3016 -0.000796968 60.3559 0.0166094 60.4071C0.0340158 60.4583 0.0645281 60.5049 0.105593 60.5431L7.00452 66.8149C7.24543 67.034 7.70082 66.8514 7.75257 66.5173L12.9181 33.3468L31.6707 50.3459C31.7014 50.3786 31.7397 50.4044 31.7829 50.4215C31.826 50.4385 31.8729 50.4464 31.9198 50.4444C31.98 50.4496 32.0408 50.4436 32.0983 50.4265C32.1559 50.4095 32.2091 50.3819 32.2546 50.3453C32.3002 50.3088 32.3371 50.264 32.3632 50.2139C32.3893 50.1638 32.404 50.1093 32.4064 50.0538L39.4071 6.67171C39.4174 6.61854 39.4137 6.56388 39.3963 6.5123C39.3789 6.46071 39.3483 6.41371 39.307 6.37522L32.3791 0.0944997C32.1381 -0.122305 31.6852 0.0580268 31.631 0.390986"
                  fill="#FDAD34"
                />
              </svg>
              <h6 style={{ marginTop: "20px" }}>{message}</h6>
              <ul className="ctrls-io">
                <li>
                  <button
                    onClick={() => reject()}
                    className="btn btn-ctrl-tf-white"
                  >
                    No permitir
                  </button>
                </li>
                <li>
                  <button onClick={() => done()} className="btn btn-ctrl-tf">
                    Permitir
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      ) : null}
      <Notifications />
      <RouterPrompt
        title={t("prompt.title")}
        message={t("prompt.message")}
        onOK={() => true}
        onCancel={() => false}
      />
    </div>
  );
};

export default connect(withRouter(Layout));
