import styled from "styled-components";

export const PatientTableBody = styled.div`
  margin-top: 14px;

  & > ._row {
    display: grid;
    grid-template-columns: 140px 1fr;
    place-items: baseline;
    padding: 5px 0;
    position: relative;
  }
`;

export const PatientTableContainer = styled.div`
  border-radius: 4px;
  border: ${({ active }) => (active ? "0.5px solid #6d59c9" : "0.5px solid #ced4da")};
  box-shadow: 0 1px 70px rgba(231, 236, 244, 0.5);
  margin: 10px 0;
  padding: 14px 20px;

  &:hover {
    border: 0.5px solid #6d59c9;
  }
`;

export const PatientTableHeader = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;

  & p {
    font-weight: 500;
    letter-spacing: 0.850962px;
    margin: 0;
    font-size: 12px;
    color: #182024;
  }

  & > .header--info {
    display: flex;
    align-items: center;

    & > p {
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  & > ._icon {
    display: flex;
    height: 10px;
    transform: rotate(180deg);
    transition: 0.3s;
    width: 10px;
  }

  & > ._active {
    transform: rotate(0deg);
    transition: 0.3s;
  }
`;
