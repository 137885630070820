const getOriginEndpoint = () => {
    switch (process.env.REACT_APP_ENV.trim()) {
        case "production": return "https://dev.trainfes-cloud.com";
        case "development-local": return "http://localhost:12005";
        case "production-local": return "http://localhost:12001";
        case "qa": return "https://api.trainfes-cloud.click";
        case "development":
        case "dev": return "https://apiv2.tfdev.click";
        default: return "https://apiv2.tfdev.click";
    }
}

export default getOriginEndpoint();