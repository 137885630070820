import { useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import API from "../services/api";

const api = new API();

export const useGetSpecialities = () => {
    // State para utilizar en los inputs tipo dropdown. 
    const [specialitiesDropdown, setSpecialitiesDropdown] = useState([])

    const specialitiesQuery = useQuery({
        queryKey: ["specialities"],
        queryFn: () => api.getSpeciality()
            .then( (res) => {
              setSpecialitiesDropdown(res.data.map((e) => {
                e["label"] = e.name;
                e["value"] = e._id;
                return e;
              }))

              return res.data
            } ),
        refetchOnWindowFocus: false,
    });

    const { mutate: addNewSpeciality } = useMutation(
        ( newSpeciality ) => {
            return api.createSpeciality( newSpeciality )
        },
        {
            onSuccess: async () => specialitiesQuery.refetch()
        }
    );

    const { mutate: editSpeciality } = useMutation(
        ( { id, data } ) => {
            return api.editSpeciality( id, data )
        },
        {
            onSuccess: async () => specialitiesQuery.refetch()
        }
    );

    const { mutate: deleteSpeciality } = useMutation(
        ( id ) => {
            return api.deleteSpeciality( id )
        },
        {
            onSuccess: async () => specialitiesQuery.refetch()
        }
    );

    return {
      specialitiesDropdown,
      specialitiesQuery,
      addNewSpeciality,
      editSpeciality,
      deleteSpeciality
    }
}
