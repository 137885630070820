import { useMutation } from '@tanstack/react-query';
import API from "../services/api";

const api = new API();

export const useGetSessions = () => {
    const sessionsData = useMutation(
        ( id ) => {
            return api.getProtocolsSession( id ).then((res) => res.data)
        }
    );

    const { mutate: addNewSession, isLoading: addNewSessionLoading } = useMutation(
        ( newSession ) => {
            return api.createProtocolsSession( newSession )
        },
        {
            onSuccess: async ({data}) => sessionsData.mutate(data.protocol)
        }
    );

    const { mutate: editSession, isLoading: editSessionLoading } = useMutation(
        ( { id, data } ) => {
            return api.editProtocolsSession( id, data )
        }
    );

    const { mutate: deleteSession, isLoading: deleteSessionLoading } = useMutation(
        ( id ) => {
            return api.deleteProtocolsSession( id )
        }
    );

    const { mutate: cloneSession, isLoading: cloneSessionLoading } = useMutation(
        ( idSession ) => {
            return api.cloneSession( idSession )
        },
        {
            onSuccess: async ({data}) => sessionsData.mutate(data.protocol)
        }
    );

    const { mutate: cloneSessionToOtherProtocol, isLoading: cloneSessionToOtherProtocolLoading } = useMutation(
        ( { idProtocol, idSession } ) => {
            return api.cloneSessionToOtherProtocol( idProtocol, idSession )
        }
    );

    const sessionsLoading = sessionsData.isLoading || addNewSessionLoading || cloneSessionLoading || cloneSessionToOtherProtocolLoading || editSessionLoading || deleteSessionLoading;

    return {
        sessionsData,
        addNewSession,
        editSession,
        deleteSession,
        sessionsLoading,
        cloneSession,
        cloneSessionToOtherProtocol
    }
}
