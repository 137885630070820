import styled from "styled-components";

export const Description = styled.span`
	margin: 0 0 15px 0;
	font-style: normal;
	font-weight: 300;
	font-size: 12px;
	letter-spacing: 0.850962px;
	color: #182024;
`;
