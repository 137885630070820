import React, { useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import { initialState, reducer } from './reducer';
import { useGetDevices, useGetMediaCategories } from '../../hooks';

import {
    Container,
    Row,
    Col,
    ButtonCircle,
    Button,
    Modal,
    Tabs,
    Input,
    Textarea
  } from "trainfes-components-library"

import { CategoriesTab, DevicesTab } from './components';
import CardLayoutPlatform from '../../components/CardLayoutPlatform';

import { CLEAR_STATE, SET_FIELDS } from './reducer/actions';
import { connect } from '../../store';

import * as S from "./MediaCategories.styles"
import { LabelTitle } from '../../styles/common.styles';

const MediaCategories = (props) => {
    const { t } = useTranslation('library');
    const { customLoading: categoriesLoading, mediaCategoriesQuery, addNewMediaCategory, deleteMediaCategory } = useGetMediaCategories();
    const { customLoading: devicesLoading, addNewDevice, deleteDevice, devicesQuery } = useGetDevices()

    const customLoading = categoriesLoading || devicesLoading;

    const [state, dispatch] = useReducer(reducer, initialState);

    const tabsObject = [
        { id: 0, title: t('categories') },
        { id: 1, title: t("devices") },
    ];
    
    const setActiveTab = ( value ) => {
        dispatch({ type: SET_FIELDS, payload: { field: "activeTab", value } })
        dispatch({ type: CLEAR_STATE })
    }


    const onCreate = () => {
        if ( customLoading ) return;

        if ( state.name == null || state.name.length === 0 || state.name.trim() === "" ) {
            return props.notify({ type: "error", title: "Error", text: t("notify.name") })
        }

        const dataToPost = {
            name: state.name,
            description: state.desc
        }

        try {
            state.activeTab === 0
                ? addNewMediaCategory( dataToPost )
                : addNewDevice( dataToPost )

            dispatch({ type: CLEAR_STATE  })

            props.notify({ type: "success", title: t('all_categories'), text: "OK" })
        } catch (error) {
            props.notify({ type: "error", title: t('all_categories'), text: "Error" })
        }
    }

    const onDelete = () => {
        if ( customLoading ) return;

        try {
            state.activeTab === 0 
                ? deleteMediaCategory( state.idCurrent ) 
                : deleteDevice( state.idCurrent );

            dispatch({ type: CLEAR_STATE })
    
            return props.notify({
                type: "success",
                title: state.activeTab === 0 ? t("media_categories") : t("devices"),
                text: t("notify.success"),
            });
        } catch (error) {
            return props.notify({ type: "error", title: "Error", text: t("notify.error") })
        }
    }
    
    return (
        <>
        <Container>
            <Row cols={12}>
                <Col xs={12}>
                    <CardLayoutPlatform
                        noPaddingBottom
                        title={ t("media_categories") }
                        // loading={ customLoading }
                        floatButton={
                            <ButtonCircle onClick={ 
                                () => dispatch({ type: SET_FIELDS, payload: { field: "newDataModal", value: true } })
                            } />
                        }
                        header={
                            <Tabs
                                data={tabsObject}
                                activeTab={state.activeTab}
                                setActiveTab={setActiveTab}
                            />
                        }
                    >
                        {
                            state.activeTab === 0 
                            ? (
                                <CategoriesTab
                                    t={t}
                                    state={state}
                                    dispatch={dispatch}
                                    notify={ props.notify }
                                    mediaCategoriesQuery={mediaCategoriesQuery} 
                                    />
                                    )
                            : (
                                <DevicesTab
                                    t={t}
                                    state={state}
                                    dispatch={dispatch} 
                                    notify={ props.notify }
                                    devicesQuery={devicesQuery}
                                />
                            )
                        }
                    </CardLayoutPlatform>
                </Col>
            </Row>
        </Container>

        <Modal
            title={ t("sure_you_want_to_delete") + "?" }
            isOpen={ state.removeModal }
            buttons={
            <>
                <Button
                    type="ghost"
                    label={t("cancel")}
                    onClick={ () => dispatch({ type: CLEAR_STATE }) }
                />
                <Button
                    label={t("remove")}
                    onClick={ () => onDelete() }
                    disabled={ customLoading }
                />
            </>
            }
        ></Modal>

        <Modal
            title={ state.activeTab === 0 ? t("new_category") : t("new_device") }
            isOpen={ state.newDataModal }
            buttons={
            <>
                <Button
                    type="ghost"
                    label={t("cancel")}
                    onClick={ () => dispatch({ type: CLEAR_STATE }) }
                />
                <Button
                    label={t("create")}
                    onClick={ () => onCreate() }
                    disabled={ customLoading }
                />
            </>
            }
        >
            <S.ModalContainer>
                <Input 
                    showRequired
                    placeholder={ state.activeTab=== 0 ? t('category_name') : t('device_name') }
                    onChange={
                        ({ target }) => dispatch({ type: SET_FIELDS, payload: { field: "name", value: target.value } })
                    }
                />

                <LabelTitle>{ t('desc') }</LabelTitle>
                <Textarea
                    placeholder=""
                    onChange={
                        ({ target }) => dispatch({ type: SET_FIELDS, payload: { field: "desc", value: target.value } })
                    }
                ></Textarea>
            </S.ModalContainer>
        </Modal>
        </>
    )

}

export default connect(MediaCategories);