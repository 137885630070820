import { useQuery, useMutation } from '@tanstack/react-query';
import axios from 'axios';

export const useGetMediaCategories = () => {

    const mediaCategoriesQuery = useQuery({
        queryKey: ["mediaCategoriesQuery"],
        queryFn: () => axios.get('/categories/media').then( (res) => res.data.data ),
        refetchOnWindowFocus: false,
    });

    const { mutate: addNewMediaCategory, isLoading: addLoading } = useMutation(
        ( newMediaCategory ) => axios.post( '/categories/media', newMediaCategory ),
        {
            onSuccess: async () => mediaCategoriesQuery.refetch()
        }
    );
    
    const { mutate: deleteMediaCategory, isLoading: deleteLoading } = useMutation(
        ( id ) => axios.delete(`/categories/media/${id}`),
        {
            onSuccess: async () => mediaCategoriesQuery.refetch()
        }
    );

    const customLoading = mediaCategoriesQuery.isLoading || addLoading || deleteLoading;

    return {
        customLoading,
        mediaCategoriesQuery,
        addNewMediaCategory, 
        deleteMediaCategory
    }
}
