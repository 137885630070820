import { createTheme } from '@mui/material/styles';

// Base MUI theam to use as default for the hole application.
export const baseTheme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {color:"red"},
      },
    },
  },
});
