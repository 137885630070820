/* eslint-disable no-self-assign */
import React, { Component } from 'react';
import '../../assets/img/icomoon/style.css';
import { Grid, TextField } from '@material-ui/core';
import Axios from 'axios'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from "@fullcalendar/timegrid";
import esLocale from '@fullcalendar/core/locales/es';
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import moment from 'moment';
import ModalSimple from '../../components/modalSimple';
import Selet from '../../components/selet';
import { getUserId } from '../../lib/router'


var eventDescription = "", eventTitle = "";
var self = null, eventDate, eventCurrent, note = "";
class CalendarView extends Component {
    constructor() {
        super();
        this.state = {
            default: {},
            name_protocol: "",
            fase: "",
            description_protocol: "",
            general_objetive: "",
            specific_objetive: "",
            public_note: "",
            private_note: "",
            name_notes: "",
            description_notes: "",
            name_records: "",
            description_records: "",
            files: [],
            name: "",
            name_video: "",
            description_video: "",
            idProtocolo: "",
            data: [],
            sessions: [],
            events: [],
            open: false,
            openEvent: false,
            openNotes: false,
            openFiles: false,
            openConfirm: false,
            protocols: [],
            patiens: [],
            optionsComplete: [],
            note: "",
            videos: [],
            status: null,
            dialog: false,
            openCheckTime: false,
            openCheckAlert: true,
            eventDescription: "",
            eventTitle: "",
            nameplanning: "",
            patient: {},
            evn: null,
            notifyValues: [
                { value: 0, label: "No notificar", },
                { value: 1, label: "15 Minutos" },
                { value: 2, label: "30 Minutos" },
                { value: 3, label: "45 Minutos" },
                { value: 4, label: "1 Hora" },
                { value: 5, label: "2 Horas" },
            ]
        };

        this.drag = React.createRef();
        this.calendarComponentRef = React.createRef();
        this.filename = React.createRef();


        this.buttons = {
            add: {
                text: 'Crear planificación',
                click: function () {
                    self.setState({ openConfirm: true });
                },
            },
            files: {
                text: 'Videos',
                click: function () {
                    self.setState({ openFiles: true });
                },
            },
            note: {
                text: 'Nota',
                click: function () {
                    self.setState({ openNotes: true });
                },
            },
        }

        this.headers = {
            left: 'prev,next today dayGridDay,dayGridWeek,dayGridMonth',
            center: 'title',
            right: 'note files add'
        }

        this.currentName = React.createRef();
    }

    binaryToString(input) {
        let bytesLeft = input;
        let result = '';

        while (bytesLeft.length) {
            const byte = bytesLeft.substr(0, 8);
            bytesLeft = bytesLeft.substr(8);
            result += String.fromCharCode(parseInt(byte, 2));
        }

        return result;
    }


    createEvent(event) {
        self.setState({
            events: self.state.events.concat(
                { type: event.type, title: event.title, description: event.description === undefined ? "" : event.description, id: Math.random().toString(36).slice(-8), date: moment(event.date).format(), idSesion: event.idSesion === undefined ? Math.random().toString(36).slice(-8) : event.idSesion, hora_inicio: null, hora_termino: null, hora: false, notify: false, notify_value: null }
            )
        })
    }

    protocolToSelect() {
        // eslint-disable-next-line array-callback-return
        return this.state.data.map(e => {
            this.setState((state) => {
                state.protocols.push({ value: e._id, label: e.name + " | " + e.fase });
                return state;
            })
        })
    }

    removeFile(item) {
        this.state.files.splice(this.state.files.findIndex(i => i.name === item.name && i.size === item.size && i.lastModified === item.lastModified), 1);
        this.setState({ files: this.state.files });
    }

    pushFile(item) {
        const file = {};
        file["_id"] = Math.random().toString(36).slice(-8);
        file['file'] = item;
        file['name'] = this.filename.current.value;

        this.setState((state) => {
            state.files.push(file);
            return state;
        })

        this.filename.current.value = "";
    }

    getPotocol() {
        Axios.get('/training/protocol')
            .then(res => {
                if (res.data.success) {
                    this.setState({ data: res.data.data });
                    this.protocolToSelect();
                }
            })
            .catch(err => this.props.notify({ type: 'error', title: "Error", text: err }))
    }

    getSessions(id) {
        Axios.get('/training/session/params?protocol=' + id)
            .then(res => {
                if (res.data.success) {
                    this.setState({ sessions: res.data.data });
                }
            })
            .catch(err => this.props.notify({ type: 'error', title: "Error", text: err }));
    }

    componentDidMount() {
        self = this;
        // this.getPotocol();
        this.eventDragMount();
        // this.getUser();
        const { user, data } = this.props.match.params;

        let protos = JSON.parse(atob(data));

        Axios.get('/user/patient/' + user)
            .then(e => {
                this.setState({ patient: e.data.data.a, });
                // eslint-disable-next-line array-callback-return
                protos.map((e, i) => {
                    if (i === 0) {
                        this.getSessions(e._id);
                        this.setState({ default: e._id });
                    }
                    this.setState((state) => {
                        state.protocols.push({ value: e._id, label: e.name + " | " + e.fase });
                        return state;
                    })
                })
            })
            .catch(err => this.props.notify({ type: 'error', title: "Error", text: err }));
    }

    eventDragMount() {
        new Draggable(this.drag.current, {
            itemSelector: ".tf-event",
            eventData: function (eventEl) {
                let title = eventEl.getAttribute("title");
                let id = eventEl.getAttribute("id");
                return {
                    title: title,
                    groupId: Math.random().toString(36).slice(-8),
                    id: Math.random().toString(36).slice(-8),
                    sessionId: id,
                    startTime: "00:00",
                    create: false,
                };
            }
        });
    }

    eventReceive() {}

    eventClick(event) {
        const { title, id, start } = event.event;

        const foundEvent = self.state.events.find(e => e.id === id);
        if (foundEvent) {
            self.openModalEvent(event, title, moment(start).format("YYYY/MM/DD HH:mm:ss"));
        }
    }

    addHour() {}

    drop(event) {
        const { date, draggedEl } = event;
        const { title, id } = draggedEl;

        self.createEvent({
            title: title,
            date: date,
            idSesion: id,
            type: 'session'
        })
    }

    action() {
        this.setState((state) => {
            let a = state.events.filter((e) => e.id !== eventCurrent.event.id);
            state.events = a;
            state.open = false;
            return state;
        })
    }

    openModalEvent(event, title, date) {
        eventCurrent = event;
        eventTitle = title;
        eventDate = date;
        this.setState({ open: true });
    }

    handleDateClick(event) {
        // levantar modal para nuevo evento personalizado
        self.setState({ openEvent: true, evn: event })
    }

    createNewEvent() {
        const { date } = this.state.evn;
        this.createEvent({
            title: eventTitle,
            date: date,
            type: 'event',
            id: Math.random().toString(36).slice(-8),
            description: eventDescription
        });

        this.setState({ openEvent: false });
    }

    getUser() {
        Axios.get("/user/getallpatients")
            .then(res => this.AutocompletePatients(res.data.data))
            .catch(err => this.props.notify({ type: 'error', title: "Error", text: err }))
    }

    AutocompletePatients(data) {
        // eslint-disable-next-line array-callback-return
        data.map(e => {
            this.setState((state) => {
                e['name'] = e.rut + " - " + e.name + " " + e.lastname;
                state.optionsComplete.push(e);
                return state;
            })
        })
    }

    createPlanification() {
        this.setState({ openConfirm: false })
        this.addPlanification(this.state.patient._id)
    }

    openDialog() {
        this.setState({ dialog: true });
    }

    closeDialog() {
        this.setState({ dialog: false });
    }


    addPlanification(userId) {
        const f = new FormData();
        f.append('name', this.state.nameplanning);
        f.append('user', userId);
        f.append('profesional', getUserId().userId);
        f.append('notes', this.state.note);
        f.append('data', JSON.stringify(this.state.events.map(e => ({ idSession: e.idSesion, date: e.date, ...e }))));
        this.state.files.map(file => f.append('files', file.file, file.name === "" ? file.file.name : file.name));

        this.openDialog();

        Axios.post('/user/calendar/planning', f)
            .then(res => {

                if (res.data.success) {
                    this.props.notify({ type: 'success', title: "Exito", text: "Operacion exitosa" })
                }
            })
            .catch(err => {
                this.props.notify({ type: 'error', title: "Error", text: err })
            });
    }

    setNotifyEvent(id, value) {
        this.setState((state) => {
            // eslint-disable-next-line array-callback-return
            state.events.find(e => {
                if (e.id === id) {
                    if (value !== 0) {
                        e['notify_value'] = value;
                        e['notify'] = true;
                    } else {
                        e['notify'] = false;
                    }
                }
            })

            return state;
        })
    }

    eventRender() { }
    select() { }


    render() {
        return (
            <Grid container className="mt-3 bg-white card-plataform">
                <Grid item xs={12} className="box-titulo bg-primario d-flex align-items-center">
                    <p className="text-left ml-3 m-0 fz-18">Calendario</p>
                </Grid>
                <Grid item xs={12} md={3} className="mt-4 px-4">
                    <p className="title-calendar mb-2">Nombre planificación</p>
                    <Grid item xs={12}>
                        <TextField
                            className="w-100 mb-2"
                            id="outlined-multiline-static"
                            variant="outlined"
                            margin="dense"
                            onChange={value => this.setState({ nameplanning: value.target.value })}
                        />
                    </Grid>
                    <p style={{ marginTop: 10 }} className="title-calendar mt-2 mb-3">Paciente</p>
                    <Grid item xs={12}>
                        <h5>{this.state.patient.name} {this.state.patient.lastname}</h5>
                    </Grid>
                    <p className="title-calendar mb-2 mt-3">Protocolos</p>
                    <Grid item xs={12}>
                        <Selet
                            className="w-100"
                            default={this.state.default}
                            data={this.state.protocols}
                            value={(id) => this.getSessions(id)}

                        />
                    </Grid>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" ref={this.drag}>
                        <p className="title-calendar mb-3">Sesiones</p>
                        {this.state.sessions.length === 0 ? <Grid container direction="row" justifyContent="center" alignItems="center">
                            <p className="color-sub-text fz-12">No hay sesiones para mostrar</p>
                        </Grid> : this.state.sessions.map((e, i) => {
                            return (
                                <div key={i} className="card-calendar tf-event" title={e.fullaname} id={e._id}>
                                    <p className="m-0">{e.fullaname} </p>
                                    {/* {e.name} |  */}
                                </div>
                            )
                        })}
                    </Grid>
                </Grid>
                <Grid item xs={12} md={9}>
                    <div className="py-3 pr-4">
                        <FullCalendar select={this.select} weekends={true} eventRender={this.eventRender} style={{ zIndex: "0" }} eventLimit={true} header={this.headers} customButtons={this.buttons} dateClick={this.handleDateClick} eventReceive={this.eventReceive} eventClick={this.eventClick} editable={true} droppable={true} drop={this.drop} events={this.state.events} plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]} locale={esLocale} rerenderDelay={0} eventDurationEditable={true} timeZone="local" ref={this.calendarComponentRef} />
                    </div>
                </Grid>
                <ModalSimple open={this.state.open} title="Planificación" textCancel="Cancelar" textAction="Eliminar" action={() => this.action()} cancel={() => this.setState({ open: false })}>
                    <Grid container>
                        <Grid item xs={12} className="mb-4">
                            <p className="color-tit-text mb-1 fz-21">{eventTitle}</p>
                            <p className="color-sub-text mb-2 fz-14">Fecha {moment(eventDate).format(" YYYY/MM/DD")}</p>
                        </Grid>
                    </Grid>
                </ModalSimple>
                <ModalSimple open={this.state.openEvent} title="Nuevo evento" textCancel="Cancelar" textAction="Crear" action={() => this.createNewEvent()} cancel={() => this.setState({ openEvent: false })}>
                    <Grid container>
                        <Grid item xs={12} className="mb-4">
                            <p className="color-tit-text mb-1 fz-21">Titulo</p>
                            <TextField
                                className="w-100 mb-2"
                                id="openevent1"
                                variant="outlined"
                                margin="dense"
                                defaultValue={this.state.eventTitle}
                                onChange={value => eventTitle = value.target.value}
                            />
                            <h6 className="m-0 color-primario">Descripción</h6>
                            <hr className="my-1"></hr>
                            <TextField
                                className="w-100 mb-2"
                                id="openevent2"
                                multiline
                                rows="4"
                                variant="outlined"
                                defaultValue={this.state.eventDescription}
                                onChange={value => eventDescription = value.target.value}
                            />
                        </Grid>
                    </Grid>
                </ModalSimple>
                <ModalSimple open={this.state.openNotes} title="Notas" textCancel="Cancelar" textAction="Aceptar" action={() => this.setState({ note: note, openNotes: false })} cancel={() => this.setState({ openNotes: false })}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextField
                                className="w-100 mb-2"
                                id="outlined-multiline-static"
                                multiline
                                rows="4"
                                variant="outlined"
                                defaultValue={this.state.note}
                                onChange={value => note = value.target.value}
                            />
                        </Grid>
                    </Grid>
                </ModalSimple>
                <ModalSimple open={this.state.openFiles} title="Videos" textCancel="Cancelar" textAction="Aceptar" action={() => this.setState({ openFiles: false })} cancel={() => this.setState({ openFiles: false })}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} container direction="row" justifyContent="center" alignItems="center">
                            <Grid item xs={10}>
                                <TextField
                                    className="w-100"
                                    id="outlined-margin-dense1"
                                    margin="dense"
                                    variant="outlined"
                                    name="Nombre"
                                    ref={this.filename}

                                />
                            </Grid>
                            <Grid item xs={1} container direction="row" justifyContent="flex-end" alignItems="center" className="pt-1">
                                <input
                                    type="file"
                                    id="avatar"
                                    name="files"
                                    accept=".png,.jpg,.pdf,audio/*,video/*,.doc,.docx,.odt" style={{ display: "none" }} onChange={ev => { this.pushFile(ev.target.files[0]) }} />
                                <label className="m-0">
                                    <label
                                        htmlFor="avatar"
                                        className="btn-square"><span className="icon-folder_upload icon-display fz-21"></span>
                                    </label>
                                </label>
                            </Grid>
                            <Grid item xs={1} container direction="row" justifyContent="flex-end" alignItems="center" className="pt-1">
                                <button className="btn-square" onClick={() => this.pushFile()}><span className="icon-plus"></span></button>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} md={10}>
                                <p className="fz-10 color-sub-text my-3">Nombre</p>
                            </Grid>
                            <Grid item xs={12} md={2} container direction="row" justifyContent="center" alignItems="center">
                                <p className="fz-10 color-sub-text my-3">Acción</p>
                            </Grid>
                        </Grid>
                        <Grid className="MuiGrid-root border-right-tf border-left-tf border-top-tf">
                            {this.state.files.map((e, i) => {

                                let icon = e.file.type.split('/')[1]
                                return (
                                    <Grid container className="border-bottom-tf py-2" key={i}>
                                        <Grid item xs={12} md={3} container direction="row" justifyContent="center" alignItems="center">
                                            <div className="icon-info-lg">
                                                <span style={{ display: "table-cell", verticalAlign: "middle" }} className={'mimetypes-' + icon}></span>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={7} container direction="row" justifyContent="flex-start" alignItems="center">
                                            <p className="m-0 fz-14 color-sub-text">{e.name}</p>
                                        </Grid>
                                        <Grid item xs={12} md={2} container direction="row" justifyContent="center" alignItems="center">
                                            <button className="btn-carrusel d-flex align-items-center justify-content-center" onClick={() => this.removeFile(e)}><span className="icon-trash"></span></button>
                                        </Grid>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid>
                </ModalSimple>
                <ModalSimple open={this.state.openConfirm} title="Crear planificación" textCancel="Cancelar" textAction="Aceptar" action={() => this.createPlanification()} cancel={() => this.setState({ openConfirm: false })}>
                    <p className="color-sub-text">¿Seguro desea crear la planificación?</p>
                </ModalSimple>
            </Grid>
        )
    }
}


export default CalendarView;
