import * as Action from "./actions"

export const initialState = {
    isEditing: false,

    currentProtocolId:  "",
    currentSessionId:   "",

    currentTrainings: [],
    
    //   Modals
    protocolModal: false,
    sessionModal: false,
    cloneProtocolModal: false,
    removeModal: false,
  
    //   Filters
    protocolFilter: "",
    filteredProtocols: [],

    // Protocolos
    protocolName: "",
    complementName: "",
    protocolDescription: "",
    protocolObjGeneral: "",
    protocolObjSpecific: "",
    protocolPublicNote: "",
    protocolTags: [],

    // Sessions
    sessionName: "",
    sessionComplementName: "",
    sessionDescription: "",
    sessionFiles: [],

    // Clone
    protocolToClone: null,
};
   
export const reducer = (state, action) => {
    switch (action.type) {
        case Action.USE_EFFECT_PARAMS:
            return {
                ...state,
                currentSessionId: action.payload.session,
                currentProtocolId: action.payload.protocol,
            }

        case Action.CHANGE_PROTOCOL:
            return {
                ...state,
                currentProtocolId: action.payload,
                currentSessionId: "",
                currentTrainings: []
            }

        case Action.CHANGE_SESSION:
            return {
                ...state,
                currentSessionId: action.payload._id,
                currentTrainings: action.payload.trainings
            }

        case Action.EDITING_PROTOCOL:
            return {
                ...state,
                isEditing: true,
                protocolModal: true,
                protocolName:           action.payload.name,
                complementName:         action.payload.fase,
                protocolDescription:    action.payload.description,
                protocolObjGeneral:     action.payload.general_objetive,
                protocolObjSpecific:    action.payload.specific_objetive,
                protocolPublicNote:     action.payload.public_note,
                protocolTags:           action.payload.tags,
            };

        case Action.EDITING_SESSION:
            return {
                ...state,
                isEditing: true,
                sessionModal: true,
                sessionName:           action.payload.name,
                sessionComplementName: action.payload.fullaname,
                sessionDescription:    action.payload.description,
                sessionFiles:          action.payload.files,
            };

        case Action.CLEAR_STATE_PROTOCOL:
            return {
                ...state,
                isEditing: false,
                protocolModal: false,
                removeModal: false,
                cloneProtocolModal: false,
                protocolName: "",
                complementName: "",
                protocolDescription: "",
                protocolObjGeneral: "",
                protocolObjSpecific: "",
                protocolPublicNote: "",
                protocolTags: [],
                protocolFilter: "",
                currentProtocolId: "",
                currentSessionId: "",
                currentTrainings: [],
            };

        case Action.CLEAR_STATE_SESSION:
            return {
                ...state,
                isEditing: false,
                sessionModal: false,
                removeModal: false,
                cloneProtocolModal: false,
                protocolToClone: null,
                sessionName: "",
                sessionComplementName: "",
                sessionDescription: "",
                sessionFiles: [],
            };

        case Action.SET_FIELDS:
            return {
                ...state,
                [action.payload.field]: action.payload.value,
            };

        case Action.SET_OPEN_MODAL:
            return {
                ...state,
                [action.payload.modal]: action.payload.value,
            };

        default:
            return state;
    }
};
  