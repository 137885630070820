import { useQuery } from '@tanstack/react-query';
import API from "../services/api";

const api = new API();

export const useGetAdminClinics = () => {
    const adminClinicsQuery = useQuery({
        queryKey: ["admin-clinics"],
        queryFn: () => api.getAllAdminClinics()
            .then((res) => {
                return (
                    res.data
                    .map((e) => (
                        { label: e.name + " " + e.lastname, value: e._id }
                    ))
                    .sort()
                )
            }),
        refetchOnWindowFocus: false,
    });

    return {
        adminClinicsQuery
    }
}
