import React, { useEffect, useReducer, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router';
import { useTranslation } from "react-i18next";

import { Virtuoso } from "react-virtuoso";

import { useGetProtocols, useGetSessions } from '../../hooks';
import { initialState, reducer } from './reducer';

import { Container, Row, Col, SearchBar, Modal, Dropdown } from "trainfes-components-library";
import { Fab, Button } from '@mui/material';
import Add from '@mui/icons-material/Add'
import Edit from '@mui/icons-material/Edit';
import CardLayoutPlatform from "../../components/CardLayoutPlatform";
import { Card as CardProtocol } from './components/Protocolos/Card';
import { Card as CardSession } from './components/Sessions/Card';
import { ModalNewProtocol } from './components/ModalNewProtocol';
import { ModalNewSession } from './components/ModalNewSession';

import * as Action from "./reducer/actions"
import * as S from "./sessionTherapist.styles"
import SessionsDetail from './components/sessionsDetail';

import { formSessionValidation, formValidation } from './ProtocolsPage.utils';
import { getUserId } from '../../lib/router';
import { connect } from '../../store';
import { filterValidation } from '../../lib/textValidation';


const ProcotolsPage = ({ notify }) => {
    const { protocol: ProtocolParam, session: SessionParam } = useParams()
    const history = useHistory()
    const { t } = useTranslation("protocols");
    const [state, dispatch] = useReducer(reducer, initialState);
    const [protocolCreated, setProtocolCreated] = useState(false);

    const { protocolsDropdown, protocolsQuery, addNewProtocol, updateProtocol, cloneProtocol, deleteProtocol, protocolLoading } = useGetProtocols();
    const { sessionsData, addNewSession, editSession, deleteSession, cloneSession, cloneSessionToOtherProtocol, sessionsLoading } = useGetSessions();

    const removeParams = () => {
        if (ProtocolParam || SessionParam) history.replace("/content/protocols")
    }

    const selectSessionFromParams = async () => {
        handleToggleProtocol({ _id: ProtocolParam })

        if (sessionsData.data) {
            const session = sessionsData.data.filter((item) => item._id === SessionParam)[0]
            handleToggleSession(session)

            removeParams()
        }
    }


    useEffect(() => {
        if (!ProtocolParam || !SessionParam) return;
        selectSessionFromParams()
    }, [sessionsData.data])


    const virtuoso = useRef(null);

    useEffect(() => {
        if (!protocolsQuery.data) return;
        if (state.protocolFilter === "") {
            return dispatch({ type: Action.SET_FIELDS, payload: { field: "filteredProtocols", value: protocolsQuery.data } })
        }
        const filterWords = filterValidation(state.protocolFilter).split(/\s+/);
        const filteredProtocols = protocolsQuery.data.filter((item) => {
            const rowString = filterValidation(
                [item.name, item.description, item.fase].join(";")
            )
            return filterWords.every((word) => rowString.includes(word))
        })
        dispatch({ type: Action.SET_FIELDS, payload: { field: "filteredProtocols", value: filteredProtocols } })
    }, [state.protocolFilter, protocolsQuery.data])


    useEffect(() => {
        if (!protocolsQuery.data) return;
        const indexProtocol = state.filteredProtocols.findIndex((item) => item._id === state.currentProtocolId)

        if (virtuoso.current) {

            virtuoso.current.scrollToIndex({
                index: Number(indexProtocol),
                align: "start",
                behavior: "auto"
            });
        }
    }, [virtuoso.current, protocolsQuery.data])

    useEffect(() => {
        if (!protocolsQuery.data || !protocolCreated) return;

        handleToggleProtocol(protocolsQuery.data[0]); // Seleccionar el primer protocolo
    }, [protocolsQuery.data, protocolCreated]);

    const handleToggleProtocol = (e) => {
        if (state.currentProtocolId === e._id || protocolLoading) return;
        dispatch({ type: Action.CHANGE_PROTOCOL, payload: e._id })
        sessionsData.mutate(e._id)
    }

    const handleToggleSession = (e) => {
        if (state.currentSessionId === e._id) return;
        dispatch({ type: Action.CHANGE_SESSION, payload: e })
    }

    const Header = () => null;
    const Footer = () => <div style={{ height: "200px", width: "100%" }}></div>;

    const createProtocol = () => {
        if (protocolLoading || !formValidation(state, t, notify)) return;

        const form = new FormData();
        form.append("name", state.protocolName);
        form.append("fase", state.complementName);
        form.append("idProfessional", getUserId().userId);
        form.append("description", state.protocolDescription);
        form.append("general_objetive", state.protocolObjGeneral);
        form.append("specific_objetive", state.protocolObjSpecific);
        form.append("public_note", state.protocolPublicNote);
        form.append("private_note", state.protocolTags.join());

        if (state.isEditing) return editProtocol(form)

        try {
            addNewProtocol(form);
            dispatch({ type: Action.CLEAR_STATE_PROTOCOL });
            notify({ type: 'success', title: t('alerts.protocol.successTitle'), text: t('alerts.protocol.successCreate') });
            setProtocolCreated(true);
        } catch (error) {
            notify({ type: 'error', title: t('alerts.protocol.errorTitle'), text: t('alerts.session.errCreate') })
        }
    }

    const editProtocol = (form) => {
        try {
            updateProtocol({ id: state.currentProtocolId, data: form })
            dispatch({ type: Action.CLEAR_STATE_PROTOCOL })
            notify({ type: "success", title: t("protocol"), text: t("notify.success") })
        } catch (error) {
            notify({ type: "error", title: t("protocol"), text: error.toString() })
        }
    }

    const handleCloneProtocol = () => {
        if (protocolLoading) return;

        try {
            cloneProtocol(state.currentProtocolId)
            notify({ type: "success", title: t("protocol"), text: t("notify.clone") })
        } catch (error) {
            notify({ type: "error", title: t("protocol"), text: error.toString() })
        }
    }

    const handleDeleteProtocol = () => {
        if (protocolLoading) return;

        try {
            deleteProtocol(state.currentProtocolId)
            dispatch({ type: Action.CLEAR_STATE_PROTOCOL })
            notify({ type: "success", title: t("protocol"), text: t("notify.delete") })
            setProtocolCreated(false)
        } catch (error) {
            notify({ type: "error", title: t("protocol"), text: error.toString() })
        }
    }


    const createSession = () => {
        if (sessionsLoading || !formSessionValidation(state, t, notify)) return;

        const dataToPost = {
            description: state.sessionDescription,
            files: state.sessionFiles,
            fullaname: state.sessionComplementName,
            idProfessional: getUserId()._id,
            links: [],
            name: state.sessionName,
            protocolId: state.currentProtocolId,
        }

        if (state.isEditing) return handleEditSession(dataToPost)

        try {
            addNewSession(dataToPost)
            sessionsData.mutate(state.currentProtocolId)
            dispatch({ type: Action.CLEAR_STATE_SESSION })
            notify({ type: 'success', title: 'Sesión', text: t('alerts.session.successCreate') })
        } catch (error) {
            notify({ type: 'error', title: 'Error', text: t('alerts.session.errCreate') })
        }
    }

    const handleEditSession = (data) => {
        try {
            editSession({ id: state.currentSessionId, data })
            sessionsData.mutate(state.currentProtocolId)
            dispatch({ type: Action.CLEAR_STATE_SESSION })
            notify({ type: 'success', title: t("protocol"), text: t('notify.success_session') })
        } catch (error) {
            notify({ type: 'error', title: 'Error', text: t('alerts.session.errCreate') })
        }
    }

    const handleCloneSession = () => {
        if (sessionsLoading) return;

        try {
            cloneSession(state.currentSessionId)
        } catch (error) {
            notify({ type: 'error', title: 'Error', text: t('alerts.session.errCreate') })
        }
    }

    const handleCloneToOtherProtocol = () => {
        if (sessionsLoading) return;

        try {
            cloneSessionToOtherProtocol({ idProtocol: state.protocolToClone, idSession: state.currentSessionId })
            dispatch({ type: Action.CLEAR_STATE_SESSION })
            notify({ type: "success", title: t("formProtocol.clone_session"), text: t("formProtocol.cloned_session") });
        } catch (error) {
            notify({ type: "error", title: t("formProtocol.clone_session"), text: t("formProtocol.session_could_not_be_cloned") });
        }
    }

    const handleDeleteSession = () => {
        if (sessionsLoading) return;

        try {
            deleteSession(state.currentSessionId)
            sessionsData.mutate(state.currentProtocolId)
            dispatch({ type: Action.CLEAR_STATE_SESSION })
            dispatch({ type: Action.SET_FIELDS, payload: { field: "currentSessionId", value: "" } })
            dispatch({ type: Action.SET_FIELDS, payload: { field: "currentTrainings", value: [] } })
            notify({ type: "success", title: t("session.deleted_session"), text: t("session.deleted_session") });
        } catch (error) {
            notify({ type: "error", title: t("session.deleted_session"), text: t("session.session_could_not_be_deleted") })
        }
    }

    if (protocolsQuery.error) return "An error has occurred: " + protocolsQuery.error.message;

    return (
        <>
            <Container>
                <Row cols={12} gap={30}>
                    <Col xs={4}>
                        <CardLayoutPlatform
                            noPaddingBottom
                            padding={"0"}
                            loading={protocolLoading}
                            title={t("protocol")}
                            floatButton={
                                <Fab
                                    size="medium"
                                    color="primary"
                                    onClick={() => dispatch({ type: Action.SET_OPEN_MODAL, payload: { modal: "protocolModal", value: true } })}
                                >
                                    <Add />
                                </Fab>
                            }
                            header={
                                <S.SearchContainer>
                                    <SearchBar
                                        placeholder={t("search")}
                                        value={state.protocolFilter}
                                        onChange={({ target }) => dispatch({ type: Action.SET_FIELDS, payload: { field: "protocolFilter", value: target.value } })}
                                    />
                                </S.SearchContainer>
                            }
                        >
                            {(!protocolsQuery.isLoading && protocolsQuery.data.length === 0) && <S.NoItemsDisplay> <p>{t("no_items_to_display")}</p> </S.NoItemsDisplay>}
                            {!protocolsQuery.isLoading &&
                                <Virtuoso
                                    style={{ height: "calc(100vh - 194px)", padding: "0 0 200px 0" }}
                                    ref={virtuoso}
                                    data={state.filteredProtocols}
                                    components={{ Header, Footer }}
                                    itemContent={(index, protocol) => {
                                        return (
                                            <CardProtocol
                                                key={protocol._id}
                                                e={protocol}
                                                handleToggle={handleToggleProtocol}
                                                currentId={state.currentProtocolId}
                                                handleMenuEdit={() => dispatch({ type: Action.EDITING_PROTOCOL, payload: protocol })}
                                                handleMenuClone={handleCloneProtocol}
                                                handleMenuDelete={handleDeleteProtocol}
                                            />
                                        );
                                    }}
                                />
                            }
                        </CardLayoutPlatform>
                    </Col>

                    <Col xs={4}>
                        <CardLayoutPlatform
                            loading={protocolLoading || sessionsLoading}
                            padding={"0"}
                            backgroundColor="yellow"
                            title={t("sessions")}
                            floatButton={
                                state.currentProtocolId !== "" &&
                                (
                                    <Fab
                                        size="medium"
                                        color="primary"
                                        onClick={() => dispatch({ type: Action.SET_OPEN_MODAL, payload: { modal: "sessionModal", value: true } })}
                                    >
                                        <Add />
                                    </Fab>
                                )}
                        >
                            {(state.currentProtocolId === "") && <S.NoItemsDisplay> <p>{t("select_a_protocol")}</p> </S.NoItemsDisplay>}
                            {(!sessionsData.isLoading && sessionsData.data && sessionsData.data.length === 0) && <S.NoItemsDisplay> <p>{t("no_items_to_display")}</p> </S.NoItemsDisplay>}

                            {(!sessionsData.isLoading && !sessionsData.isError && state.currentProtocolId !== "")
                                && sessionsData.data.map((item) => {
                                    return (
                                        <CardSession
                                            key={item._id}
                                            e={item}
                                            handleToggle={handleToggleSession}
                                            currentSessionId={state.currentSessionId}
                                            handleMenuEdit={() => dispatch({ type: Action.EDITING_SESSION, payload: item })}
                                            handleMenuClone={handleCloneSession}
                                            handleCloneToOtherProtocol={() => dispatch({ type: Action.SET_FIELDS, payload: { field: "cloneProtocolModal", value: true } })}
                                            handleMenuDelete={() => dispatch({ type: Action.SET_FIELDS, payload: { field: "removeModal", value: true } })}
                                        />
                                    )
                                })}
                        </CardLayoutPlatform>
                    </Col>

                    <Col xs={4}>
                        <CardLayoutPlatform
                            title={t("detailsSession")}
                            floatButton={
                                state.currentSessionId !== "" && (
                                    <Fab
                                        size="medium"
                                        color="primary"
                                        onClick={() => history.push(`/sessionsStudio/${state.currentSessionId}`)}
                                    >
                                        {state.currentTrainings.length > 0 ? <Edit /> : <Add />}
                                    </Fab>
                                )
                            }
                        >
                            {(state.currentSessionId === "") && <S.NoItemsDisplay> <p>{t("select_a_session")}</p> </S.NoItemsDisplay>}
                            {(!sessionsData.isLoading && state.currentSessionId !== "" && state.currentTrainings.length === 0) && <S.NoItemsDisplay> <p>{t("no_items_to_display")}</p> </S.NoItemsDisplay>}

                            <SessionsDetail
                                idProtocolo={state.currentProtocolId}
                                idSession={state.currentSessionId}
                                trainings={state.currentTrainings}
                            />
                        </CardLayoutPlatform>
                    </Col>
                </Row>
            </Container>

            {/* PROTOCOLS MODALS */}
            <Modal
                title={state.isEditing ? t('formProtocol.editTitle') : t('formProtocol.title')}
                isOpen={state.protocolModal}
                buttons={
                    <>
                        <Button
                            variant="outlined"
                            size="large"
                            onClick={() => dispatch({ type: Action.CLEAR_STATE_PROTOCOL })}
                        >{t('formProtocol.cancel')}</Button>
                        <Button
                            variant="contained"
                            size="large"
                            disabled={protocolLoading}
                            onClick={createProtocol}
                        >{t('formProtocol.accept')}</Button>
                    </>
                }
            >
                <ModalNewProtocol t={t} state={state} dispatch={dispatch} />
            </Modal>


            {/* SESSIONS MODALS */}
            <Modal
                title={state.isEditing ? t('session.edit') : t('session.title')}
                isOpen={state.sessionModal}
                buttons={
                    <>
                        <Button
                            variant="outlined"
                            size="large"
                            onClick={() => dispatch({ type: Action.CLEAR_STATE_SESSION })}
                        >{t('formProtocol.cancel')}</Button>
                        <Button
                            variant="contained"
                            size="large"
                            disabled={sessionsLoading}
                            onClick={createSession}
                        >{t('formProtocol.accept')}</Button>
                    </>
                }
            >
                <ModalNewSession t={t} state={state} dispatch={dispatch} />
            </Modal>

            <Modal
                title={t("formProtocol.copy_this_session_to_other_protocol")}
                isOpen={state.cloneProtocolModal}
                buttons={
                    <>
                        <Button
                            variant="outlined"
                            size="large"
                            onClick={() => dispatch({ type: Action.CLEAR_STATE_SESSION })}
                        >{t('formProtocol.cancel')}</Button>
                        <Button
                            variant="contained"
                            size="large"
                            disabled={!state.protocolToClone}
                            onClick={handleCloneToOtherProtocol}
                        >{t('formProtocol.accept')}</Button>
                    </>
                }
            >
                <S.DropdownContainer>
                    <Dropdown
                        placeholder={t("select_a_protocol")}
                        portal
                        options={protocolsDropdown}
                        onChange={(value) => dispatch({ type: Action.SET_FIELDS, payload: { field: "protocolToClone", value } })}
                        value={state.protocolToClone}
                    />
                </S.DropdownContainer>
            </Modal>

            <Modal
                title={t("session.delete_session")}
                isOpen={state.removeModal}
                buttons={
                    <>
                        <Button
                            variant="outlined"
                            size="large"
                            onClick={() => dispatch({ type: Action.SET_FIELDS, payload: { field: "removeModal", value: false } })}
                        >{t('formProtocol.cancel')}</Button>
                        <Button
                            variant="contained"
                            size="large"
                            onClick={handleDeleteSession}
                        >{t('formProtocol.accept2')}</Button>
                    </>
                }
            >
                <p>{t("session.do_you_want_to_delete_this_session")}</p>
            </Modal>
        </>
    )
}

export default connect(ProcotolsPage)