import React, { useState, useEffect } from "react";
import { Modal, Button, Input, Dropdown } from "trainfes-components-library";

import ButtonAdd from "../../../../components/buttonAdd";
import ProgressBar from "../../../../components/progressBar";
import LoaderContainer from "../../../../components/loader/container";
import { eventListener } from "../../../../services/socket";
import { connect } from "../../../../store";
import { useTranslation } from "react-i18next";
import ImgUpload from "../../../../assets/img/illustrations/upload.svg";
import "../../../../assets/css/video.css";

import { mediaFilter } from '../../../../lib/media.filters';

import { trackWindowScroll } from "react-lazy-load-image-component";

import MediaManager from "../../media";
import {
  CardsContainer,
  ImgStyled,
  Label,
  ModalDescription,
  TextArea,
  VideoModalContainer,
} from "../../Library.styles";
import { Card } from "../../components/Card";
const mediaManager = new MediaManager();

const Imagenes = (props) => {
  const { t } = useTranslation("library");
  const [load, setLoad] = useState(true);
  const [openVideo, setOpen] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);
  const [addFile, setAddFile] = useState(false);
  const [current, setCurrent] = useState(null);
  const [removeId, setRemoveId] = useState(null);
  const [percentUpload, setPercentUpload] = useState(0);
  const [dataUpload, setDataUpload] = useState("0 / 0");
  const [step, setStep] = useState(0);
  const [video, setVideo] = useState(null);
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [cover, setCover] = useState(null);
  const [cat, setCat] = useState("");
  const [device, setDevice] = useState("");

  useEffect(() => {
    getData(true);

    eventListener(mediaManager.evFileUpload, async (data) => {
      if ((await mediaManager.getParseFileCurret()) === data.id) {
        setPercentUpload(data.progress);
        setDataUpload(data.data);
      }
    });
  }, []);

  const getData = (loader = false) => {
    setLoad(loader);
    mediaManager
      .getImages()
      .then((res) => {
        setLoad(false);
        props.setImages(res);
      })
      .catch((err) => console.log(err));
  };

  const clear = () => {
    // setVideo(null)
    setName("");
    setDesc("");
    setCover(null);
    setCat(null);
    setDevice(null);
  };

  const notify = (message) => {
    props.notify({ type: "error", title: t("images"), text: message });
    return false;
  };

  const subir = () => {
    if (!name || name.length === 0 || name === "")
      return notify(t("notify.name"));
    if (!cat) return notify(t("notify.category"));
    if (!device) return notify(t("notify.device"));
    if (!video) return notify(t("notify.image"));

    setStep(2);
    mediaManager.setCurrentFile(video);
    mediaManager
      .uploadImage({
        media: video,
        name: name,
        desc: desc,
        cover: cover,
        cat: cat,
        device: device,
      })
      .then(() => {
        getData();
        setStep(3);
        clear();
      })
      .catch((err) => console.log(err));
  };

  const remove = () => {
    if (removeId != null) {
      mediaManager
        .deleteMedia(removeId._id)
        .then(() => {
          getData();
          setOpenRemove(false);
        })
        .catch((err) => console.log(err));
    }
  };

  const categoriesOptions = props.store.categories_media.map((category) => {
    return { value: category._id, label: category.name };
  });

  const devicesOptions = props.store.devices_media.map((device) => {
    return { value: device._id, label: device.name };
  });

  return (
    <LoaderContainer active={load}>
      <>
        <CardsContainer>
          {props.store.images
            .filter(
              (f) =>
                f.cat._id.includes(props.sidebarFilterState.category) ||
                props.sidebarFilterState.category === ""
            )
            .filter(
              (f) =>
                f.device._id.includes(props.sidebarFilterState.device) ||
                props.sidebarFilterState.device === ""
            )
            .filter(
              (f) => mediaFilter(f, props.filter)
            )
            .map((e, i) => {
              return (
                <Card
                  key={i}
                  img={e.src}
                  onClick={() => {
                    setOpen(true);
                    setCurrent(e);
                  }}
                  scrollPosition={props.scrollPosition}
                  filename={e.name}
                  onClickDelete={() => {
                    setOpenRemove(true);
                    setRemoveId(e);
                  }}
                />
              );
            })}
        </CardsContainer>

        <ButtonAdd action={() => setAddFile(true)} />

        {current && (
          <Modal
            title={current.name}
            width="fit-content"
            isOpen={openVideo}
            handleClose={() => setOpen(false)}
            buttons={
              <Button
                label={t("close")}
                width="142px"
                onClick={() => setOpen(false)}
              />
            }
          >
            <ImgStyled src={current.src} className="" alt="" />

            <ModalDescription>{current.desc}</ModalDescription>
          </Modal>
        )}

        {removeId && (
          <Modal
            title={`${t("sure_you_want_to_delete")} ${removeId.name}?`}
            isOpen={openRemove}
            handleClose={() => setOpenRemove(false)}
            buttons={
              <>
                <Button
                  type="ghost"
                  label={t("cancel")}
                  onClick={() => setOpenRemove(false)}
                  width="142px"
                />
                <Button
                  label={t("delete")}
                  width="142px"
                  onClick={() => {
                    remove();
                    setOpenRemove(false);
                  }}
                />
              </>
            }
          ></Modal>
        )}

        <Modal
          title={t("upload_images")}
          isOpen={addFile}
          handleClose={() => setAddFile(false)}
          buttons={
            <>
              <Button
                type="ghost"
                label={t("cancel")}
                onClick={() => setAddFile(false)}
                width="142px"
              />
              <Button
                label={t("upload")}
                width="142px"
                onClick={() => subir()}
              />
            </>
          }
        >
          <VideoModalContainer>
            <Input
              placeholder={t("image_name")}
              value={name}
              onChange={(v) => setName(v.target.value)}
            />
            <div className="_dropdowns">
              <Dropdown
                key={`category-${cat}`}
                placeholder={t("category")}
                options={categoriesOptions}
                value={categoriesOptions.filter((e) => e.value === cat)[0]}
                onChange={(c) => setCat(c.value)}
                type="line"
                portal
              />
              <Dropdown
                key={`device-${device}`}
                placeholder={t("devices")}
                options={devicesOptions}
                value={devicesOptions.filter((e) => e.value === device)[0]}
                onChange={(c) => setDevice(c.value)}
                type="line"
                portal
              />
            </div>

            <div className="_description">
              <Label>{t("desc")}</Label>
              <TextArea
                value={desc}
                onChange={(v) => setDesc(v.target.value)}
              ></TextArea>
            </div>

            {step === 0 ? (
              <div className="browse-files text-center my-2">
                <label htmlFor="fileupload" className="custom-file-upload">
                  <img src={ImgUpload} className="img-fluid" alt="Subir"></img>
                </label>
                <p className="fw-600 color-sub-text py-3">
                  {t("Search_here_for_your_image_from_your_computer")}
                  <br></br>
                  {t("to_start_uploading_it_to_the_library")}
                </p>
                <label htmlFor="fileupload" className="btn-purple-tf mx-auto">
                  {t("search_your_image")}
                </label>
              </div>
            ) : step === 1 ? (
              <div className="browse-files text-center my-2">
                <img src={cover} className="img-fluid" alt="Subir"></img>
                <p className="fw-600 color-sub-text py-3">
                  {video && video.name}
                </p>
                <label htmlFor="fileupload" className="btn-purple-tf mx-auto">
                  {t("search_other_image")}
                </label>
              </div>
            ) : step === 2 ? (
              <div className="browse-files text-center my-2">
                <img src={ImgUpload} className="img-fluid" alt="Subir"></img>
                <p className="fw-600 color-sub-text py-3">
                  {t("uploading_your_image")}
                </p>
                <ProgressBar value={percentUpload} />
                <div className="text-center">
                  <p>
                    <strong>{dataUpload}</strong>
                  </p>
                </div>
              </div>
            ) : step === 3 ? (
              <div className="browse-files text-center my-2">
                <img src={ImgUpload} className="img-fluid" alt="Subir"></img>
                <p className="fw-600 color-sub-text py-3">
                  {t("your_image_has_been_uploaded_successfully")}
                </p>
                <label
                  htmlFor="fileupload"
                  className="btn-purple-tf mx-auto"
                  onClick={() => setStep(0)}
                >
                  {t("uploading_other_image")}
                </label>
              </div>
            ) : null}
          </VideoModalContainer>
        </Modal>
      </>
      <input
        type="file"
        id="fileupload"
        accept="image/png, image/jpeg, image/gif"
        style={{ display: "none" }}
        onChange={async (file) => {
          if (file.target.files[0].size > 5000000) {
            props.notify({
              type: "error",
              title: t("notify.error_title"),
              text: t("notify.image_size"),
            });
            return;
          }
          if (
            !file.target.files[0].type.includes("png") &&
            !file.target.files[0].type.includes("jpeg") &&
            !file.target.files[0].type.includes("gif")
          ) {
            props.notify({
              type: "error",
              title: t("notify.error_title"),
              text: t("notify.image_type"),
            });
            return;
          }
          setVideo(file.target.files[0]);
          setStep(1);
          setCover(mediaManager.getCover(file.target.files[0]));
        }}
      />
    </LoaderContainer>
  );
};

export default connect(trackWindowScroll(Imagenes));
