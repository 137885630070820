import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, MenuItem } from '@material-ui/core';


const useStyles = makeStyles({
    outlined: {
        borderColor: 'red',
        width: '100%'
    },

    selectMenu: {
        margin: '10px 15px',
    },
});


export default function MultilineTextFields(props) {
    const classes = useStyles();
    const items = () => props.data != null ? props.data : [];
    const change = value => {
        if (props.value !== undefined) props.value(value.target.value);
    }

    return (
        <div noValidate autoComplete="off">
            <TextField className={classes.outlined} style={{ width: '100%' }}
                id={"outlined-select-currency" + (Math.random() - Math.floor(0.5)).toString()}
                select
                label={props.name}
                value={props.default}
                variant="outlined"
                margin="dense"
                onChange={change}
                disabled={props.disabled}
            >

                {items().map(option => (
                    <MenuItem key={option.value || ""} value={option.value || ""} className={classes.selectMenu}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
        </div>
    );
}

MultilineTextFields.defaultProps = {
    disabled: false
}