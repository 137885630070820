import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Expand from "../../../../components/expand";
import {
  ArrowPatientCardIcon,
} from "../../../ClinicalRecordsPage/components/PatientCard/icons";
import * as S from "./protocolo.styles";

import { Row, Col, Container } from "trainfes-components-library";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ContentCopy from '@mui/icons-material/ContentCopy';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export const Card = ({
  e,
  currentId,
  handleToggle,
  handleMenuEdit,
  handleMenuClone,
  handleMenuDelete,
  active,
}) => {
  const { t } = useTranslation("protocols");

  const [showBody, setShowBody] = useState(false);
  const handleToggleBody = () => setShowBody((prev) => !prev);

  const handleClic = (e) => {
    if (currentId !== e._id) handleToggle(e);
    handleToggleBody();
  };

  useEffect(() => {
    if (currentId !== e._id) {
      setShowBody(false);
    } else {
      setShowBody(true);
    }
  }, [currentId, active]);

  const getTags = (str) => {
    let tags = str.trim().split(",");
    return tags.join(" - ");
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (callBack) => {
    return () => {
      handleClose();
      callBack();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <S.CardContainer active={currentId === e._id} id={currentId}>
      <S.CardHeader onClick={() => handleClic(e)}>
        <div className="header--info">
          <p className="_title">{e.name}</p>
          <p className="_sub-title">{e.fase}</p>
        </div>

        <div className={`_icon ${showBody ? "_active" : ""}`}>
          <ArrowPatientCardIcon />
        </div>
      </S.CardHeader>

      {showBody && (
        <S.CardBody>
          <div>
            <S.DescriptionTitle>{t("desc")}</S.DescriptionTitle>
            <S.Hr />
          </div>
          <Container>
            <Row cols={12}>
              <Col xs={11} className="padding-0">
                <S.Description>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: e.description,
                    }}
                  ></span>
                </S.Description>
              </Col>

              <Col xs={1}>
                <IconButton
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClickListItem}
                  aria-label="Example">
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem onClick={handleMenuItemClick(handleMenuEdit)}>
                    <ListItemIcon>
                      <Edit fontSize="small" />
                    </ListItemIcon>
                    {t("formProtocol.edit")}
                  </MenuItem>
                  <MenuItem onClick={handleMenuItemClick(handleMenuClone)}>
                    <ListItemIcon>
                      <ContentCopy fontSize="small" />
                    </ListItemIcon>
                    {t("formProtocol.clone")}
                  </MenuItem>
                  <MenuItem onClick={handleMenuItemClick(handleMenuDelete)}>
                    <ListItemIcon>
                      <Delete fontSize="small" />
                    </ListItemIcon>
                    {t("formProtocol.delete")}
                  </MenuItem>
                </Menu>
              </Col>
            </Row>
          </Container>
          <div>
            <Expand titulo={t("og")}>
              <span
                dangerouslySetInnerHTML={{
                  __html: e.general_objetive,
                }}
              ></span>
            </Expand>

            <Expand titulo={t("oe")}>
              <span
                dangerouslySetInnerHTML={{
                  __html: e.specific_objetive,
                }}
              ></span>
            </Expand>

            <Expand titulo={t("np")}>
              <span
                dangerouslySetInnerHTML={{
                  __html: e.public_note,
                }}
              ></span>
            </Expand>

            <Expand titulo={t("diagnosis")}>
              <span
                dangerouslySetInnerHTML={{
                  __html: getTags(e.private_note),
                }}
              ></span>
            </Expand>
          </div>
        </S.CardBody>
      )}
    </S.CardContainer>
  );
};
