import styled from "styled-components";

export const FormStyled = styled.div`
  & > .muscleselectr--dropdowns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
  }
`;

export const Body = styled.div`
  background: #f3f6fd;
  border: 1px dashed #ced4da;
  border-radius: 10px;
  display: grid;
  grid-gap: 35px 15px;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  place-items: center;
  padding: 15px 18px;
  margin: 34px 0;
  height: 307px;
  max-height: 307px;
  overflow-y: auto;
`;
