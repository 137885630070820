export const formValidation = (state, t, notify) => {
    if (state.name == null || state.name.length === 0 || state.name === "") {
        notify({ type: "error", title: t("subsidiary.subsidiary"), text: t("notify.name") });
        return false;
      }
  
      if ( !state.representative ) {
        notify({
          type: "error",
          title: t("subsidiary.subsidiary"),
          text: t("notify.representative"),
        });
        return false;
      }
  
      if (state.country == null || state.country.length === 0 || state.country === "default" || state.country === "") {
        notify({ type: "error", title: t("subsidiary.subsidiary"), text: t("notify.country") });
        return false;
      }
  
      if (state.region == null || state.region.length === 0 || state.region === "default" || state.region === "") {
        notify({ type: "error", title: t("subsidiary.subsidiary"), text: t("notify.state") });
        return false;
      }
  
      if (state.commune == null || state.commune.length === 0 || state.commune === "default" || state.comune === "") {
        notify({ type: "error", title: t("subsidiary.subsidiary"), text: t("notify.city") });
        return false;
      }
  
      if (state.address == null || state.address.length === 0 || state.address === "") {
        notify({ type: "error", title: t("subsidiary.subsidiary"), text: t("notify.address") });
        return false;
      }

      if (
        // eslint-disable-next-line no-useless-escape
        !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
          state.email
        )
      ) {
        notify({ type: "error", title: t("subsidiary.subsidiary"), text: t("notify.email") });
        return false;
      }

      if (state.phone_one == null || state.phone_one.length === 0 || state.phone_one === "") {
        notify({ type: "error", title: t("subsidiary.subsidiary"), text: t("subsidiary.phone_required") });
        return false;
      }
  
      if (state.specialities == null || state.specialities.length === 0) {
        notify({
          type: "error",
          title: t("subsidiary.subsidiary"),
          text: t("specialties.select_specialty"),
        });
        return false;
      }

      return true
}