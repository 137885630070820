import API from "../../../../../services/api";
import ENDPOINT from "../../../../../services/endpoint";
const api = new API();

class MediaManager {
  _helperUri(data) {
    return data.map((media) => {
      media["src"] = media.src.includes("amazon")
        ? media.src
        : ENDPOINT + media.src;
      return media;
    });
  }

  async getImages() {
    return new Promise((resolve, reject) => {
      api
        .getMediaImages()
        .then((files) => resolve(this._helperUri(files.data)))
        .catch((err) => reject(err));
    });
  }
}

export default MediaManager;
