// -> Convierte una fecha str tipo "dd/mm/yyyy" a una fecha tipo Js. (Para poder aplicar filtrados).
export const dateToJs = (str) => {
  const [day, month, year] = str.split("/");

  return new Date(+year, month - 1, +day);
};

// -> Convierte una fecha str tipo "2022-04-14T19:37:05.610Z" a una fecha tipo Js. (Para poder aplicar filtrados).
export const dateStampToJs = (str) => {
  const [date] = str.split("T");
  const [year, month, day] = date.split("-");

  return new Date(+year, month - 1, +day);
};

// -> Transforma de YYYY-MM-DD a DD/MM/YYYY.
export const transformDate = (date) => {
  const splitedDate = date.split("-");

  if (parseInt(splitedDate[0]) > 1000) {
    const newDate = `${splitedDate[2]}/${splitedDate[1]}/${splitedDate[0]}`;
    return newDate;
  }

  return date.replaceAll("-", "/");
};
