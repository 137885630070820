import { LazyLoadImage } from "react-lazy-load-image-component";
import PhoneInput from "react-phone-input-2";
import styled from "styled-components";

export const HeaderContainer = styled.div`
  padding: 1rem 25px;
`;

export const ViewContainer = styled.div`
  padding: 1rem 0 0 0;
`;

export const IconsContainer = styled.div`
  & > svg {
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
`;

export const Avatar = styled(LazyLoadImage)`
  border-radius: 50%;
`;

const Chip = styled.div`
  border-radius: 4px;
  width: 80px;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  margin: auto;
`;

export const ActiveChip = styled(Chip)`
  background: rgba(73, 195, 158, 0.3);
  color: #49c39e; ;
`;

export const InactiveChip = styled(Chip)`
  background: rgba(253, 2, 2, 0.3);
  color: #fd0202;
`;

// Modal New user
export const FormContainer = styled.div`
  display: grid;
  grid-gap: 22px 12px;
  grid-template-columns: repeat(3, 1fr);
`;

export const Container = styled.div`
  height: calc( 100vh - 45px );
  min-height: 600px;
  overflow-y: auto;
  padding: 30px 26px;
`;

export const FormValidationText = styled.span`
  color: red;
  display: block;
  font-size: 9px;
`;

export const Title = styled.p`
  font-size: 12px;
  color: #000000;
  margin: 0;
  font-weight: 500;
`;

export const ActiveContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 35px;

  & > p {
    font-size: 12px;
    color: #000000;
    margin: 0;
    font-weight: 500;
    margin-right: 35px;
  }

  .__inactive {
    color: #9AA2A8;
    margin-right: 15px;
  }

  .__active {
    color: #49C39E;
    margin-left: 15px;
  }
`;

export const SpecialityContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin: 35px 0;
  place-items: center;
  grid-gap: 12px;

  & > div {
    width: 100%;
  }

  & > .__specialities {
    display: grid;
    grid-template-columns: 60px 1fr;
    place-items: center;

    & > div:first-child {
      place-self: center start;
    }

    & > .__box {
    background: #E3EAFF;
    border-radius: 4px;
    width: 100%;
    height: 100%;

      & > p {
        padding-top: 15px;
        text-align: center;
      }
    }

    & > .__grid {
      display: grid !important;
      grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    }
  }
 
`;

export const PhoneInputStyled = styled(PhoneInput)`
  & > .form-control {
    height: 46px;
    width: 100%;
    border-radius: 4px;
    border: 0.5px solid #9aa2a8;

    &:focus {
      box-shadow: none;
    }
  }

  & > .special-label {
    font-size: 14px;
    color: ${({ focus }) => (focus ? "#281b65" : "#9aa2a8")};
    font-weight: 300;
    left: 15px;
    top: -10px;
    padding: 0 5px;
    transition: color 0.2s;
  }
`;