import styled from "styled-components";

export const RatingStyled = styled.div`
  display: flex;
  align-items: center;

  & > div {
    /* margin-right: 4px; */
  }

  svg {
    fill: ${({ color }) => color};
  }
`;
