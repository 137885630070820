import styled from "styled-components";

export const Title = styled.p`
  margin: 0 0 0 16px;
  flex: 1;
  font-size: 18px;
`;

export const SaveButton = styled.button`
  border: 2px solid #fff;
  color: #fff;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 6px;
  letter-spacing: 1px;
  text-transform: capitalize;
  background-color: transparent;
  margin: 0 5px;
`;

export const Icon = styled.div`
  cursor: pointer;
  fill: white;
  &:hover {
    opacity: 0.7;
  }
`;

export const Toolbar = styled.div`
  position: relative;
  align-items: center;
  background-color: #281b65;
  box-sizing: border-box;
  display: flex;
  height: 45px;
  padding: 0 24px;
  position: relative;
  color: white;
  display: flex;
  width: 100%;
  /* margin-bottom: 15px; */
`;
