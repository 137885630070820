export const musclesWalkfes = (t) => {
  return [
    {
      ch: 1,
      img: {
        name: t("right_quadriceps"),
        category: "60ec7d24449b17e986856ce8",
        position: "right",
        image:
          "https://dashboard.trainfes-cloud.com/assets/Bici/cuadriceps-derecha.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Bici/cuadriceps-derecha.png",
      },
      showImage: true,
    },
    {
      ch: 2,
      img: {
        name: t("left_quadriceps"),
        category: "60ec7d24449b17e986856ce8",
        position: "left",
        image:
          "https://dashboard.trainfes-cloud.com/assets/Bici/cuadriceps-Izquierdo.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Bici/cuadriceps-Izquierdo.png",
      },
      showImage: true,
    },
    {
      ch: 3,
      img: null,
      showImage: false,
    },
    {
      ch: 4,
      img: null,
      showImage: false,
    },
    {
      ch: 5,
      img: null,
      showImage: false,
    },
    {
      ch: 6,
      img: null,
      showImage: false,
    },
  ];
};

export const channelsWalkfes = (t) => {
  return [
    {
      ch: 1,
      index: 100,
      keyId: t("right_quadriceps"),
      name: t("right_quadriceps"),
    },
    {
      ch: 2,
      index: 100,
      keyId: t("left_quadriceps"),
      name: t("left_quadriceps"),
    },
  ];
};

export const musclesFES4 = (t) => {
  return [
    {
      ch: 1,
      img: {
        name: t("right_quadriceps"),
        category: "60ec7d24449b17e986856ce8",
        position: "right",
        image:
          "https://dashboard.trainfes-cloud.com/assets/Bici/cuadriceps-derecha.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Bici/cuadriceps-derecha.png",
      },
      showImage: true,
    },
    {
      ch: 2,
      img: {
        name: t("right_hamstring"),
        category: "60ec7d24449b17e986856ce8",
        position: "right",
        image:
          "https://dashboard.trainfes-cloud.com/assets/Bici/isquiotibiales-derecho.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Bici/isquiotibiales-derecho.png",
      },
      showImage: true,
    },
    {
      ch: 3,
      img: {
        name: t("left_quadriceps"),
        category: "60ec7d24449b17e986856ce8",
        position: "left",
        image:
          "https://dashboard.trainfes-cloud.com/assets/Bici/cuadriceps-Izquierdo.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Bici/cuadriceps-Izquierdo.png",
      },
      showImage: true,
    },
    {
      ch: 4,
      img: {
        name: t("left_hamstring"),
        category: "60ec7d24449b17e986856ce8",
        position: "left",
        image:
          "https://dashboard.trainfes-cloud.com/assets/Bici/isquiotibiales-izquierdo.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Bici/isquiotibiales-izquierdo.png",
      },
      showImage: true,
    },
    {
      ch: 5,
      img: null,
      showImage: false,
    },
    {
      ch: 6,
      img: null,
      showImage: false,
    },
  ];
};

export const channelsFES4 = (t) => {
  return [
    {
      ch: 1,
      index: 100,
      keyId: t("right_quadriceps"),
      name: t("right_quadriceps"),
    },
    {
      ch: 2,
      index: 100,
      keyId: t("right_hamstring"),
      name: t("right_hamstring"),
    },
    {
      ch: 3,
      index: 100,
      keyId: t("left_quadriceps"),
      name: t("left_quadriceps"),
    },
    {
      ch: 4,
      index: 100,
      keyId: t("left_hamstring"),
      name: t("left_hamstring"),
    },
  ];
};

export const musclesFES6Advance = (t) => {
  return [
    {
      ch: 1,
      img: {
        name: t("right_quadriceps"),
        category: "60ec7d24449b17e986856ce8",
        position: "right",
        image:
          "https://dashboard.trainfes-cloud.com/assets/Bici/cuadriceps-derecha.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Bici/cuadriceps-derecha.png",
      },
      showImage: true,
    },
    {
      ch: 2,
      img: {
        name: t("left_quadriceps"),
        category: "60ec7d24449b17e986856ce8",
        position: "left",
        image:
          "https://dashboard.trainfes-cloud.com/assets/Bici/cuadriceps-Izquierdo.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Bici/cuadriceps-Izquierdo.png",
      },
      showImage: true,
    },
    {
      ch: 3,
      img: {
        name: t("right_hamstring"),
        category: "60ec7d24449b17e986856ce8",
        position: "right",
        image:
          "https://dashboard.trainfes-cloud.com/assets/Bici/isquiotibiales-derecho.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Bici/isquiotibiales-derecho.png",
      },
      showImage: true,
    },
    {
      ch: 4,
      img: {
        name: t("left_hamstring"),
        category: "60ec7d24449b17e986856ce8",
        position: "left",
        image:
          "https://dashboard.trainfes-cloud.com/assets/Bici/isquiotibiales-izquierdo.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Bici/isquiotibiales-izquierdo.png",
      },
      showImage: true,
    },
    {
      ch: 5,
      img: {
        name: t("right_gastrocnemius"),
        category: "60ec7d24449b17e986856ce8",
        position: "right",
        image:
          "https://dashboard.trainfes-cloud.com/assets/Bici/gastrocnemios-derecho.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Bici/gastrocnemios-derecho.png",
      },
      showImage: true,
    },
    {
      ch: 6,
      img: {
        name: t("left_gastrocnemius"),
        category: "60ec7d24449b17e986856ce8",
        position: "left",
        image:
          "https://dashboard.trainfes-cloud.com/assets/Bici/gastrocnemios-izquierdo.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Bici/gastrocnemios-izquierdo.png",
      },
      showImage: true,
    },
  ];
};

export const channelsFES6Advance = (t) => {
  return [
    {
      ch: 1,
      index: 100,
      keyId: t("right_quadriceps"),
      name: t("right_quadriceps"),
    },
    {
      ch: 2,
      index: 100,
      keyId: t("left_quadriceps"),
      name: t("left_quadriceps"),
    },
    {
      ch: 3,
      index: 100,
      keyId: t("right_hamstring"),
      name: t("right_hamstring"),
    },
    {
      ch: 4,
      index: 100,
      keyId: t("left_hamstring"),
      name: t("left_hamstring"),
    },
    {
      ch: 5,
      index: 100,
      keyId: t("right_gastrocnemius"),
      name: t("right_gastrocnemius"),
    },
    {
      ch: 6,
      index: 100,
      keyId: t("left_gastrocnemius"),
      name: t("left_gastrocnemius"),
    },
  ];
};

export const musclesFES6Pro = (t) => {
  return [
    {
      ch: 1,
      img: {
        name: t("right_quadriceps"),
        category: "60ec7d24449b17e986856ce8",
        position: "right",
        image:
          "https://dashboard.trainfes-cloud.com/assets/Bici/cuadriceps-derecha.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Bici/cuadriceps-derecha.png",
      },
      showImage: true,
    },
    {
      ch: 2,
      img: {
        name: t("right_hamstring"),
        category: "60ec7d24449b17e986856ce8",
        position: "right",
        image:
          "https://dashboard.trainfes-cloud.com/assets/Bici/isquiotibiales-derecho.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Bici/isquiotibiales-derecho.png",
      },
      showImage: true,
    },
    {
      ch: 3,
      img: {
        name: t("right_gastrocnemius"),
        category: "60ec7d24449b17e986856ce8",
        position: "right",
        image:
          "https://dashboard.trainfes-cloud.com/assets/Bici/gastrocnemios-derecho.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Bici/gastrocnemios-derecho.png",
      },
      showImage: true,
    },
    {
      ch: 4,
      img: {
        name: t("left_quadriceps"),
        category: "60ec7d24449b17e986856ce8",
        position: "left",
        image:
          "https://dashboard.trainfes-cloud.com/assets/Bici/cuadriceps-Izquierdo.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Bici/cuadriceps-Izquierdo.png",
      },
      showImage: true,
    },
    {
      ch: 5,
      img: {
        name: t("left_hamstring"),
        category: "60ec7d24449b17e986856ce8",
        position: "left",
        image:
          "https://dashboard.trainfes-cloud.com/assets/Bici/isquiotibiales-izquierdo.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Bici/isquiotibiales-izquierdo.png",
      },
      showImage: true,
    },
    {
      ch: 6,
      img: {
        name: t("left_gastrocnemius"),
        category: "60ec7d24449b17e986856ce8",
        position: "left",
        image:
          "https://dashboard.trainfes-cloud.com/assets/Bici/gastrocnemios-izquierdo.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Bici/gastrocnemios-izquierdo.png",
      },
      showImage: true,
    },
  ];
};

export const channelsFES6Pro = (t) => {
  return [
    {
      ch: 1,
      index: 100,
      keyId: t("right_quadriceps"),
      name: t("right_quadriceps"),
    },
    {
      ch: 2,
      index: 100,
      keyId: t("right_hamstring"),
      name: t("right_hamstring"),
    },
    {
      ch: 3,
      index: 100,
      keyId: t("right_gastrocnemius"),
      name: t("right_gastrocnemius"),
    },
    {
      ch: 4,
      index: 100,
      keyId: t("left_quadriceps"),
      name: t("left_quadriceps"),
    },
    {
      ch: 5,
      index: 100,
      keyId: t("left_hamstring"),
      name: t("left_hamstring"),
    },
    {
      ch: 6,
      index: 100,
      keyId: t("left_gastrocnemius"),
      name: t("left_gastrocnemius"),
    },
  ];
};
