import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom'
import Main from "../../components/main";
import LoaderContainer from "../../components/loader/container";
import Calendar from "../../components/calendar";
import { useTranslation } from "react-i18next";
import { connect } from "../../store";
import { Row, Col } from "../../components/grid";
import { getUrl } from "../../lib/utils";
import { InputCheckBoxSchedule, InputCheckBox, ModalSync, ModalConfirm, ModalAsync, ChartComplex, UserData, ItemsPlannningsAndEvenstComponent } from './components'
import Ctrl from './controller';

const ClinicalDataPatients = (props) => {
    const history = useHistory();
    const { t, i18n } = useTranslation("data_clinical");
    const [score, setScore] = useState(0);
    const [avatar, setAvatar] = useState("");
    const [patient, setPatient] = useState("");
    const [events, setEvents] = useState([]);
    const [allEvents, setAllEvents] = useState([]);
    const [eventsSchedules, setEventsSchedules] = useState([]);
    const [chartData, setChartData] = useState([0, 0, 0, 0, 0]);
    const [plannings, setPlannings] = useState([]);
    const [schedules, setSchedules] = useState([]);
    const [allSchedules, setAllSchedules] = useState([]);
    const [load, setLoad] = useState(true);
    const [currentEvent, setCurrentEvent] = useState(null);
    const [currentEventSchedule, setCurrentEventSchedule] = useState(null);
    const [openSync, setOpenSync] = useState(false);
    const [openAsync, setOpenAsync] = useState(false);
    const [currentFinally, setCurrentFinally] = useState(null);
    const [confirm, setConfirm] = useState(false);

    useEffect(() => {
        getData(props.match.params.id);
    }, []);

    const addDays = (date, days) => {
        let result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    const getDataRangeFromSelectedDate = () => {
        const dateStorage = Ctrl.getDateStorage();
        if (!dateStorage || !dateStorage.to) {
            throw new Error("No se pudo obtener la fecha 'to' del almacenamiento.");
        }
        const selectedDateFrom = new Date(dateStorage.from);
        const selectedDateTo = new Date(dateStorage.to);

        const fromDate = addDays(selectedDateFrom, -90); 
        const toDate = addDays(selectedDateTo, 90);

        return {
            from: fromDate.toISOString(),
            to: toDate.toISOString()
        };
    }

    const getData = (id) => {
        const date = getDataRangeFromSelectedDate();
        const getPlansEvents = new Promise((resolve) => {
            Ctrl.getPlansAndPrograms(id, date)
                .then(({ plannings, programs }) => {
                    setPlannings(plannings);
                    setSchedules(programs);
                    resolve();
                })
                .catch(err => console.log(err))
        })

        const getSchedule = new Promise((resolve, reject) => {
            Ctrl.scheduleEventsByPatient(id, date)
                .then(res => { setEventsSchedules(res); setAllSchedules(res); resolve(res) })
                .catch(err => reject(err))
        })

        const getEvents = new Promise((resolve, reject) => {
            Ctrl.getEventsPlanningByUser(id, date)
                .then(res => { setEvents(res); setAllEvents(res); resolve(res) })
                .catch(err => reject(err))
        })

        const getChartData = new Promise((resolve, reject) => {
            Ctrl.getFollowChartPatient(id, date)
                .then(res => {
                    setScore(res.score > 0 ? res.score : 0);
                    setPatient(res.name);
                    setAvatar(getUrl(res.avatar));
                    setChartData({
                        percent_success: res.percent_success,
                        percent_pending: res.percent_pending,
                        percent_recover: res.percent_recovery,
                        percent_lost: res.percent_lost,
                        all_realizadas: res.all_realizadas,
                        all_pendientes: res.all_pendientes,
                        all_recuperadas: res.all_recuperadas,
                        all_perdidas: res.all_perdidas,
                        completion: res.completion
                    });
                    resolve(res);
                })
                .catch(err => reject(err))
        })

        Promise.all([getSchedule, getEvents, getChartData, getPlansEvents])
            .then(() => setLoad(false))
            .catch(err => { setLoad(false); console.log(err) })
    };


    const finallyPlanning = (data) => {
        Ctrl.finallyPlanning(data._id)
            .then(() => {
                props.notify({ type: "success", title: t('success'), text: t("notify.success_plan") });
                getData(props.match.params.id);
            })
            .catch(() => props.notify({ type: "error", title: t('error'), text: t("notify.error") }));
    };

    const finallyProgram = (data) => {
        Ctrl.finallyProgram(data._id)
            .then(() => {
                props.notify({ type: "success", title: t('success'), text: t("notify.success_plan") });
                getData(props.match.params.id);
            })
            .catch(() => props.notify({ type: "error", title: t('error'), text: t("notify.error") }));
    }

    const openModalFinally = (type, data) => {
        setCurrentFinally({ type, data });
        setConfirm(true);
    }

    const finallyConfirm = (params) => {
        setConfirm(false);
        const { type, data } = params;
        if (type === 0) finallyPlanning(data);
        else if (type === 1) finallyProgram(data);
    }

    const renderEventContent = (event, props, all) => {
        return <div>
            <p className="ellipsis" style={{ margin: 0, fontSize: 14, color: all.textColor }}>
                {all.timeText}
            </p>
            <p className="ellipsis" style={{ margin: 0, fontSize: 14, color: all.textColor }}>
                <b>{event.title}</b>
            </p>
        </div>
    };

    const openModal = (data) => {
        const { extendedProps, id, startStr, title, groupId } = data;
        if (extendedProps.type === "async") {
            setOpenSync(false);
            setOpenAsync(true);
            setCurrentEvent({ ...extendedProps, id, startStr, title, groupId });
        } else {
            setOpenAsync(false);
            setOpenSync(true);
            setCurrentEventSchedule({ ...extendedProps, id, startStr, title, groupId });
        }
    };

    const onSync = (data) => {
        Ctrl.changeStatusEvent(data._id, data.status)
            .then(() => {
                setOpenSync(false);
                getData(props.match.params.id);
                props.notify({ type: "success", title: t('success'), text: t('stateUpdate') });
            })
            .catch(() => {
                setOpenSync(false);
                getData(props.match.params.id);
                props.notify({ type: "error", title: t('error'), text: t('stateUpdateError') });
            })
    }

    const onAsync = (data) => {
        Ctrl.changeSessionPlanning(data._id, data.status, data.session)
            .then(() => {
                setOpenAsync(false);
                getData(props.match.params.id);
                props.notify({ type: "success", title: t('success'), text: t('stateUpdate') });
            })
            .catch(() => {
                setOpenAsync(false);
                props.notify({ type: "error", title: t('error'), text: t('stateUpdateError') });
            })
    }

    return (
        <Main>
            <Col xs={12}>
                <div className="card-plataform bg-white mb-3">
                    <div className="box-titulo bg-primario d-flex align-items-center">
                        <p className="text-left ml-3 m-0 fz-18">{t("title_detail")}</p>
                    </div>
                    <Row>
                        <Col md={3} className="pr-0">
                            <LoaderContainer active={load} className="my-2 scroll-list-p" style={{ maxHeight: "100vh" }}>
                                <UserData avatar={avatar} name={patient} score={score} />
                                <ChartComplex percentSuccess={chartData.percent_success} percentLost={chartData.percent_lost} percentPending={chartData.percent_pending} percentRecovery={chartData.percent_recover} success={chartData.all_realizadas} lost={chartData.all_perdidas} pending={chartData.all_pendientes} recovery={chartData.all_recuperadas} completion={chartData.completion} />
                                <ItemsPlannningsAndEvenstComponent
                                    planningsChild={plannings && plannings.map((e, i) => <InputCheckBox key={i} data={e} index={i} events={events} allEvents={allEvents} onEvents={ev => setEvents(ev)} onFinallyPlanning={(ev) => openModalFinally(0, ev)} />)}
                                    schedulesChild={schedules && schedules.map((e, i) => <InputCheckBoxSchedule key={i} data={e} index={i} schedules={allSchedules} eventsSchedules={eventsSchedules} onFinallyPlanning={(ev) => openModalFinally(1, ev)} />)}
                                />
                            </LoaderContainer>
                        </Col>
                        <Col md={9} className="pl-0">
                            <LoaderContainer active={load}>
                                <div className="pr-3 pl-0">
                                    <Calendar
                                        locale={i18n.language}
                                        headers={{
                                            left: "goBack prev next today",
                                            center: "title",
                                            right: "planning", // "planning schedule",
                                        }}
                                        height={"calc(100vh - 80px)"}
                                        renderEvent={renderEventContent}
                                        initialView="dayGridWeek"
                                        dayMinWidth={100}
                                        eventsResources={[{ events: eventsSchedules.map(e => ({ ...e, backgroundColor: e.borderColor, textColor: "#FFF" })) }, { events: events }]}
                                        eventClick={(v) => openModal(v)}
                                        buttons={{
                                            goBack: { text: t("follow.goback"), click: () => history.goBack() },
                                            planning: { text: t("plans"), click: () => history.push(`/treatment/planning/${props.match.params.id}`) },
                                            schedule: { text: t("schedule"), click: () => history.push(`/treatment/schedule/add/${props.match.params.id}`) },
                                        }}
                                    />
                                </div>
                            </LoaderContainer>
                        </Col>
                    </Row>
                </div>
            </Col>

            <ModalSync currentEvent={currentEventSchedule} open={openSync} onClose={() => setOpenSync(false)} onAction={onSync} />
            <ModalAsync currentEvent={currentEvent} open={openAsync} onClose={() => setOpenAsync(false)} onAction={onAsync} />
            <ModalConfirm open={confirm} setOpen={setConfirm} data={currentFinally} onAction={finallyConfirm} />
        </Main>
    );
};

export default connect(ClinicalDataPatients);
