import { UPDATE_VERSION, PUSH_NOTIFIACTION, REMOVE_NOTIFIACTION, USER_PROFILE, VIDEOS, CAT_MEDIA, DEVICE_MEDIA, FILES, IMAGES, GET_ROOMS, PROMPT_ACTIVE, PROMPT_MESSAGE } from './variables'

const setVersion = (data) => ({ type: UPDATE_VERSION, payload: data });
const pushNotification = (data) => ({ type: PUSH_NOTIFIACTION, payload: data });
const removeNotification = (data) => ({ type: REMOVE_NOTIFIACTION, payload: data });
const setUserProfile = (data) => ({ type: USER_PROFILE, payload: data });
const setVideos = (data) => ({ type: VIDEOS, payload: data });
const setFiles = (data) => ({ type: FILES, payload: data });
const setImages = (data) => ({ type: IMAGES, payload: data });
const setCategoriesMedia = (data) => ({ type: CAT_MEDIA, payload: data });
const setDeviceMedia = (data) => ({ type: DEVICE_MEDIA, payload: data });
const setRooms = (data) => ({ type: GET_ROOMS, payload: data });
const setActivePromp = (data) => ({ type: PROMPT_ACTIVE, payload: data });
const setPrompMessage = (data) => ({ type: PROMPT_MESSAGE, payload: data });

const actions = {
    setVersion,
    pushNotification,
    removeNotification,
    setUserProfile,
    setVideos,
    setFiles,
    setImages,
    setCategoriesMedia,
    setDeviceMedia,
    setRooms,
    setActivePromp,
    setPrompMessage
}

export default actions;