import React from "react";
import { CKEditor } from "ckeditor4-react";
import { useTranslation } from "react-i18next";

// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.bubble.css";
// import "react-quill/dist/quill.snow.css";
// const Inline = ReactQuill.import('blots/inline');

import "./style.css";

const QuillComponent = (props) => {
  const change = (content) => {
    if (props.onChange !== undefined) {
      props.onChange(content);
    }
  };
  const { t } = useTranslation("global");

  return (
    <CKEditor
      initData={props.value}
      data={props.value}
      onChange={(a) => change(a.editor.getData())}
      config={{
        language: t("idiom"),
        toolbarGroups: [
          { name: "document", groups: ["mode", "document", "doctools"] },
          { name: "clipboard", groups: ["clipboard", "undo"] },
          {
            name: "editing",
            groups: ["find", "selection", "spellchecker", "editing"],
          },
          { name: "forms", groups: ["forms"] },
          { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
          {
            name: "paragraph",
            groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"],
          },
          { name: "links", groups: ["links"] },
          { name: "insert", groups: ["insert"] },
          { name: "styles", groups: ["styles"] }, // corresponde a los botones "Estilo y Formato"
          { name: "colors", groups: ["colors"] },
          { name: "tools", groups: ["tools"] },
          { name: "others", groups: ["others"] },
          { name: "about", groups: ["about"] },
        ],
        removeButtons:
          "Source,Save,NewPage,ExportPdf,Preview,Print,Templates,PasteText,PasteFromWord,Find,Replace,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Subscript,Superscript,CopyFormatting,RemoveFormat,CreateDiv,BidiLtr,BidiRtl,Language,Anchor,Image,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,About,Maximize,ShowBlocks",
      }}
    />
  );
  //   const [theme, setTheme] = useState("snow");
  //   const modules = {
  //     toolbar: [
  //       [{ size: [] }],
  //       ["bold", "italic", "underline"],
  //       [
  //         { list: "ordered" },
  //         { list: "bullet" },
  //         { indent: "-1" },
  //         { indent: "+1" },
  //         { align: [] },
  //       ],
  //       ["link"],
  //       [{ color: [] }, { background: [] }],
  //     ],
  //     clipboard: {
  //       matchVisual: false,
  //     },
  //   };

  //   const formats = [
  //     "size",
  //     "bold",
  //     "italic",
  //     "underline",
  //     // "strike",
  //     // "blockquote",
  //     // "code-block",
  //     "list",
  //     "bullet",
  //     "indent",
  //     "link",
  //     // "image",
  //     // "video",
  //     "color",
  //     "background",
  //     "align",
  //   ];

  //   const handleChange = (content, delta, source, editor) => {
  //     if (props.onChange != undefined) {
  //       props.onChange(content);
  //     }
  //   };

  //   return (
  //     <div>
  //       <ReactQuill
  //         theme={theme}
  //         onChange={handleChange}
  //         value={props.value}
  //         modules={modules}
  //         formats={formats}
  //         placeholder={props.placeholder}
  //       />
  //     </div>
  //   );
};

QuillComponent.defaultProps = {
  placeholder: "Ingresar texto",
};

// class QuillComponent extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       theme: "snow",
//     };
//     // this.handleChange = this.handleChange.bind(this);
//   }

//   handleChange(content, delta, source, editor) {
//     if (this.props.onChange != undefined) {
//       console.log("content ->", content);
//       console.log("delta ->", delta);
//       console.log("source ->", source);
//       console.log("editor ->", editor, editor.getContents());
//       this.props.onChange(content);
//     }
//   }

//   render() {
//     return (
//       <div className="app">
//         <ReactQuill
//           theme={this.state.theme}
//           onChange={this.handleChange}
//           value={this.props.value}
//           modules={QuillComponent.modules}
//           formats={QuillComponent.formats}
//           placeholder={this.props.placeholder}
//         />
//       </div>
//     );
//   }
// }

/* Quill modules to attach to editor */
// QuillComponent.modules = {
//   toolbar: [
//     [{ size: [] }],
//     ["bold", "italic", "underline"],
//     [
//       { list: "ordered" },
//       { list: "bullet" },
//       { indent: "-1" },
//       { indent: "+1" },
//       { align: [] },
//     ],
//     ["link"],
//     [{ color: [] }, { background: [] }],
//   ],
//   clipboard: {
//     matchVisual: false,
//   },
// };

// QuillComponent.formats = [
//   "size",
//   "bold",
//   "italic",
//   "underline",
//   // "strike",
//   // "blockquote",
//   // "code-block",
//   "list",
//   "bullet",
//   "indent",
//   "link",
//   // "image",
//   // "video",
//   "color",
//   "background",
//   "align",
// ];

// QuillComponent.propTypes = {
//   placeholder: PropTypes.string,
// };

export default QuillComponent;
//  "image", "video"
//  "strike", "blockquote",
// , "code-block"
