import React, { useState } from "react";
import { Dropdown, Button } from "trainfes-components-library";

import { useTranslation } from "react-i18next";
import { SidebarContent } from "../../../../components/CardLayoutPlatform/styles";
import { connect } from "../../../../store";

const initialState = {
  category: "",
  position: "",
  device: "",
};

const FilterSidebar = ({ handleClose, setSidebarFilterState, store, activeTab }) => {
  const { t } = useTranslation("library");

  const dropdownOptions = store.categories_media.map((category) => {
    return { value: category._id, label: category.name };
  });

  const devicesOptions = store.devices_media.map((device) => {
    return { value: device._id, label: device.name };
  });

  const positionOptions = [
    {
      value: "left",
      label: t("left"),
    },
    {
      value: "right",
      label: t("right"),
    },
    {
      value: "normal",
      label: t("normal"),
    },
  ];

  const [currentFilter, setCurrentFilter] = useState(initialState);

  const handleSubmit = () => {
    setSidebarFilterState(currentFilter);
    handleClose();
  };

  const handleClearFilter = () => {
    setCurrentFilter(initialState);
    setSidebarFilterState(initialState);
    handleClose();
  };

  return (
    <SidebarContent>
      <div className="form">
        <p>{t("categories")}</p>
        <Dropdown
          key={`dropdown_${
            dropdownOptions.filter((category) => category.value === currentFilter.category)[0]
          }`}
          height={36}
          placeholder={""}
          value={dropdownOptions.filter((category) => category.value === currentFilter.category)[0]}
          onChange={(v) =>
            setCurrentFilter({
              ...currentFilter,
              category: v.value,
            })
          }
          options={dropdownOptions}
        />

        {activeTab === 3 ? (
          <>
            <p className="mrgn-top-30">{t("position")}</p>
            <Dropdown
              key={`dropdown_${
                dropdownOptions.filter((position) => position.value === currentFilter.position)[0]
              }`}
              height={36}
              placeholder={""}
              value={
                dropdownOptions.filter((position) => position.value === currentFilter.position)[0]
              }
              onChange={(v) =>
                setCurrentFilter({
                  ...currentFilter,
                  position: v.value,
                })
              }
              options={positionOptions}
            />
          </>
        ) : null}

        {activeTab !== 3 ? (
          <>
            <p className="mrgn-top-30">{t("devices")}</p>
            <Dropdown
              key={`dropdown_${
                devicesOptions.filter((device) => device.value === currentFilter.device)[0]
              }`}
              height={36}
              placeholder={""}
              value={devicesOptions.filter((device) => device.value === currentFilter.device)[0]}
              onChange={(v) =>
                setCurrentFilter({
                  ...currentFilter,
                  device: v.value,
                })
              }
              options={devicesOptions}
            />
          </>
        ) : null}
      </div>

      <Button width="100%" label={t("apply_filters")} onClick={handleSubmit} />
      <Button width="100%" type="ghost" label={t("clear_filters")} onClick={handleClearFilter} />
    </SidebarContent>
  );
};

export default connect(FilterSidebar);
