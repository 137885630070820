import React, { useEffect, useState } from 'react';
import { Grid, TextField, Fab } from '@material-ui/core';
import { connect } from '../../store'
import { useTranslation } from 'react-i18next';
import ModalSimple from '../../components/modalSimple';
import PublishIcon from '@material-ui/icons/Publish';
import { PDFThumnail } from '../../components/fileViewer';
// import ImgPDF from '../../assets/img/illustrations/pdf.svg';
// import ImgJPG from '../../assets/img/illustrations/jpg.svg';
// import DOMAIN from '../../endpoint';
import axios from 'axios';
import './style.css'


const UploadFiles = (props) => {
    const { t } = useTranslation('global')
    // const [files, setFiles] = useState([]);
    const [addFile, setAddFile] = useState(false);
    const [data, setData] = useState([]);
    const [dataFilter, setDataFilter] = useState([]);
    const [presSelect, setPreSelect] = useState([]);
    // const [search, setSearch] = useState("");

    const getMedia = () => {
        axios.get('/media')
            .then(res => {
                setData(res.data.data);
                setDataFilter(res.data.data);
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        getMedia()
    }, [])


    useEffect(() => {
        let d = Object.assign([], presSelect);
        props.value.map(e => d.push(e._id));
        setPreSelect(d);
    }, [props.value])

    const upload = () => {
        // setFiles(data.filter(e => presSelect.includes(e._id)));
        // setAddFile(false);
        if (props.onChange !== undefined) props.onChange(data.filter(e => presSelect.includes(e._id)));
        setAddFile(false);
    }

    const removeFile = item => {
        let d = Object.assign([], props.value);
        d.splice(d.findIndex(e => e._id === item._id), 1);
        if (props.onChange !== undefined) props.onChange(d);

        // let d = Object.assign([], files);
        // d.splice(d.findIndex(e => e._id === item._id), 1);
        // setFiles(d);
    }

    const preselect = (data, val) => {
        let d = Object.assign([], presSelect);
        if (val.target.checked) d.push(data._id);
        else d.splice(d.findIndex(e => e._id === data._id), 1);
        setPreSelect(d);
    }

    const filterByPatient = (val) => {
        setDataFilter(data.filter(e => e.name.toLocaleLowerCase().includes(val.toLocaleLowerCase())))
    }

    return (
        <Grid container direction="row">
            {/* <p className="title-calendar mb-3">{props.title == null ? t('uploadFiles.title') : props.title}</p> */}
            <Grid container className="head-table">
                <Grid item xs={10} container direction="row" justifyContent="flex-start" alignItems="center" className="pl-4">
                    <p className="fz-14 color-sub-text m-0">{t('select_files')}</p>
                </Grid>
                <Grid item xs={2} container direction="row" justifyContent="center" alignItems="center">
                    <Fab onClick={() => setAddFile(true)} className="bg-purple text-white" aria-label="add" size="small"><PublishIcon /></Fab>
                </Grid>
            </Grid>
            <Grid container direction="row" className="">
                {props.value.map((e, i) => {
                    return (
                        <Grid container className="border-table-records py-2" key={i}>
                            <Grid className="px-4" item xs={12} md={10} container direction="row" justifyContent="flex-start" alignItems="center">
                                <p className="fz-14 color-sub-text m-0">{e.name}</p>
                            </Grid>
                            <Grid item xs={12} md={2} container direction="row" justifyContent="center" alignItems="center">
                                <button className="btn-carrusel d-flex align-items-center justify-content-center" onClick={() => removeFile(e)}><span className="icon-trash"></span></button>
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
            <ModalSimple open={addFile} cancel={() => setAddFile(false)} title="Encuentra tus archivos desde tu librería TrainFES" textCancel="Cancelar" textAction="Subir" action={upload}>
                <Grid item xs={12} container direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={12} md={12} className="pr-1">
                        <TextField
                            className="mb-3 mt-0"
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Buscar"
                            type="text"
                            fullWidth
                            defaultValue=""
                            onChange={v => filterByPatient(v.target.value)}
                        />
                    </Grid>

                    <div className="browse-files text-center my-2">
                        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">

                            {dataFilter.map((e, i) => {
                                return (
                                    props.extentions.includes(e.other) && 
                                    <Grid key={i} item xs={4} className="p-2">
                                        <input className="inputFile" id={i + 'ID'} type="checkbox" style={{ display: 'none' }} onChange={v => preselect(e, v)} defaultChecked={presSelect.includes(e._id)} />
                                        <label className="bg-files labelFile" htmlFor={i + 'ID'}>
                                            <p
                                                className="fz-12 color-primario fw-600 mt-2 mb-0"
                                                style={{
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    marginBottom: 8
                                                }}
                                            >{e.type === 2 ? "PDF" : "IMG"}</p>
                                            
                                            {e.type === 2 ? <PDFThumnail className="view" src={e.src} /> : <img src={e.src} className="img-fluid" alt="Archivos"></img>}

                                            <p
                                                className="fz-12 color-primario fw-600 mt-2 mb-0"
                                                style={{
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    marginTop: 8
                                                }}
                                            >{e.name}</p>
                                        </label>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </div>
                </Grid>
            </ModalSimple>
        </Grid>

    )

}

UploadFiles.defaultProps = {
    title: null,
    value: [],
    extentions: ['.jpeg', '.png', '.jpg', '.pdf']
}

export default connect(UploadFiles);