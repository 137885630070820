import React from "react";
import { Title } from "../../../../styles/Modos.styles";
import * as S from "../../TableMuscles.styles";

export const TableMuscles = ({ t }) => {
  const tableHeaders = [t("right_muscles"), 1, 2, 3, 4];
  const tableHeadersTwo = [t("left_muscles"), 1, 2, 3, 4];

  const tableData = [
    {
      muscle: t("right_quadriceps"),
      1: <S.Check className="__blue" />,
      2: false,
      3: false,
      4: false,
    },

    {
      muscle: t("right_hamstring"),
      1: false,
      2: <S.Check className="__blue" />,
      3: false,
      4: false,
    },
    {
      muscle: t("right_gastrocnemius"),
      1: <S.Check />,
      2: false,
      3: false,
      4: false,
    },
  ];

  const tableDataTwo = [
    {
      muscle: t("left_quadriceps"),
      1: false,
      2: false,
      3: <S.Check className="__blue" />,
      4: false,
    },

    {
      muscle: t("left_hamstring"),
      1: false,
      2: false,
      3: false,
      4: <S.Check className="__blue" />,
    },
    {
      muscle: t("left_gastrocnemius"),
      1: false,
      2: false,
      3: <S.Check />,
      4: false,
    },
  ];

  return (
    <>
      <Title>
        {t("muscles_to_work")} <span className="__red">*</span>
      </Title>
      <S.Table>
        <S.RowHeader>
          {tableHeaders.map((header, i) => (
            <div
              key={i}
              className={`${
                header === 1 || header === 2 || header === 3 || header === 4 ? "__dark-bg" : null
              }`}
            >
              {header}
            </div>
          ))}
        </S.RowHeader>
        {tableData.map((row, i) => (
          <S.Row key={i}>
            <div>{row.muscle}</div>
            <div>{row[1]}</div>
            <div>{row[2]}</div>
            <div>{row[3]}</div>
            <div>{row[4]}</div>
          </S.Row>
        ))}
      </S.Table>
      <S.Table>
        <S.RowHeader>
          {tableHeadersTwo.map((header, i) => (
            <div
              key={i}
              className={`${
                header === 1 || header === 2 || header === 3 || header === 4 ? "__dark-bg" : null
              }`}
            >
              {header === 1 && 3}
              {header === 2 && 4}
              {header !== 1 && header !== 2 ? header : null}
            </div>
          ))}
        </S.RowHeader>
        {tableDataTwo.map((row, i) => (
          <S.Row key={i}>
            <div>{row.muscle}</div>
            <div>{row[1]}</div>
            <div>{row[2]}</div>
            <div>{row[3]}</div>
            <div>{row[4]}</div>
          </S.Row>
        ))}
      </S.Table>
      <S.InfoText>
        <p>*{t("conf_suggested")}</p>
        <p>*{t("conf_optional")}</p>
      </S.InfoText>
      <S.ReferenceText>
        <strong>{t("this_table_is_not_intended")},</strong> {t("it_is_a_reference")}
      </S.ReferenceText>
    </>
  );
};
