import React, { Component } from "react";
import TabTherapist from "../../../components/tabTherapist";
import { Col } from "react-bootstrap";
import Main from "../../../components/main";
import { withTranslation } from "react-i18next";

class PatientsTherapist extends Component {
  render() {
    const { t } = this.props;

    return (
      <Main>
        <Col xs="12">
          <div className="card-plataform bg-white mb-3">
            <div className="box-titulo bg-primario d-flex align-items-center">
              <p className="text-left ml-3 m-0 fz-18">{t("patient_evolution")}</p>
            </div>
            <div className="box-patient mb-3">
              <TabTherapist
                id={this.props.match.params["id"]}
                event={this.props.match.params["event"]}
              />
            </div>
          </div>
        </Col>
      </Main>
    );
  }
}

export default withTranslation("patient_file")(PatientsTherapist);
