import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import {
  LiStyled,
  DropdownMenuHeader,
  DropdownMenuItems,
  IconMenuItem,
  VerticalLine,
  DropdownMenuBody,
} from "../styles";
import { SidebarArrow } from "../icons";
import { getUserId } from "../../../lib/router";

export const MenuItem = ({ item, onMouseLeave }) => {
  const location = useLocation();

  const [subMenuExpanded, setSubMenuExpanded] = useState(false);

  const handleToggleSubMenu = () => setSubMenuExpanded((prev) => !prev);
  const handleCloseSubMenu = () => setSubMenuExpanded(false);

  useEffect(() => {
    handleCloseSubMenu();
  }, [onMouseLeave]);

  const [subRoutes, setSubRoutes] = useState([]);

  useEffect(() => {
    if (item.children) {
      setSubRoutes(item.children.map((item) => item.path));
    }
  }, []);

  return (
    <LiStyled>
      {item.path ? (
        <NavLink
          activeClassName="active-menu"
          exact={true}
          to={item.path}
          style={{ textDecoration: "none" }}
        >
          <div>
            <span className={`${item.icon} fa color-primario icon-menu`}></span>
            <span className="nav-text">{item.text}</span>
          </div>
        </NavLink>
      ) : (
        <>
          <DropdownMenuHeader
            onClick={handleToggleSubMenu}
            className={`${
              subRoutes.includes(location.pathname) ? "active-menu" : ""
            }`}
          >
            <IconMenuItem
              className={`${item.icon} fa color-primario`}
            ></IconMenuItem>
            <span className="nav-text">{item.text}</span>
            <SidebarArrow
              className={`${subMenuExpanded ? "arrow--active" : ""}`}
            />
          </DropdownMenuHeader>
          {subMenuExpanded && (
            <DropdownMenuBody>
              {item.children.map((menuItem) => {
                if (!menuItem.roles.includes(getUserId().rol)) return null
                return (
                <DropdownMenuItems
                  key={menuItem.path}
                  className={
                    menuItem.path === location.pathname ? "active-menu" : ""
                  }
                >
                  <NavLink
                    activeClassName="active-menu"
                    exact={true}
                    to={menuItem.path}
                    style={{ textDecoration: "none" }}
                  >
                    <div>
                      {/* REMPLAZAR  */}
                      <IconMenuItem className={`fa line-dropdown __top-0`}>
                        <VerticalLine />
                      </IconMenuItem>
                      <span className="nav-text">{menuItem.text}</span>
                    </div>
                  </NavLink>
                </DropdownMenuItems>
                )
              })}
            </DropdownMenuBody>
          )}
        </>
      )}
    </LiStyled>
  );
};
