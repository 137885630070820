import React, { useState } from "react";
import { PlayVideo, PauseVideo, MenuVertical, VolumeUp, VolumeOff, ExpandVideo } from "../../../assets/icons";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { DropDown } from "../../DropDown";

export const Controls = ({
  handlePlayPause,
  isPlaying,
  video,
  visible,
  currentTime,
  duration,
  videoUrl,
  playbackRate,
  handlePlaybackRateChange,
  isFullScreen,
  handleFullScreenToggle,
  // handleRestart,
}) => {
  const [options] = useState([0.5, 0.75, 1, 1.25, 1.5, 1.75, 2]);
  const [menuVisible, setMenuVisible] = useState(false);
  const [dropdownClicked, setDropdownClicked] = useState(false);
  const [volume, setVolume] = useState(1);
  const [hovered, setHovered] = useState(false);
  const [muted, setMuted] = useState(false);
  const type = "video_player";
  const { t } = useTranslation("global");

  const handleDropdownClick = () => {
    setDropdownClicked(!dropdownClicked);
  };

  const handleDownloadClick = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(videoUrl, { mode: 'cors' });
      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'video.mp4';
      link.click();
    } catch (error) {
      console.error('Error during download:', error);
    }
  };

  const handleTimeChange = (e) => {
    video.current.currentTime = e.target.value;
  };

  const handleVolumeChange = (e) => {
    setVolume(e.target.value);
    video.current.volume = e.target.value;
    if (muted) {
      setMuted(false);
      video.current.muted = false;
    }
  };

  const handleMuteToggle = () => {
    setMuted(!muted);
    video.current.muted = !muted;
    if (!muted) {
      setVolume(0);
    } else {
      setVolume(video.current.volume);
    }
  };

  return (
    <ControlsContainer visible={visible}>
      <ContainerButtons>
        <div style={{ display: "flex", alignItems: "center" }}>
          <PlayButton onClick={handlePlayPause}>
            {isPlaying ? (
              <PauseVideo style={{ width: "14px", height: "14px" }} />
            ) : (
              <PlayVideo style={{ width: "14px", height: "14px" }} />
            )}
          </PlayButton>

          <TimeContainer>
            <span>
              {Math.floor(currentTime / 60)}:
              {("0" + Math.floor(currentTime % 60)).slice(-2)} /{" "}
              {Math.floor(duration / 60)}:
              {("0" + Math.floor(duration % 60)).slice(-2)}
            </span>
          </TimeContainer>

          <VolumeContainer onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
            <VolumeButton onClick={handleMuteToggle}>
              {muted || Math.abs(volume) < 0.0001 ? (
                <VolumeOff style={{ width: "14px", height: "14px", fill: "#fff" }} />
              ) : (
                <VolumeUp style={{ width: "14px", height: "14px", fill: "#fff" }} />
              )}
            </VolumeButton>
            {hovered && (
              <VolumeSlider type="range" min="0" step="0.01" max="1" value={volume} onChange={handleVolumeChange} />
            )}
          </VolumeContainer>
        </div>
        
        <div style={{ display: "flex", alignItems: "center" }}>
        
          <FullScreenButton onClick={handleFullScreenToggle}>
            {isFullScreen ? (
              <ExpandVideo style={{ width: "14px", height: "14px", fill: "#fff" }} />
            ) : (
              <ExpandVideo style={{ width: "14px", height: "14px", fill: "#fff" }} />
            )}
          </FullScreenButton>

          <MenuButton onClick={() => setMenuVisible(!menuVisible)}>
            <MenuVertical style={{ width: "14px", height: "14px", fill: "#fff" }} />
          </MenuButton>

        </div>

      </ContainerButtons>

      <InputContainer>
        <StyledInput type="range" min="0" step="0.01" max={duration} value={currentTime} onChange={handleTimeChange} />
      </InputContainer>

      {menuVisible && (
        <MenuContainer>
          <MenuItem onClick={(e) => {handleDownloadClick(e); setMenuVisible(false);}}>{t("video_player.download")}</MenuItem>
          <MenuItem onClick={handleDropdownClick}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ marginRight: "5px" }}>{t("video_player.speed")}</span>
              <DropDown
                value={playbackRate}
                options={options}
                onChange={(e) => {handlePlaybackRateChange(e.target.value); setMenuVisible(false)}}
                type={type}
                dropdownClicked={dropdownClicked}
              />
            </div>
          </MenuItem>
        </MenuContainer>
      )}
    </ControlsContainer>
  );
};

const ControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 6px;
  left: 0;
  width: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
  color: white;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
`;

const ContainerButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 5px;
  max-height: 24px;
`;

const PlayButton = styled.button`
  border: none;
  background: transparent;
  display: flex;
  justify-content: center;
`;

const TimeContainer = styled.div`
  span {
    font-size: 12px;
    margin: 2px 5px;
  }
`;

const MenuButton = styled.button`
  border: none;
  background: transparent;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 11px;
  margin-right: 11px;
  margin-top: 7px;
  margin-bottom: 13px;
`;

const StyledInput = styled.input`
  width: 100%;
  height: 1px;
  background-color: #fff;
  outline: none;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  box-sizing: border-box;
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
  }
  &:focus {
    outline: none;
    background-color: #fff;
  }
`;

const MenuContainer = styled.div`
  position: absolute;
  top: -69px;
  right: 36px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 1;
  max-height: 100px;
  max-width: 200px;
  overflow-x: auto;
  overflow-y: auto;
  overflow: hidden;
  margin-right: -5px;
  margin-bottom: -5px;
`;

const MenuItem = styled.div`
  cursor: pointer;
  color: #000;
  padding: 10px;
  border-radius: 3px;
  &:hover {
    background-color: #281b65;
    color: #fff;
    svg {
      fill: #fff;
    }
  }
  white-space: nowrap;
`;

const VolumeContainer = styled.div`
  display: flex;
  align-items: center;
`;

const VolumeButton = styled.button`
  border: none;
  background: transparent;
  margin-right: 5px;
`;

const VolumeSlider = styled.input`
  width: 50px;
  height: 1px;
  background-color: #fff;
  outline: none;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  box-sizing: border-box;
  margin-top: 3px;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
  }

  &:focus {
    outline: none;
    background-color: #fff;
  }
`;

const FullScreenButton = styled.button`
  border: none;
  background: transparent;
`;

// const RestartButton = styled.button`
//   border: none;
//   background: transparent;
//   margin-right: 5px;
// `;