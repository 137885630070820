import styled from "styled-components";

export const ConfigButton = styled.div`
  align-items: center;
  background: #281b65;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 30px;
  justify-content: center;
  width: 30px;
`;

export const ModalConfigContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  padding-bottom: 35px;
`;

export const ConfigTimeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
