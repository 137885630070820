import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import IcoPerfil from '../assets/img/iconMenu/icon-perfil.svg';


const useStyles = theme => ({
	root: {
		'& > *': {
			margin: theme.spacing(0),
		},
	},
	input: {
		display: 'none',
	},

})

class UploadFileAvatar extends React.Component {
	constructor() {
		super();
		this.state = {
			file: null
		}


		this.img = React.createRef();
	}

	readURL(file) {
		return new Promise((res, rej) => {
			const reader = new FileReader();
			reader.onload = e => res(e.target.result);
			reader.onerror = e => rej(e);
			reader.readAsDataURL(file);
		});
	}

	async preview(event) {
		if (!event.files[0].type.startsWith('image/')) {
			this.props.notify({ type: "error", title: "Error", text: this.props.t("tab1.invalid_ImageFormat") });
			return;
		}
		const file = event.files[0];
		this.setState({ file: file });
		const url = await this.readURL(file);
		this.img.current.src = url;
		this.value();
	}

	value() {
		if (this.props.value != null) this.props.value(this.state.file);
	}

	render() {
		return (
			<div className={this.props.classes.root}>
				<input accept="image/*" className={this.props.classes.input} id="icon-button-file" type="file" onChange={event => this.preview(event.target)} />
				<label className={this.props.classes.label} htmlFor="icon-button-file">
					<IconButton fontSize="large" color="primary" aria-label="upload picture" component="span">
						<img ref={this.img} src={this.props.img || IcoPerfil} style={{ cursor: "pointer", borderRadius: 100, width: 40, height: 40, objectFit: "cover" }} className="img-fluid" alt="Icon Perfilt"></img>
					</IconButton>
				</label>
			</div>
		)
	}

}

export default withStyles(useStyles)(UploadFileAvatar);