import React, { useState } from 'react'

import { InputBorder, Dropdown } from "trainfes-components-library"

import { useGetAdminClinics, useGetSpecialities } from '../../../hooks'
import { Container, PhoneInputStyled } from '../subsidiary.styles';

import Places from "../../../components/places";

import { SET_FIELDS, SET_INPUT_FIELDS } from '../reducer';

export const ModalNewSubsidiary = ({ state, dispatch, t }) => {
    const { specialitiesDropdown } = useGetSpecialities()
    const { adminClinicsQuery } = useGetAdminClinics()

    const [focusPhone, setFocusPhone] = useState(false);

    const handleChange = ( input, value ) => {
        dispatch({ 
            type: SET_INPUT_FIELDS, 
            payload: {
                input, 
                value  
            } 
        })
    }

    const getDataAddress = (data) => {
        dispatch({ type: SET_FIELDS, payload: { field: "country", value: data.country } })
        dispatch({ type: SET_FIELDS, payload: { field: "commune", value: data.state } })
        dispatch({ type: SET_FIELDS, payload: { field: "region", value: data.region } })
        dispatch({ type: SET_FIELDS, payload: { field: "address", value: data.addressComplete } })
        dispatch({ type: SET_FIELDS, payload: { field: "stateAddress", value: data.addressComplete } })
      };

    return (
        <Container>
            <InputBorder 
                placeholder={t("subsidiary.subsidiary")}
                value={ state.name }
                onChange={ (e) => handleChange( "name", e.target.value ) } 
            />

            <Dropdown 
                portal
                showRequired
                placeholder={t("subsidiary.representative")}
                options={ adminClinicsQuery.data }
                value={ state.representative.value }
                onChange={ (selectedOption) => handleChange( "representative", selectedOption.value ) } 
            />
            <Places 
                newInput
                placeholder={t("subsidiary.address")}
                label={""}
                value={ state.stateAddress }
                onChangeCoords={getDataAddress}
                onChangeAddress={ e => dispatch({ type: SET_FIELDS, payload: { field: "stateAddress", value: e } }) }
            />
            
            <InputBorder 
                placeholder={t("subsidiary.other_address")}
                showRequired={ false }
                value={ state.address2 } 
                onChange={ (e) => handleChange( "address2", e.target.value ) }  
            />

            <InputBorder 
                placeholder={t("subsidiary.mail")} 
                value={ state.email } 
                onChange={ (e) => handleChange( "email", e.target.value ) }  
            />

            <PhoneInputStyled 
                specialLabel={t("subsidiary.phone_1")}
                required={true}
                country={"cl"}
                countryCodeEditable={false} 
                focus={focusPhone}
                onFocus={ () => setFocusPhone(true) }
                onBlur={ () => setFocusPhone(false) }
                value={ state.phone_one } 
                onChange={ (value) => handleChange( "phone_one", value ) }
            />
            <PhoneInputStyled 
                specialLabel={t("subsidiary.phone_2")}
                country={"cl"}
                countryCodeEditable={false} 
                focus={focusPhone}
                onFocus={ () => setFocusPhone(true) }
                onBlur={ () => setFocusPhone(false) }
                value={ state.phone_two } 
                onChange={ (value) => handleChange( "phone_two", value ) }  
            />
            <PhoneInputStyled 
                specialLabel={t("subsidiary.phone_3")}
                country={"cl"}
                countryCodeEditable={false} 
                focus={focusPhone}
                onFocus={ () => setFocusPhone(true) }
                onBlur={ () => setFocusPhone(false) }
                value={ state.phone_three } 
                onChange={ (value) => handleChange( "phone_three", value ) }  
            />

            <Dropdown 
                isMulti
                portal
                showRequired
                placeholder={t("specialties.specialties")} 
                options={ specialitiesDropdown }
                value={ state.specialities } 
                onChange={ (value) => handleChange( "specialities", [ ...value ] ) }  
            />
        </Container>
  )
}

