import React, { Component, Fragment } from "react";
import { Grid } from "@material-ui/core";
import TimePicker from "../timePicker";
import Counter from "../counter";
import Slider from "../slider";
import { withTranslation } from "react-i18next";
import TableBlocks from "../tableBlocks";
import Tab from "../../components/tab";
import ChartTF from "../chartTf/treadmill";

class Treadmill extends Component {
  constructor() {
    super();
    this.state = {
      calentamiento: { hours: 0, minutes: 5, seconds: 0 },
      velocidad_calentamiento: 0,
      inclinacion_calentamiento: 0,
      entrenamiento_paso: { hours: 0, minutes: 0, seconds: 0 },
      velocidad_paso: 0,
      inclinacion_paso: 0,
      recuperacion: { hours: 0, minutes: 5, seconds: 0 },
      velocidad_recuperacion: 10,
      inclinacion_recuperacion: 10,
      frecuencia_cardiaca_min: 60,
      frecuencia_cardiaca_max: 140,
      tab: 0,
      select: 0,
      type: 0,
      data: [],
    };
  }

  values() {
    if (this.props.values !== undefined) this.props.values(this.state);
  }

  componentDidUpdate() {
    this.values();
  }

  componentDidMount() {
    this.values();
    if (this.props.data.params && Object.keys(this.props.data.params).length !== 0) {
      let params = this.props.data.params;
      this.setState({
        calentamiento: params.calentamiento,
        data: params.data,
        entrenamiento_paso: params.entrenamiento_paso,
        frecuencia_cardiaca_max: params.frecuencia_cardiaca_max,
        frecuencia_cardiaca_min: params.frecuencia_cardiaca_min,
        inclinacion_calentamiento: params.inclinacion_calentamiento,
        inclinacion_paso: params.inclinacion_paso,
        inclinacion_recuperacion: params.inclinacion_recuperacion,
        recuperacion: params.recuperacion,
        select: params.select,
        tab: params.tab,
        type: params.type,
        velocidad_calentamiento: params.velocidad_calentamiento,
        velocidad_paso: params.velocidad_paso,
        velocidad_recuperacion: params.velocidad_recuperacion,
      });
    }
  }

  appendData() {
    let d = Object.assign([], this.state.data);
    let pos = d.length === 0 ? 1 : d.length - 1;

    let obj = {
      pos: pos,
      time: this.state.entrenamiento_paso,
      tiempo: this.getTimeToStr(this.state.entrenamiento_paso),
      fase: this.state.type === 0 ? "Paso" : "Rampa",
      type: this.state.type,
      velocidad: this.state.velocidad_paso,
      inclinacion: this.state.inclinacion_paso,
    };

    d.push(obj);
    this.setState({ data: d });
  }

  removeItem(pos) {
    let d = Object.assign([], this.state.data);
    d.splice(
      d.findIndex((e) => e.pos === pos),
      1
    );
    this.setState({
      data: d.sort(function (a, b) {
        return a.pos - b.pos;
      }),
    });
  }

  getTimeToStr(obj, h = false) {
    const { hours, minutes, seconds } = obj;
    return `${h ? (hours < 10 ? "0" + hours + ":" : hours + ":") : ""} ${minutes < 10 ? "0" + minutes : minutes
      }:${seconds < 10 ? "0" + seconds : seconds} `;
  }

  setCalentamientoTime(input) {
    const d = Object.assign([], this.state.data);
    d.map((e) => {
      if (e.id === "calentamiento") e["tiempo"] = this.getTimeToStr(input);
      return e;
    });

    this.setState({ calentamiento: input });
  }

  setCalentamientoVelocity(input) {
    const d = Object.assign([], this.state.data);
    d.map((e) => {
      if (e.id === "calentamiento") e["velocidad"] = input;
      return e;
    });

    this.setState({ velocidad_calentamiento: input });
  }

  setCalentamientoInclination(input) {
    const d = Object.assign([], this.state.data);
    d.map((e) => {
      if (e.id === "calentamiento") e["inclinacion"] = input;
      return e;
    });

    this.setState({ inclinacion_calentamiento: input });
  }

  setRecuperacionTime(input) {
    const d = Object.assign([], this.state.data);
    d.map((e) => {
      if (e.id === "recuperacion") e["tiempo"] = this.getTimeToStr(input);
      return e;
    });

    this.setState({ recuperacion: input });
  }

  setRecuperacionVelocity(input) {
    const d = Object.assign([], this.state.data);
    d.map((e) => {
      if (e.id === "recuperacion") e["velocidad"] = input;
      return e;
    });

    this.setState({ velocidad_recuperacion: input });
  }

  setRecuperacionInclination(input) {
    const d = Object.assign([], this.state.data);
    d.map((e) => {
      if (e.id === "recuperacion") e["inclinacion"] = input;
      return e;
    });

    this.setState({ inclinacion_recuperacion: input });
  }

  toInt(obj = { hours: 0, minutes: 0, seconds: 0 }) {
    let { hours, minutes, seconds } = obj;
    return hours * 3600 + minutes * 60 + seconds;
  }

  parseTimeString(m, s) {
    return `${parseInt(m) < 10 ? "0" + m : m}:${parseInt(s) < 10 ? "0" + s : s
      }`;
  }

  transformArrData(data) {
    return data.map((e) => {
      let str = "";
      if (e.time.minutes > 0) {
        str += e.time.minutes + "m ";
      }

      if (e.time.seconds > 0) {
        str += e.time.seconds + "s";
      }
      return [
        str,
        this.toInt(e.time),
        e.velocidad,
        e.inclinacion,
        { color: "#281B65", type: parseInt(e.type) },
      ];
    });
  }

  render() {
    const { t } = this.props;
    return (
      <Fragment>
        <Grid container direction="row" justifyContent="center">
          <Grid item xs={12} md={7} className="pl-4">
            <Tab
              value={this.state.select}
              onChange={(i) => this.setState({ select: i })}
              tabs={[
                {
                  title: t("graphic"),
                  content: (
                    <div className="grafic my-4">
                      <ChartTF
                        data={{
                          data: this.transformArrData(this.state.data),
                          yLabel: t('speed_incline'), //t('threshold')
                          xLabel: t("duration"),
                        }}
                      />
                    </div>
                  ),
                },
                {
                  title: t("information"),
                  content: (
                    <TableBlocks
                      data={this.state.data}
                      remove={(v) => this.removeItem(v)}
                    ></TableBlocks>
                  ),
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={5} className="pl-4">
            <h6 className="title-calendar my-3">{t("adjustments")}</h6>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} md={6} className="pr-1">
                <div className="border-tf py-2 px-3">
                  <p className="m-0 fz-10 color-sub-text">
                    {t("frecuecyCard")} (Mín. 60 - 160)
                  </p>
                  <p className="m-0 color-primario fz-16">
                    <strong>{this.state.frecuencia_cardiaca_min}</strong>
                  </p>
                  <Slider
                    title={t("frecuecyCard")}
                    min={60}
                    max={160}
                    defaultValue={this.state.frecuencia_cardiaca_min}
                    onChange={(value) =>
                      this.setState({ frecuencia_cardiaca_min: value })
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6} className="pl-1">
                <div className="border-tf py-2 px-3">
                  <p className="m-0 fz-10 color-sub-text">
                    {t("frecuecyCardMax")} (Máx. 90 - 220)
                  </p>
                  <p className="m-0 color-primario fz-16">
                    <strong>{this.state.frecuencia_cardiaca_max}</strong>
                  </p>
                  <Slider
                    title={t("frecuecyCardMax")}
                    min={90}
                    max={220}
                    defaultValue={this.state.frecuencia_cardiaca_max}
                    onChange={(value) =>
                      this.setState({ frecuencia_cardiaca_max: value })
                    }
                  />
                </div>
              </Grid>
            </Grid>
            <div className="bg-primario-300 px-2 pb-2 border-radius-4">
              <p className="title-calendar my-3 pt-2">{t("config")}</p>
              <Grid container direction="row" justifyContent="center">
                <Grid item xs={12} md={6} className="pr-1">
                  <div
                    className="border-radius-3 py-2 px-3 bg-white"
                    style={{ height: 101 }}
                  >
                    <p className="fz-10 color-sub-text m-0">{t("type")}</p>
                    <select
                      style={{
                        height: 35,
                        padding: "0px 8px",
                        cursor: "pointer",
                      }}
                      className="form-control my-3 d-block w-100"
                      onChange={(v) => this.setState({ type: v.target.value })}
                    >
                      <option value={0}>{t("step")}</option>
                      <option value={1}>{t("ramp")}</option>
                    </select>
                  </div>
                </Grid>
                <Grid item xs={12} md={6} className="pl-1">
                  <div className="border-radius-3 py-2 px-3 bg-white">
                    <p className="fz-10 color-sub-text m-0">{t("step_ramp_duration")}</p>
                    <TimePicker
                      data={this.state.entrenamiento_paso}
                      minutes={true}
                      seconds={true}
                      values={(value) =>
                        this.setState({ entrenamiento_paso: value })
                      }
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={6} className="pr-1 mt-2">
                  <div className="border-radius-3 py-2 px-3 bg-white">
                    <p className="fz-10 color-sub-text m-0">
                      {t("velocity")} (-5 a 40 km/h.)
                    </p>
                    <Counter
                      data={this.state.velocidad_paso}
                      title="-5km - 40km"
                      min={-5}
                      max={40}
                      values={(value) =>
                        this.setState({ velocidad_paso: value })
                      }
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={6} className="pl-1 mt-2">
                  <div className="border-radius-3 py-2 px-3 bg-white">
                    <p className="fz-10 color-sub-text m-0">
                      {t("inclination")} (-10 a 25%)
                    </p>
                    <Counter
                      data={this.state.inclinacion_paso}
                      title="-10% - 25%"
                      min={-10}
                      max={25}
                      values={(value) => {
                        this.setState({ inclinacion_paso: value });
                      }}
                    />
                  </div>
                </Grid>
                <button
                  className="mt-2 btn btn-tf-xs w-100"
                  onClick={() => this.appendData()}
                >
                  {t("add")}
                </button>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default withTranslation("sessionStudio")(Treadmill);
