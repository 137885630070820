import styled from "styled-components";

import { Row, Col } from "trainfes-components-library";
import { ArrowIcon } from "../../../../../../assets/icons";

export const ExpandStyled = styled.div`
  & > .align-center {
    text-align: center;
  }

  & .arrow__active {
    transform: translate(-50%, -50%) rotate(0deg);
  }
`;

export const ArrowStyled = styled(ArrowIcon)`
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translate(-50%, -50%) rotate(180deg);

  transition: 0.3s;
`;

export const ColStyled = styled(Col)`
  padding-left: 0;
  padding-right: 0;

  text-align: center;

  &:first-child {
    text-align: left;
  }
`;

export const RowStyled = styled(Row)`
  border-bottom: 0.5px solid #ced4da;
  cursor: pointer;
  padding: 5px 0;
  position: relative;
`;

export const Text = styled.span`
  align-items: center;
  color: #263238;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.850962px;
`;

export const HeaderCol = styled(Text)`
  font-weight: 600;
`;

export const TimeText = styled(Text)`
  color: #fdad34;
`;

export const BodyExpand = styled.div`
  background: #fafbfe;
  padding: 5px 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 50px;
  justify-content: center;
  align-items: center;

  & > ul {
    display: flex;
    flex-direction: row;
    text-align: center;
  }
`;
