import React, { useState, useEffect } from "react";

const Counter = (props) => {
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    if (props.data !== null && props.data !== undefined) setCounter(props.data);
  }, [props.data]);

  const update = (v) => {
    if (props.values !== undefined) props.values(v);
  };

  const plus = () => {
    let max = props.max ? props.max : 60;
    if (counter < max) {
      setCounter(counter + 1);
      update(counter + 1);
    }
  };

  const minus = () => {
    let min = props.min ? props.min : 0;
    if (counter > min) {
      setCounter(counter - 1);
      update(counter - 1);
    }
  };

  const validNan = () => {
    if (isNaN(counter)) setCounter(0);
  };

  const change = (v) => {
    if (isNaN(parseInt(v))) {
      setCounter(parseInt(props.min));
      update(parseInt(props.min));
      return;
    }

    let value = v;
    value = parseInt(v);

    if (value <= props.min) {
      setCounter(parseInt(props.min));
      update(parseInt(props.min));
    } else if (value >= props.max) {
      setCounter(parseInt(props.max));
      update(parseInt(props.max));
    } else {
      setCounter(value);
      update(value);
    }
  };

  // TODO: Llevar a libreria de componentes.
  // Non-standard: This feature is non-standard and is not on a standards track. Do not use it on production sites facing the Web: it will not work for every user.
  return (
    <div className="d-flex align-items-end justify-content-center">
      <div className="TimePickers">
        <div className="lcd relative">
          <p>{props.title}</p>
          <input
            onBlur={validNan}
            className="no-arrows-input counter-input"
            type="number"
            value={Number(counter).toString()}
            onChange={(c) => change(c.target.value)}
            onKeyUp={(c) => change(c.target.value)}
          />
        </div>
        <div className="controls">
          <button className="plus" onClick={() => plus()}>
            <span className="material-icons">expand_less</span>
          </button>
          <button className="minus" onClick={() => minus()}>
            <span className="material-icons">expand_more</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Counter;
