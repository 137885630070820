import React, { useEffect } from "react";

import {
  Dropdown,
  SearchBar,
  TableServices,
} from "trainfes-components-library";

import { IconsContainer, TableHeader } from "../../../styles/views.styles";
import { SET_FIELDS } from "../reducer/actions";
import { Delete } from "../../../assets/icons/pages";
import { filterValidation } from "../../../lib/textValidation";

export const DevicesTab = ({ t, state, dispatch, devicesQuery }) => {
  const handleOpenDelete = (id) => {
    dispatch({ type: SET_FIELDS, payload: { field: "idCurrent", value: id } });
    dispatch({
      type: SET_FIELDS,
      payload: { field: "removeModal", value: true },
    });
  };

  const columns = [
    {
      key: "name",
      text: t("name"),
      alignContent: "center",
      alignTitle: "center",
    },
    {
      key: "description",
      text: t("desc"),
      alignContent: "center",
      alignTitle: "center",
    },
    {
      key: "controls",
      text: t("actions"),
      type: "custom",
      alignContent: "center",
      alignTitle: "center",
      elem: (payload) => (
        <IconsContainer>
          <Delete onClick={() => handleOpenDelete(payload._id)} />
        </IconsContainer>
      ),
    },
  ];

  const filterOptions = [
    {
      label: t("name"),
      value: "name",
      key: "name",
    },
    {
      label: t("desc"),
      value: "description",
      key: "description",
    },
  ];

  useEffect(() => {
    handleFilter();
  }, [state.inputFilter, devicesQuery.data]);

  const handleFilter = () => {
    let data = Object.assign([], devicesQuery.data);

    if (state.inputFilter === "") {
      return dispatch({
        type: SET_FIELDS,
        payload: {
          field: "filtered",
          value: data.reverse(),
        },
      });
    }

    return dispatch({
      type: SET_FIELDS,
      payload: {
        field: "filtered",
        value: data.reverse().filter((e) => {
          return filterValidation(e[state.dropdownFilter]).includes(
            filterValidation(state.inputFilter)
          );
        }),
      },
    });
  };

  return (
    <>
      <TableHeader>
        <p>{t("search_by")}</p>
        <Dropdown
          height={38}
          options={filterOptions}
          value={
            filterOptions.filter((e) => e.value === state.dropdownFilter)[0]
          }
          onChange={(e) =>
            dispatch({
              type: SET_FIELDS,
              payload: { field: "dropdownFilter", value: e.value },
            })
          }
        />
        <SearchBar
          borderRadius="6px"
          height="38px"
          placeholder={t("search")}
          onChange={(e) =>
            dispatch({
              type: SET_FIELDS,
              payload: { field: "inputFilter", value: e.target.value },
            })
          }
        />
      </TableHeader>

      <TableServices
        data={state.filtered}
        columns={columns}
        searchKeys="title mode"
        autoHeight={false}
        perPage={10}
        pagination={true}
        filterSelect={false}
        filterInput={false}
        perPageSelect={false}
        textEmptyData="Sin datos para mostrar"
      />
    </>
  );
};
