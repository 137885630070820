import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { Controller } from "react-hook-form";

import { Dropdown, InputBorder, Switch, ButtonCircle } from "trainfes-components-library";

import API from "../../../../services/api";

import { DatePickerComponent } from "../../../../components/DatePickerv2";
import TableHours from "../../../../components/hours";
import Places from "../../../../components/places";
import { options } from "../../../../lib/validation.helpers";


import * as S from "../../users.styles"
import { ADD_SPECIALITY, REMOVE_SPECIALITY, SET_FIELD } from "../../reducer/actions";
import { SpecialityBlock } from "../SpecialityBlock";

const api = new API();

export const NewUserModal = ({
  t,
  control,
  errors,
  state, 
  dispatch,
  notify,
  getDataAddress,
  table,
  setTable,
}) => {
  const [focusPhone, setFocusPhone] = useState(false);
  const currentYear = new Date().getFullYear();

  const rolOptions = [
    { label: t("therapist"), value: "therapist" },
    { label: t("admin-clinic"), value: "admin-clinic" },
    { label: t("admin"), value: "admin" },
  ];

  const { data: subsidiaries } = useQuery({
    queryKey: ["subsidiaries"],
    queryFn: () => api.getSubsidiary().then((res) => res.data),
  });


  const handleSetField = (field, value) => {
    dispatch({ type: SET_FIELD, payload: { field, value } })
  }
  
  const handleAddSpeciality = () => {
    if ( !state.currentSpeciality ) {
      return notify({ type: "error", title: "Error", text: t("create_therapist:no_specialties") });
    }

    if ( state.selectedSpecialities.some((elem) => elem === state.currentSpeciality )) {
      return notify({ type: "error", title: "Error", text: t("create_therapist:speciality_already_selected") });
    }

    dispatch({ type: ADD_SPECIALITY, payload: state.currentSpeciality })
  }

  const handleDeleteSpeciality = (payload) => {
    dispatch({ type: REMOVE_SPECIALITY, payload })
  }

  return (
    <S.Container>
      <S.FormContainer>
        <div>
          <Dropdown
            placeholder={t("create_patient:role")}
            portal
            options={rolOptions}
            onChange={(option) => handleSetField( "currentRol", option.value )}
            value={rolOptions.filter((e) => e.value === state.currentRol)[0]}
            disabled={state.isEditing}
            showRequired
          />
        </div>

        <div>
          <Controller
            name="rut"
            control={control}
            rules={{ required: t("create_patient:notify.invalid_id") }}
            render={({ field }) => (
              <InputBorder placeholder={t("create_patient:idPatient")} {...field} disabled={state.isEditing} />
            )}
          />
          {errors.rut && (
            <S.FormValidationText>{errors.rut.message}</S.FormValidationText>
          )}
        </div>

        <div>
          <Controller
            name="name"
            control={control}
            rules={{ 
              required: t("create_patient:notify.name_required"),
              pattern: {
                value: /^[^\s]+(?:$|.*[^\s]+$)/,
                message: t('create_patient:pattern.messageBlank')
              } 
            }}
            render={({ field }) => (
              <InputBorder placeholder={t("create_patient:name")} {...field} />
            )}
          />
          {errors.name && (
            <S.FormValidationText>{errors.name.message}</S.FormValidationText>
          )}
        </div>

        <div>
          <Controller
            name="lastname"
            control={control}
            rules={{ required: t("create_patient:notify.surname_required") }}
            render={({ field }) => (
              <InputBorder placeholder={t("last_name")} {...field} />
            )}
          />
          {errors.lastname && (
            <S.FormValidationText>{errors.lastname.message}</S.FormValidationText>
          )}
        </div>

        <div>
          <Controller
            name="email"
            control={control}
            rules={{
              required: t("create_patient:notify.email_required"),
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: t("create_patient:notify.email_required"),
              },
            }}
            render={({ field }) => (
              <InputBorder placeholder={t("create_patient:email")} {...field} />
            )}
          />
          {errors.email && (
            <S.FormValidationText>{errors.email.message}</S.FormValidationText>
          )}
        </div>

        <div>
          <Controller
            control={control}
            name="phone"
            rules={{
              required: t("create_patient:notify.phone_required"),
              minLength: {
                value: 7,
                message: t("create_patient:notify.phone_required"),
              },
            }}
            render={({ field: { ref, ...field } }) => (
              <S.PhoneInputStyled
                {...field}
                inputExtraProps={{
                  ref,
                  required: true,
                }}
                country={"cl"}
                countryCodeEditable={false}
                enableSearch
                searchPlaceholder={t("create_patient:search")}
                specialLabel={t("create_patient:phone")}
                focus={focusPhone}
                onFocus={() => setFocusPhone(true)}
                onBlur={() => setFocusPhone(false)}
              />
            )}
          />
          {errors.phone && (
            <S.FormValidationText>{errors.phone.message}</S.FormValidationText>
          )}
        </div>

        <div>
          <Places
            placeholder={t("create_patient:address")}
            newInput
            label={""}
            value={state.stateAddress}
            onChangeCoords={getDataAddress}
            onChangeAddress={val => handleSetField( "stateAddress", val )}
          />
        </div>

        <div>
          <Controller
            name="other_address"
            control={control}
            render={({ field }) => (
              <InputBorder
                showRequired={false}
                placeholder="Depto/Block"
                {...field}
              />
            )}
          />
          {errors.other_address && (
            <S.FormValidationText>
              {errors.other_address.message}
            </S.FormValidationText>
          )}
        </div>

        <div>
          <Controller
            control={control}
            rules={{ required: t("sessionStudio:validation.fields") }}
            name="birthdate"
            render={({ field: { onChange, value }, ref }) => (
              <DatePickerComponent
                asRequired
                inputRef={ref}
                width="100%"
                placeholder={t("create_patient:birthdate")}
                range={false}
                position="bottom"
                value={
                  value && {
                    day: Number(value.split("/")[0]),
                    month: Number(value.split("/")[1]),
                    year: Number(value.split("/")[2]),
                  }
                }
                // el maximo es la fecha actual
                maximumDate={{ year: currentYear, month: 12, day: 31 }}
                onChange={(val) => {
                  onChange(`${val.day}/${val.month}/${val.year}`);
                }}
              />
            )}
          />
          {errors.birthdate && (
            <S.FormValidationText>{errors.birthdate.message}</S.FormValidationText>
          )}
        </div>

        <div>
          <Controller
            control={control}
            name="genre"
            render={({ field: { onChange }, value, name, ref }) => (
              <Dropdown
                inputRef={ref}
                name={name}
                placeholder={t("create_patient:genre")}
                defaultValue={state.genre}
                portal
                options={ options(t) }
                onChange={(option) => onChange(option.value)}
                value={value}
              />
            )}
          />
          {errors.genre && (
            <S.FormValidationText>{errors.genre.message}</S.FormValidationText>
          )}
        </div>
      </S.FormContainer>

      <S.ActiveContainer>
        <p>{t("users:activate_a_state")}:</p>
        <p className="__inactive">{t("users:inactive")}</p>
        <Switch checked={state.isUserActive} onChange={({ target }) => handleSetField( "isUserActive", target.checked )}/>
        <p className="__active">{t("users:active")}</p>
      </S.ActiveContainer>

      {state.currentRol === "therapist" && (
        <S.SpecialityContainer>
          <Dropdown 
            placeholder={t("users:select_a_specialty")}
            options={state.specialities || []}
            onChange={opt => handleSetField( "currentSpeciality", opt.value )}
          />
          <div className="__specialities">
            <ButtonCircle onClick={handleAddSpeciality} />
            <div className={`__box ${state.selectedSpecialities < 1 ? "" : "__grid" }`} >
              {state.selectedSpecialities < 1 
                ? ( <S.Title>{t("users:no_selected_specialities")}</S.Title> )
                : ( state.selectedSpecialities.map((item) => <SpecialityBlock key={item} state={state} selectedId={item} onDelete={handleDeleteSpeciality} />) )
              }
              
            </div>
          </div>
        </S.SpecialityContainer>
      )}
      

      {state.currentRol === "therapist" && (
        <>
          <S.Title>{t("users:choose_a_schedule")}:</S.Title>
          <TableHours
            onChangeParse={(data) => handleSetField( "schedule", data )}
            onChange={(data) => setTable( data )}
            data={table}
            subsidiaries={subsidiaries}
          />
        </>
      )}
    </S.Container>
  );
};