import { overlapingValidation } from "../../../lib/modes.helpers";

const timeToInt = (obj = { hours: 0, minutes: 0, seconds: 0 }) => {
  let { hours, minutes, seconds } = obj;
  return hours * 3600 + minutes * 60 + seconds;
};

export const validations = {
  walkfes: (data, notify, t) => {
    if (data !== null && data !== undefined) {
      if (data["technical_name"].length === 0 || data["technical_name"] === "") {
        if (notify !== undefined)
          notify({
            type: "error",
            title: t("validation.title"),
            text: t("validation.name"),
          });
        return false;
      }

      if (data["select"] === 0) {
        if (timeToInt(data["tiempo_trabajo"]) === 0) {
          if (notify !== undefined)
            notify({
              type: "error",
              title: t("validation.title"),
              text: t("validation.time"),
            });
          return false;
        }
      }

      if (data["select"] === 1) {
        if (data["num_steps"] === 0) {
          if (notify !== undefined)
            notify({
              type: "error",
              title: t("validation.title"),
              text: t("validation.number"),
            });
          return false;
        }
      }

      return true;
    } else {
      if (notify !== undefined)
        notify({
          type: "error",
          title: t("validation.title"),
          text: t("validation.fields"),
        });
      return false;
    }
  },

  sensor: (data, notify, t) => {
    if (data !== null && data !== undefined) {
      let channels = data["channels"].some((e) => e);

      if (data["technical_name"].length === 0 || data["technical_name"] === "") {
        if (notify !== undefined)
          notify({
            type: "error",
            title: t("validation.title"),
            text: t("validation.name"),
          });
        return false;
      }

      if (!channels) {
        if (notify !== undefined)
          notify({
            type: "error",
            title: t("validation.title"),
            text: t("validation.muscle"),
          });
        return false;
      }

      if (data["musclesSelected"][0].img) {
        if (
          !data["configs"][0] &&
          !data["configs"][6] &&
          !data["configs"][12] &&
          !data["configs"][18]
        ) {
          if (notify !== undefined)
            notify({
              type: "error",
              title: t("validation.title"),
              text: t("validation.all_channels_pulse"),
            });
          return false;
        }
      }

      if (data["musclesSelected"][1].img) {
        if (
          !data["configs"][1] &&
          !data["configs"][7] &&
          !data["configs"][13] &&
          !data["configs"][19]
        ) {
          if (notify !== undefined)
            notify({
              type: "error",
              title: t("validation.title"),
              text: t("validation.all_channels_pulse"),
            });
          return false;
        }
      }

      if (data["musclesSelected"][2].img) {
        if (
          !data["configs"][2] &&
          !data["configs"][8] &&
          !data["configs"][14] &&
          !data["configs"][20]
        ) {
          if (notify !== undefined)
            notify({
              type: "error",
              title: t("validation.title"),
              text: t("validation.all_channels_pulse"),
            });
          return false;
        }
      }

      if (data["musclesSelected"][3].img) {
        if (
          !data["configs"][3] &&
          !data["configs"][9] &&
          !data["configs"][15] &&
          !data["configs"][21]
        ) {
          if (notify !== undefined)
            notify({
              type: "error",
              title: t("validation.title"),
              text: t("validation.all_channels_pulse"),
            });
          return false;
        }
      }

      if (data["musclesSelected"][4].img) {
        if (
          !data["configs"][4] &&
          !data["configs"][10] &&
          !data["configs"][16] &&
          !data["configs"][22]
        ) {
          if (notify !== undefined)
            notify({
              type: "error",
              title: t("validation.title"),
              text: t("validation.all_channels_pulse"),
            });
          return false;
        }
      }

      if (data["musclesSelected"][5].img) {
        if (
          !data["configs"][5] &&
          !data["configs"][11] &&
          !data["configs"][17] &&
          !data["configs"][23]
        ) {
          if (notify !== undefined)
            notify({
              type: "error",
              title: t("validation.title"),
              text: t("validation.all_channels_pulse"),
            });
          return false;
        }
      }

      let config = data["configs"].some((e) => e);
      if (!config) {
        if (notify !== undefined)
          notify({
            type: "error",
            title: t("validation.title"),
            text: t("validation.channel"),
          });
        return false;
      }

      if (data["num_contraccion"] === 0) {
        if (notify !== undefined)
          notify({
            type: "error",
            title: t("validation.title"),
            text: t("validation.contractions"),
          });
        return false;
      }

      if (data["time"] === "00:00:00") {
        if (notify !== undefined)
          notify({
            type: "error",
            title: t("validation.title"),
            text: t("validation.working_time"),
          });
        return false;
      }

      return true;
    } else {
      if (notify !== undefined)
        notify({
          type: "error",
          title: t("validation.title"),
          text: t("validation.muscle"),
        });
      return false;
    }
  },

  graphic: (data, notify, t) => {
    if (data !== null && data !== undefined) {
      let channels = data["musclesSelected"].some((e) => e.img);

      if ( data["channels"].some( (channel, i ) => overlapingValidation( data[`ch${i + 1}`] ) ) ) {
        if (notify !== undefined)
        notify({
          type: "error",
          title: t("validation.title"),
          text: t("validation.overlaping_pulse"),
        });
        return false;
      }
      
      if (data["technical_name"].length === 0 || data["technical_name"] === "") {
        if (notify !== undefined)
          notify({
            type: "error",
            title: t("validation.title"),
            text: t("validation.name"),
          });
        return false;
      }

      if (!channels) {
        if (notify !== undefined)
          notify({
            type: "error",
            title: t("validation.title"),
            text: t("validation.muscle"),
          });
        return false;
      }

      if (
        (data["musclesSelected"][0].img && data["ch1"].length === 0) ||
        (data["musclesSelected"][1].img && data["ch2"].length === 0) ||
        (data["musclesSelected"][2].img && data["ch3"].length === 0) ||
        (data["musclesSelected"][3].img && data["ch4"].length === 0) ||
        (data["musclesSelected"][4].img && data["ch5"].length === 0) ||
        (data["musclesSelected"][5].img && data["ch6"].length === 0)
      ) {

        if (notify !== undefined)
          notify({
            type: "error",
            title: t("validation.title"),
            text: t("validation.all_channels_pulse"),
          });
        return false;
      }

      let config = Object.assign(
        [],
        data["ch1"],
        data["ch2"],
        data["ch3"],
        data["ch4"],
        data["ch5"],
        data["ch6"]
      );

      if (config.length === 0) {
        if (notify !== undefined)
          notify({
            type: "error",
            title: t("validation.title"),
            text: t("validation.pulse"),
          });
        return false;
      }

      if (data["num_contraccion"] === 0) {
        if (notify !== undefined)
          notify({
            type: "error",
            title: t("validation.title"),
            text: t("validation.contractions"),
          });
        return false;
      }

      if (data["channels"].some((channel, i) => data[`ch${i + 1}`].length > 0 && !data["musclesSelected"][i].img)) {
        if (notify !== undefined)
          notify({
            type: "error",
            title: t("validation.title"),
            text: t("validation.channel_image"),
          });
        return false;
      }
    
      return true;
    } else {
      if (notify !== undefined)
        notify({
          type: "error",
          title: t("validation.title"),
          text: t("validation.fields"),
        });
      return false;
    }
  },
  bike: (data, notify, t) => {
    if (data !== null && data !== undefined) {
      let channels = data["musclesSelected"].some((e) => e.img);

      if (!channels) {
        if (notify !== undefined)
          notify({
            type: "error",
            title: t("validation.title"),
            text: t("validation.muscle"),
          });
        return false;
      }

      if (data["technical_name"].length === 0 || data["technical_name"] === "") {
        if (notify !== undefined)
          notify({
            type: "error",
            title: t("validation.title"),
            text: t("validation.name"),
          });
        return false;
      }

      if (data["tiempo_trabajo"] === null || timeToInt(data["tiempo_trabajo"] === 0)) {
        if (notify !== undefined)
          notify({
            type: "error",
            title: t("validation.title"),
            text: t("validation.working_time"),
          });
        return false;
      }

      if (data["rpm"] === 0 || data["rpm"] === null) {
        if (notify !== undefined)
          notify({
            type: "error",
            title: t("validation.title"),
            text: t("validation.rpm"),
          });
        return false;
      }

      return true;
    } else {
      if (notify !== undefined)
        notify({
          type: "error",
          title: t("validation.title"),
          text: t("validation.working_time"),
        });
      return false;
    }
  },
  rowing: (data, notify, t) => {
    if (data !== null && data !== undefined) {
      let channels = data["musclesSelected"].some((e) => e.img);

      if (!channels) {
        if (notify !== undefined)
          notify({
            type: "error",
            title: t("validation.title"),
            text: t("validation.muscle"),
          });
        return false;
      }

      if (data["technical_name"].length === 0 || data["technical_name"] === "") {
        if (notify !== undefined)
          notify({
            type: "error",
            title: t("validation.title"),
            text: t("validation.name"),
          });
        return false;
      }

      if (data["tiempo_trabajo"] === null || timeToInt(data["tiempo_trabajo"] === 0)) {
        if (notify !== undefined)
          notify({
            type: "error",
            title: t("validation.title"),
            text: t("validation.working_time"),
          });
        return false;
      }

      if (data["rpm"] === 0 || data["rpm"] === null) {
        if (notify !== undefined)
          notify({
            type: "error",
            title: t("validation.title"),
            text: t("validation.rpm"),
          });
        return false;
      }

      return true;
    } else {
      if (notify !== undefined)
        notify({
          type: "error",
          title: t("validation.title"),
          text: t("validation.working_time"),
        });
      return false;
    }
  },
  bipedestacion: (data, notify, t) => {
    if (data !== null && data !== undefined) {
      let channels = data["musclesSelected"].some((e) => e.img);

      if (!channels) {
        if (notify !== undefined)
          notify({
            type: "error",
            title: t("validation.title"),
            text: t("validation.muscle"),
          });
        return false;
      }

      if (data["technical_name"].length === 0 || data["technical_name"] === "") {
        if (notify !== undefined)
          notify({
            type: "error",
            title: t("validation.title"),
            text: t("validation.name"),
          });
        return false;
      }

      if (timeToInt(data["sentado_parado"]) === 0) {
        if (notify !== undefined)
          notify({
            type: "error",
            title: t("validation.title"),
            text: t("validation.sitting_time_0_nd"),
          });
        return false;
      }

      if (timeToInt(data["sentado_parado"]) > 3) {
        if (notify !== undefined)
          notify({
            type: "error",
            title: t("validation.title"),
            text: t("validation.sitting_time_3_nd"),
          });
        return false;
      }

      if (timeToInt(data["parado_sentado"]) === 0) {
        if (notify !== undefined)
          notify({
            type: "error",
            title: t("validation.title"),
            text: t("validation.stopped_time_3_nd"),
          });
        return false;
      }

      if (timeToInt(data["parado_sentado"]) > 3) {
        if (notify !== undefined)
          notify({
            type: "error",
            title: t("validation.title"),
            text: t("validation.stopped_time_3_nd"),
          });
        return false;
      }

      if (data["bipedestaciones"] === 0) {
        if (notify !== undefined)
          notify({
            type: "error",
            title: t("validation.title"),
            text: t("validation.standing"),
          });
        return false;
      }

      return true;
    } else {
      if (notify !== undefined)
        notify({
          type: "error",
          title: t("validation.title"),
          text: t("validation.sitting_time_0_nd"),
        });
      return false;
    }
  },
  tens: (data, notify, t) => {
    if (data !== null && data !== undefined) {
      if (data["name"].length === 0 || data["name"] === "") {
        if (notify !== undefined)
          notify({
            type: "error",
            title: t("validation.title"),
            text: t("validation.name"),
          });
        return false;
      }

      if (data["name"].length === 0 || data["name"] === "") {
        if (notify !== undefined)
          notify({
            type: "error",
            title: t("validation.title"),
            text: t("validation.name"),
          });
        return false;
      }

      if (timeToInt(data["tiempo_trabajo"]) === 0) {
        if (notify !== undefined)
          notify({
            type: "error",
            title: t("validation.title"),
            text: t("validation.working_time"),
          });
        return false;
      }

      return true;
    } else {
      if (notify !== undefined)
        notify({
          type: "error",
          title: t("validation.title"),
          text: t("validation.name"),
        });
      return false;
    }
  },
  flexibility: (data, notify, t) => {
    if (data !== null && data !== undefined) {
      if (data["name"].length === 0 || data["name"] === "") {
        if (notify !== undefined)
          notify({
            type: "error",
            title: t("validation.title"),
            text: t("validation.name"),
          });
        return false;
      }

      if (data["repeticiones"] === null || data["repeticiones"] === 0) {
        if (notify !== undefined)
          notify({
            type: "error",
            title: t("validation.title"),
            text: t("validation.repetitions"),
          });
        return false;
      }

      return true;
    } else {
      if (notify !== undefined)
        notify({
          type: "error",
          title: t("validation.title"),
          text: t("validation.name"),
        });
      return false;
    }
  },
  neuromodulation: (data, notify, t) => {
    if (data !== null && data !== undefined) {
      if (data["name"].length === 0 || data["name"] === "") {
        if (notify !== undefined)
          notify({
            type: "error",
            title: t("validation.title"),
            text: t("validation.name"),
          });
        return false;
      }

      if (data["name"].length === 0 || data["name"] === "") {
        if (notify !== undefined)
          notify({
            type: "error",
            title: t("validation.title"),
            text: t("validation.name"),
          });
        return false;
      }

      if (timeToInt(data["tiempo_trabajo"]) === 0) {
        if (notify !== undefined)
          notify({
            type: "error",
            title: t("validation.title"),
            text: t("validation.working_time"),
          });
        return false;
      }
      return true;
    } else {
      if (notify !== undefined)
        notify({
          type: "error",
          title: t("validation.title"),
          text: t("validation.name"),
        });
      return false;
    }
  },
  cardiovascular: (data, notify, t) => {
    if (data !== null && data !== undefined) {
      if (data["name"].length === 0 || data["name"] === "") {
        if (notify !== undefined)
          notify({
            type: "error",
            title: t("validation.title"),
            text: t("validation.name"),
          });
        return false;
      }

      if (data["select"] === 1) {
        if (data["tiempo_trabajo"] === null || timeToInt(data["tiempo_trabajo"]) === 0) {
          if (notify !== undefined)
            notify({
              type: "error",
              title: t("validation.title"),
              text: t("validation.working_time"),
            });
          return false;
        }
      }

      if (data["select"] === 0) {
        if (data["repeticiones"] === null || data["repeticiones"] === 0) {
          if (notify !== undefined)
            notify({
              type: "error",
              title: t("validation.title"),
              text: t("validation.repetitions"),
            });
          return false;
        }
      }

      return true;
    } else {
      if (notify !== undefined)
        notify({
          type: "error",
          title: t("validation.title"),
          text: t("validation.name"),
        });
      return false;
    }
  },
  warnings: (data, notify, t) => {
    if (data !== null && data !== undefined) {
      if (data["technical_name"].length === 0 || data["technical_name"] === "") {
        if (notify !== undefined)
          notify({
            type: "error",
            title: t("validation.title"),
            text: t("validation.name"),
          });
        return false;
      }

      return true;
    } else {
      if (notify !== undefined)
        notify({
          type: "error",
          title: t("validation.title"),
          text: t("validation.fields"),
        });
      return false;
    }
  },
  pause: (data, notify, t) => {
    if (data !== null && data !== undefined) {
      if (data["technical_name"].length === 0 || data["technical_name"] === "") {
        if (notify !== undefined)
          notify({
            type: "error",
            title: t("validation.title"),
            text: t("validation.name"),
          });
        return false;
      }

      if (data["tiempo_trabajo"] === null || timeToInt(data["tiempo_trabajo"]) === 0) {
        if (notify !== undefined)
          notify({
            type: "error",
            title: t("validation.title"),
            text: t("validation.pause"),
          });
        return false;
      }
      return true;
    } else {
      if (notify !== undefined)
        notify({
          type: "error",
          title: t("validation.title"),
          text: t("validation.name"),
        });
      return false;
    }
  },
  records: (data, notify, t) => {
    if (data !== null && data !== undefined) {
      if (data["technical_name"].length === 0 || data["technical_name"] === "") {
        if (notify !== undefined)
          notify({
            type: "error",
            title: t("validation.title"),
            text: t("validation.name"),
          });
        return false;
      }

      if (data["files"].length === 0) {
        if (notify !== undefined)
          notify({
            type: "error",
            title: t("validation.title"),
            text: t("validation.pdf"),
          });
        return false;
      }
      return true;
    } else {
      if (notify !== undefined)
        notify({
          type: "error",
          title: t("validation.title"),
          text: t("validation.name"),
        });
      return false;
    }
  },
  video: (data, notify, t) => {
    if (data !== null && data !== undefined) {
      if (data["technical_name"].length === 0 || data["technical_name"] === "") {
        if (notify !== undefined)
          notify({
            type: "error",
            title: t("validation.title"),
            text: t("validation.name"),
          });
        return false;
      }

      if (data["videos"].length === 0) {
        if (notify !== undefined)
          notify({
            type: "error",
            title: t("validation.title"),
            text: t("validation.video"),
          });
        return false;
      }
      return true;
    } else {
      if (notify !== undefined)
        notify({
          type: "error",
          title: t("validation.title"),
          text: t("validation.video"),
        });
      return false;
    }
  },
  general: (data, notify, t) => {
    if (data !== null && data !== undefined) {
      if (data["technical_name"].length === 0 || data["technical_name"] === "") {
        if (notify !== undefined)
          notify({
            type: "error",
            title: t("validation.title"),
            text: t("validation.name"),
          });
        return false;
      }

      if (data["select"] === 0) {
        if (data["repeticiones"] === null || data["repeticiones"] === 0) {
          if (notify !== undefined)
            notify({
              type: "error",
              title: t("validation.title"),
              text: t("validation.repetitions"),
            });
          return false;
        }
      }

      if (data["select"] === 1) {
        if (timeToInt(data["tiempo_trabajo"]) === 0) {
          if (notify !== undefined)
            notify({
              type: "error",
              title: t("validation.title"),
              text: t("validation.time"),
            });
          return false;
        }
      }

      return true;
    } else {
      if (notify !== undefined)
        notify({
          type: "error",
          title: t("validation.title"),
          text: t("validation.name"),
        });
      return false;
    }
  },
  treadmill: (data, notify, t) => {
    if (data !== null && data !== undefined) {
      return true;
    } else {
      if (notify !== undefined)
        notify({
          type: "error",
          title: t("validation.title"),
          text: t("validation.fields"),
        });
      return false;
    }
  },
  biofeedback: (data, notify, t) => {
    if (data !== null && data !== undefined) {
      if (data["technical_name"].length === 0 || data["technical_name"] === "") {
        if (notify !== undefined)
          notify({
            type: "error",
            title: t("validation.title"),
            text: t("validation.name"),
          });
        return false;
      }

      if (data.data.length <= 0) {
        if (notify !== undefined)
          notify({
            type: "error",
            title: t("validation.title"),
            text: t("validation.fields"),
          });
        return false;
      }

      return true;
    } else {
      if (notify !== undefined)
        notify({
          type: "error",
          title: t("validation.title"),
          text: t("validation.fields"),
        });
      return false;
    }
  },
  cicloergometro: (data, notify, t) => {
    if (data !== null && data !== undefined) {
      return true;
    } else {
      if (notify !== undefined)
        notify({
          type: "error",
          title: t("validation.title"),
          text: t("validation.fields"),
        });
      return false;
    }
  },
  evaluations: (data, notify, t) => {
    if (data !== null && data !== undefined) {
      if (data["technical_name"].length === 0 || data["technical_name"] === "") {
        if (notify !== undefined)
          notify({
            type: "error",
            title: t("validation.title"),
            text: t("validation.name"),
          });
        return false;
      }

      if (data["evaluationCurrent"] == null) {
        if (notify !== undefined)
          notify({
            type: "error",
            title: t("validation.title"),
            text: t("validation.evaluation"),
          });
        return false;
      }
      return true;
    } else {
      if (notify !== undefined)
        notify({
          type: "error",
          title: t("validation.title"),
          text: t("validation.name"),
        });
      return false;
    }
  },
};
