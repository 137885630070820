import React, { useState, useEffect, useRef } from "react";
import { ArrowDown } from "../../assets/icons";
import styled from "styled-components";

export const DropDown = ({ options, onChange, value, type, dropdownClicked }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const dropDownRef = useRef(null);

  const toggling = () => setIsOpen(!isOpen);

  const onHeaderClicked = () => setIsOpen(!isOpen);

  const onOptionClicked = (option) => {
    setSelectedOption(option);
    onChange({ target: { value: option } });
    toggling();
  };

  const handleClickOutside = (event) => {
    if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    setIsOpen(dropdownClicked);
  }, [dropdownClicked]);


  useEffect(() => {
    setSelectedOption(value);
  }, [value]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <DropDownContainer type={type} ref={dropDownRef}>
      <DropDownHeader onClick={onHeaderClicked}>
        {selectedOption || value}{type === 'video_player' && 'x'}
        <ArrowDown
          style={{ width: "15px", height: "15px", marginLeft: type === "video_player" ? "2px" : "5px" }}
        />
      </DropDownHeader>
      <DropDownListContainer type={type} className={isOpen ? "open" : ""}>
        <DropDownList type={type} className={isOpen ? "open" : ""}>
          {options.map((option, index) => (
            <ListItem key={`${option}-${index}`} onClick={() => onOptionClicked(option)}>
              {type === "video_player" ? `${option}x` : option}
            </ListItem>
          ))}
        </DropDownList>
      </DropDownListContainer>
    </DropDownContainer>
  );
};

const DropDownContainer = styled.div`
  display: flex;
  position: relative;
  width: 35px;
  height: 40px;
  margin: 0 auto;
  ${(props) =>
    props.type === "video_player" &&
    `
    height: 0px;
    width: 100%;
  `}
`;

const DropDownHeader = styled.div`
  cursor: pointer;
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DropDownListContainer = styled.div`
  position: absolute;
  top: 100%;
  left: -8px;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease, visibility 0.2s ease;
  &.open {
    opacity: 1;
    visibility: visible;
  }
  max-height: calc(100vh - ${(props) => props.top}px);
  ${(props) =>
    props.type === "video_player" &&
    `
    top: 16px;
    left: -17px;
  `}
`;

const DropDownList = styled.ul`
  padding: 0;
  margin: 0;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  color: black;
  font-size: 1.3rem;
  font-weight: 500;
  list-style: none;
  max-height: 103px;
  overflow-y: auto;
  width: 100%;
  transition: opacity 0.1s ease, visibility 0.1s ease;
  border-radius: 4px;
  &.open {
    opacity: 1;
    visibility: visible;
  }
  ${(props) =>
    props.type === "video_player" &&
    `
    position: fixed;
    width: auto;
    max-height: 145px;
  `}
`;

const ListItem = styled.li`
  margin: 0.2em 0;
  padding: 0.4em 0.8em;
  cursor: pointer;
  &:hover {
    background-color: rgb(40, 27, 101);
    color: white;
  }
`;
