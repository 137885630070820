export const UPDATE_VERSION = 'UPDATE_VERSION';
export const PUSH_NOTIFIACTION = 'PUSH_NOTIFIACTION';
export const REMOVE_NOTIFIACTION = 'REMOVE_NOTIFIACTION';
export const USER_PROFILE = 'USER_PROFILE';
export const VIDEOS = 'VIDEOS';
export const FILES = 'FILES';
export const IMAGES = 'IMAGES';
export const CAT_MEDIA = 'CAT_MEDIA';
export const DEVICE_MEDIA = 'DEVICE_MEDIA';
export const GET_ROOMS = 'GET_ROOMS';
export const PROMPT_ACTIVE = "PROMPT_ACTIVE";
export const PROMPT_MESSAGE = "PROMPT_MESSAGE";