import React from "react";
import PropTypes from "prop-types";

import DatePicker from "@amir04lm26/react-modern-calendar-date-picker";
import "@amir04lm26/react-modern-calendar-date-picker/lib/DatePicker.css";

import { DatePickerContainer, DatePickerInput, DatePicketInputContainer } from "./styles";

import { CalendarIcon } from "./icons";

// Funciones comunes
const toNativeDate = (date) => new Date(date.year, date.month - 1, date.day);
const getMonthLength = (date) => new Date(date.year, date.month, 0).getDate();
const defaultTransform = (digit) => digit;

const commonLocaleProperties = {
  weekStartingIndex: 1,
  getToday: (gregorainTodayObject) => gregorainTodayObject,
  toNativeDate: toNativeDate,
  getMonthLength: getMonthLength,
  transformDigit: defaultTransform,
  yearLetterSkip: 0,
  isRtl: false,
  digitSeparator: ','
};

const SpanishLocale = {
  ...commonLocaleProperties,
  months: [
    'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
    'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
  ],
  weekDays: [
    { name: 'Domingo', short: 'Dom', isWeekend: true },
    { name: 'Lunes', short: 'Lun' },
    { name: 'Martes', short: 'Mar' },
    { name: 'Miércoles', short: 'Mié' },
    { name: 'Jueves', short: 'Jue' },
    { name: 'Viernes', short: 'Vie' },
    { name: 'Sábado', short: 'Sáb', isWeekend: true }
  ],
  nextMonth: 'Siguiente mes',
  previousMonth: 'Mes anterior',
  openMonthSelector: 'Abrir selector de mes',
  openYearSelector: 'Abrir selector de año',
  closeMonthSelector: 'Cerrar selector de mes',
  closeYearSelector: 'Cerrar selector de año',
  from: 'Desde',
  to: 'Hasta',
  defaultPlaceholder: 'Seleccione una fecha'
};

const EnglishLocale = {
  ...commonLocaleProperties,
  months: [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ],
  weekDays: [
    { name: 'Sunday', short: 'Sun', isWeekend: true },
    { name: 'Monday', short: 'Mon' },
    { name: 'Tuesday', short: 'Tue' },
    { name: 'Wednesday', short: 'Wed' },
    { name: 'Thursday', short: 'Thu' },
    { name: 'Friday', short: 'Fri' },
    { name: 'Saturday', short: 'Sat', isWeekend: true }
  ],
  nextMonth: 'Next month',
  previousMonth: 'Previous month',
  openMonthSelector: 'Open month selector',
  openYearSelector: 'Open year selector',
  closeMonthSelector: 'Close month selector',
  closeYearSelector: 'Close year selector',
  from: 'From',
  to: 'To',
  defaultPlaceholder: 'Select a date'
};

const minimumValidateDate = {
  year: 1923,
  month: 1,
  day: 1
};

export const DatePickerComponent = ({
  minimumDate,
  maximumDate,
  range = true,
  width = "200px",
  onChange,
  value,
  height = "48px",
  placeholder = "dd/mm/yyyy",
  position = "auto",
  asRequired = false,
  locale = "es"
}) => {
  const renderCustomInput = ({ ref }) => (
    <DatePicketInputContainer>
      <CalendarIcon />
      <DatePickerInput
        height={height}
        width={width}
        readOnly
        onChange={onChange}
        ref={ref}
        placeholder={!asRequired ? placeholder : null}
        value={
          range
            ? value.from && value.to
              ? `${value.from.day}/${value.from.month}/${value.from.year} - ${value.to.day}/${value.to.month}/${value.to.year}`
              : ""
            : value
            ? `${value.day}/${value.month}/${value.year}`
            : ""
        }
        className="my-custom-input-class"
      />
      {asRequired && (
        <label className={`borde-label ${value !== "" ? "top-0" : null}`}>
          {placeholder}
          <span style={{ color: "red" }}>*</span>
        </label>
      )}
    </DatePicketInputContainer>
  );

  return (
    <DatePickerContainer>
      <DatePicker
        value={value}
        onChange={onChange}
        inputPlaceholder={`${placeholder}${asRequired ? "*" : null}`}
        renderInput={renderCustomInput}
        colorPrimary="#281B65"
        colorPrimaryLight="#EAE5FF"
        calendarClassName="custom-calendar"
        shouldHighlightWeekends
        minimumDate={minimumDate || minimumValidateDate}
        maximumDate={maximumDate}
        calendarPopperPosition={position}
        locale={locale === "es" ? SpanishLocale : EnglishLocale}
      />
    </DatePickerContainer>
  );
};

DatePickerComponent.propTypes = {
  /**
   * Input type single date or range
   */
  range: PropTypes.bool,
  /**
   * Input width
   */
  width: PropTypes.string,
};
