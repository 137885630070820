export const menuList = (t) => {

  const menu_availables = [
    {
      icon: "icon-home",
      roles: ["superadmin", "admin", "therapist", "admin-clinic"],
      path: "/",
      text: t("home"),
    },

    {
      icon: "icon-patient",
      roles: ["superadmin", "admin-clinic", "therapist","admin"],
      path: "/clinical-records",
      text: t("clinical_records"),
    },
    {
      icon: "icon-user",
      roles: ["superadmin", "admin", "admin-clinic"],
      path: "/users",
      text: t("users_roles"),
    },
    {
      icon: "icon-calendar-user",
      roles: ["superadmin", "admin", "therapist", "admin-clinic"],
      path: null,
      text: t("treatment"),
      type: "dropdown",
      children: [
        // {
        //   roles: ["superadmin", "admin", "admin-clinic"],
        //   path: "/treatment/programs",
        //   text: t("sales"),
        // },
        {
          roles: ["superadmin", "admin", "admin-clinic", "therapist"],
          path: "/treatment/planning",
          text: t("plannings"),
        },
        // {
        //   roles: ["superadmin", "admin", "therapist", "admin-clinic"],
        //   path: "/treatment/schedule",
        //   text: t("schedule"),
        // },
      ],
    },
    {
      icon: "icon-media-category",
      roles: ["superadmin", "therapist", "admin-clinic"],
      path: null,
      text: t("content_management"),
      type: "dropdown",
      children: [
        {
          roles: ["superadmin", "therapist", "admin-clinic"],
          path: "/content/protocols",
          text: t("sessions"),
        },
        {
          roles: ["superadmin", "admin-clinic"],
          path: "/content/media-library",
          text: t("library"),
        },
        {
          roles: ["superadmin", "admin-clinic"],
          path: "/content/media-categories",
          text: t("media_categories"),
        },
        {
          roles: ["superadmin", "admin-clinic"],
          path: "/content/evaluations",
          text: t("evaluations"),
        },
        {
          roles: ["superadmin", "admin-clinic"],
          path: "/content/evaluation-categories",
          text: t("evaluation_categories"),
        },
      ],
    },
    {
      icon: "icon-services",
      roles: ["superadmin", "admin", "admin-clinic"],
      path: null,
      text: t("resources"),
      type: "dropdown",
      children: [
        {
          roles: ["superadmin", "admin-clinic"],
          path: "/resources/subsidiary",
          text: t("branch_configurator"),
        },
        // {
        //   roles: ["superadmin", "admin-clinic"],
        //   path: "/resources/physical-resources",
        //   text: t("physical_resource_configurator"),
        // },
        {
          roles: ["superadmin", "admin-clinic"],
          path: "/resources/specialties",
          text: t("specialties_configurator"),
        },
        // {
        //   roles: ["superadmin", "admin-clinic", "admin"],
        //   path: "/resources/service-maintainer",
        //   text: t("services_configurator"),
        // },
      ],
    },
    // {
    //   icon: "icon-export",
    //   roles: ["superadmin", "admin", "admin-clinic"],
    //   path: "/reportspatients",
    //   text: t("reports"),
    // },
  ];

  return { menu_availables };
};
