import { useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import API from "../services/api";

const api = new API();

export const useGetCategoriesEvaluations = ( useId ) => {
    const [categoriesEvaluationsDropdown, setCategoriesEvaluationsDropdown] = useState([])

    const CategoriesEvaluationsQuery = useQuery({
        queryKey: ["categories-evaluations"],
        queryFn: () => api.getCategoriesEvaluations()
                .then( (res) => {
                    setCategoriesEvaluationsDropdown(
                        res.data.map((e) => {
                            e["label"] = e.name;
                            e["value"] = !useId ? e.name : e._id;
                            return e;
                        })
                    )
                    return res.data
                } ),
        refetchOnWindowFocus: false,
    });

    const { mutate: addCategoriesEvaluations, isLoading: postLoading } = useMutation(
        ( newCategoriesEvaluations ) => {
            return api.createCategoriesEvaluations( newCategoriesEvaluations )
        },
        {
            onSuccess: async () => CategoriesEvaluationsQuery.refetch()
        }
    );

    const { mutate: deleteCategoriesEvaluations, isLoading: deleteLoading } = useMutation(
        ( id ) => {
            return api.deleteCategoriesEvaluations( id )
        },
        {
            onSuccess: async () => CategoriesEvaluationsQuery.refetch()
        }
    );


    const customLoading = CategoriesEvaluationsQuery.isLoading || postLoading || deleteLoading;

    return {
        categoriesEvaluationsDropdown,
        CategoriesEvaluationsQuery,
        addCategoriesEvaluations,
        deleteCategoriesEvaluations,
        customLoading
    }

}
