export const formValidation = ( state, t, notify ) => {
    if (state.protocolName == null || state.protocolName.length === 0 || state.protocolName === '') {
        notify({ type: 'error', title: 'Error', text: t('alerts.session.nameField') })
        return false;
    }

    if (state.complementName == null || state.complementName.length === 0 || state.complementName === '') {
        notify({ type: 'error', title: 'Error', text: t('alerts.session.complementField') })
        return false;
    }

    return true
}

export const formSessionValidation = ( state, t, notify ) => {
    if (state.sessionName == null || state.sessionName.length === 0 || state.sessionName === '') {
        notify({ type: 'error', title: 'Error', text: t('alerts.session.nameField') })
        return false;
    }

    if (state.sessionComplementName == null || state.sessionComplementName.length === 0 || state.sessionComplementName === '') {
        notify({ type: 'error', title: 'Error', text: t('alerts.session.complementField') })
        return false;
    }

    return true
}