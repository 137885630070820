import styled from "styled-components";

export const RecordCardContainer = styled.div`
  padding-top: 20px;

  & p {
    margin: 0;
    font-weight: 300;
    font-size: 12px;
    letter-spacing: 0.850962px;
    color: #182024;
  }

  ._bold {
    font-weight: 500;
  }
`;

export const RecordCardHeader = styled.div`
  background-color: #f6f6f6;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  /* align-items: center; */
  /* justify-content: space-between; */
  padding: 10px 7px;

  ._profession {
    margin-right: 20px;
  }

  //& > div {
  //  display: flex;
  //  align-items: center;
  //}

  & > p > span {
    font-weight: 300;
  }
`;

export const RecordCardBody = styled.div`
  & > div:first-child {
    padding-top: 5px;
    font-weight: 300;
    margin-bottom: 10px;
  }
`;

export const BlockColor = styled.div`
  background-color: ${({ bg }) => bg};
  border-radius: 3px;
  height: 15px;
  margin-right: 10px;
  width: 15px;
`;

export const RecordCardExpand = styled.div`
  & > .recordcard--expand_body {
    margin: 10px 0 4px 0;
  }

  & .recordcard--bold_text {
    margin: 0;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.850962px;
    color: #182024;
  }

  & .recordcard--expand_desc {
    margin-bottom: 4px;
    display: flex;

    & > p {
      margin-right: 14px;

      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      letter-spacing: 0.850962px;

      color: #182024;
    }
  }

  & .recordcard--m_4 {
    margin-bottom: 4px;
  }

  & > .recordcard--expand {
    display: flex;
    align-items: center;
    cursor: pointer;

    & > svg {
      transform: rotate(180deg);
      margin-left: 5px;
    }

    & > .recordcard--active {
      transform: rotate(0deg);
    }
  }
`;

export const ProfessionalContainer = styled.div`
  display: grid;

  & > div {
    display: grid;
    align-items: center;
    grid-template-columns: 25px 1fr;
  }
`;
