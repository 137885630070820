import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Menu, MenuItem, IconButton } from '@material-ui/core';
// import { Link } from 'react-router-dom';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import '../assets/img/icomoon/style.css';


const useStyles = makeStyles({
	root: {
		color: '#281B65',

		'&:focus': {
			outline: 'none',
		},
	},
});

const ITEM_HEIGHT = 48;

export default function SimpleMenu(props) {

	const classes = useStyles();

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div>
			<IconButton fontSize="small" className={classes.root}
				aria-label="more"
				aria-controls="long-menu"
				aria-haspopup="true"
				onClick={handleClick}
			>
				<MoreVertIcon />
			</IconButton>
			<Menu
				id="long-menu"
				anchorEl={anchorEl}
				keepMounted
				open={open}
				onClose={handleClose}
				PaperProps={{
					style: {
						maxHeight: ITEM_HEIGHT * 4.5,
						width: '20ch',
						color: '#636262',
						padding: '5px 10px',
						boxShadow: '15px 10px 30px #DDDDDD',
					},
				}}
			>

				{props.data ? props.data.map((e,i) => {
					return (
						<MenuItem key={i} onClick={handleClose}>
							<Grid item xs={12} container onClick={() => e.action()}>
								<Grid item xs={11}>
									{e.title}
								</Grid>
								<Grid item xs={1} container direction="row" justifyContent="center" alignItems="center">
									<span className="icon-arrow-point-to-right color-primario fz-10"></span>
								</Grid>
							</Grid>
						</MenuItem>
					)
				}) : null}

			</Menu>
		</div>
	);
}


