import React, { useState } from "react";
import { TwitterPicker } from "react-color";
import { useTranslation } from "react-i18next";

const ColorPicker = (props) => {
  const { t } = useTranslation("global");

  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const popover = {
    position: "absolute",
    zIndex: "2",
  };
  const cover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  };

  const changeColor = (hex) => {
    // handleClose();
    if (props.onChange !== undefined) props.onChange(hex);
  };

  return (
    <div className="d-flex justify-content-end">
      <button
        onClick={handleClick}
        style={{
          background: props.color,
          border: `none`,
          borderRadius: 3,
          height: 45,
          color: "#fff",
          width: 300,
        }}
      >
        {t("select_color")}
      </button>
      {displayColorPicker ? (
        <div style={popover}>
          <div style={cover} onClick={handleClose} />
          <TwitterPicker color={props.color} onChange={(v) => changeColor(v.hex)} />
        </div>
      ) : null}
    </div>
  );
};

ColorPicker.defaultProps = {
  color: "#5943C1",
};

export default ColorPicker;
