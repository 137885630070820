import React, { Fragment } from "react";


const Main = ({ title, children }) => (
    <Fragment>
        <div className="col-md-12 mx-auto" id="main" style={{ height: "" }}>
            <h6 className="text-black-50 pl-2 mt-3">{title}</h6>
            <div className="row d-flex justify-content-center">
                {children}
            </div>
        </div>
    </Fragment>
)


export default Main;
