import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';


function LinearProgressWithLabel(props) {
    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress variant="determinate" {...props} />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="secondary">{`${Math.round(
                        props.value,
                    )}%`}</Typography>
                </Box>
            </Box>
        </>
    );
}


const BorderLinearProgress = withStyles(() => ({
    root: {
        height: 6,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: '#E7ECF4',
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#5943C1',
    },
}))(LinearProgressWithLabel);


const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
});

export default function CustomizedProgressBars(props) {
    const classes = useStyles();

    // const deletPercent = data => parseInt(data.split('%')[0]);
    return (
        <div className={classes.root}>
            <BorderLinearProgress value={props.value} />
        </div>
    );
}

