import React from 'react';
import './style.css';
import { Grid } from '@material-ui/core';

const Expand = (props) => {

    const idKey = "expanded-" + Math.random().toString(36).slice(-8) + Math.random().toString(36).slice(-8);

    return (
        <div style={{ width: "100%" }}>
            <input type="checkbox" name="accordeon" id={idKey} className="input-radio-open" />
            <header className="element__header">
                <label className="header__title" htmlFor={idKey}>
                    <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                        <Grid item xs={11}>
                            <p className="m-0">{props.titulo}</p>
                        </Grid>
                        <Grid item xs={1} container direction="row" justifyContent="center" alignItems="center">
                            <svg className="rotate-icons" width="10" height="10" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_2778:1584)">
                                    <path d="M9.0099 15.2901L0.410482 6.69044C-0.136827 6.1434 -0.136827 5.25646 0.410482 4.70968C0.957304 4.16285 1.8442 4.16285 2.39097 4.70968L10.0001 12.3191L17.609 4.7099C18.1561 4.16308 19.0429 4.16308 19.5897 4.7099C20.1368 5.25672 20.1368 6.14362 19.5897 6.69066L10.9902 15.2904C10.7166 15.5638 10.3585 15.7003 10.0002 15.7003C9.6417 15.7003 9.28331 15.5635 9.0099 15.2901Z" fill="#281B65" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_2778:1584">
                                        <rect width="20" height="20" fill="white" transform="translate(20) rotate(90)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </Grid>
                    </Grid>
                </label>
            </header>
            <div className="element__content">
                <div className="content__wrapper">
                    <span className="m-0">{props.children}</span>
                </div>
            </div>
        </div>
    )
}



export default Expand;