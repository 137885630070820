import React, { useState, useEffect } from "react";
import { Modal, Button } from "trainfes-components-library";
import API from "../../../../../../services/api";
import { connect } from "../../../../../../store";

import { Card } from "../../../../components/Card/";
import FindElectrodes from "../../../../components/FindElectrodesModal";

const api = new API();

const CardComponent = ({ data, notify, handleGetData, setLoading, t, store }) => {
  const { _id, name, left, normal, right } = data;

  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [positioning, setPositioning] = useState(null);

  const dropdownOptions = store.categories_media.map((category) => {
    return { value: category._id, label: category.name };
  });

  const handleGetPositioningById = () => {
    api.getPositioningById(_id).then(({ data }) => {
      setPositioning({
        ...data,
        category: dropdownOptions.filter((option) => option.value === data.category)[0],
      });
    });
  };

  useEffect(() => {
    handleGetPositioningById();
  }, []);

  // Methods
  const handleDelete = () => {
    try {
      setLoading(true);
      api.deletePositioningById(_id).then(() => {
        handleGetData();
      });
    } catch (error) {
      notify({
        type: "error",
        title: t("notify.error"),
        text: t("notify.error"),
      });
    }
  };

  const allowedExtensions = ['jpg', 'jpeg', 'png', 'gif', 'webp'];

  const handleValidationElectrodes = () => {
    const { name, description, left, normal, right, category } = positioning;

    if (name.trim() === "" || description.trim() === "" || !category) {
      notify({
        type: "error",
        title: t("notify.error"),
        text: t("notify.input_missed"),
      });
      return;
    }

    if (
      (!normal || normal === "null") &&
      (!left || left === "null") &&
      (!right || right === "null")
    ) {
      notify({
        type: "error",
        title: t("notify.error"),
        text: t("notify.images_missed"),
      });
      return;
    }

    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("left", left);
    formData.append("normal", normal);
    formData.append("right", right);
    formData.append("category", category.value);

    if (normal && normal !== "null") {
      if (left && left !== "null") {
        if (!right || right === "null") {
          // ALERTA ERROR (cargue imagen derecha)
          notify({
            type: "error",
            title: t("notify.error"),
            text: t("notify.right_image_missed"),
          });
          return;
        }
      }

      if (right && right !== "null") {
        if (!left || left === "null") {
          // ALERTA ERROR (cargue imagen izquierda)
          notify({
            type: "error",
            title: t("notify.error"),
            text: t("notify.left_image_missed"),
          });
          return;
        }
      }

      let leftFile, normalFile, rightFile;

      if (left) {
        if (left.name) { 
          leftFile = left.name.split('.').pop();
        }
      }

      if (normal) {
        if (normal.name) {
          normalFile = normal.name.split('.').pop();
        }
      }
      
      if (right) {
        if (right.name) {
          rightFile = right.name.split('.').pop(); 
        }
      }

      if (leftFile && !allowedExtensions.includes(leftFile)) {
        notify({
          type: "error",
          title: t("notify.error"),
          text: t("notify.invalid_image_format"),
        });
        return;
      }

      if (normalFile && !allowedExtensions.includes(normalFile)) {
        notify({
          type: "error",
          title: t("notify.error"),
          text: t("notify.invalid_image_format"),
        });
        return;
      }

      if (rightFile && !allowedExtensions.includes(rightFile)) {
        notify({
          type: "error",
          title: t("notify.error"),
          text: t("notify.invalid_image_format"),
        });
        return;
      }

      try {
        // POST API
        setLoading(true);
        setShowModal(false);
        api
          .putPositioningById(_id, formData)
          .then(() => {
            setShowModal(false);
          })
          .finally(() => handleGetData());

        // ALERTA SUCCESS
        notify({
          type: "success",
          title: t("notify.success"),
          text: t("notify.success"),
        });
        setShowModal(false);
        return;
      } catch (error) {
        notify({
          type: "error",
          title: t("notify.error"),
          text: t("notify.error"),
        });
        return;
      }
    }

    if (left && left !== "null") {
      if (!right || right === "null") {
        // ALERTA ERROR (cargue imagen derecha)
        notify({
          type: "error",
          title: t("notify.error"),
          text: t("notify.right_image_missed"),
        });
        return;
      }
    }

    if (right && right !== "null") {
      if (!left || left === "null") {
        // ALERTA ERROR (cargue imagen izquierda)
        notify({
          type: "error",
          title: t("notify.error"),
          text: t("notify.left_image_missed"),
        });
        return;
      }
    }

    let leftFile, normalFile, rightFile;

    if (left) {
      if (left.name) { 
        leftFile = left.name.split('.').pop();
      }
    }

    if (normal) {
      if (normal.name) {
        normalFile = normal.name.split('.').pop();
      }
    }
    
    if (right) {
      if (right.name) {
        rightFile = right.name.split('.').pop(); 
      }
    }

    if (leftFile && !allowedExtensions.includes(leftFile)) {
      notify({
        type: "error",
        title: t("notify.error"),
        text: t("notify.invalid_image_format"),
      });
      return;
    }

    if (normalFile && !allowedExtensions.includes(normalFile)) {
      notify({
        type: "error",
        title: t("notify.error"),
        text: t("notify.invalid_image_format"),
      });
      return;
    }

    if (rightFile && !allowedExtensions.includes(rightFile)) {
      notify({
        type: "error",
        title: t("notify.error"),
        text: t("notify.invalid_image_format"),
      });
      return;
    }

    try {
      // POST API
      setLoading(true);
      setShowModal(false);
      api
        .putPositioningById(_id, formData)
        .then(() => {
          setShowModal(false);
        })
        .finally(() => handleGetData());

      // ALERTA SUCCESS
      notify({
        type: "success",
        title: t("notify.success"),
        text: t("notify.success"),
      });
      return;
    } catch (error) {
      notify({
        type: "error",
        title: t("notify.error"),
        text: t("notify.error"),
      });
      return;
    }
  };

  return (
    <>
      <Card
        filename={name}
        onClick={() => setShowModal(true)}
        onClickDelete={() => setShowDeleteModal(true)}
        img={normal !== "null" ? normal : left ? left : right}
      />
      <Modal
        title={t("edit_electrode_positioning")}
        width="fit-content"
        isOpen={showModal}
        handleClose={() => setShowModal(false)}
        buttons={
          <>
            <Button
              type="ghost"
              label={t("cancel")}
              onClick={() => setShowModal(false)}
              width="142px"
            />
            <Button label={t("save")} width="142px" onClick={() => handleValidationElectrodes()} />
          </>
        }
      >
        {positioning && (
          <FindElectrodes positioning={positioning} setPositioning={setPositioning} notify={notify} />
        )}
      </Modal>
      <Modal
        title={`${t("sure_you_want_to_delete")} ${name}?`}
        isOpen={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        buttons={
          <>
            <Button
              type="ghost"
              label={t("cancel")}
              onClick={() => setShowDeleteModal(false)}
              width="142px"
            />
            <Button label={t("delete")} width="142px" onClick={handleDelete} />
          </>
        }
      ></Modal>
    </>
  );
};
export default connect(CardComponent);
