import React from "react";
import Loader from "./index";
import { useTranslation } from "react-i18next";

const LoaderContainer = ({
  active = true,
  height = "70vh",
  heightContent = "",
  className = {},
  style = {},
  text,
  children,
}) => {
  const { t } = useTranslation("global");
  return active ? (
    <div className="container-loader" style={{ minHeight: height }}>
      <div className="card-loader" style={style}>
        <div className="dblock">
          <Loader />
          <p>{text !== undefined ? text : t("loader")}</p>
        </div>
      </div>
    </div>
  ) : (
    <div className={className} style={{ height: heightContent, style }}>
      {children}
    </div>
  );
};

export default LoaderContainer;
