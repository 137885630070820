import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { RadioButton, Checkbox } from "trainfes-components-library";
import { CrossIcon } from "../../../../../assets/icons";

export const RadioInput = ({
  editingId,
  id,
  index,
  isActive,
  onChange,
  onClick,
  onRemove,
  placeholder,
  stored,
  type,
  value,
  hideCheckmark = false,
  numLabel = null,
}) => {
  const [active] = useState(isActive);
  const { t } = useTranslation('evaluations')
  // const handleActive = () => setActive(true);

  const valid = editingId === id && stored;

  const typeItemRender = (type) => {
    switch (type) {
      case "multi_select":
        return <RadioButton disabled />;
      case "checkboxes":
        return <Checkbox disabled />;
      case "dropdown":
        return <span>{`${index}.`}</span>;
      case "scale_linear":
        return hideCheckmark ? (
          <span>{numLabel}</span>
        ) : (
          <RadioButton disabled />
        );
      default:
        return <RadioButton disabled />;
    }
  };

  return (
    <Container type={type}>
      {active ? (
        <>
        <StyledLabel hideCheckmark={hideCheckmark}>{typeItemRender(type)}</StyledLabel>
          <InputContainer>
            {valid ? (
              <Text className="__stored">{value}</Text>
            ) : (
              <>
                <Input
                  placeholder={placeholder}
                  value={value}
                  onChange={onChange}
                />
                <CrossStyled onClick={onRemove} />
              </>
            )}
          </InputContainer>
        </>
      ) : (
        <>
        <StyledLabel hideCheckmark={hideCheckmark}>{typeItemRender(type)}</StyledLabel>
          <Text onClick={onClick}>{t('add_option')}</Text>
        </>
      )}
    </Container>
  );
};

const StyledLabel = styled.label`
  label {
    padding: 0px 15px;
    margin: -8px 0px;
  }

  ${(props) =>
    props.hideCheckmark &&
    `
    span.checkmark {
      border: 1px solid #9aa2a8;
      background-color: transparent;
    }
    label {
      padding: 0px 0px;
      margin: 0px 0px;
    }
  `}
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 35px 1fr;
  place-items: center;
  margin-bottom: 20px;

  ${(props) =>
    props.type === "scale_linear" &&
    `
    grid-template-columns: 23px 1fr;
    margin-bottom: 0px;
  `}
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
`;

const CrossStyled = styled(CrossIcon)`
  position: absolute;
  right: 0;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

const Input = styled.input`
  width: 100%;
  border: none;
  font-size: 14px;
  padding: 3px;

  border-bottom: 0.5px solid #9aa2a8;
  outline: none;

  &:hover {
    border-bottom: 0.5px solid #5943c1;
  }

  &:active {
    border-bottom: 0.5px solid #5943c1;
  }
`;

const Text = styled.span`
  font-weight: 400;
  font-size: 12px;
  color: #4632a4;
  cursor: pointer;
  justify-self: flex-start;
  align-self: center;
  height: 27px;
  line-height: 2.4;

  &.__stored {
    color: #182024;
  }

  &:hover {
    opacity: 0.7;
  }
`;
