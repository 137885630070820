import React from 'react'
import styled from 'styled-components';

import { Input } from "trainfes-components-library"
import UploadFiles from '../../../components/uploadFiles';
import Editor from "../../../components/editor"

import * as Action from "../reducer/actions"

export const ModalNewSession = ({ t, state, dispatch }) => {
  return (
    <div>
        <InputsContainer>
                <Input
                    placeholder={t("session.name")}
                    value={ state.sessionName }
                    onChange={ ({ target }) => dispatch({ type: Action.SET_FIELDS, payload: { field: "sessionName", value: target.value } }) }
                />
                <Input
                    placeholder={t("session.complement")}
                    value={ state.sessionComplementName }
                    onChange={ ({ target }) => dispatch({ type: Action.SET_FIELDS, payload: { field: "sessionComplementName", value: target.value } }) }
                />
        </InputsContainer>

        <SubTitle>{t("desc")}</SubTitle>
        <Editor 
            value={state.sessionDescription} 
            onChange={ (value) => dispatch({ type: Action.SET_FIELDS, payload: { field: "sessionDescription", value: value } }) } 
        />

        <SubTitle>{t('uploadFiles')}</SubTitle>
        <UploadFiles 
            extentions={['.png', '.jpg', '.jpeg', '.gif', '.svg']} 
            title={t('session.upload')} 
            value={state.sessionFiles} 
            onChange={ (files) => dispatch({ type: Action.SET_FIELDS, payload: { field: "sessionFiles", value: files } })} 
        />
    </div>
  )
}

const SubTitle = styled.h6`
    margin: 1rem 0 0.5rem 0;
    color: #281b65;
`;

const InputsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
`;