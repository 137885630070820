import React from "react";
import styled from "styled-components";
import { RadioButton } from "trainfes-components-library";

export const LinearScale = ({ question, preview }) => {
    const min = question.data[0] && question.data[0].name ? parseInt(question.data[0].name) : 1;
    const max = question.data[1] && question.data[1].name ? parseInt(question.data[1].name) : 5;
    const range = Array.from(Array(max - min + 1), (_, i) => i + min);
    const etiq1 = question.data[2] && question.data[2].name ? question.data[2].name : "";
    const etiq2 = question.data[3] && question.data[3].name ? question.data[3].name : "";
    return (
        
        <div>
        <ContainerHorizontal>
          {range.map((num, i) => (
            <ContainerVertical key={i}>
              <React.Fragment key={i}>
                {etiq1 === "" && etiq2 === "" ? (
                  <>
                    <NumberOptionBlank></NumberOptionBlank>
                    <RadioButton disabled={preview}/>
                    <NumberOption>{num}</NumberOption>
                  </>
                ) : (
                  <>
                  {i === 0 ? (
                    <>
                      {etiq1 !== "" ? <NumberOption>{etiq1}</NumberOption> : <NumberOptionBlank />}
                    </>
                  ) : i === range.length - 1 ? (
                    <>
                      {etiq2 !== "" ? <NumberOption>{etiq2}</NumberOption> : <NumberOptionBlank />}
                    </>
                  ) : (
                    <NumberOptionBlank></NumberOptionBlank>
                  )}
                  <RadioButton disabled={preview}/>
                  <NumberOption>{num}</NumberOption>
                  {/* span para unir los RadioButton */}
                  {/* {i < range.length - 1 && <span />} */}
                  </>
                )}
              </React.Fragment>
            </ContainerVertical>
          ))}
        </ContainerHorizontal>
      </div>
    );
}

const ContainerHorizontal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  justify-content: center;

  span {
    width: 10%;
    height: 1px;
    /* background-color: #fdad34; */
  }

  label  {
    left: 12px;
    top: -4px
  }
`;

const ContainerVertical = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NumberOption = styled.div`
  width: 100%;
  display: flex;
  margin: 26px 0;
  max-width: 37px;
  max-height: 0px;
  justify-content: center;
  align-items: center;
`;

const NumberOptionBlank = styled.div`
  width: 100%;
  height: 22px;
  display: flex;
  margin: 15px 0;
`;