import { motion } from "framer-motion"
import styled, { css } from "styled-components";

export const Container = styled.div`
  padding: 0 25px 30px 25px;
  background: #f3f6fd;
  height: calc(100vh - 45px);
  max-height: calc(100vh - 45px);
  overflow: auto;
`;

export const Form = styled.div`
  display: grid;
  border-radius: 4px;
  grid-gap: 15px;
  margin-bottom: 15px;
  max-width: 704px;
  width: 100%;
  margin: 0 auto 15px auto;
  background: #fff;
  padding: 15px;
`;

export const Body = styled.div`
  background: #f3f6fd;
  border-radius: 4px;
  
  overflow: auto;
  width: 100%;
`;

const BoxCommon = styled(motion.div)`
  background: #ffffff;
  max-width: 704px;
  width: 100%;
  border-radius: 4px;
  padding: 20px 22px 0px;
  position: relative;
  margin: 0 auto 15px auto;

  & > .__button {
    position: absolute;
    right: -50px;
    top: 0;
    display: flex;
    flex-direction: column;
  
    & > div {
      margin-bottom: 5px;
    }
  }

  & > .__head,
  & > .__body {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 20px;
  }

  & > .__head {
    margin-bottom: 17px;
  }

  .__body label {
    display: flex;
    justify-content: center;
  }
`;

export const MainBox = styled(BoxCommon)`
  border: 1.5px dashed #6979F8;
  margin-bottom: 30px;

  & > .__title {
    font-weight: 600;
    font-size: 16px;
    color: #182024;
    margin-bottom: 15px;
  }
`;

export const Box = styled(BoxCommon)`
  min-height: 120px;

  & > .__title {
    font-weight: 600;
    font-size: 16px;
    color: #182024;
  }

  ${({ dragging }) =>
    dragging &&
    css`
      opacity: 1;
      border: 1.5px dashed #6979F8;
    `}

  ${({ dragOver }) =>
    dragOver &&
    css`
      border: 1.5px dashed #6979F8;
    `}
`;

export const DragContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${({ dragStart }) =>
    dragStart &&
    css`
      opacity: 0.6;
    `}
`;

export const BoxsContainer = styled.div`
  max-width: 704px;
  width: 100%;
  margin: auto;
`;

export const BodySingleColumn = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

export const ImagePreview = styled.div`
  width: 100%;
  min-height: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 17px;

  img {
    max-width: 170px;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const ContainerUpload = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  /* PhotoIcon */

  svg {
    cursor: pointer;
  }

`;

export const ContainerButtonEditIMG = styled.div`
  position: absolute;
  top: 94px;
  right: 232px;
`;

export const Spacer = styled.div`
  margin: 4px 0;
`;