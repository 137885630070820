import React from "react";

import styled from 'styled-components'
import { Textarea } from "trainfes-components-library";
import { SET_VARS_DATA } from "../../reducer/evaluationsActions";

export const ParagraphComp = ({
  state,
  dispatch,
  stored,
  editingId,
  questionId,
  typeRenderData,
  setTypeRenderData,
  value,
  textareaValue,
  setTextareaValue,
}) => {
  const { varsData } = state;

  const onTextareaChange = (e) => {
    const newArray = JSON.parse(JSON.stringify([...typeRenderData]));
    newArray[0] = {
      ...newArray[0],
      name: e.target.value,
    };

    setTypeRenderData(newArray);
    setTextareaValue(e.target.value);
  };

  const onTextareaEditingChange = (e) => {
    const newDataArr = [...varsData];
    const index = newDataArr.findIndex((el) => el.id === questionId);
    newDataArr[index].data[0] = {
      ...newDataArr[index].data[0],
      name: e.target.value,
    };

    dispatch({
      type: SET_VARS_DATA,
      payload: newDataArr,
    });
  };

  if (stored && editingId === questionId) {
    return (
      <Container>
        <Textarea
          placeholder=""
          value={value}
          onChange={onTextareaEditingChange}
          disabled
        />
      </Container>
    );
  }

  if (stored) {
    return (
      <Container>
        <Textarea placeholder="" value={value} onChange={() => {}} disabled />
      </Container>
    );
  }

  return (
    <Container>
      <Textarea
        placeholder=""
        value={textareaValue}
        onChange={onTextareaChange}
        disabled
      />
    </Container>
  );
};

const Container = styled.div`
  padding-bottom: 10px;
`;