import React from 'react';
import styled from 'styled-components'

export function ImageQuestion(props) {
  const { image } = props.question;

  return (
    <>
      { image &&
        <ImagePreview>
          <img src={image} alt="imagen" />
        </ImagePreview>
      }
    </>
  );
}

const ImagePreview = styled.div`
  width: 100%;
  min-height: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  pointer-events: none; // para el drag and drop, así no se selecciona la imagen al arrastrarla

  img {
    max-width: 170px;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;