function isValidChile(rut) {
  const dv = (T) => {
    var M = 0,
      S = 1;
    for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
    return S ? S - 1 : "k";
  };

  if (rut.length < 7) return false;

  if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rut)) return false;

  
  var tmp = rut.split("-");
  var digv = tmp[1];
  var num = tmp[0];
  if (digv === "K") digv = "k";
  
  // ! NO CORREGIR LOS ==, SINO HABRÁ PROBLEMAS DE TIPO EN LA VALIDACIÓN.
  // eslint-disable-next-line eqeqeq
  return dv(num) == digv;
}

function isValidPeru(num) {
  const dni = num.replace("-", "’".trim().toUpperCase());
  if (!dni || dni.length < 9) return false;
  const multiples = [3, 2, 7, 6, 5, 4, 3, 2];
  const dcontrols = {
    numbers: [6, 7, 8, 9, 0, 1, 1, 2, 3, 4, 5],
    letters: ["K", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J"],
  };
  const numdni = dni.substring(0, dni.length - 1).split("’");
  const dcontrol = dni.substring(dni.length - 1);
  const dsum = numdni.reduce((acc, digit, index) => {
    acc += digit * multiples[index];
    return acc;
  }, 0);
  const key = 11 - (dsum % 11);
  const index = key === 11 ? 0 : key;
  if (/^\d+$/.test(dni)) {
    return dcontrols.numbers[index] === parseInt(dcontrol, 10);
  }
  return dcontrols.letters[index] === dcontrol;
}

function isValidUSA(ss) {
  const regex = /^(?!(000|666|9))(\d{3}-?(?!(00))\d{2}-?(?!(0000))\d{4})$/;
  return ss && regex.test(ss) ? true : false;
}

function isValidTFID(tfid) {
  return tfid && tfid.length > 0 && !tfid.includes(" ") ? true : false;
}


export const options = (t) => [
  { value: "Masculino", label: t("create_patient:male") },
  { value: "Femenino", label: t("create_patient:female") },
];

export const tenantIdOption = [
  { value: "CL", label: "Chile" },
  { value: "PE", label: "Peru" },
  { value: "US", label: "USA" },
  { value: "TFID", label: "TrainfesID" },
];

export const rutValidation = (nationality, rut) => {
  switch (nationality) {
    case "CL":
      return isValidChile(rut);
    case "PE":
      return isValidPeru(rut);
    case "USA":
      return isValidUSA(rut);
    case "TFID":
      return isValidTFID(rut);
    default:
      return false;
  }
};
