import React, { useState, useEffect } from "react";
import { Grid, TextField, Fab } from "@material-ui/core";
import { Table, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import Upload from "../../components/upload";
import { connect } from "../../store";
import { useTranslation } from "react-i18next";
import Selet from "../../components/selet";
import AddIcon from "@material-ui/icons/Add";
import Tab from "../../components/tab";
import { Col } from "react-bootstrap";
import Main from "../../components/main";
import { getUserId, setToken } from "../../lib/router";
import TableHours from "../../components/hours";
import API from "../../services/api";
import axios from "axios";
import { getUrl } from "../../lib/utils";
import { eventListener } from "../../services/socket";

import PhoneInput from "react-phone-input-2";

const api = new API();
const fase1 = [
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
  "00:00",
  "01:00",
  "02:00",
  "03:00",
  "04:00",
  "05:00",
  "06:00",
];
const defaultSchedule = [
  {
    id: 0,
    day: "Lunes", 
    fases: [
      {fase: 1, from: "09:00", to: "18:00", enabledsEnd: fase1, suc: "", active: false},
      {fase: 2, from: "09:00", to: "18:00", enabledsEnd: fase1, suc: "", active: false},
      {fase: 3, from: "09:00", to: "18:00", enabledsEnd: fase1, suc: "", active: false}
    ]
  },
  {
    id: 1,
    day: "Martes", 
    fases: [
      {fase: 1, from: "09:00", to: "18:00", enabledsEnd: fase1, suc: "", active: false},
      {fase: 2, from: "09:00", to: "18:00", enabledsEnd: fase1, suc: "", active: false},
      {fase: 3, from: "09:00", to: "18:00", enabledsEnd: fase1, suc: "", active: false}
    ]
  },
  {
    id: 2,
    day: "Miercoles", 
    fases: [
      {fase: 1, from: "09:00", to: "18:00", enabledsEnd: fase1, suc: "", active: false},
      {fase: 2, from: "09:00", to: "18:00", enabledsEnd: fase1, suc: "", active: false},
      {fase: 3, from: "09:00", to: "18:00", enabledsEnd: fase1, suc: "", active: false}
    ]
  },
  {
    id: 3,
    day: "Jueves", 
    fases: [
      {fase: 1, from: "09:00", to: "18:00", enabledsEnd: fase1, suc: "", active: false},
      {fase: 2, from: "09:00", to: "18:00", enabledsEnd: fase1, suc: "", active: false},
      {fase: 3, from: "09:00", to: "18:00", enabledsEnd: fase1, suc: "", active: false}
    ]
  },
  {
    id: 4,
    day: "Viernes", 
    fases: [
      {fase: 1, from: "09:00", to: "18:00", enabledsEnd: fase1, suc: "", active: false},
      {fase: 2, from: "09:00", to: "18:00", enabledsEnd: fase1, suc: "", active: false},
      {fase: 3, from: "09:00", to: "18:00", enabledsEnd: fase1, suc: "", active: false}
    ]
  },
  {
    id: 5,
    day: "Sabado", 
    fases: [
      {fase: 1, from: "09:00", to: "18:00", enabledsEnd: fase1, suc: "", active: false},
      {fase: 2, from: "09:00", to: "18:00", enabledsEnd: fase1, suc: "", active: false},
      {fase: 3, from: "09:00", to: "18:00", enabledsEnd: fase1, suc: "", active: false}
    ]
  },
  {
    id: 6,
    day: "Domingo", 
    fases: [
      {fase: 1, from: "09:00", to: "18:00", enabledsEnd: fase1, suc: "", active: false},
      {fase: 2, from: "09:00", to: "18:00", enabledsEnd: fase1, suc: "", active: false},
      {fase: 3, from: "09:00", to: "18:00", enabledsEnd: fase1, suc: "", active: false}
    ]
  },
]
let fileUploading = null;

const TherapistProfile = (props) => {
  const { t } = useTranslation("profile");
  const [file, setFile] = useState(null);
  const [name, setName] = useState("");
  const [rut, setRut] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [avatar, setAvatar] = useState("");
  const [schedule, setSchedule] = useState({});
  const [subsidiaries, setSubsidiaries] = useState([]);
  const [specialitiesAdd, setSpecialitiesAdd] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [specialty, setSpecialty] = useState(null);
  const [cargo, setCargo] = useState("");
  const [subcargo, setSubCargo] = useState("");
  const [currentPass, setCurrentPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  
  const [data, setData] = useState([]);

  const parseHashFileName = () => {
    return `${fileUploading.name.replace(new RegExp(" ", "g"), "_")}:::${fileUploading.size}`;
  };

  useEffect(() => {
    let { user } = getUserId();

    eventListener("file_upload", function (data) {
      if (parseHashFileName() === data.id) {
        console.log("file_upload ->", data.progress, "%");
      }
    });

    getSucursales();
    setRut(user.rut);
    setName(user.name);
    setLastname(user.lastname);
    setEmail(user.email);
    setPhone(user.phone);
    setAvatar(user.avatar);
    setCargo(user.job);
    setSubCargo(user.subjob);
    setSpecialitiesAdd(user.specialities.map((e) => ({ label: e.name, value: e._id })));
    let sch = [];
    let i = 0;
    try {
      if (Object.keys(user.schedule).length === 0) {
        setData(defaultSchedule);
        return;
      }
      for (let k in user.schedule) {
        sch.push({
          id: i,
          day: k.charAt(0).toUpperCase() + k.charAt(1),
          fases:
            user.schedule[k]["fases"].length === 0
              ? [
                  { fase: 1, from: "09:00", to: "18:00", enabledsEnd: fase1, suc: "", active: false },
                  { fase: 2, from: "09:00", to: "18:00", enabledsEnd: fase1, suc: "", active: false },
                  { fase: 3, from: "09:00", to: "18:00", enabledsEnd: fase1, suc: "", active: false },
                ]
              : user.schedule[k]["fases"],
        });
        i++;
      }
    } catch (error) {
      console.log(error);
    }
    setData(sch);

    api
      .getSpeciality()
      .then((res) => setSpecialities(res.data))
      .catch((err) => console.log(err));
  }, []);

  const saveSchedule = () => {
    const currentSchedule = getUserId().user.schedule;
    
    // Función para comparar fases de un día, excluyendo "_id", ya que, es un objeto de MongoDB
    const fasesAreIdentical = (oldFases, newFases) => {
        if (oldFases.length !== newFases.length) return false;

        for (let i = 0; i < oldFases.length; i++) {
            if (
                oldFases[i].fase !== newFases[i].fase ||
                oldFases[i].to !== newFases[i].to ||
                oldFases[i].from !== newFases[i].from ||
                oldFases[i].suc !== newFases[i].suc ||
                oldFases[i].active !== newFases[i].active
            ) {
                return false;
            }
        }

        return true;
    };

    const schedulesAreIdentical = (oldSchedule, newSchedule) => {
        const days = ["lun", "mar", "mie", "jue", "vie", "sab", "dom"];

        for (let day of days) {
            if (!fasesAreIdentical(oldSchedule[day].fases, newSchedule[day].fases) || 
                oldSchedule[day].enabled !== newSchedule[day].enabled) {
                return false;
            }
        }
        
        return true;
    };

    if (schedulesAreIdentical(currentSchedule, schedule)) {
        props.notify({ type: "error", title: t("no_changes_title"), text: t("no_changes") });
        return;
    }

    axios
      .put(`/user/schedule/${getUserId().userId}`, schedule)
      .then((response) => {
        setToken(response.data.token);
        props.notify({ type: "success", title: t("availability"), text: t("success") });
      })
      .catch((err) => {
        props.notify({ type: "error", title: t("availability"), text: t("error") });
        console.log(err);
      });
  };

  const saveSpecialities = () => {
    axios
      .put(`/user/specialities/${getUserId().userId}`, {
        data: specialitiesAdd.map((e) => e.value),
      })
      .then((response) => {
        setToken(response.data.token);
        props.notify({ type: "success", title: t("specialties"), text: t("success") });
      })
      .catch((err) => {
        props.notify({ type: "error", title: t("specialties"), text: t("error") });
        console.log(err);
      });
  };

  const getSucursales = () => {
    api
      .getSubsidiary()
      .then((res) => setSubsidiaries(res.data))
      .catch((err) => console.log(err));
  };

  const especialidades = () => {
    return specialities.map((e) => ({ label: e.name, value: e._id })).sort();
  };

  const addSpeciality = () => {
    if (!specialty) {
      props.notify({ type: "error", title: t("tab6.title"), text: t("tab6.no_specialties") });
      return;
    }

    let a = Object.assign([], specialitiesAdd);

    let index = especialidades().findIndex((e) => e.value === specialty);
    a.push(especialidades()[index]);
    setSpecialitiesAdd(a);
  };

  const deleteSpeciality = (i) => {
    let a = Object.assign([], specialitiesAdd);
    a.splice(
      a.findIndex((d) => d.value === i),
      1
    );
    setSpecialitiesAdd(a);
  };

  const saveProfile = () => {
    if (name === "" || lastname === "" || email === "" || phone === "") {
      return props.notify({ type: "error", title: "Error", text: t("tab1.noWhite") });
    }

    if (
      name === props.store.user.name &&
      lastname === props.store.user.lastname &&
      email === props.store.user.email &&
      phone === props.store.user.phone &&
      file === null
    ) {
      return props.notify({
        type: "error",
        title: "Error",
        text: t("tab1.noChange"),
      });
    }  

    const form = new FormData();
    form.append("name", name);
    form.append("lastname", lastname);
    form.append("email", email);
    form.append("phone", phone);
    form.append("job", cargo);
    form.append("subjob", subcargo);

    if (file != null) {
      form.append("files", file, file.name);
      fileUploading = file;
    }

    api
      .setProfile(getUserId().userId, form)
      .then((res) => {
        setToken(res.token);
        props.notify({ type: "success", title: t("tab1.success"), text: t("tab1.successMessage") });
      })
      .catch((err) =>
        props.notify({ type: "error", title: t("tab1.notyTitle"), text: err.toString() })
      );
  };

  const setPassword = () => {
    if (newPass.length === 0 || /^\s+$/.test(newPass))
      props.notify({ type: "error", title: t("tab1.notyTitle"), text: t("tab1.noWhite") });
    else {
      if (newPass !== confirmPass)
        props.notify({ type: "error", title: t("tab1.notyTitle"), text: t("tab1.noMatch") });
      else {
        api
          .setPasswordProfile(getUserId().userId, { password: currentPass, newpass: newPass })
          .then((res) => {
            if (res.success)
              props.notify({ type: "success", title: t("tab1.notyTitle"), text: t("tab1.update") });
            else props.notify({ type: "error", title: t("tab1.notyTitle"), text: t("tab2.err") });
          })
          .catch((error) => {
            var errMessage = JSON.stringify(error.data.err, null, 2).replace(/"/g, "");
            if (errMessage === "Password incorrect") {
              props.notify({ type: "error", title: t("tab1.notyTitle"), text: t('tab2.incorrect_pass') });
            } else {
              props.notify({ type: "error", title: t("tab1.notyTitle"), text: t("tab1.error") });
            }
          })
      }
    }
  };

  return (
    <Main>
      <Col xs="12">
        <Grid className="card-plataform bg-white">
          <div className="box-titulo bg-primario d-flex align-items-center">
            <p className="text-left ml-3 m-0 fz-18">{t("title")}</p>
          </div>
          <Tab
            tabs={[
              {
                title: t("tab1.title"),
                content: (
                  <Grid container direction="row">
                    <Grid
                      item
                      xs={6}
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      className="pt-4 pb-3"
                    >
                      <Grid
                        item
                        xs={3}
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Upload img={getUrl(avatar)} value={(val) => setFile(val)} notify={props.notify} t={t} />
                      </Grid>
                      <Grid item xs={9} className="pl-3">
                        <h5 className="m-0">
                          {name} {lastname}
                        </h5>
                        <label
                          style={{ cursor: "pointer" }}
                          className="hover m-0"
                          htmlFor="icon-button-file"
                        >
                          {t("tab1.changeAvatar")}
                        </label>
                      </Grid>
                    </Grid>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                      <Grid item xs={6}>
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                          <Grid
                            item
                            xs={3}
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <p className="m-0 pr-3 fz-12 color-sub-text">{t("tab1.id")}</p>
                          </Grid>
                          <Grid
                            item
                            xs={9}
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            className="pl-3"
                          >
                            <input
                              type="text"
                              disabled={true}
                              className="w-100 mr-5 my-2 form-control"
                              defaultValue={rut || ""}
                            />
                          </Grid>
                        </Grid>
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                          <Grid
                            item
                            xs={3}
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <p className="m-0 pr-3 fz-12 color-sub-text">{t("tab1.name")}</p>
                          </Grid>
                          <Grid
                            item
                            xs={9}
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            className="pl-3"
                          >
                            <input
                              type="text"
                              className="w-100 mr-5 my-2 form-control"
                              defaultValue={name || ""}
                              onChange={(value) => setName(value.target.value)}
                            />
                          </Grid>
                        </Grid>
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                          <Grid
                            item
                            xs={3}
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <p className="m-0 pr-3 fz-12 color-sub-text">{t("tab1.lastname")}</p>
                          </Grid>
                          <Grid
                            item
                            xs={9}
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            className="pl-3"
                          >
                            <input
                              type="text"
                              className="w-100 mr-5 my-2 form-control"
                              defaultValue={lastname || ""}
                              onChange={(value) => setLastname(value.target.value)}
                            />
                          </Grid>
                        </Grid>
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                          <Grid
                            item
                            xs={3}
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <p className="m-0 pr-3 fz-12 color-sub-text">{t("tab1.email")}</p>
                          </Grid>
                          <Grid
                            item
                            xs={9}
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            className="pl-3"
                          >
                            <input
                              type="text"
                              className="w-100 mr-5 my-2 form-control"
                              defaultValue={email || ""}
                              onChange={(value) => setEmail(value.target.value)}
                            />
                          </Grid>
                        </Grid>
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                          <Grid
                            item
                            xs={3}
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <p className="m-0 pr-3 fz-12 color-sub-text">{t("tab1.phone")}</p>
                          </Grid>
                          <Grid
                            item
                            xs={9}
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            className="pl-3"
                          >
                            <PhoneInput
                              inputClass="w-100"
                              containerClass="w-100 mr-5 my-2"
                              value={ phone || "" }
                              inputExtraProps={{ required: true }}
                              country={"cl"}
                              countryCodeEditable={false}
                              enableSearch
                              searchPlaceholder={t("search")}
                              specialLabel={""}
                              onChange={(value) => setPhone(value)}
                            />
                          </Grid>
                        </Grid>
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                          <Grid
                            item
                            xs={3}
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <p className="m-0 pr-3 fz-12 color-sub-text">{t("tab1.occupation")}</p>
                          </Grid>
                          <Grid
                            item
                            xs={9}
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            className="pl-3"
                          >
                            <input
                              type="text"
                              className="w-100 mr-5 my-2 form-control"
                              value={cargo}
                              onChange={(v) => setCargo(v.target.value)}
                            />
                          </Grid>
                        </Grid>
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                          <Grid
                            item
                            xs={3}
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <p className="m-0 pr-3 fz-12 color-sub-text">
                              {t("tab1.desc_occupation")}
                            </p>
                          </Grid>
                          <Grid
                            item
                            xs={9}
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            className="pl-3"
                          >
                            <input
                              type="text"
                              className="w-100 mr-5 my-2 form-control"
                              value={subcargo}
                              onChange={(v) => setSubCargo(v.target.value)}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        className="m-3"
                      >
                        <button className="btn-white-tf py-2" onClick={saveProfile}>
                          {t("tab1.save")}
                        </button>
                      </Grid>
                    </Grid>
                  </Grid>
                ),
              },
              {
                title: t("tab6.title"),
                content: (
                  <>
                    <Grid container direction="row">
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        className="pt-4"
                      >
                        <Grid
                          item
                          xs={1}
                          container
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="center"
                        >
                          <p className="m-0 pr-3 fz-12 color-sub-text">{t("tab6.specialty")}</p>
                        </Grid>
                        <Grid item xs={10} className="pl-3">
                          <Selet
                            name={t("tab6.specialties")}
                            default={specialty || ""}
                            data={especialidades()}
                            value={(value) => setSpecialty(value)}
                          />
                        </Grid>
                        <Grid item xs={1} className="pl-3">
                          <Fab
                            onClick={addSpeciality}
                            className="bg-purple text-white"
                            aria-label="add"
                            size="small"
                          >
                            <AddIcon />
                          </Fab>
                        </Grid>
                      </Grid>

                      <TableContainer className="py-4 px-3">
                        <Table>
                          <TableHead>
                            <TableRow align="left">
                              <TableCell style={{ background: "#EFECFC", width: 210 }}>
                                {t("tab6.specialties")}
                              </TableCell>
                              {specialitiesAdd.length === 0 ? (
                                <TableCell colSpan={4} className="border-t bg-primario text-white">
                                  {t("tab6.no_specialties")}
                                </TableCell>
                              ) : (
                                specialitiesAdd.map((e, i) => (
                                  <TableCell
                                    colSpan={4}
                                    className="border-t bg-primario text-white"
                                    key={i}
                                  >
                                    {e.label}{" "}
                                    <span
                                      style={{ marginLeft: 10 }}
                                      className="icon-trash pointer"
                                      onClick={() => deleteSpeciality(e.value)}
                                    ></span>
                                  </TableCell>
                                ))
                              )}
                            </TableRow>
                          </TableHead>
                        </Table>
                      </TableContainer>

                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        className="m-3"
                      >
                        <button className="btn-white-tf py-2" onClick={saveSpecialities}>
                          {t("tab1.save")}
                        </button>
                      </Grid>
                    </Grid>
                  </>
                ),
              },
              {
                title: t("tab7.title"),
                content: (
                  <Grid container direction="row" className="p-3">
                    <TableHours
                      onChangeParse={(data) => setSchedule(data)}
                      onChange={(data) => setData(data)}
                      data={data}
                      subsidiaries={subsidiaries}
                    />
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      className="my-3"
                    >
                      <button className="btn-white-tf py-2" onClick={saveSchedule}>
                        {t("tab1.save")}
                      </button>
                    </Grid>
                  </Grid>
                ),
              },
              {
                title: t("tab2.title"),
                content: (
                  <Grid container direction="row" className="pt-4">
                    <Grid item xs={6}>
                      <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Grid
                          item
                          xs={3}
                          container
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="center"
                        >
                          <p className="m-0 pr-3 fz-12 color-sub-text">{t("tab2.prevPass")}</p>
                        </Grid>
                        <Grid
                          item
                          xs={9}
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          className="pl-3"
                        >
                          <TextField
                            onChange={(val) => setCurrentPass(val.target.value)}
                            className="w-100 mr-5"
                            id="outlined-margin-dense2"
                            margin="dense"
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                      <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Grid
                          item
                          xs={3}
                          container
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="center"
                        >
                          <p className="m-0 pr-3 fz-12 color-sub-text">{t("tab2.newPass")}</p>
                        </Grid>
                        <Grid
                          item
                          xs={9}
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          className="pl-3"
                        >
                          <TextField
                            onChange={(val) => setNewPass(val.target.value)}
                            className="w-100 mr-5"
                            id="outlined-margin-dense2"
                            margin="dense"
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                      <Grid container direction="row" justifyContent="center">
                        <Grid
                          item
                          xs={3}
                          container
                          direction="row"
                          justifyContent="flex-end"
                        >
                          <p className="m-0 pr-3 pt-3 fz-12 color-sub-text">{t("tab2.confirm")}</p>
                        </Grid>
                        <Grid
                          item
                          xs={9}
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          className="pl-3"
                        >
                          <TextField
                            onChange={(val) => setConfirmPass(val.target.value)}
                            className="w-100 mr-5"
                            id="outlined-margin-dense2"
                            margin="dense"
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      className="m-3"
                    >
                      <button onClick={setPassword} className="btn-white-tf py-2">
                        {t("tab1.save")}
                      </button>
                    </Grid>
                  </Grid>
                ),
              },
            ]}
          />
        </Grid>
      </Col>
    </Main>
  );
};

export default connect(TherapistProfile);
