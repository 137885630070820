import styled from "styled-components";

export const CardLayoutPlatformStyled = styled.div`
  background-color: white;
  box-shadow: 0 2px 20px rgba(221, 221, 221, 0.3);
  margin: 1rem 0;

  height: 100%;
  min-height: calc(100vh - 80px);
  max-height: calc(100vh - 80px);
  position: relative;
`;

export const Children = styled.div`
  padding-bottom: ${({ noPaddingBottom }) =>
    noPaddingBottom ? "0px" : "20px"};
`;

export const Body = styled.div`
  max-height: ${({ headerHeight }) => `calc(100% - ${headerHeight}px)`};
  padding: ${({ padding }) => padding};
  overflow-y: ${({ overflowVirtuoso }) => (overflowVirtuoso ? "hidden" : "auto")};
`;

export const SidebarContainer = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: 45px;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: calc(100% - 45px);
  background: rgba(0, 0, 0, 0.1);
  visibility: ${({ showSidebar }) => (showSidebar ? "visible" : "hidden")};
  opacity: ${({ showSidebar }) => (showSidebar ? 1 : 0)};
  pointer-events: ${({ showSidebar }) => (showSidebar ? "auto" : "none")};
  transition: all 0.3s;

  & > .cardlayout--sidebar {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1500;
    background-color: white;
    width: 230px;
    height: 100%;
    transition: right 0.5s;
    padding: 0 15px 15px 15px;
  }
`;

export const SidebarTitle = styled.div`
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  color: #281b65;
  margin: 26px 0 40px 0;

  & > svg {
    fill: #281b65;
    margin-right: 7px;
  }
`;

export const FloatButton = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
`;

export const SpaceFooter = styled.div`
  height: 34px;
`;

export const Footer = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 100%;
  background-color: white;
  overflow-y: hidden;
`;

export const Title = styled.div`
  align-items: center;
  background: ${({ bg }) => bg};
  color: white;
  display: flex;
  height: 45px;
  padding: 20px 10px 20px 10px;

  & > p {
    font-size: 18px;
    margin: 0;
    text-align: left;
  }
`;

export const SidebarContent = styled.div`
  height: calc(100% - 84px);
  display: grid;
  grid-template-rows: 1fr 45px;

  & > .form > p {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;

    color: #182024;

    margin: 0 0 4px 0;

    &:last-child {
      margin: 30px 0 4px 0;
    }
  }

  & > .form > label {
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 1px;
    color: #182024;
  }

  & > .form > .mrgn-top-30 {
    margin-top: 30px;
  }
`;
