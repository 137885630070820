import styled from "styled-components";


export const BoxUSerData = styled.div`
    padding: 20px 0;
    background:#FFF;
    border-radius: 3px;
`

export const WrapperView = styled.div`
    padding:15px;
`

export const BoxLegend = styled.div`
    width: 15px;
    height:15px;
    border-radius: 3px;
    margin-right: 8px;
    background: ${props => props && props.color ? props.color : "#9CB2CD"};
    border: 1px solid ${props => props && props.color ? props.color : "#9CB2CD"};
`

export const BoxLegendWrapper = styled.div`
    p{ font-size: 14px; margin:0; font-weight: 500}
    display: flex;
    align-items:center;
    margin-left: 25px;
`

export const WrapperLegens = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items:center;
`