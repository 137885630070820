import React, { Component } from 'react';
import { Grid } from '@material-ui/core';

class Evaluation extends Component{

    constructor(){
        super();

        this.state = {
            channels:[]
        }
    }

    values() {
        let data = Object.assign({}, this.state);
        data['config_channels'] = this.state.channels.filter(e => e.index !== 100);
        if (this.props.values !== undefined) {
            this.props.values(data);
        }
    }

    componentDidUpdate(){
        this.values();
    }

    render (){
        return (
            <Grid container direction="row" justifyContent="center"> 
                <Grid item xs={12} md={6} className="pl-4">
                    <p className="title-calendar mb-2">Evaluación</p>
                </Grid>
                <Grid item xs={12} md={6} className="px-4">
                </Grid>
            </Grid>
        )
    }
}


export default Evaluation;