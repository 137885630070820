import React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';

const useStyles = () => ({

    root: {
        minWidth: '550px',
        maxWidth: '700px',
    },
    buttons: {
        padding: '16px 24px',
    },
    buttonsIcons: {
        color: '#281B65',
        fontSize: 30,
        width: '60px',
        height: '60px',
        background: '#FFFFFF',
        boxShadow: '0px 5px 30px rgba(221, 221, 221, 0.6)',
        borderRadius: '3px',
        padding: '5px',
        border: 'none',
        margin: '10px',
        marginTop: '20px',
        '&:focus': {
            outline: 'none',
        },
        '&:hover': {
            backgroundColor: '#281B65',
            color: '#FFF',
        },
    },


});


class ModalSimple extends React.Component {
    handleClose() {
        if (this.props.cancel !== undefined) this.props.cancel();
    }

    action() {
        if (this.props.action !== undefined) this.props.action();
    }

    render() {
        const { t } = this.props;
        return (
            <Dialog style={{zIndex: 100000}} open={this.props.open || false} onClose={() => this.handleClose()} aria-labelledby="form-dialog-title" >
                <DialogTitle id="form-dialog-title" style={{ textTransform: this.props.titleCapitalize ? 'capitalize' : 'normal' }}>{this.props.title}<p className="m-0 fz-14">{this.props.subtitle}</p></DialogTitle>
                <DialogContent className={this.props.classes.root}>{this.props.children}</DialogContent>
                <DialogActions className={this.props.classes.buttons}>

                    {this.props.otherButton ? <button className="btn-purple-tf" onClick={() => this.props.otherButtonAction()}>
                        {this.props.otherButtonText}
                    </button> : null}

                    {this.props.controlReverse ? <>
                        {this.props.onlyCancel !== true ? <button className="btn-white-tf" onClick={() => this.action()}>
                            {this.props.textAction || t('accept')}
                        </button> : null}
                        <button className="btn-tf" onClick={() => this.handleClose()}>
                            {this.props.textCancel || t('cancel')}
                        </button>
                    </> : <>
                        <button className="btn-tf" onClick={() => this.handleClose()}>
                            {this.props.textCancel || t('cancel')}
                        </button>
                        {this.props.onlyCancel !== true ? <button className="btn-white-tf" onClick={() => this.action()}>
                            {this.props.textAction || t('accept')}
                        </button> : null}
                    </>}
                </DialogActions>
            </Dialog>
        );
    }
}

ModalSimple.defaultProps = {
    titleCapitalize: false,
    controlReverse: false,
    otherButton: false,
    otherButtonText: "",
    otherButtonAction: () => { }
}

export default withStyles(useStyles)(withTranslation('global')(ModalSimple));




