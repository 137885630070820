import React, { useState, useEffect, Fragment } from 'react';
import { Grid, FormGroup, FormControlLabel, Checkbox } from "@material-ui/core";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { useTranslation } from 'react-i18next'

const fase1 = ['07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00'];

const TableHours = (props) => {
    const { t } = useTranslation('global');

    const [table, setTable] = useState([]);

    useEffect(() => onChange(table), [table]);
    useEffect(() => {
        if (props.data && Array.isArray(props.data)) setTable(props.data);
    }, []);

    const validSuc = (str) => {
        if (str === undefined || str == null || str.length === 0 || str === "") {
            return sucursales().length > 0 ? sucursales()[0].value : "";
        } else {
            return str;
        }

    }

    const processDataTable = (data) => {
        let response = {};
        const getID = (id) => id === 0 ? "lun" : id === 1 ? "mar" : id === 2 ? "mie" : id === 3 ? "jue" : id === 4 ? "vie" : id === 5 ? "sab" : id === 6 ? "dom" : "";
        data.forEach(e => {
            response[getID(e.id)] = {
                fases: e.fases.map(r => ({ fase: r.fase, from: r.from, to: r.to, suc: validSuc(r.suc), active: r.active })),
                enabled: e.fases.some(v => v.active)
            }
        });

        return response;
    }

    const sucursales = () => {
        return props.subsidiaries.map(e => ({ label: e.name, value: e._id })).sort();
    }


    const onChange = (data) => {
        if (props.onChange) props.onChange(data);
        if (props.onChangeParse) props.onChangeParse(processDataTable(data));
    }

    const setDataTable = (id, fase, property, value) => {
        let d = Object.assign([], table).map(e => {
            if (e['id'] === id) {
                e['fases'].forEach(f => {
                    if (f['fase'] === fase) {
                        if (property === "from") {
                            f[property] = value;
                            let n = fase1.findIndex(i => i === value) + 1;
                            f['enabledsEnd'] = Object.assign([], fase1).splice(n < fase1.length ? n : fase1.length - n, fase1.length);
                        } else f[property] = value;
                    }
                })
            }

            return e;
        });

        setTable(d);
    }

    const getEndHours = (value) => {
        let n = fase1.findIndex(i => i === value) + 1;
        return Object.assign([], fase1).splice(n < fase1.length ? n : fase1.length - n, fase1.length);
    }

    const daysTranslateLanguage = (idDay) => {
        const translations = [ t("table.mon"), t("table.tue"), t("table.web"), t("table.thu"), t("table.fri"), t("table.sat"), t("table.sun"),];
        return translations[idDay] || "";
    };

    const widgetTableDay = (d, index) => {
        return (
            <TableRow key={index} align="left border">
                <TableCell style={{ background: "#EFECFC" }}> {daysTranslateLanguage(d['id'])} </TableCell>
                {d['fases'] && d['fases'].map(e => {
                    return (
                        <Fragment key={e['fase']}>
                            <TableCell className="border-t bg-grey-chat">
                                <Grid container direction="row" justifyContent="center" alignItems="center">
                                    <FormGroup>
                                        <FormControlLabel className="m-0" control={<Checkbox onChange={v => {
                                            v.persist();
                                            setDataTable(d['id'], e['fase'], "active", v.target.checked);
                                        }} checked={e['active']} />} />
                                    </FormGroup>
                                </Grid>
                            </TableCell>
                            <TableCell className="border-t">
                                <Grid container direction="row" justifyContent="center" alignItems="center">
                                    <select style={{ width: "90px" }} disabled={!e['active']} className="form-control selectLight" id="exampleFormControlSelect1" label="Desde" placeholder="Desde" onChange={v => { v.persist(); setDataTable(d['id'], e['fase'], "from", v.target.value); }} value={e['from']}>
                                        {fase1.map((w, i) => <option key={i}  value={w}>{w}</option>)}
                                    </select>
                                </Grid>
                            </TableCell>
                            <TableCell className="border-t">
                                <Grid container direction="row" justifyContent="center" alignItems="center">
                                    <select style={{ width: "90px" }} disabled={!e['active']} className="form-control selectLight" id="exampleFormControlSelect1" label="Desde" placeholder="Desde" onChange={v => { v.persist(); setDataTable(d['id'], e['fase'], "to", v.target.value); }} value={e['to']}>
                                        {getEndHours(e['from']).map((w, i) => {
                                            return <option key={i} value={w}>{w}</option>
                                        })}
                                    </select>
                                </Grid>
                            </TableCell>
                            <TableCell className="border-t">
                                <Grid container direction="row" justifyContent="center" alignItems="center">
                                    <select disabled={!e['active']} className="form-control selectLight" id="exampleFormControlSelect1" label="Desde" placeholder="Desde" onChange={v => { v.persist(); setDataTable(d['id'], e['fase'], "suc", v.target.value); }} value={e['suc']}>
                                        {sucursales().map((w, i) => <option key={i} value={w.value}>{w.label}</option>)}
                                    </select>
                                </Grid>
                            </TableCell>
                        </Fragment>
                    )
                })}
            </TableRow>
        )
    }


    return (
        <TableContainer className="pt-4">
            <Table>
                <TableHead>
                    <TableRow align="left">
                        <TableCell style={{ background: "#EFECFC" }}>Z</TableCell>
                        <TableCell colSpan={4} className="border-t bg-primario text-white">{t('table.zone1')}</TableCell>
                        <TableCell colSpan={4} className="border-t text-white" style={{backgroundColor: "#FDAD34"}}>{t('table.zone2')}</TableCell>
                        <TableCell colSpan={4} className="border-t bg-primario text-white">{t('table.zone3')}</TableCell>
                    </TableRow>
                    <TableRow align="left">
                        <TableCell className="border-t bg-primario text-white">D</TableCell>
                        <TableCell className="border-t color-secundario" style={{ background: "#EFECFC" }}><span className="icon-checked fz-18"></span></TableCell>
                        <TableCell className="border-t" style={{ background: "#EFECFC" }}>{t('table.from')}</TableCell>
                        <TableCell className="border-t" style={{ background: "#EFECFC" }}>{t('table.to')}</TableCell>
                        <TableCell className="border-t" style={{ background: "#EFECFC" }}>{t('table.subsidiary')}</TableCell>
                        <TableCell className="border-t color-secundario" style={{ background: "#EFECFC" }}><span className="icon-checked fz-18"></span></TableCell>
                        <TableCell className="border-t" style={{ background: "#EFECFC" }}>{t('table.from')}</TableCell>
                        <TableCell className="border-t" style={{ background: "#EFECFC" }}>{t('table.to')}</TableCell>
                        <TableCell className="border-t" style={{ background: "#EFECFC" }}>{t('table.subsidiary')}</TableCell>
                        <TableCell className="border-t color-secundario" style={{ background: "#EFECFC" }}><span className="icon-checked fz-18"></span></TableCell>
                        <TableCell className="border-t" style={{ background: "#EFECFC" }}>{t('table.from')}</TableCell>
                        <TableCell className="border-t" style={{ background: "#EFECFC" }}>{t('table.to')}</TableCell>
                        <TableCell className="border-t" style={{ background: "#EFECFC" }}>{t('table.subsidiary')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody style={{ background: "#F8F8F8" }}>
                    {table && table.map((e, index) => widgetTableDay(e, index))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}


TableHours.defaultProps = {
    subsidiaries: [],
    data: []
}


export default TableHours;