import React, { useState, useEffect, useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import Scrollgrid from '@fullcalendar/scrollgrid';
import esLocale from "@fullcalendar/core/locales/es";
import enLocale from "@fullcalendar/core/locales/en-au";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import "./style.css";

const Calendar = (props) => {
  const ref = useRef();
  const [events, setEvents] = useState([]);
  const [resources, setResources] = useState([]);
  const [eventResources, setEventResources] = useState([])

  useEffect(() => {
    const options = ["day", "week", "month", "resource"];
    if (props.customView != null && props.customView !== undefined && options.includes(props.customView)) {
      switch (props.customView.toLowerCase()) {
        case "day":
          return ref.current.getApi().changeView("timeGridDay");
        case "week":
          return ref.current.getApi().changeView("timeGridWeek");
        case "month":
          return ref.current.getApi().changeView("dayGridMonth");
        case "resource":
          return ref.current.getApi().changeView("resourceTimeGridDay");
        default:
          return;
      }
    }

  }, [props.customView])

  const handleDateClick = (event) => {
    if (props.handleDateClick !== undefined) props.handleDateClick(event);
  };

  const eventReceive = (event) => {
    if (props.eventReceive !== undefined)
      props.eventReceive(event.event, event.event.extendedProps, event);
  };

  const eventClick = (event) => {
    if (props.eventClick !== undefined)
      props.eventClick(event.event, event.event.extendedProps, event);
  };

  const dropEvent = (event) => {
    if (event.event) {
      if (props.dropEvent !== undefined)
        props.dropEvent("event", event.event.extendedProps, event);
    } else {
      let extendedProps = {};
      for (
        var i = 0, atts = event.draggedEl.attributes, n = atts.length;
        i < n;
        i++
      ) {
        if (atts[i].nodeName === "event") {
          extendedProps[atts[i].nodeName] = JSON.parse(atts[i].nodeValue);
        } else {
          extendedProps[atts[i].nodeName] = atts[i].nodeValue;
        }
      }
      if (props.dropEvent !== undefined)
        props.dropEvent("drag", extendedProps, event);
    }
  };

  const renderEvent = (event) => {
    if (props.renderEvent !== undefined) {
      return props.renderEvent(event.event, event.event.extendedProps, event);
    } else {
      return (
        <>
          <b>{event.timeText}</b>
          <i>{event.event.title}</i>
        </>
      );
    }
  };

  useEffect(() => {
    if (props.events !== undefined && Array.isArray(props.events)) {
      setEvents(props.events);
    }
  }, [props.events]);

  useEffect(() => {
    if (props.resources !== undefined && Array.isArray(props.resources)) {
      setResources(props.resources);
    }
  }, [props.resources]);

  useEffect(() => {
    if (props.eventsResources !== undefined && Array.isArray(props.eventsResources)) {
      setEventResources(props.eventsResources);
    }
  }, [props.eventsResources]);

  useEffect(() => {
    if (props.refDrag !== undefined) {
      new Draggable(props.refDrag.current, {
        itemSelector: props.dragSelector,
        mirrorSelector: ".gu-mirror",
        eventData: function (eventEl) {
          let extendedProps = {};
          for (var i = 0, atts = eventEl.attributes, n = atts.length; i < n; i++) {
            if (atts[i].nodeName === "event") {
              extendedProps[atts[i].nodeName] = JSON.parse(atts[i].nodeValue);
            } else {
              extendedProps[atts[i].nodeName] = atts[i].nodeValue;
            }
          }
          if (props.onDragable !== undefined) {
            return props.onDragable(extendedProps);
          }
        },
      });
    }

    ref.current.getApi().setOption("height", props.height);
  }, []);

  const onManualResizing = () => {
    // setTimeout(() => {
    //     ref.current.getApi().updateSize();
    //     if (props.onManualResizing != undefined) props.onManualResizing(false);
    // }, 300)
  };

  useEffect(() => {
    if (props.manualResize) onManualResizing();
  }, [props.manualResize]);

  const eventDrop = (data) => {
    if (props.eventDrop !== undefined) props.eventDrop(data);
  };

  const getLocale = (locale) => {
    switch (locale.toLowerCase().trim()) {
      case "es":
        return esLocale;
      case "en":
        return enLocale;
      default:
        return esLocale;
    }
  };

  return (
    <div className="ccalendar">
      <FullCalendar
        // schedulerLicenseKey={"GPL-My-Project-Is-Open-Source"}
        schedulerLicenseKey={"0892371523-fcs-1667574577"}
        locale={getLocale(props.locale)}
        // select={(start, end, allDay) => { 
        //   console.log(start, end, allDay)
        // }}
        scrollTimeReset={false}
        weekends={props.weekends}
        headerToolbar={props.headers}
        footerToolbar={props.footer}
        customButtons={props.buttons}
        dateClick={handleDateClick}
        eventReceive={eventReceive}
        eventClick={eventClick}
        editable={props.editable}
        eventStartEditable={props.eventStartEditable}
        droppable={props.droppable}
        drop={(event) => dropEvent(event)}
        eventDrop={props.eventDrop ? props.eventDrop : eventDrop}
        events={events}
        eventSources={eventResources}
        resources={resources}
        plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin, resourceTimeGridPlugin, Scrollgrid]}
        eventContent={renderEvent}
        eventDurationEditable={true}
        timeZone="local"
        hiddenDays={props.hiddenDays}
        eventOrder="start,hour"
        eventTimeFormat={{ hour: "2-digit", minute: "2-digit", hour12: false }}
        allDaySlot={props.allDaySlot}
        initialView={props.initialView}
        slotMinTime={props.slotMinTime}
        slotMaxTime={props.slotMaxTime}
        handleWindowResize={props.handleWindowResize}
        expandRows={props.expandRows}
        stickyHeaderDates={true}
        stickyFooterScrollbar={true}
        slotDuration={props.slotDuration}
        slotLabelInterval={props.slotDuration}
        slotLabelFormat={{
          hour: "numeric",
          minute: "2-digit",
          omitZeroMinute: false,
          meridiem: "short",
        }}
        nowIndicator={true}
        eventOverlap={props.eventOverlap}
        slotEventOverlap={props.slotEventOverlap}
        fixedMirrorParent={document.querySelector(props.fixedMirrorParent)}
        selectOverlap={props.selectOverlap}
        selectable={props.selectable}
        aspectRatio={props.aspectRatio}
        dayMinWidth={props.dayMinWidth}
        contentHeight={5}
        validRange={props.validRange}
        // selectAllow={props.selectAllow}
        ref={ref}
        businessHours={props.businessHours}
        selectConstraint={props.businessHours}
        initialDate={props.initialDate}
        eventConstraint="businessHours"
      />
    </div>
  );
};

Calendar.defaultProps = {
  fixedMirrorParent: 'body',
  selectConstraint: {},
  selectAllow: {},
  validRange: null,
  dayMaxEvents: false,
  eventMaxStack: 10,
  aspectRatio: 1.35,
  locale: "es",
  expandRows: true,
  handleWindowResize: true,
  eventOverlap: false,
  slotEventOverlap: false,
  selectOverlap: false,
  selectable: true,
  hiddenDays: [],
  editable: false,
  droppable: false,
  weekends: true,
  eventLimit: false,
  drag: "dm294rvcmccjfveccewcfmc2ewocx3e",
  events: [],
  eventsResources: [],
  resources: [],
  headers: {
    left: "today prev title next",
    center: "dayGridMonth timeGridWeek timeGridDay",
    right: "add",
  },
  buttons: {
    add: {
      text: "Agendar nueva cita",
      click: function () { },
    },
  },
  height: "calc(100vh - 20px)",
  initialView: "timeGridWeek",
  slotMinTime: "08:00:00",
  slotMaxTime: "20:00:00",
  slotDuration: "00:15:00",
  allDaySlot: false,
  refDrag: undefined,
  dragSelector: ".event",
  customView: null,
  dayMinWidth: 200,
  businessHours: [],
  eventStartEditable: false,
  eventDrop: null,
  initialDate: null
};

export default Calendar; //connect(Calendar);
