import React, { useState, useEffect } from 'react'

import Skeleton from 'react-loading-skeleton'
import styled, { css } from 'styled-components'

import { RadioButton, Checkbox, Textarea, Dropdown } from "trainfes-components-library";
import { ImageQuestion } from '../ImagePreview/';
import { LinearScale } from '../../../../components/linearScale';

import { useGetCategoriesEvaluations } from '../../../../hooks'

import 'react-loading-skeleton/dist/skeleton.css'

export const ModalPreview = ({ t, state, dispatch }) => {

  const { CategoriesEvaluationsQuery } = useGetCategoriesEvaluations()
  const [category, setCategory] = useState({})

  const modes = [
    { name: t('modes.normal'), cod: 0 },
    { name: t('modes.main'), cod: 1 },
    { name: t('modes.order'), cod: 2 },
  ];

  useEffect(() => {

    if (!CategoriesEvaluationsQuery.data) return;
    
    const selectedCategory = CategoriesEvaluationsQuery.data.filter( (item) => item._id === state.cat )[0]
    setCategory(selectedCategory) 

    dispatch({ type: "SET_CATEGORY", payload: selectedCategory })

  }, [CategoriesEvaluationsQuery.data])
  
  const EVAL_OPTIONS = { 
    "multi_select": t("multi_select"),
    "checkboxes": t("checkboxes"),
    "dropdown": "Dropdown",
    "paragraph": t("paragraph"),
    "bool": t("yes/no"),
    "number": t("number"),
    "scale_linear": t("scale_linear"),
    "string": t("paragraph"),
    "textarea": t("paragraph"),
  }
  
  const typeRender = (question) => {
    switch (question.type) {
      case "multi_select":
        return (
            <div className='__multi-select'>
                <ImageQuestion question={question} />
              <div className='__multi-select__options'>
                { question.data.map(({ name }, i) => (
                  <div key={i}> <RadioButton disabled /> <span className='margin-left-35'>{ name }</span> </div>
                )) }
              </div>
            </div>
        )        
    
      case "checkboxes":
        return (
            <div className='__checkboxes'>
              <ImageQuestion question={question} />
              <div className='__checkboxes__options'>
                {question.data.map(({ name }, i) => (
                  <div key={i}> <Checkbox disabled /> <span className='margin-left-35'>{ name }</span> </div>
                ))}
              </div>
            </div>
        )
        
      case "paragraph":
        return ( <div> <ImageQuestion question={question} /> <Textarea placeholder="" onChange={() => {}} disabled></Textarea> </div> )  
      
      case "string":
        return ( <div> <ImageQuestion question={question} /> <Textarea placeholder="" onChange={() => {}} disabled></Textarea> </div> )

      case "textarea":
        return ( <div> <ImageQuestion question={question} /> <Textarea placeholder="" onChange={() => {}} disabled></Textarea> </div> )
        
      case "dropdown":
        return ( <div> <ImageQuestion question={question} /> <Dropdown isOptionDisabled={() => true} placeholder={t("select")} options={ question.data.map(({ name }) => { return { label: name, value: name, isDisabled: true } }) }/> </div> ) 
      
      case "bool":
        return ( 
          <>
            <ImageQuestion question={question} />
            <div> <RadioButton disabled /> <span className='margin-left-35'>{ t("yes") }</span> </div>
            <div> <RadioButton disabled /> <span className='margin-left-35'>{ t("no") }</span> </div>
          </>
        )  

      case "number":
        return ( <div> <ImageQuestion question={question} /> <Textarea placeholder="" onChange={() => {}} disabled></Textarea> </div> )

      case "scale_linear":
        return ( <div> <ImageQuestion question={question} /> <LinearScale question={question} preview={true}/> </div> )

      default:
        break;
    }
  }

  const mode = modes.find(item => item.cod === state.type)

  // En caso de que no se haya encontrado el modo, se muestra no_type
  const [timedOut, setTimedOut] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setTimedOut(true);
    }, 3000);
  
    return () => clearTimeout(timeout);
  }, [])

  return (
    <Container>
      <p className='__title'><strong>{ state.name }</strong></p>
      <p><strong>{t("category")}</strong>: { !category ? <Skeleton /> : category.name } </p>
      <p><strong>{t("type")}</strong>: { mode ? mode.name : !timedOut ? <Skeleton /> : <i>{t("no_type")}</i> }</p>
      <p><strong>{t("instructions")}</strong>: { state.instructions ? state.instructions : <i>{t("no_description")}</i> }</p>
      <p><strong>{t("desc")}</strong>: { state.desc ? state.desc : <i>{t("no_description")}</i> }</p>

      <div>
        {
          state.varsData.map((question, index) => (
            <div key={`${question._id}-${index}`} className='__box'>
              <p className='margin-bottom-0'><strong>{ question.name } | </strong><i>{ EVAL_OPTIONS[question.type] }</i></p>
              { typeRender(question) }
            </div>
          ))
        }
      </div>
    </Container>
  )
}

// media query
const breakpoints = {
  large: '1217px',
};

const Container = styled.div`
  p, span {
    font-size: 14px;
  }

  & > .__title {
    font-size: 16px;
  } 

  label {
    margin-bottom: 15px;
  }

  ${css`
    @media (max-width: ${breakpoints.large}) {
      label {
        padding-left: 30px;
      }
    }
  `}

  .__box {
    border: 0.5px solid #9AA2A8;
    border-radius: 4px;
    padding: 15px 15px 20px 15px;
    margin-bottom: 12px;
  }

  .margin-left-35 {
    margin-left: 35px;
  }

  .margin-bottom-0 {
    margin-bottom: 5px;
  }
`;