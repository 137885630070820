import styled from "styled-components";

export const Container = styled.div`
  & p {
    margin: 0;
  }

  cursor: move;
  height: 65px;
  width: 100%;
  background: #ffffff;
  border-radius: 3px;
  border: 1px solid #ced4da;
  box-shadow: 0px 1px 70px rgba(231, 236, 244, 0.5);
  margin: 14px 0;
  position: relative;
  display: grid;
  grid-template-columns: 30px 180px repeat(5, 1fr) 60px;
  place-items: center;
  padding: 0 20px;
`;

export const Index = styled.div`
  align-items: center;
  background-color: #fdad34;
  border-radius: 50%;
  color: #ffffff;
  display: flex;
  font-size: 8px;
  font-weight: 500;
  height: 16px;
  justify-content: center;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 16px;
`;

export const Icon = styled.div`
  background-color: #fff;
  & > span {
    font-size: 30px;
  }

  & > svg {
    max-width: 30px;
    max-height: 30px;
    fill: #281b65;
    color: #fff;

    & > circle {
      fill: white;
    }

    & path {
    }
  }
`;

export const Info = styled.div`
  justify-self: flex-start;
  padding-left: 15px;

  & > .__title {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.850962px;
    color: #281b65;
  }

  & > .__name {
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.850962px;
    color: #182024;
    display: flex;
  }
`;

export const SubInfo = styled.div`
  text-align: center;
  & > .__title {
    font-weight: 400;
    font-size: 10px;
    color: #8598ad;
  }

  & > .__value {
    font-weight: 500;
    font-size: 12px;
    color: #281b65;
  }

  & > .__time {
    font-weight: 500;
    font-size: 12px;
    color: #fdad34;
  }
`;

export const Icons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  & > svg {
    cursor: pointer;
    width: 12px;
    height: 12px;

    &:first-child {
      margin-right: 11px;
    }

    &:last-child {
      margin-left: 11px;
    }

    &:hover {
      opacity: 0.7;
    }
  }
`;
