import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion"
import { v4 as uuidv4 } from "uuid";

import { TextField, MenuItem, Dialog, DialogTitle, DialogActions, Button, Fab } from '@mui/material';
// Imported separately for bundle size: See https://mui.com/material-ui/icons/#usage
import Check from '@mui/icons-material/Check'
import Edit from '@mui/icons-material/Edit'
import Delete from '@mui/icons-material/Delete'
import Add from '@mui/icons-material/Add'

import { PhotoIcon } from "../../../assets/icons";
import { ModalImage } from './ModalImage/';
import { ImageQuestion } from './ImagePreview';

import { RadioInputsComp } from "./RadioInputsComp";
import { LinearScaleComp } from "./LinearScaleComp";

import * as S from "./ModalNewEvaluation.styles";
import { ParagraphComp } from "./ParagraphComp";
import {
  SET_FIELDS,
  SET_INPUT_FIELD,
  SET_VARS_DATA,
  SET_IMAGE_FILE,
  UPDATE_VARS_DATA,
  CLEAR_QUESTION_IMAGE,
} from "../reducer/evaluationsActions";
import { RadioInput } from "./RadioInputsComp/RadioInput";
import { useGetCategoriesEvaluations } from "../../../hooks";
import { validateVarsData } from "../EvaluationsPage.utils";

export const ModalNewEvaluation = ({ t, notify, state, dispatch, modes }) => {
  const { name } = state;
  const { categoriesEvaluationsDropdown: categories } = useGetCategoriesEvaluations(true);
  const [draggingIndex, setDraggingIndex] = useState(null);
  const [dragOverIndex, setDragOverIndex] = useState(null);
  const [selectedQuestionId, setSelectedQuestionId] = useState(null);

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("text/plain", index);
    setDraggingIndex(index);
    setDragOverIndex(null);
  };

  const handleDrop = (event, index) => {
    event.preventDefault();
    const dragSourceIndex = draggingIndex;
    const dropTargetIndex = index;

    if (dragSourceIndex !== null && dragSourceIndex !== dropTargetIndex) {
      dispatch({
        type: UPDATE_VARS_DATA,
        payload: {
          sourceIndex: dragSourceIndex,
          destinationIndex: dropTargetIndex,
        },
      });
    }

    setDraggingIndex(null);
    setDragOverIndex(null);
  };

  const handleDragOver = (event, index) => {
    event.preventDefault();
    if (draggingIndex !== null && index !== null && draggingIndex !== index) {
      event.dataTransfer.dropEffect = "move";
      setDragOverIndex(index);
    }
  };

  const [showImageModal, setShowImageModal] = useState(false);

  const mainBoxRef = useRef(null);

  useEffect(() => {

    if (mainBoxRef.current) mainBoxRef.current.scrollIntoView({ behavior: "smooth" });

  }, [state.varsData])

  const INITIAL_RENDER_DATA = [{ id: uuidv4(), name: "" }];

  const categoriesOptions = categories.map((category) => {
    return {
      value: category._id,
      label: category.name,
    };
  });

  const categorySelected = categoriesOptions.filter((category) => category.value === state.cat);

  const evaluationsOptions = modes.map((evaluation) => {
    return { value: evaluation.cod, label: evaluation.name };
  });

  const evaluationSelected = evaluationsOptions.filter(
    (typeEval) => typeEval.value === state.type
  );

  const EVAL_OPTIONS = [
    { value: "multi_select", label: t("multi_select") },
    { value: "checkboxes", label: t("checkboxes") },
    { value: "dropdown", label: "Dropdown" },
    { value: "paragraph", label: t("paragraph") },
    { value: "bool", label: t("yes/no") },
    { value: "number", label: t("number") },
    { value: "scale_linear", label: t("scale_linear") },
  ];

  const [varType, setVarType] = useState(EVAL_OPTIONS[0]);

  const [title, setTitle] = useState("");
  const handleChangeTitle = (e) => setTitle(e.target.value);

  const [textareaValue, setTextareaValue] = useState("");

  const [typeRenderData, setTypeRenderData] = useState(INITIAL_RENDER_DATA);

  useEffect(() => {
    if (varType.value === "scale_linear") {
      setTypeRenderData([
        { id: uuidv4(), name: "1" },
        { id: uuidv4(), name: "5" },
        { id: uuidv4(), name: "" },
        { id: uuidv4(), name: "" },
      ]);
    } else if (varType.value === "bool") {
      setTypeRenderData([
        { id: uuidv4(), name: t("yes") },
        { id: uuidv4(), name: t("no") },
      ]);
    } else {
      setTypeRenderData(INITIAL_RENDER_DATA);
    }
  }, [varType]);

  const [editingId, setEditingId] = useState(null);
  const handleSetEditingId = (id) => setEditingId(id);

  const defaultBoolData = [
    { id: "yes", name: t("yes") },
    { id: "no", name: t("no") }
  ];

  const handleOnAdd = () => {
    // setEditingId(null);

    if (title.trim() === "") {
      return notify({ type: "error", title: t("evaluations"), text: t("title_empty") });
    }

    if (varType.value !== "paragraph" && varType.value !== "number" && varType.value !== "scale_linear" && varType.value !== "string" && varType.value !== "textarea" && varType.value !== "bool") {
      if (typeRenderData.some((e) => e.name === "") || typeRenderData.length <= 0 || textareaValue.trim().length === "") {
        return notify({ type: "error", title: t("evaluations"), text: t("options_empty") });
      }
    }

    const validationResult = validateVarsData(state.varsData);
    if (!validationResult.valid) {
      return notify({ type: "error", title: t("evaluations"), text: t(validationResult.error) });
    }

    const currentData = {
      id: uuidv4(),
      name: title,
      type: varType.value,
      data: [...typeRenderData],
      image: state.imageFile && state.imageFile.src ? state.imageFile.src : null,
    };

    dispatch({ type: SET_VARS_DATA, payload: [...state.varsData, currentData] });
    dispatch({ type: SET_IMAGE_FILE, payload: null });

    setTitle("");
    setTextareaValue("");
    setTypeRenderData(INITIAL_RENDER_DATA);

    if (varType.value === "bool") {
      setTypeRenderData([
        { id: uuidv4(), name: t("yes") },
        { id: uuidv4(), name: t("no") },
      ]);
    } else if (varType.value === "scale_linear") {
      setTypeRenderData([
        { id: uuidv4(), name: "1" },
        { id: uuidv4(), name: "5" },
        { id: uuidv4(), name: "" },
        { id: uuidv4(), name: "" },
      ]);
    }
  };

  const handleAddAndEditImage = () => {
    setShowImageModal(true);
    setEditingId(null);
  };

  const [idToDeleteIMG, setIdToDeleteIMG] = useState(null);
  const [showDeleteModalIMG, setShowDeleteModalIMG] = useState(false);

  const handleDeleteImage = (id) => {
    setIdToDeleteIMG(id);
    setShowDeleteModalIMG((prev) => !prev);
  };

  const handleDeleteCurrentImage = (id) => {
    dispatch({ type: CLEAR_QUESTION_IMAGE, payload: { questionId: id } });
    setSelectedQuestionId(null);
    setEditingId(null);
  };

  const handleSaveEditing = () => {
    const validationResult = validateVarsData(state.varsData);
    if (!validationResult.valid) {
      return notify({ type: "error", title: t("evaluations"), text: t(validationResult.error) });
    }

    setEditingId(null);
    setSelectedQuestionId(null);
  };

  const [idToDelete, setIdToDelete] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleToggleDeleteModal = (id) => {
    setIdToDelete(id);
    setShowDeleteModal((prev) => !prev);
  };

  const handleDeleteQuestion = (id) => {
    dispatch({ type: SET_VARS_DATA, payload: state.varsData.filter((e) => e.id !== id) });
  };

  const handleSelectBoxes = (question) => {
    dispatch({ type: SET_FIELDS, payload: { field: "selectedBox", value: question } });
    setSelectedQuestionId(question ? question.id || question._id : null);
    if (state.selectedBox !== question) setEditingId(null);
  };

  const handleEditingTitle = (id, event) => {
    const items = Array.from(state.varsData);

    let item = items.filter((e) => e.id === id || e._id === id)[0];
    item["name"] = event.target.value;

    dispatch({ type: SET_VARS_DATA, payload: items });
  };

  const openImageModal = async () => {
    setShowImageModal(true);
    setSelectedQuestionId(null);
  };

  const mainBoxVarTypeRender = (type) => {
    switch (type) {
      case "paragraph" || "string" || "textarea":
        return (
          <ParagraphComp
            state={state}
            dispatch={dispatch}
            typeRenderData={typeRenderData}
            setTypeRenderData={setTypeRenderData}
            textareaValue={textareaValue}
            setTextareaValue={setTextareaValue}
          />
        );

      case "number":
        return (
          <ParagraphComp
            state={state}
            dispatch={dispatch}
            typeRenderData={typeRenderData}
            setTypeRenderData={setTypeRenderData}
            textareaValue={textareaValue}
            setTextareaValue={setTextareaValue}
          />
        );

      case "bool":
        return (
          <div className="__inputs">
            <RadioInput
              isActive
              placeholder={`${t("yes")}`}
              value={`${t("yes")}`}
              onChange={() => { }}
            />
            <RadioInput
              isActive
              placeholder={`${t("no")}`}
              value={`${t("no")}`}
              onChange={() => { }}
            />
          </div>
        );

      case "scale_linear":
        return (
          <LinearScaleComp
            state={state}
            dispatch={dispatch}
            type={type}
            setTypeRenderData={setTypeRenderData}
            typeRenderData={typeRenderData}
          />
        );

      default:
        return (
          <RadioInputsComp
            state={state}
            dispatch={dispatch}
            type={varType.value}
            typeRenderData={typeRenderData}
            setTypeRenderData={setTypeRenderData}
          />
        );
    }
  };

  return (
    <>
      <S.Container>
        <S.Form>
          <TextField
            required
            label={t("evaluation_name")}
            variant="outlined"
            value={name}
            onChange={(v) => dispatch({ type: SET_INPUT_FIELD, field: "name", payload: v.target.value })}
          />
          <TextField
            required
            select
            label={t("category")}
            value={categorySelected && categorySelected[0] ? categorySelected[0].value : ""}
            onChange={(v) => dispatch({ type: SET_INPUT_FIELD, field: "cat", payload: v.target.value })}
            SelectProps={{
              MenuProps: {
                style: { zIndex: 10000 }
              }
            }}
          >
            {categoriesOptions.map((option) => {
              return <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
            })}
          </TextField>
          <TextField
            required
            select
            label={t("evaluation_type")}
            value={evaluationSelected && evaluationSelected[0] ? evaluationSelected[0].value : ""}
            onChange={(v) => dispatch({ type: SET_INPUT_FIELD, field: "type", payload: v.target.value })}
            SelectProps={{
              MenuProps: {
                style: { zIndex: 10000 }
              }
            }}
          >
            {evaluationsOptions.map((option) => {
              return <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
            })}
          </TextField>
          <TextField
            required
            label={t("instructions")}
            multiline
            minRows={2}
            maxRows={3}
            onChange={(v) => dispatch({ type: SET_INPUT_FIELD, field: "instructions", payload: v.target.value })}
          />
          <TextField
            required
            label={t("desc")}
            multiline
            minRows={2}
            maxRows={3}
            onChange={(v) => dispatch({ type: SET_INPUT_FIELD, field: "desc", payload: v.target.value })}
          />
        </S.Form>

        <S.Body>
          <S.BoxsContainer className="boxes">
            {state.varsData.map((question, i) => {
              return (
                <S.DragContainer
                  key={i}
                  draggable={true}
                  dragStart={draggingIndex === i}
                  onDragStart={(e) => handleDragStart(e, i)}
                  onDragOver={(e) => handleDragOver(e, i)}
                  onDrop={(e) => handleDrop(e, i)}
                  data-index={i}
                >
                  <S.Box
                    key={i}
                    dragging={draggingIndex !== null && draggingIndex === i}
                    dragOver={dragOverIndex === i}
                    className="box"
                    onClick={() => handleSelectBoxes(question)}
                    initial={{ opacity: 0, y: 100 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    {editingId === question.id || editingId === question._id ? (
                      <div className="__head">
                        <TextField
                          variant="outlined"
                          value={question.name}
                          onChange={(event) => handleEditingTitle(question.id || question._id, event)}
                        />
                      </div>
                    ) : (
                      <p className="__title">{question.name}</p>
                    )}
                    {question.type === "paragraph" || question.type === "number" || question.type === "string" || question.type === "textarea" ? (
                      <S.BodySingleColumn >
                        <ImageQuestion question={question} />
                        <ParagraphComp
                          state={state}
                          dispatch={dispatch}
                          value={" "}
                          stored
                          questionId={question.id || question._id}
                          typeRenderData={question.data || []}
                          editingId={editingId}
                        />
                      </S.BodySingleColumn>
                    ) : question.type === "multi_select" ? (
                      <S.BodySingleColumn>
                        <ImageQuestion question={question} />
                        <RadioInputsComp
                          state={state}
                          dispatch={dispatch}
                          stored
                          type={question.type}
                          questionId={question.id || question._id}
                          typeRenderData={question.data || []}
                          editingId={editingId}
                        />
                      </S.BodySingleColumn>
                    ) : question.type === "scale_linear" ? (
                      <S.BodySingleColumn>
                        <ImageQuestion question={question} />
                        <LinearScaleComp
                          state={state}
                          dispatch={dispatch}
                          stored
                          type={question.type}
                          questionId={question.id || question._id}
                          typeRenderData={question.data || []}
                          editingId={editingId}
                        />
                      </S.BodySingleColumn>
                    ) : question.type === "bool" && (question.data === undefined || question.data === null || question.data.length === 0) ? (
                      <S.BodySingleColumn>
                        <ImageQuestion question={question} />
                        <RadioInputsComp
                          state={state}
                          dispatch={dispatch}
                          stored
                          type={question.type}
                          questionId={question.id || question._id}
                          typeRenderData={defaultBoolData}
                          editingId={editingId}
                        />
                      </S.BodySingleColumn>
                    ) : (
                      <S.BodySingleColumn>
                        <ImageQuestion question={question} />
                        <RadioInputsComp
                          state={state}
                          dispatch={dispatch}
                          stored
                          type={question.type}
                          questionId={question.id || question._id}
                          typeRenderData={question.data || []}
                          editingId={editingId}
                        />
                      </S.BodySingleColumn>
                    )}
                    {
                      state.selectedBox === question &&
                      <motion.div
                        className="__button"
                        initial={{ opacity: 0, x: 30 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.2 }}
                      >
                        {editingId === question.id || editingId === question._id ? (
                          <>
                            <Fab size="small" color="primary" onClick={() => handleSaveEditing(question.id || question._id)}>
                              <Check />
                            </Fab>
                            {question.image ? (
                              <S.ContainerButtonEditIMG>
                                <Fab size="small" color="primary" onClick={() => handleAddAndEditImage(question.id || question._id, question.image)}>
                                  <Edit />
                                </Fab>
                                <S.Spacer />
                                <Fab size="small" color="primary" onClick={() => handleDeleteImage(question.id || question._id)}>
                                  <Delete />
                                </Fab>
                              </S.ContainerButtonEditIMG>
                            ) : [null, undefined, ""].includes(question.image) ? (
                              <>
                                <S.Spacer />
                                <Fab size="small" color="primary" onClick={() => handleAddAndEditImage(question.id || question._id, question.image)}>
                                  <Add />
                                </Fab>
                              </>
                            ) : null}
                          </>
                        ) : (
                          <>
                            {question.data === undefined || question.data === null || question.data.length === 0 ? (
                              <>
                                <Fab size="small" color="primary" onClick={() => handleSetEditingId(question.id || question._id)}>
                                  <Edit />
                                </Fab>
                                <S.Spacer />
                                <Fab size="small" color="primary" onClick={() => handleToggleDeleteModal(question.id)}>
                                  <Delete />
                                </Fab>
                              </>
                            ) : (
                              <>
                                <Fab size="small" color="primary" onClick={() => handleSetEditingId(question.id || question._id)}>
                                  <Edit />
                                </Fab>
                                <S.Spacer />
                                <Fab size="small" color="primary" onClick={() => handleToggleDeleteModal(question.id)}>
                                  <Delete />
                                </Fab>
                              </>
                            )}
                          </>
                        )}
                      </motion.div>
                    }
                  </S.Box>
                </S.DragContainer>
              );
            })}
          </S.BoxsContainer>
          <S.MainBox ref={mainBoxRef}>
            <h4 className="__title">{t("create_eval_var")}</h4>
            {state.imageFile &&
              <S.ImagePreview>
                <img src={state.imageFile.src} alt="Imagen seleccionada" />
                <div style={{ position: "absolute", top: "3px", right: "174px" }}>
                  <Fab size="small" color="primary" onClick={() => dispatch({ type: SET_IMAGE_FILE, payload: null })}>
                    <Delete />
                  </Fab>
                </div>
              </S.ImagePreview>
            }
            <div className="__head">
              <TextField
                label={t("question")}
                variant="outlined"
                value={title}
                onChange={handleChangeTitle}
              />
              <TextField
                select
                label={t("multi_select")}
                value={varType.value ?? ''}
                onChange={(value) => setVarType(value.target)}
                SelectProps={{
                  MenuProps: {
                    style: { zIndex: 10001 }
                  }
                }}
              >
                {EVAL_OPTIONS.map((option) => {
                  return <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                })}
              </TextField>
            </div>
            <div className="__body">
              {mainBoxVarTypeRender(varType.value)}
              <S.ContainerUpload>
                <PhotoIcon onClick={openImageModal} />
              </S.ContainerUpload>
              <ModalImage show={showImageModal} onClose={() => setShowImageModal(false)} dispatch={dispatch} questionId={selectedQuestionId} />
            </div>
            <div className="__button">
              <Fab size="small" color="primary" onClick={handleOnAdd}>
                <Add />
              </Fab>
            </div>
          </S.MainBox>
        </S.Body>
      </S.Container>
      <Dialog
        open={showDeleteModal}
        sx={{ zIndex: 10001 }}
        fullWidth
      >
        <DialogTitle>{`${t("really_want_to_delete")}?`}</DialogTitle>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              setShowDeleteModal(false);
              setIdToDelete(null);
            }}
          >{t("cancel")}</Button>
          <Button
            variant="contained"
            onClick={() => {
              handleDeleteQuestion(idToDelete);
              setShowDeleteModal(false);
              setIdToDelete(null);
            }}
          >{t("delete")}</Button>
        </DialogActions>

      </Dialog>
      <Dialog
        open={showDeleteModalIMG}
        sx={{ zIndex: 10001 }}
        fullWidth
      >
        <DialogTitle>{`${t("really_want_to_delete_img")}?`}</DialogTitle>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              setShowDeleteModalIMG(false);
              setSelectedQuestionId(null);
            }}
          >{t("cancel")}</Button>
          <Button
            variant="contained"
            onClick={() => {
              handleDeleteCurrentImage(idToDeleteIMG);
              setShowDeleteModalIMG(false);
              setIdToDeleteIMG(null);
            }}
          >{t("delete")}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};