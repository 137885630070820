import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import "../../../../src/assets/css/video.css";
import axios from "axios";
import { connect } from "../../../store";
import { TextField, Button, InputAdornment } from '@mui/material';
import Search from '@mui/icons-material/Search';
import * as M from "../styles/Modos.styles";
import { FilterIcon } from "../../../assets/icons";
import { FileCard } from "../components/FileCard";
import { SidebarContainerStyled } from "../records";
import { SidebarTitle } from "../../CardLayoutPlatform/styles";
import FilterSidebar from "../components/FilterSidebar";
import {
  getSecondsFromString,
  secondsToDate,
} from "../../../lib/modes.helpers";

import { trackWindowScroll } from "react-lazy-load-image-component";

import LinesEllipsis from "react-lines-ellipsis";

var self;
class Video extends Component {
  constructor() {
    super();
    this.state = {
      openVideo: false,
      videos: [],
      sources: [],
      current: null,
      devices: [],
      categories: [],
      search: "",
      filter: "",

      technical_name: "",
      public_name: "",
      description: "",

      showSidebar: false,
      filterCategory: "",
      filterDevice: "",

      time: "",
    };
  }

  getThumnail(id) {
    return `https://vumbnail.com/${id}.jpg`;
  }

  values() {
    if (this.props.values !== undefined)
      this.props.values({
        videos: this.state.videos.map((e) => {
          e.cover = "";
          return e;
        }),
        time: this.state.time,
        public_name: this.state.public_name,
        technical_name: this.state.technical_name,
        description: this.state.description,
        openVideo: this.state.openVideo,
        showSidebar: this.state.showSidebar,
      });
  }

  getTotalTime() {
    let totalSeconds = 0;

    this.state.videos.forEach((item) => {
      totalSeconds += getSecondsFromString(item.other);
    });

    this.setState({ time: secondsToDate(totalSeconds) });
    return secondsToDate(totalSeconds);
  }

  componentDidUpdate() {
    this.values();
  }

  componentDidMount() {
    self = this;
    this.dataFilterCategory();
    this.dataFilterDevices();
    this.values();
    this.getVideos();

    if (
      this.props.data.params &&
      Object.keys(this.props.data.params).length !== 0
    ) {
      const { videos, technical_name, public_name, description, time } =
        this.props.data.params;

      if (time) {
        this.setState({
          time,
        });
      }

      this.setState(
        {
          videos,
        },
        () => this.setState({ time: this.getTotalTime() })
      );

      if (technical_name) {
        this.setState({
          technical_name,
        });
      }

      if (public_name) {
        this.setState({
          public_name,
        });
      }

      if (description) {
        this.setState({
          description,
        });
      }
    }
  }

  dataFilterCategory() {
    this.setState({
      categories: this.props.store.categories_media.map((e) => ({
        name: e.name,
        value: true,
        type: e._id,
      })),
    });
  }

  dataFilterDevices() {
    this.setState({
      devices: this.props.store.devices_media.map((e) => ({
        name: e.name,
        value: true,
        type: e._id,
      })),
    });
  }

  getVideos() {
    axios
      .get("/media/videos")
      .then((res) => {
        this.setState({ sources: res.data.data });
      })
      .catch((err) => console.log(err));
  }

  addList(e) {
    let list = Object.assign([], this.state.videos);
    list.unshift(e);
    this.setState({ videos: list });
  }

  remove(id) {
    let list = Object.assign([], this.state.videos);
    list.splice(
      list.findIndex((e) => e._id === id),
      1
    );
    this.setState({ videos: list });
  }

  handleClick = (card) => {
    if (this.state.videos.some((e) => e._id === card._id)) {
      this.setState(
        { videos: this.state.videos.filter((item) => item._id !== card._id) },
        () => this.getTotalTime()
      );
      return;
    }

    this.setState({ videos: [card, ...this.state.videos] }, () =>
      this.getTotalTime()
    );
  };

  setFilterCategory(val) {
    self.setState({ filterCategory: val });
  }

  setfilterDevice(val) {
    self.setState({ filterDevice: val });
  }

  setShowSidebar(bool) {
    self.setState({ showSidebar: bool });
  }

  handleCategoryFilter(item) {
    if (this.state.filterCategory === "") return item;
    return item.cat._id === this.state.filterCategory.value;
  }

  handleDeviceFilter(item) {
    if (this.state.filterDevice === "") return item;
    return item.device._id === this.state.filterDevice.value;
  }

  setFilter(e) {
    self.setState({ filter: e.target.value });
  }

  setPublicName(e) {
    self.setState({ public_name: e });
  }

  setTechnicalName(e) {
    self.setState({ technical_name: e });
  }

  setDescription(e) {
    self.setState({ description: e });
  }

  render() {
    const { t, store } = this.props;

    return (
      <M.ScreenContainer>
        <M.RecordsVideoForm>
          <div>
            <TextField
              fullWidth
              required
              label={t("tech_name")}
              variant="outlined"
              value={this.state.technical_name}
              onChange={({ target }) => this.setTechnicalName(target.value)}
            />
            <TextField
              fullWidth
              label={t("public_name")}
              variant="outlined"
              value={this.state.public_name}
              onChange={({ target }) => this.setPublicName(target.value)}
            />
          </div>
          <TextField
              label={t("description")}
              variant="outlined"
              multiline
              rows={4}
              value={this.state.description}
              onChange={({ target }) => this.setDescription(target.value)}
            />
        </M.RecordsVideoForm>

        <M.ContainerBody>
          <M.SidePanel>
            {this.state.videos.map((file, i) => (
              <div key={i} className="__video">
                <div className="__info">
                  <span>
                    <LinesEllipsis
                      text={file.name}
                      maxLine="3"
                      ellipsis="..."
                      trimRight
                      basedOn="letters"
                    />
                  </span>
                  <div>
                    <span className="__serie">{t("duration")}</span>
                    <hr />
                    <span className="__time">{file.other}</span>
                  </div>
                </div>
                <M.CloseStyled
                  onClick={() => {
                    this.remove(file._id);
                  }}
                />
              </div>
            ))}
          </M.SidePanel>

          <M.DataBody>
            <SidebarContainerStyled
              showSidebar={this.state.showSidebar}
              onClick={() => this.setShowSidebar(false)}
            >
              <div
                className="cardlayout--sidebar"
                onClick={(e) => e.stopPropagation()}
              >
                <SidebarTitle>
                  <FilterIcon /> {t("filters")}
                </SidebarTitle>
                <FilterSidebar
                  t={t}
                  filterCategory={this.state.filterCategory}
                  setFilterCategory={this.setFilterCategory}
                  filterDevice={this.state.filterDevice}
                  setfilterDevice={this.setfilterDevice}
                  setShowSidebar={this.setShowSidebar}
                />
              </div>
            </SidebarContainerStyled>

            <div className="__header">
              <TextField
                fullWidth
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                placeholder={t("search")}
                variant="outlined"
                value={this.state.filters}
                onChange={this.setFilter}
              />
              <Button
                variant="outlined"
                startIcon={<FilterIcon />}
                onClick={() => this.setShowSidebar(true)}
              >{t("filters")}</Button>
            </div>

            <div className="__body">
              {JSON.parse(JSON.stringify([...store.videos]))
                .filter(
                  (f) =>
                    f.name
                      .toLowerCase()
                      .includes(this.state.filter.toLowerCase()) ||
                    this.state.filter === ""
                )
                .filter((item) => this.handleDeviceFilter(item))
                .filter((item) => this.handleCategoryFilter(item))
                .map((card, i) => {
                  return (
                    <FileCard
                      key={i}
                      t={t}
                      scrollPosition={this.props.scrollPosition}
                      file={card}
                      list={this.state.videos}
                      type={card.type}
                      img={card.type === 0 ? card.cover : card.src}
                      name={card.name}
                      videoDuration={card.type === 0 ? card.other : null}
                      onClick={() => {
                        this.handleClick(card);
                      }}
                    />
                  );
                })}
            </div>
          </M.DataBody>
        </M.ContainerBody>
      </M.ScreenContainer>
    );
  }
}

export default connect(
  withTranslation("sessionStudio")(trackWindowScroll(Video))
);
