import React, { Component } from "react";
import TimePicker from "../timePicker";
import Counter from "../counter";
import { withTranslation } from "react-i18next";
import ImgBipeMarch from "../../assets/img/bloques/bipedestacion-marcha.svg";

import {
  InputBorder,
  Textarea,
  Container,
  Row,
  Col,
  RadioTab,
  Button,
  Slider,
  Modal,
} from "trainfes-components-library";

import * as M from "./styles/Modos.styles";
import * as C from "./styles/configbutton.styles";

import { ConfigIcon } from "../../assets/icons";
import { getSeconds, secondsToDate } from "../../lib/modes.helpers";

var self;
class Walkfes extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      description: "",
      icon: "icon-alert",
      files: [],
      video: "",
      type: true,
      repeticiones_tiempo: 0,
      pausa_repeticiones_tiempo: { hours: 0, minutes: 0, seconds: 0 },
      tiempo_trabajo: { hours: 0, minutes: 0, seconds: 0 },
      channels: [],
      select: 0,
      repeticiones_paso: 0,
      pausa_repeticiones_paso: { hours: 0, minutes: 0, seconds: 0 },
      num_steps: 0,

      // Temp -> temporal para configuración de modal.
      frecuenciaTemp: 35,
      ancho_pulsoTemp: 200,

      frecuencia: 35,
      ancho_pulso: 200,

      time: "00:00:00",

      tab_active: "0",

      technical_name: "",
      public_name: "",
    };

    this.currentName = React.createRef();
  }

  values() {
    if (this.props.values !== undefined) {
      let data = Object.assign({}, this.state);
      data["config_channels"] = this.state.channels.filter((e) => e.index !== 100);
      this.props.values(data);
    }
  }

  componentDidUpdate() {
    this.values();
  }

  componentDidMount() {
    self = this;
    if (this.props.data.params && Object.keys(this.props.data.params).length !== 0) {
      const {
        num_steps,
        tiempo_trabajo,
        pausa_repeticiones_tiempo,
        repeticiones_tiempo,
        pausa_repeticiones_paso,
        repeticiones_paso,
        select,
        technical_name,
        public_name,
        description,
        time,
        frecuencia,
        ancho_pulso,
        tab_active,
      } = this.props.data.params;
      this.setState(
        {
          num_steps,
          tiempo_trabajo,
          repeticiones_tiempo,
          pausa_repeticiones_tiempo,
          repeticiones_paso,
          pausa_repeticiones_paso,
          select,
        },
        () => this.getTotalTime()
      );
      if (tab_active) {
        this.setState({
          tab_active,
        });
      }

      if (frecuencia) {
        this.setState({ frecuencia, frecuenciaTemp: frecuencia });
      }

      if (ancho_pulso) {
        this.setState({ ancho_pulso, ancho_pulsoTemp: ancho_pulso });
      }

      if (time) {
        this.setState({ time }, () => this.getTotalTime());
      }

      if (public_name) {
        this.setState({
          public_name,
        });
      }

      if (description) {
        this.setState({
          description,
        });
      }

      if (technical_name) {
        this.setState({
          technical_name,
        });
      }
    }
  }

  setPublicName(e) {
    self.setState({ public_name: e });
  }

  setTechnicalName(e) {
    self.setState({ technical_name: e });
  }

  setDescription(e) {
    self.setState({ description: e });
  }

  setTabActive(val) {
    self.setState({ tab_active: val });
  }

  setShowConfigModal(bool) {
    self.setState({ showConfigModal: bool });
  }

  setFrecuencyTemp(value) {
    self.setState({ frecuenciaTemp: value });
  }

  setPulseWidthTemp(value) {
    self.setState({ ancho_pulsoTemp: value });
  }

  handleConfigSubmit() {
    self.setState({
      ancho_pulso: self.state.ancho_pulsoTemp,
      frecuencia: self.state.frecuenciaTemp,
    });
    self.setShowConfigModal(false);
  }

  getTotalTime() {
    const totalSecondsWorkTime = getSeconds(this.state.tiempo_trabajo);

    self.setState({
      time: secondsToDate(totalSecondsWorkTime),
    });
  }

  render() {
    const { t } = this.props;

    return (
      <M.ScreenContainer>
        <Container>
          <Row cols={12}>
            <Col xs={12}>
              <M.RecordsVideoForm>
                <div>
                  <InputBorder
                    placeholder={t("tech_name")}
                    value={this.state.technical_name}
                    onChange={({ target }) => this.setTechnicalName(target.value)}
                  />
                  <InputBorder
                    placeholder={t("public_name")}
                    value={this.state.public_name}
                    onChange={({ target }) => this.setPublicName(target.value)}
                    showRequired={false}
                  />
                </div>
                <Textarea
                  placeholder={t("description")}
                  value={this.state.description}
                  onChange={({ target }) => this.setDescription(target.value)}
                />
              </M.RecordsVideoForm>
            </Col>
          </Row>

          <Row cols={12}>
            <Col xs={6}>
              <C.ConfigTimeContainer style={{ marginBottom: "30px" }}>
                <M.TimeContainer>
                  <M.TimeTotal>{t("total_time")}:</M.TimeTotal>
                  <M.Box padding={"10px"}>
                    <M.TimeText>{this.state.time}</M.TimeText>
                  </M.Box>
                </M.TimeContainer>
                <C.ConfigButton onClick={() => this.setShowConfigModal(true)}>
                  <ConfigIcon />
                </C.ConfigButton>
              </C.ConfigTimeContainer>

              <M.TabsContainer>
                <RadioTab
                  label={t("time")}
                  id="repeat"
                  value={"0"}
                  onChange={({ target }) => {
                    this.setTabActive(target.value);
                    this.setState({ select: 0 });
                  }}
                  checked={this.state.tab_active === "0"}
                />
                <RadioTab
                  label={t("steps")}
                  id="time"
                  value={"1"}
                  onChange={({ target }) => {
                    this.setTabActive(target.value);
                    this.setState({ select: 1 });
                  }}
                  checked={this.state.tab_active === "1"}
                />
              </M.TabsContainer>

              {this.state.tab_active === "0" ? (
                <M.Box padding={"10px 15px 0px 15px"} style={{ width: 335 }}>
                  <M.GridTitle>{t("timeOfWork")}</M.GridTitle>
                  <M.SliderContainer margin="0">
                    <TimePicker
                      data={this.state.tiempo_trabajo}
                      hours={true}
                      minutes={true}
                      seconds={true}
                      values={(value) =>
                        this.setState({ tiempo_trabajo: value }, () => this.getTotalTime())
                      }
                    />
                  </M.SliderContainer>
                </M.Box>
              ) : (
                <M.Box padding={"10px 15px 0px 15px"} style={{ width: 335 }}>
                  <M.GridTitle>{t("num_steps")}</M.GridTitle>
                  <M.SliderContainer margin="0">
                    <Counter
                      data={this.state.num_steps}
                      title={t("steps")}
                      min={1}
                      max={50000}
                      values={(value) => this.setState({ num_steps: value })}
                    />
                  </M.SliderContainer>
                </M.Box>
              )}
            </Col>
            <Col xs={6}>
              <img
                src={ImgBipeMarch}
                style={{ marginTop: "40px" }}
                className="img-fluid"
                alt="BipeMarch"
              />
            </Col>
          </Row>
        </Container>

        <Modal
          isOpen={this.state.showConfigModal}
          buttons={
            <>
              <Button
                type="ghost"
                width="142px"
                label={t("cancel")}
                onClick={() => {
                  this.setFrecuencyTemp(this.state.frecuencia);
                  this.setPulseWidthTemp(this.state.ancho_pulso);
                  this.setShowConfigModal(false);
                }}
              />
              <Button width="142px" label={t("save")} onClick={this.handleConfigSubmit} />
            </>
          }
        >
          <C.ModalConfigContainer>
            <M.Box padding={"10px 15px 0px 15px"}>
              <M.GridTitle>{t("frecuency")} (HZ)</M.GridTitle>
              <M.SliderIndicator>{this.state.frecuenciaTemp || 0}</M.SliderIndicator>
              <M.SliderContainer>
                <Slider
                  step={1}
                  value={this.state.frecuenciaTemp}
                  min={5}
                  max={60}
                  onChange={(value) => this.setFrecuencyTemp(value)}
                />
              </M.SliderContainer>
            </M.Box>

            <M.Box padding={"10px 15px 0px 15px"}>
              <M.GridTitle>{t("pulse_width")} (US)</M.GridTitle>
              <M.SliderIndicator>{this.state.ancho_pulsoTemp || 0}</M.SliderIndicator>
              <M.SliderContainer>
                <Slider
                  step={1}
                  value={this.state.ancho_pulsoTemp}
                  min={20}
                  max={400}
                  onChange={(value) => this.setPulseWidthTemp(value)}
                />
              </M.SliderContainer>
            </M.Box>
          </C.ModalConfigContainer>
        </Modal>
      </M.ScreenContainer>
    );
  }
}

export default withTranslation("sessionStudio")(Walkfes);
