import React, { useState, useEffect } from "react";
import { Modal, Button, Input, Dropdown } from "trainfes-components-library";

import ButtonAdd from "../../../../components/buttonAdd";
import ImgUpload from "../../../../assets/img/illustrations/upload.svg";
import ProgressBar from "../../../../components/progressBar";
import LoaderContainer from "../../../../components/loader/container";
import { eventListener } from "../../../../services/socket";
import { connect } from "../../../../store";
import { PDFThumnail } from "../../../../components/fileViewer";
import { useTranslation } from "react-i18next";
import "../../../../assets/css/video.css";

import { mediaFilter } from '../../../../lib/media.filters';

import MediaManager from "../../media";
import { Card } from "../../components/Card";

import {
  CardsContainer,
  DocumentStyled,
  Label,
  ModalDescription,
  TextArea,
  VideoModalContainer,
} from "../../Library.styles";
import { Page } from "react-pdf";

const mediaManager = new MediaManager();

const Files = (props) => {
  const { t } = useTranslation("library");
  const [load, setLoad] = useState(true);
  const [openVideo, setOpen] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);
  const [addFile, setAddFile] = useState(false);
  const [current, setCurrent] = useState(null);
  const [removeId, setRemoveId] = useState(null);
  const [percentUpload, setPercentUpload] = useState("0%");
  const [dataUpload, setDataUpload] = useState("0 / 0");
  const [step, setStep] = useState(0);
  const [file, setFile] = useState(null);
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [cover, setCover] = useState(null);
  const [cat, setCat] = useState("");
  const [device, setDevice] = useState("");

  useEffect(() => {
    getData(true);

    eventListener(mediaManager.evFileUpload, async (data) => {
      if ((await mediaManager.getParseFileCurret()) === data.id) {
        setPercentUpload(data.progress);
        setDataUpload(data.data);
      }
    });
  }, []);

  const getData = (loader = false) => {
    setLoad(loader);
    mediaManager
      .getFiles()
      .then((res) => {
        setLoad(false);
        props.setFiles(res);
      })
      .catch((err) => console.log(err));
  };

  const notify = (message) => {
    props.notify({ type: "error", title: t("files"), text: message });
    return false;
  };

  const subir = () => {
    if (!name || name.length === 0 || name === "") return notify(t("notify.name"));
    if (!cat) return notify(t("notify.category"));
    if (!device) return notify(t("notify.device"));
    if (!file) return notify(t("notify.file"));

    setStep(2);
    mediaManager.setCurrentFile(file);
    mediaManager
      .uploadFiles({
        media: file,
        name: name,
        desc: desc,
        cover: cover,
        cat: cat,
        device: device,
      })
      .then(() => {
        getData();
        clear();
        setStep(3);
      })
      .catch((err) => console.log(err));
  };

  const clear = () => {
    setFile(null);
    setName("");
    setDesc("");
    setCover(null);
    setCat(null);
    setDevice(null);
  };

  const uploadVideo = () => {
    return (
      <div className="browse-files text-center my-2">
         <label htmlFor="fileupload" className="custom-file-upload">
          <img src={ImgUpload} className="img-fluid" alt="Subir"></img>
        </label>
        <p className="fw-600 color-sub-text py-3">
          {t("Search_here_for_your_pdf_from_your_computer")}
          <br></br>
          {t("to_start_uploading_it_to_the_library")}
        </p>
        <label htmlFor="fileupload" className="btn-purple-tf mx-auto">
          {t("search_your_pdf")}
        </label>
      </div>
    );
  };

  const listVideo = () => {
    return (
      <div className="browse-files text-center my-2">
        <PDFThumnail src={cover} />
        <p className="fw-600 color-sub-text py-3">{file && file.name}</p>
        <label htmlFor="fileupload" className="btn-purple-tf mx-auto">
          {t("search_other_pdf")}
        </label>
      </div>
    );
  };

  const uploadingVideo = () => {
    return (
      <div className="browse-files text-center my-2">
        <img src={ImgUpload} className="img-fluid" alt="Subir"></img>
        <p className="fw-600 color-sub-text py-3">{t("uploading_your_pdf")}</p>
        <ProgressBar value={percentUpload} />
        <div className="text-center">
          <p>
            <strong>{dataUpload}</strong>
          </p>
        </div>
      </div>
    );
  };

  const successUpload = () => {
    return (
      <div className="browse-files text-center my-2">
        <img src={ImgUpload} className="img-fluid" alt="Subir"></img>
        <p className="fw-600 color-sub-text py-3">{t("your_pdf_has_been_uploaded_successfully")}</p>
        <label htmlFor="fileupload" className="btn-purple-tf mx-auto" onClick={() => setStep(0)}>
          {t("uploading_other_pdf")}
        </label>
      </div>
    );
  };

  const remove = () => {
    if (removeId != null) {
      mediaManager
        .deleteMedia(removeId._id)
        .then(() => {
          getData();
          setOpenRemove(false);
        })
        .catch((err) => console.log(err));
    }
  };

  const categoriesOptions = props.store.categories_media.map((category) => {
    return { value: category._id, label: category.name };
  });

  const devicesOptions = props.store.devices_media.map((device) => {
    return { value: device._id, label: device.name };
  });

  // eslint-disable-next-line no-unused-vars
  const [numPages, setNumPages] = useState(null);
  const [pageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <LoaderContainer active={load}>
      <>
        <CardsContainer>
          {props.store.files
            .filter(
              (f) =>
                f.cat._id.includes(props.sidebarFilterState.category) ||
                props.sidebarFilterState.category === ""
            )
            .filter(
              (f) =>
                f.device._id.includes(props.sidebarFilterState.device) ||
                props.sidebarFilterState.device === ""
            )
            .filter(
              (f) => mediaFilter(f, props.filter)
            )
            .map((e, i) => {
              return (
                <Card
                  key={i}
                  isPdf
                  img={e.src}
                  onClick={() => {
                    setOpen(true);
                    setCurrent(e);
                  }}
                  filename={e.name}
                  onClickDelete={() => {
                    setOpenRemove(true);
                    setRemoveId(e);
                  }}
                />
              );
            })}
        </CardsContainer>

        <ButtonAdd action={() => setAddFile(true)} />

        {current && (
          <Modal
            title={current.name}
            width="fit-content"
            isOpen={openVideo}
            handleClose={() => setOpen(false)}
            buttons={<Button label={t("close")} width="142px" onClick={() => setOpen(false)} />}
          >
            {/* <PDFThumnail className="view" src={current.src} /> */}
            <DocumentStyled
              file={current.src}
              onLoadError={console.error}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page pageNumber={pageNumber} style={{ maxHeight: 600 }} />
            </DocumentStyled>

            <ModalDescription>{current.desc}</ModalDescription>
          </Modal>
        )}

        {removeId && (
          <Modal
            title={`${t("sure_you_want_to_delete")} ${removeId.name}?`}
            isOpen={openRemove}
            handleClose={() => setOpenRemove(false)}
            buttons={
              <>
                <Button
                  type="ghost"
                  label={t("cancel")}
                  onClick={() => setOpenRemove(false)}
                  width="142px"
                />
                <Button
                  label={t("delete")}
                  width="142px"
                  onClick={() => {
                    remove();
                    setOpenRemove(false);
                  }}
                />
              </>
            }
          ></Modal>
        )}

        <Modal
          title={t("upload_pdf")}
          isOpen={addFile}
          handleClose={() => setAddFile(false)}
          buttons={
            <>
              <Button
                type="ghost"
                label={t("cancel")}
                onClick={() => setAddFile(false)}
                width="142px"
              />
              <Button label={t("upload")} width="142px" onClick={() => subir()} />
            </>
          }
        >
          <VideoModalContainer>
            <Input
              placeholder={t("pdf_name")}
              value={name}
              onChange={(v) => setName(v.target.value)}
            />
            <div className="_dropdowns">
              <Dropdown
                key={`category-${cat}`}
                placeholder={t("category")}
                options={categoriesOptions}
                value={categoriesOptions.filter((e) => e.value === cat)[0]}
                onChange={(c) => setCat(c.value)}
                type="line"
                portal
              />
              <Dropdown
                key={`device-${device}`}
                placeholder={t("devices")}
                options={devicesOptions}
                value={devicesOptions.filter((e) => e.value === device)[0]}
                onChange={(c) => setDevice(c.value)}
                type="line"
                portal
              />
            </div>

            <div className="_description">
              <Label>{t("desc")}</Label>
              <TextArea value={desc} onChange={(v) => setDesc(v.target.value)}></TextArea>
            </div>

            {step === 0
              ? uploadVideo()
              : step === 1
              ? listVideo()
              : step === 2
              ? uploadingVideo()
              : step === 3
              ? successUpload()
              : null}
          </VideoModalContainer>
        </Modal>
      </>
      <input
        type="file"
        accept="application/pdf"
        id="fileupload"
        style={{ display: "none" }}
        onChange={async (file) => {
          if (!file.target.files[0].type.includes("pdf")) {
            return notify(t("notify.file_type"));
          }
          setFile(file.target.files[0]);
          setStep(1);
          setCover(mediaManager.getCover(file.target.files[0]));
        }}
      />
    </LoaderContainer>
  );
};

export default connect(Files);
