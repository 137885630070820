import API from '../../services/api'
import ENDPOINT from "../../services/endpoint"
const api = new API();

class MediaManager {
    evFileUpload = "file_upload";
    _fileCurrent = null;

    _helperUri(data) {
        return data.map(media => {
            media['src'] = media.src.includes("amazon") ? media.src : ENDPOINT + media.src;
            return media;
        });
    }

    _helperUriVideo(data) {
        return data.map(media => {
            media['provider'] = media.src.includes("http") ? "amazon" : "vimeo";
            return media;
        });
    }

    async getImages() {
        return new Promise((resolve, reject) => {
            api.getMediaImages()
                .then(files => resolve(this._helperUri(files.data)))
                .catch(err => reject(err))
        });
    }

    async uploadImage(data) {
        return await api.createMediaImages(await this._formdata(data));
    }

    async getFiles() {
        return new Promise((resolve, reject) => {
            api.getMediaFiles()
                .then(files => resolve(this._helperUri(files.data)))
                .catch(err => reject(err))
        });
    }

    async uploadFiles(data) {
        return await api.createMediaFiles(await this._formdata(data));
    }

    async getVideos() {
        return new Promise((resolve, reject) => {
            api.getMediaVideos()
                .then(files => resolve(this._helperUriVideo(files.data)))
                .catch(err => reject(err))
        });
    }

    async uploadVideo(data, onUploadProgress = () => {}) {
        return await api.createMediaVideos(await this._formdata(data), { onUploadProgress });
    }

    async deleteMedia(id) {
        return await api.deleteMedia(id);
    }

    async _formdata(data) {
        const form = new FormData();
        form.append('media', data.media);
        form.append('name', data.name);
        form.append('desc', data.desc);
        form.append('cover', data.cover);
        form.append('cat', data.cat);
        form.append('device', data.device);
        if (data && data.duration) form.append('duration', data.duration);
        return form;
    }

    async setCurrentFile(file) {
        this._fileCurrent = file;
    }

    async getParseFileCurret() {
        if (this._fileCurrent) {
            return `${this._fileCurrent.name.replace(new RegExp(" ", 'g'), "_")}:::${this._fileCurrent.size}`
        } else {
            return "";
        }

    }

    getCover(file) {
        try {
            var urlCreator = window.URL || window.webkitURL;
            return urlCreator.createObjectURL(file);
        } catch (ex) {
            return null;
        }
    }
}


export default MediaManager;