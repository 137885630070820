import React from 'react'
import styled from 'styled-components';

import { DeleteIcon } from "./assets"

export const SpecialityBlock = ({ state, selectedId, onDelete }) => {
  
  const selectedInfo = state.specialities.filter(({ value }) => value === selectedId )[0]
  
  return (
    <Container>
      <p>{ selectedInfo.label }</p>
      <DeleteIcon onClick={() => onDelete(selectedId)}/>
    </Container>
  )
}

const Container = styled.div`
  height: 100%;
  background-color: #E3EAFF;
  color: #281B65;
  border: 1px solid white;
  height: 50px;

  display: grid;
  grid-template-columns: 1fr 50px;
  place-items: center;
  text-align: center;

  & > p {
    font-weight: 500;
    font-size: 12px;  
    margin: 0;
  }

  & > svg {
    cursor: pointer;
    fill: #281B65;

    &:hover {
      opacity: 0.7;
    }
  }
`;