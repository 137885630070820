import { useQuery, useMutation } from '@tanstack/react-query';
import { useState } from 'react';

import API from "../services/api";

const api = new API();

export const useGetSubsidiaries = () => {
    // Array para usar en dropdowns
    const [subsidiariesOptions, setSubsidiariesOptions] = useState([])

    const subsidiariesQuery = useQuery({
        queryKey: ["subsidiaries"],
        queryFn: () => api.getSubsidiary()
            .then((res) => {
                return (
                    setSubsidiariesOptions(
                        res.data.map((e) => {
                            return { label: e.name, value: e._id }
                        })
                    ),

                    res.data.map((e) => {
                        e["encargado"] = e["representative"]
                          ? e["representative"]["name"] + " " + e["representative"]["lastname"]
                          : "-";
                        e["repre"] = e["representative"] ? e["representative"]["_id"] : "-";
                        return e;
                    })
                )
            }),
        refetchOnWindowFocus: false,
    });

    const { mutate: addNewSubsidiary } = useMutation(
        ( newSubsidiary ) => {
            return api.createSubsidiary( newSubsidiary )
        },
        {
            onSuccess: async () => subsidiariesQuery.refetch()
        }
    );
    
    const { mutate: editSubsidiary } = useMutation(
        ( { id, data } ) => {
            return api.editSubsidiary( id, data )
        },
        {
            onSuccess: async () => subsidiariesQuery.refetch()
        }
    );

    const { mutate: deleteSubsidiary } = useMutation(
        ( id ) => {
            return api.deleteSubsidiary( id )
        },
        {
            onSuccess: async () => subsidiariesQuery.refetch()
        }
    );


    return {
        subsidiariesQuery,
        addNewSubsidiary,
        editSubsidiary,
        deleteSubsidiary,

        subsidiariesOptions
    }
}
