import styled from "styled-components";

export const ModesContainer = styled.div`
  background: #f3f8ff;
  height: calc(100vh - 125px);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: repeat(5, 85px);
  grid-gap: 14px;
  padding: 20px;
  overflow: auto;

  & > .__block-disable {
    pointer-events: none;

    & * {
      color: #9aa2a8;
      fill: #9aa2a8;
    }
  }
`;

export const HeaderContainer = styled.div``;

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 22px 22px 10px 0;
`;

export const FluxContainer = styled.div`
  width: 100%;
  padding: 0 22px 22px 22px;
  height: calc(100vh - 200px);
  overflow-y: auto;
`;

export const RowStyled = styled.div`
  display: grid;
  grid-template-columns: 331px 1fr;
`;

export const Block = styled.div`
  width: 85px;
  height: 85px;

  background: #ffffff;
  border: 1px solid #ecf2ff;
  border-radius: 3px;
  cursor: pointer;
  position: relative;

  & svg {
    fill: #281b65;
    color: #fff;

    circle {
      fill: #fff;
    }
  }

  &:hover {
    background: #97B8FF;
    & p {
      color: #fff;
    }
    & svg {
      fill: #fff;
      color: #97B8FF;

      circle {
        fill: #97B8FF;
      }
    }
  }

  & > .block--icon_sensor {
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;

export const BlockTitle = styled.p`
  color: #281b65;
  font-size: 8px;
  font-weight: 400;
  letter-spacing: 0.7px;
  text-align: center;
  padding: 0;
  margin: 5px 0 0 0;
`;

export const Icon = styled.div`
  margin-top: 5px;
  display: flex;
  justify-content: center;
`;
