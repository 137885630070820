import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import {
  Container,
  Row,
  Col,
  RadioIconTab,
} from "trainfes-components-library";
import { TextField } from '@mui/material';
import { HelpIcon, PainIcon, WarningIcon } from "../../../assets/icons";

import * as M from "../styles/Modos.styles";
import * as S from "./Warnings.styles";

var self;
class Warnings extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      description: "",
      typeMessage: "alert",
      files: [],
      video: "",
      type: true,
      channels: [],

      technical_name: "",
      public_name: "",
    };

    this.currentName = React.createRef();
  }

  values() {
    let data = Object.assign({}, this.state);
    data["config_channels"] = this.state.channels.filter((e) => e.index !== 100);
    if (this.props.values !== undefined) this.props.values(data);
  }

  componentDidUpdate() {
    this.values();
  }

  componentDidMount() {
    self = this;
    if (this.props.data.params && Object.keys(this.props.data.params).length !== 0) {
      const { name, typeMessage, description, technical_name, public_name } =
        this.props.data.params;
      this.setState({
        name,
        description,
        typeMessage,
      });

      if (name && !technical_name) {
        this.setState({
          technical_name: name,
        });
      }

      if (technical_name) {
        this.setState({
          technical_name,
        });
      }

      if (public_name) {
        this.setState({
          public_name,
        });
      }
    }
  }

  setPublicName(e) {
    self.setState({ public_name: e });
  }

  setTechnicalName(e) {
    self.setState({ technical_name: e });
  }

  setDescription(e) {
    self.setState({ description: e });
  }

  setTypeMessage(v) {
    self.setState({ typeMessage: v });
  }

  render() {
    const { t } = this.props;
    return (
      <M.ScreenContainer>
        <Container>
          <Row cols={12}>
            <Col xs={12}>
              <M.RecordsVideoForm>
                <div>
                  <TextField
                    fullWidth
                    required
                    label={t("tech_name")}
                    value={this.state.technical_name}
                    onChange={({ target }) => this.setTechnicalName(target.value)}
                  />
                  <TextField
                    fullWidth
                    label={t("public_name")}
                    value={this.state.public_name}
                    onChange={({ target }) => this.setPublicName(target.value)}
                  />
                </div>
                <TextField
                  label={t("description")}
                  fullWidth
                  multiline
                  rows={4}
                  value={this.state.description}
                  onChange={({ target }) => this.setDescription(target.value)}
                />
              </M.RecordsVideoForm>
            </Col>
          </Row>

          <Row cols={12} style={{ marginTop: 26 }}>
            <Col xs={6}>
              <M.Title>
                {t("select_a_warning_type")} <span className="__red">*</span>
              </M.Title>
              <S.RadioContainer>
                <RadioIconTab
                  label={t("warning")}
                  icon={<WarningIcon />}
                  value={"alert"}
                  id="alert"
                  onChange={({ target }) => self.setTypeMessage(target.value)}
                  checked={this.state.typeMessage === "alert"}
                />
                <RadioIconTab
                  label={t("pain")}
                  icon={<PainIcon />}
                  value={"pain"}
                  id="pain"
                  onChange={({ target }) => self.setTypeMessage(target.value)}
                  checked={this.state.typeMessage === "pain"}
                />
                <RadioIconTab
                  label={t("help")}
                  icon={<HelpIcon />}
                  value={"help"}
                  id="help"
                  onChange={({ target }) => self.setTypeMessage(target.value)}
                  checked={this.state.typeMessage === "help"}
                />
              </S.RadioContainer>
            </Col>
            <Col xs={6}></Col>
          </Row>
        </Container>

        {/* <Grid container direction="row" justifyContent="center">
          <Grid item xs={12} md={6} className="pl-4">
            <p className="title-calendar mb-2">{t("name")}</p>
            <Grid item xs={12}>
              <TextField
                className="w-100"
                id="outlined-margin-dense1"
                margin="dense"
                variant="outlined"
                value={this.state.name}
                onChange={(value) => this.setState({ name: value.target.value })}
              />
            </Grid>
            <p className="title-calendar mt-3 mb-2">{t("description")}</p>
            <Grid item xs={12}>
              <TextField
                className="w-100"
                id="outlined-margin-dense1"
                margin="dense"
                multiline
                rows={7}
                variant="outlined"
                value={this.state.description}
                onChange={(value) => this.setState({ description: value.target.value })}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} className="px-4">
            <p className="title-calendar mb-3">{t("warning")}</p>
            <Grid container direction="row" className="border-tf rounded p-3">
              <Grid item xs={12} container direction="row">
                <Grid
                  item
                  xs={3}
                  container
                  direction="row"
                  justifyContent="space-around"
                  alignItems="center"
                >
                  <div className="d-flex justify-content-center d-flex align-items-center mx-auto">
                    <input
                      type="radio"
                      id="check1"
                      name="alert"
                      checked={this.state.typeMessage === "alert" ? true : false}
                      onChange={(value) =>
                        value.target.checked ? this.setState({ typeMessage: "alert" }) : null
                      }
                    ></input>
                    <label
                      htmlFor="check1"
                      className="radio-alert fz-40"
                      style={{ padding: "8px 15px" }}
                    >
                      <span className="icon-alert"></span>
                      <p className="fz-10 color-primario" style={{ margin: "8px" }}>
                        {t("alert")}
                      </p>
                    </label>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={3}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <div className="d-flex justify-content-center d-flex align-items-center mx-auto">
                    <input
                      type="radio"
                      id="check2"
                      name="alert"
                      checked={this.state.typeMessage === "help" ? true : false}
                      onChange={(value) =>
                        value.target.checked ? this.setState({ typeMessage: "help" }) : null
                      }
                    ></input>
                    <label
                      htmlFor="check2"
                      className="radio-alert fz-40"
                      style={{ padding: "8px 15px" }}
                    >
                      <span className="icon-help"></span>
                      <p className="fz-10 color-primario" style={{ margin: "8px" }}>
                        {t("help")}
                      </p>
                    </label>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={3}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <div className="d-flex justify-content-center d-flex align-items-center mx-auto">
                    <input
                      type="radio"
                      id="check3"
                      name="alert"
                      checked={this.state.typeMessage === "pain" ? true : false}
                      onChange={(value) =>
                        value.target.checked ? this.setState({ typeMessage: "pain" }) : null
                      }
                    ></input>
                    <label
                      htmlFor="check3"
                      className="radio-alert fz-40"
                      style={{ padding: "8px 15px" }}
                    >
                      <span className="icon-pain"></span>
                      <p className="fz-10 color-primario" style={{ margin: "8px" }}>
                        {t("pain")}
                      </p>
                    </label>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={3}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <div className="d-flex justify-content-center d-flex align-items-center mx-auto">
                    <input
                      type="radio"
                      id="check4"
                      name="alert"
                      checked={this.state.typeMessage === "pause" ? true : false}
                      onChange={(value) =>
                        value.target.checked ? this.setState({ typeMessage: "pause" }) : null
                      }
                    ></input>
                    <label
                      htmlFor="check4"
                      className="radio-alert fz-40"
                      style={{ padding: "8px 15px" }}
                    >
                      <span className="icon-pause"></span>
                      <p className="fz-10 color-primario" style={{ margin: "8px" }}>
                        {t("pause")}
                      </p>
                    </label>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid> */}
      </M.ScreenContainer>
    );
  }
}

export default withTranslation("sessionStudio")(Warnings);
