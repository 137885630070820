import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab, Typography, Box, Paper } from '@material-ui/core';


function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={3}>{children}</Box>}
		</Typography>
	);
}

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const styles = makeStyles({
	root: {
		width: '',
		maxWidth: 'none',
		color: '#281B65',
		boxShadow: 'none',
		height: '44px',
		fontSize: '14px',
		zIndex: 0,
		borderBottom: '4px solid #E7ECF4',
		'&:focus': {
			outline: 'none',
		},
	},
	label: {
		textTransform: 'capitalize',
		fontSize: '14px !important',
	},

});


export default function DisabledTabs(props) {
	const classes = styles();
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
		if (props.onChange !== undefined) props.onChange(newValue);
	};

	useEffect(()=>{
		if(props.value !== undefined){
			setValue(props.value);
		}
	}, [props.value])

	return (
		<Paper square>
			<Tabs value={value} onChange={handleChange} aria-label="disabled tabs example">
				{props.tabs.length}
				{props.tabs !== undefined ? props.tabs.map((e, i) => {
					return (
						<Tab  key={i} className={classes.root} label={e.title} id={props.id} {...a11yProps(i)} />
					)
				}) : null}
			</Tabs>

			{props.tabs !== undefined ? props.tabs.map((e, i) => {
				return (
					<TabPanel id={props.id} value={value} index={i} key={i}>{e.content}</TabPanel>
				)
			}) : null}
		</Paper>
	);
}
