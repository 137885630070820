import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export const TableBioFeedback = (props) => {
    const { t } = useTranslation('sessionStudio');
    const remove = (id) => {
        if (props.remove !== undefined) props.remove(id);
    }

    const getTimeToStr = (obj, h = false) => {
        const { hours, minutes, seconds } = obj;
        return `${h ? hours < 10 ? '0' + hours + ':' : hours + ':' : ''} ${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds} `;
    }

    return (
        <TableContainer className="mt-4 mb-5 scroll-table-blocks" >
            <Table>
                <TableHead style={{ background: "rgba(192, 181, 242, 0.25)" }}>
                    <TableRow align="left">
                        <TableCell>{'Pos'}</TableCell>
                        <TableCell>{t('table.duration')}</TableCell>
                        <TableCell>{t('table.objective')}</TableCell>
                        <TableCell>{t('table.type')}</TableCell>
                        <TableCell>{t('table.action')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody style={{ background: "#F8F8F8" }}>
                    {props.data && props.data.map((e, i) => {
                        return (
                            <TableRow align="left" key={i}>
                                <TableCell>{i+1}</TableCell>
                                <TableCell>{getTimeToStr(e.duracion)}</TableCell>
                                <TableCell>{e.objetivo}</TableCell>
                                <TableCell>{e.typeData === 0 ? "Paso" : "Rampa"}</TableCell>
                                <TableCell className='d-flex align-items-center justify-content-center'><button className="btn-carrusel d-flex align-items-center justify-content-center"><span className="icon-trash pointer" onClick={() => remove(e.id)}></span></button></TableCell>
                            </TableRow>
                        )
                    })}
 
                </TableBody>
            </Table>
        </TableContainer>
    )
}