import React from 'react';
import './style.css';
import { connect } from '../store'
import { TextField } from '@material-ui/core';
 
const NoMobile = () => {
    return (
        <div className="container pt-5">
            <div className="row d-flex align-items-center justify-content-center ">
                <div className='col-12 d-flex align-items-center justify-content-center'>
                    <svg width="153" height="48" viewBox="0 0 153 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.3889 11.3251C18.4187 11.3596 18.4242 11.435 18.4069 11.5505L17.6722 16.507C17.6708 16.5464 17.6614 16.5851 17.6445 16.6207C17.6276 16.6563 17.6036 16.688 17.574 16.714C17.5443 16.7399 17.5097 16.7596 17.4722 16.7716C17.4347 16.7837 17.3951 16.7879 17.3559 16.7841H11.6631L8.92969 35.2223C8.91628 35.298 8.87549 35.3662 8.81509 35.4138C8.76081 35.4665 8.68897 35.4973 8.61338 35.5002H3.4035C3.36635 35.5039 3.32888 35.4979 3.29476 35.4827C3.26063 35.4676 3.23102 35.4438 3.20882 35.4138C3.17195 35.3573 3.15739 35.289 3.16797 35.2223L5.89581 16.8185H0.237423C0.203352 16.8184 0.169739 16.8106 0.139132 16.7957C0.108525 16.7807 0.0817299 16.759 0.0607783 16.7321C0.0373693 16.7066 0.0199977 16.6761 0.00994653 16.6429C-0.000104688 16.6098 -0.00257838 16.5748 0.00270863 16.5406L0.74292 11.5505C0.74669 11.5096 0.759083 11.4698 0.779285 11.434C0.799487 11.3981 0.827043 11.367 0.860142 11.3425C0.917376 11.2985 0.987331 11.2743 1.05951 11.2734H18.1735C18.2881 11.2734 18.3603 11.2907 18.3893 11.3253" fill="#281B65"/>
                        <path d="M35.6338 35.4649C35.6001 35.5339 35.5145 35.5685 35.3764 35.5685H29.3733C29.3262 35.5741 29.2786 35.5634 29.2383 35.5383C29.1981 35.5132 29.1676 35.4751 29.152 35.4303L26.0862 26.5567L24.6763 26.5222L23.3968 35.1524C23.3892 35.2138 23.3689 35.2729 23.3371 35.3259C23.3136 35.3643 23.2851 35.3994 23.2524 35.4303C23.1732 35.5104 23.0679 35.5594 22.9556 35.5685H17.7806C17.7464 35.5685 17.7127 35.5607 17.6819 35.5457C17.6512 35.5308 17.6243 35.509 17.6032 35.4821C17.5801 35.4563 17.563 35.4258 17.5531 35.3927C17.5432 35.3596 17.5407 35.3247 17.5459 35.2906L21.0643 11.5486C21.0685 11.5078 21.0811 11.4684 21.1012 11.4327C21.1214 11.397 21.1487 11.3659 21.1815 11.3412C21.2391 11.2974 21.3093 11.2731 21.3817 11.2722H31.2143C32.4169 11.2259 33.485 11.4684 34.4186 11.9998C35.3499 12.529 36.1133 13.3098 36.6213 14.2528C37.1551 15.223 37.4236 16.3204 37.4267 17.545C37.404 18.0074 37.3754 18.3541 37.3412 18.5851C37.1151 20.1544 36.4914 21.6398 35.5294 22.9002C34.5906 24.1579 33.2967 25.1055 31.8143 25.621L35.6797 35.152C35.6981 35.1752 35.7117 35.2018 35.7194 35.2304C35.7272 35.2589 35.729 35.2888 35.7248 35.318C35.7206 35.3473 35.7104 35.3754 35.6949 35.4006C35.6794 35.4258 35.6588 35.4475 35.6346 35.4645L35.6338 35.4649ZM31.7646 18.9659L31.7748 18.8968C31.8538 18.6274 31.8696 18.3433 31.8208 18.0668C31.7721 17.7902 31.6601 17.5287 31.4937 17.3025C31.3256 17.0694 31.1029 16.8811 30.8451 16.7542C30.5872 16.6273 30.3021 16.5657 30.0149 16.5748H26.1503L25.4414 21.3578H29.306C29.6232 21.3585 29.9372 21.2942 30.2287 21.1691C30.5202 21.044 30.7831 20.8606 31.0012 20.6302C31.3998 20.1536 31.6635 19.5789 31.765 18.966" fill="#281B65"/>
                        <path d="M62.1296 35.3607C62.0841 35.4306 62.0504 35.4643 62.0284 35.4643C61.9624 35.4964 61.8885 35.5087 61.8157 35.4997H56.3646C56.3268 35.4978 56.29 35.4875 56.2567 35.4696C56.2235 35.4517 56.1946 35.4266 56.1722 35.3961L56.1189 35.2917L54.8315 31.4099H46.1024L43.6641 35.2917C43.6603 35.3152 43.632 35.3497 43.5794 35.3961C43.5199 35.4558 43.4412 35.4925 43.3572 35.4997H37.9055C37.8595 35.5043 37.813 35.4972 37.7705 35.4791C37.7279 35.461 37.6906 35.4325 37.662 35.3961C37.615 35.3262 37.6362 35.2226 37.7256 35.0836L53.2027 11.2025C53.2308 11.1476 53.2743 11.1021 53.3278 11.0715C53.3813 11.0408 53.4425 11.0263 53.5041 11.0298C53.5419 11.0313 53.5789 11.0415 53.6122 11.0594C53.6455 11.0774 53.6743 11.1027 53.6964 11.1334L53.7498 11.237L62.1189 35.0836C62.1442 35.125 62.1586 35.1722 62.1605 35.2208C62.1624 35.2693 62.1519 35.3175 62.1299 35.3607H62.1296ZM51.9168 21.7395L49.5438 25.4132H53.1666L51.9168 21.7395Z" fill="#281B65"/>
                        <path d="M64.1382 35.5346C64.0934 35.4751 64.0736 35.4004 64.0833 35.3266L67.578 11.5146C67.5888 11.4277 67.6312 11.3478 67.6972 11.2902C67.7515 11.237 67.8237 11.206 67.8997 11.2031H73.2134C73.2475 11.2033 73.2811 11.2113 73.3117 11.2264C73.3423 11.2415 73.3691 11.2633 73.39 11.2902C73.4147 11.3215 73.4323 11.3578 73.4415 11.3966C73.4507 11.4354 73.4514 11.4757 73.4434 11.5148L69.9488 35.3266C69.9327 35.4067 69.8914 35.4796 69.831 35.5346C69.8057 35.5648 69.7744 35.5896 69.7392 35.6075C69.704 35.6253 69.6655 35.6357 69.6261 35.6382H64.3125C64.277 35.6372 64.2424 35.6271 64.2119 35.609C64.1814 35.5909 64.156 35.5653 64.1382 35.5346Z" fill="#281B65"/>
                        <path d="M93.9028 0.277419L90.4429 23.6256L78.2574 11.5641C78.2261 11.5215 78.182 11.4901 78.1316 11.4745C78.0812 11.4588 78.0271 11.4597 77.9772 11.477C77.8681 11.5123 77.8021 11.6104 77.7778 11.7721L73.2947 42.7488C73.2881 42.7863 73.2906 42.8248 73.3019 42.8611C73.3132 42.8975 73.3331 42.9306 73.3599 42.9576L77.8555 47.4077C78.0125 47.5632 78.3093 47.4336 78.343 47.1966L81.709 23.6608L93.929 35.7223C93.949 35.7455 93.974 35.7639 94.0021 35.776C94.0303 35.7881 94.0608 35.7936 94.0914 35.7922C94.1306 35.796 94.1702 35.7916 94.2077 35.7796C94.2452 35.7675 94.2799 35.7479 94.3096 35.7219C94.3392 35.696 94.3633 35.6643 94.3803 35.6287C94.3973 35.5932 94.4069 35.5545 94.4085 35.5151L98.9704 4.73383C98.9771 4.69611 98.9747 4.65733 98.9634 4.62073C98.952 4.58412 98.9321 4.55077 98.9052 4.52347L94.3906 0.0670512C94.2336 -0.08678 93.9385 0.0411722 93.9032 0.277419" fill="#FDAD34"/>
                        <path d="M118.204 5.14334C118.225 5.1733 118.239 5.20734 118.246 5.24321C118.252 5.27908 118.251 5.31595 118.243 5.35137L117.493 10.4115C117.489 10.4523 117.476 10.4918 117.456 10.5276C117.436 10.5634 117.409 10.5946 117.376 10.6195C117.319 10.6637 117.248 10.6882 117.176 10.6894H108.205L107.656 14.3976L114.699 14.3631C114.733 14.3632 114.767 14.3711 114.798 14.386C114.828 14.401 114.855 14.4227 114.876 14.4495C114.899 14.4752 114.916 14.5056 114.926 14.5387C114.937 14.5719 114.939 14.6068 114.934 14.641L114.178 19.7348C114.165 19.8103 114.124 19.8784 114.064 19.9264C114.01 19.979 113.938 20.0097 113.862 20.0127L106.818 20.0473L105.488 29.0245C105.484 29.0654 105.471 29.1049 105.451 29.1407C105.431 29.1765 105.403 29.2077 105.37 29.2325C105.313 29.2765 105.243 29.301 105.171 29.3024H99.8573C99.7906 29.3033 99.7261 29.2782 99.6775 29.2325C99.6512 29.2055 99.6323 29.1722 99.6226 29.1358C99.6128 29.0993 99.6125 29.061 99.6218 29.0245L103.131 5.35155C103.135 5.31066 103.147 5.27107 103.168 5.23526C103.188 5.19946 103.215 5.16822 103.248 5.14352C103.305 5.09954 103.376 5.07531 103.448 5.07445H118.008C118.044 5.07089 118.08 5.07522 118.114 5.08716C118.148 5.09909 118.178 5.11834 118.204 5.14352" fill="#281B65"/>
                        <path d="M126.184 14.3969L132.675 14.3624C132.714 14.36 132.754 14.3665 132.79 14.3815C132.827 14.3964 132.859 14.4194 132.886 14.4487C132.909 14.4742 132.926 14.5045 132.937 14.5375C132.947 14.5705 132.949 14.6053 132.944 14.6395L132.189 19.7348C132.17 19.8176 132.123 19.8912 132.055 19.9428C132.005 19.9879 131.939 20.0126 131.872 20.0119L125.346 20.0473L124.801 23.721H132.565C132.771 23.721 132.862 23.8136 132.834 23.9981L132.084 29.0582C132.057 29.2442 131.939 29.3361 131.732 29.3361H118.415C118.348 29.3373 118.283 29.3122 118.235 29.2662C118.209 29.2392 118.19 29.2059 118.18 29.1694C118.17 29.133 118.17 29.0946 118.18 29.0582L121.693 5.3508C121.707 5.2755 121.748 5.20783 121.808 5.16081C121.862 5.10746 121.935 5.07639 122.011 5.0737H135.328C135.368 5.07136 135.407 5.07798 135.444 5.09306C135.481 5.10814 135.514 5.13129 135.54 5.16081C135.563 5.18629 135.58 5.21652 135.59 5.24934C135.6 5.28217 135.603 5.31681 135.598 5.3508L134.842 10.4462C134.828 10.5213 134.788 10.5889 134.728 10.6369C134.663 10.6942 134.578 10.725 134.491 10.7233H126.728L126.184 14.3969Z" fill="#281B65"/>
                        <path d="M150.006 25.9224C149.193 27.0669 148.087 28.0087 146.689 28.7476C145.292 29.4866 143.661 29.8563 141.798 29.8567C139.475 29.8567 137.388 29.3483 135.538 28.3315C135.482 28.3066 135.433 28.2683 135.395 28.22C135.357 28.1717 135.331 28.1148 135.32 28.0544C135.299 27.9738 135.305 27.8881 135.339 27.8119L137.68 23.8947C137.733 23.8316 137.778 23.7619 137.814 23.6874C137.895 23.5566 138.019 23.4583 138.165 23.4095C138.28 23.4095 138.544 23.4911 138.958 23.652C139.659 23.8836 140.287 24.0686 140.842 24.207C141.453 24.3524 142.08 24.4223 142.708 24.415C143.436 24.4204 144.159 24.3031 144.847 24.0681C145.514 23.8373 145.893 23.41 145.986 22.7862C146.005 22.6832 146.009 22.5779 145.998 22.4737C145.994 22.0357 145.751 21.6199 145.269 21.2264C144.787 20.8329 144.007 20.3127 142.93 19.6658C141.887 19.0735 140.884 18.4138 139.927 17.6911C139.147 17.0833 138.486 16.3371 137.976 15.4899C137.45 14.6233 137.247 13.624 137.368 12.492C137.656 9.92667 138.695 8.00303 140.484 6.7211C142.273 5.43918 144.409 4.79809 146.892 4.79785C148.742 4.79942 150.575 5.15239 152.293 5.83797C152.343 5.84574 152.39 5.8655 152.431 5.89557C152.472 5.92564 152.505 5.96512 152.527 6.01065C152.548 6.05364 152.56 6.10062 152.562 6.14851C152.563 6.1964 152.555 6.24413 152.537 6.28855L150.525 10.5518C150.396 10.8054 150.304 10.9561 150.251 11.0016C150.198 11.0471 150.093 11.0597 149.936 11.0361C149.258 10.7169 148.549 10.4673 147.821 10.2911C147.085 10.1016 146.33 10.0027 145.57 9.99674C144.856 9.99674 144.262 10.1236 143.788 10.3774C143.56 10.4951 143.364 10.6674 143.218 10.879C143.073 11.0906 142.982 11.3349 142.953 11.5902C142.926 11.7507 142.913 11.9134 142.915 12.0762C142.939 12.5385 143.188 12.9543 143.663 13.3235C144.136 13.694 144.911 14.2024 145.989 14.8487C147.058 15.4246 148.086 16.0732 149.065 16.79C149.873 17.4043 150.54 18.1843 151.021 19.0775C151.527 20.0022 151.688 21.0884 151.504 22.336C151.319 23.5836 150.82 24.7791 150.006 25.9224Z" fill="#281B65"/>
                    </svg>
                </div>
                <div className="col-12 pt-4 d-flex align-items-center justify-content-center">
                    <p className='m-0 fz-18' style={{ color:"#3F567A" }}>Califique su atención</p>               
                </div>
                <div className="col-12 p-3 d-flex align-items-center justify-content-center">
                    <span className="icon-heart-filled color-secundario fz-26 px-1 pointer"></span>
                    <span className="icon-heart-outlined color-secundario fz-26 px-1 pointer"></span>
                    <span className="icon-heart-outlined color-secundario fz-26 px-1 pointer"></span>
                    <span className="icon-heart-outlined color-secundario fz-26 px-1 pointer"></span>
                    <span className="icon-heart-outlined color-secundario fz-26 px-1 pointer"></span>                      
                </div>
                <div className='col-6'>
                    <p className='fw-500 fz-14 m-0'style={{ color:"#3F567A" }}>Deje su comentario</p>
                        <TextField
                            className="w-100"
                            id="desc"
                            multiline
                            rows="5"
                            variant="outlined"
                            margin="dense"
                            type="text"
                            fullWidth
                        />
                </div>
                <div className='col-12 mt-4 d-flex align-items-center justify-content-center '>
                    <button className="btn-tf mr-4">Cancelar</button>
                    <button className="btn-white-tf">Guardar</button>
                </div>
            </div>
        </div>
    )
}

export default connect(NoMobile);
