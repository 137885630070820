import React, { useState, useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";

import { InputBorder, Textarea, Button, SearchBar } from "trainfes-components-library";

import * as M from "../styles/Modos.styles";
// import * as S from "./Records.styles";
import { FilterIcon } from "../../../assets/icons";
import { FileCard } from "../components/FileCard";
import { connect } from "../../../store";
import { SidebarContainer, SidebarTitle } from "../../CardLayoutPlatform/styles";
import styled from "styled-components";
import FilterSidebar from "../components/FilterSidebar";
import LinesEllipsis from "react-lines-ellipsis";

const Records = (props) => {
  const ref = useRef(null);

  const { t } = useTranslation("sessionStudio");
  const [name, setName] = useState("");

  const [inputs, setInputs] = useState({
    public_name: "",
    technical_name: "",
  });

  const [description, setDescription] = useState("");

  // * Lista de elementos seleccionados.
  const [files, setFiles] = useState([]);

  // * Lista de elementos para mostrar.
  const [list, setList] = useState([]);

  const values = () => {
    let res = {};
    res["name"] = name;
    res["public_name"] = inputs.public_name;
    res["technical_name"] = inputs.technical_name;
    res["description"] = description;
    res["files"] = files;
    if (props.values !== undefined) props.values(res);
  };

  useEffect(() => {
    setList([...props.store.files, ...props.store.images]);
  }, []);

  useEffect(() => values(), [name, inputs.public_name, inputs.technical_name, description, files]);

  useEffect(() => {
    if (props.data.params && Object.keys(props.data.params).length !== 0) {
      const { name, files, description, public_name, technical_name } = props.data.params;
      setName(name);
      setDescription(description);
      setFiles(files);
      setInputs({ public_name, technical_name });

      if (!technical_name) setInputs({ ...inputs, technical_name: "" });
      if (!public_name) setInputs({ ...inputs, public_name: "" });
      if (name && !technical_name) setInputs({ ...inputs, technical_name: name });
    }
  }, [props.data]);

  const handleClick = (card) => {
    if (files.some((e) => e._id === card._id)) {
      setFiles(files.filter((item) => item._id !== card._id));
      return;
    }

    setFiles([card, ...files]);
  };

  const handleDelete = (file) => {
    setFiles(files.filter((item) => item !== file));
  };

  // * Sidebar
  const [showSidebar, setShowSidebar] = useState(false);

  // * Filtros
  const [filterCategory, setFilterCategory] = useState("");
  const [filterDevice, setfilterDevice] = useState("");

  const handleCategoryFilter = (item) => {
    if (filterCategory === "") return item;
    return item.cat._id === filterCategory.value;
  };

  const handleDeviceFilter = (item) => {
    if (filterDevice === "") return item;
    return item.device._id === filterDevice.value;
  };

  const [filter, setFilter] = useState("");

  return (
    <M.ScreenContainer>
      <M.RecordsVideoForm>
        <div>
          <InputBorder
            placeholder={t("tech_name")}
            value={inputs.technical_name}
            onChange={({ target }) => setInputs({ ...inputs, technical_name: target.value })}
          />
          <InputBorder
            placeholder={t("public_name")}
            value={inputs.public_name}
            onChange={({ target }) => setInputs({ ...inputs, public_name: target.value })}
            showRequired={false}
          />
        </div>
        <Textarea
          placeholder={t("description")}
          value={description}
          onChange={({ target }) => setDescription(target.value)}
        />
      </M.RecordsVideoForm>

      <M.ContainerBody>
        <M.SidePanel>
          {files.map((file, i) => (
            <div key={i} className="__file">
              <span>
                <LinesEllipsis
                  text={file.name}
                  maxLine="6"
                  ellipsis="..."
                  trimRight
                  basedOn="letters"
                />
              </span>
              <M.CloseStyled onClick={() => handleDelete(file)} />
            </div>
          ))}
        </M.SidePanel>

        <M.DataBody>
          <SidebarContainerStyled showSidebar={showSidebar} onClick={() => setShowSidebar(false)}>
            <div ref={ref} className="cardlayout--sidebar" onClick={(e) => e.stopPropagation()}>
              <SidebarTitle>
                <FilterIcon /> {t("filters")}
              </SidebarTitle>
              <FilterSidebar
                t={t}
                filterCategory={filterCategory}
                setFilterCategory={setFilterCategory}
                filterDevice={filterDevice}
                setfilterDevice={setfilterDevice}
                setShowSidebar={setShowSidebar}
              />
            </div>
          </SidebarContainerStyled>

          <div className="__header">
            <SearchBar
              placeholder={t("search")}
              onChange={(event) => setFilter(event.target.value)}
            />
            <Button
              type="ghost"
              icon={<FilterIcon />}
              label={t("filters")}
              width="102px"
              onClick={() => setShowSidebar(true)}
            />
          </div>

          <div className="__body">
            {list
              .filter((f) => f.name.toLowerCase().includes(filter.toLowerCase()) || filter === "")
              .filter((item) => handleDeviceFilter(item))
              .filter((item) => handleCategoryFilter(item))
              .map((card, i) => (
                <FileCard
                  key={i}
                  type={card.type}
                  file={card}
                  list={files}
                  img={card.src}
                  name={card.name}
                  onClick={() => handleClick(card)}
                />
              ))}
          </div>
        </M.DataBody>
      </M.ContainerBody>
    </M.ScreenContainer>
  );
};

export const SidebarContainerStyled = styled(SidebarContainer)`
  top: 0;
  height: 100%;
`;

export default connect(Records);
