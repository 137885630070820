import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import API from "../../../../services/api";
import { connect } from "../../../../store";

import { useForm } from "react-hook-form";

import {
  Button,
  Modal,
} from "trainfes-components-library";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Edit from '@mui/icons-material/Edit';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { ModalNewOrEditUserComponent } from "../ModalNewOrEditUser";
import { Rating } from "../../../../components/Rating";

import { ArrowPatientCardIcon } from "./icons";
import {
  PatientCardBody,
  PatientCardContainer,
  PatientCardHeader,
} from "./styles";

import {
  rutValidation,
  tenantIdOption,
} from "../../../../lib/validation.helpers";
import { transformDate } from "../../../../lib/date.helpers";
import { FORM_INITIAL_VALUE } from "../../clinicalRecords.helpers";

const PatientCard = ({
  data,
  idActual,
  onClick = () => { },
  notify,
  handleGetPatients,
  historyLoading,
}) => {
  const { t } = useTranslation(["registers", "create_patient"]);
  const api = new API();

  const { email, name, lastname, rut, phone, percent_compliance, rating, _id } = data;

  const [showBody, setShowBody] = useState(idActual === _id);
  const [currentForm, setCurrentForm] = useState({});

  const handleToggle = () => {
    if (historyLoading) return;
    onClick(data._id);
    setShowBody((prev) => !prev);
  };

  useEffect(() => {
    if (idActual !== _id) {
      setShowBody(false);
      return;
    }

    const controller = new AbortController();
    const { signal } = controller;

    api
      .getPatienId(_id, { signal })
      .then((res) => {
        setCurrentForm({
          ...res.data,
          birthdate: transformDate(res.data.birthdate),

          nationality:
            tenantIdOption.find(
              (item) => res.data.nationality === item.value
            ) || tenantIdOption[3],
        });

        setDefaultNationalitySelect(
          tenantIdOption.find((item) => res.data.nationality === item.value) ||
          tenantIdOption[3]
        );

        return res;
      })
      .then((res) => {
        setRegion(res.data.region || "");
        setCountry(res.data.country || "");
        setCommune(res.data.commune || "");
        setAddress(res.data.address || "");
        setStateAddress(`${res.data.address || ""}, ${res.data.commune || ""}`);
      })
      .catch((error) => {
        if (error.name !== "AbortError") {
          console.error(error.message);
        }
      });

    return () => {
      controller.abort();
      setCurrentForm({});
    };
  }, [idActual]);

  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...FORM_INITIAL_VALUE,
      ...currentForm,
    },
  });

  useEffect(() => {
    reset({
      ...FORM_INITIAL_VALUE,
      ...currentForm,
    });
  }, [currentForm]);

  // -> DefaultValue para el componente Dropdown.
  const [defaultNationalitySelect, setDefaultNationalitySelect] = useState({});

  const [country, setCountry] = useState("");
  const [commune, setCommune] = useState("");
  const [region, setRegion] = useState("");
  const [address, setAddress] = useState();

  const [stateAddress, setStateAddress] = useState();

  const getDataAddress = (data) => {
    setCountry(data.country);
    setCommune(data.state);
    setRegion(data.region);
    setAddress(`${data.address} ${data.number}`);
    setStateAddress(data.addressComplete);
  };

  const onSubmit = async (data) => {
    if (rutValidation(data.nationality, data.rut)) {
      notify({
        type: "error",
        title: t("create_patient:notify.invalid_id"),
        text: t("create_patient:notify.invalid_id"),
      });
      return;
    }

    if (country.trim() === "") {
      notify({
        type: "error",
        title: t("create_patient:notify.err"),
        text: t("create_patient:notify.country"),
      });
      return;
    }

    if (region.trim() === "") {
      notify({
        type: "error",
        title: t("create_patient:notify.err"),
        text: t("create_patient:notify.state"),
      });
      return;
    }

    if (commune.trim() === "") {
      notify({
        type: "error",
        title: t("create_patient:notify.err"),
        text: t("create_patient:notify.city"),
      });
      return;
    }

    if (address.trim() === "") {
      notify({
        type: "error",
        title: t("create_patient:notify.err"),
        text: t("create_patient:notify.address"),
      });
      return;
    }

    const form = new FormData();
    form.append("nationality", data.nationality);
    form.append("rut", data.nationality === 'CL' ? data.rut.trim() : data.rut);
    form.append("name", data.name);
    form.append("lastname", data.lastname);
    form.append("email", data.email);
    form.append("phone", data.phone);
    form.append("injury_years", data.injury_years);
    form.append("genre", data.genre);
    form.append("birthdate", data.birthdate.replaceAll("/", "-"));
    form.append("country", country);
    form.append("region", region);
    form.append("commune", commune);
    form.append("rol", data.rol);
    form.append("address", address);
    form.append("other_address", data.other_address);
    form.append("innsurance_company", data.innsurance_company);
    form.append("clinic", data.clinic);
    form.append("treating_doctor", data.treating_doctor);
    form.append("years_injury", data.years_injury);
    form.append("reference", data.reference);

    try {
      await api.updatePatient(_id, form);

      notify({
        type: "success",
        title: t("create_patient:notify.update_success"),
        text: t("create_patient:notify.update_success"),
      });

      handleGetPatients();
      handleCloseModal();
    } catch (error) {
      notify({
        type: "error",
        title: t("create_patient:notify.err"),
        text: t("create_patient:notify.err"),
      });
    }
  };


  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleEdit = () => {
    handleShowModal();
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <PatientCardContainer active={idActual === _id}>
        <PatientCardHeader onClick={() => handleToggle()}>
          <div className="header--info">
            <p className="_title">
              {name} {lastname}
            </p>
            <p className="_rut">{rut}</p>
          </div>

          <div className={`_icon ${showBody ? "_active" : ""}`}>
            <ArrowPatientCardIcon />
          </div>
        </PatientCardHeader>
        {showBody && (
          <PatientCardBody>
            <div className="_row _underline">
              <span className="_th">{t("perception")}</span>
              <span className="_th">{t("compliance")}</span>
            </div>
            <div className="_row __mb-20">
              <Rating color="yellow" value={rating} />
              <span className="_bold">{percent_compliance}%</span>
              <IconButton
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClickListItem}
                aria-label="Example">
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={handleEdit}>
                  <ListItemIcon>
                    <Edit fontSize="small" />
                  </ListItemIcon>
                  {t("create_patient:edit")}
                </MenuItem>
              </Menu>
            </div>

            <div className="_row _underline">
              <span className="_th">{t("phone")}</span>
              <span className="_th">E-mail</span>
            </div>
            <div className="_row __mb-20">
              <span>{phone.split(" ")}</span>
              <span>{email}</span>
            </div>
          </PatientCardBody>
        )}
      </PatientCardContainer>
      <Modal
        title={t("edit_patient")}
        isOpen={showModal}
        buttons={
          <>
            <Button
              type="ghost"
              label={t("create_patient:btn_cancel")}
              onClick={handleCloseModal}
            />
            <Button
              label={t("create_patient:btn_save")}
              onClick={handleSubmit(onSubmit)}
            />
          </>
        }
      >
        <ModalNewOrEditUserComponent
          isEditing={true}
          handleSubmit={handleSubmit}
          errors={errors}
          onSubmit={onSubmit}
          control={control}
          stateAddress={stateAddress}
          setStateAddress={setStateAddress}
          getDataAddress={getDataAddress}
          defaultNationalitySelect={defaultNationalitySelect}
          t={t}
        />
      </Modal>
    </>
  );
};

export default connect(PatientCard);
