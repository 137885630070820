import React from "react";
import { Title } from "../../styles/Modos.styles";
import * as S from "./TableMuscles.styles";

export const TableMuscles = ({ t }) => {
  const tableHeaders = [t("muscles"), 1, 2, 3, 4, 5, 6];
  const tableData = [
    {
      muscle: t("quadriceps"),
      1: false,
      2: <S.Check className="__blue" />,
      3: false,
      4: <S.Check className="__blue" />,
      5: false,
      6: false,
    },
    {
      muscle: t("glute_maximus"),
      1: <S.Check className="__blue" />,
      2: false,
      3: <S.Check className="__blue" />,
      4: false,
      5: false,
      6: false,
    },
    {
      muscle: t("ABS"),
      1: false,
      2: false,
      3: false,
      4: false,
      5: <S.Check />,
      6: <S.Check />,
    },
    { muscle: t("lumbar"), 1: false, 2: false, 3: false, 4: false, 5: <S.Check />, 6: <S.Check /> },
    {
      muscle: t("tibialis_anterior"),
      1: <S.Check />,
      2: false,
      3: <S.Check />,
      4: false,
      5: <S.Check />,
      6: <S.Check />,
    },
    {
      muscle: t("fibular"),
      1: <S.Check />,
      2: false,
      3: <S.Check />,
      4: false,
      5: <S.Check />,
      6: <S.Check />,
    },
    {
      muscle: t("glute_medius"),
      1: <S.Check />,
      2: false,
      3: <S.Check />,
      4: false,
      5: <S.Check />,
      6: <S.Check />,
    },
  ];

  return (
    <>
      <Title>
        {t("muscles_to_work")} <span className="__red">*</span>
      </Title>
      <S.Table>
        <S.RowHeader>
          {tableHeaders.map((header, i) => (
            <div key={i}>{header}</div>
          ))}
        </S.RowHeader>
        {tableData.map((row, i) => (
          <S.Row key={i}>
            <div>{row.muscle}</div>
            <div>{row[1]}</div>
            <div>{row[2]}</div>
            <div>{row[3]}</div>
            <div>{row[4]}</div>
            <div>{row[5]}</div>
            <div>{row[6]}</div>
          </S.Row>
        ))}
      </S.Table>
      <S.InfoText>
        <p>*{t("conf_suggested")}</p>
        <p>*{t("conf_optional")}</p>
      </S.InfoText>
    </>
  );
};
