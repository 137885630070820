import React, { Component } from "react";
import TimePicker from "../timePicker";
import Counter from "../counter";
import { withTranslation } from "react-i18next";

import {
  Col,
  Container,
  RadioTab,
  Row,
  SliderMulticolor,
  Tooltip,
} from "trainfes-components-library";

import { TextField } from '@mui/material';

import * as M from "./styles/Modos.styles";
import * as C from "./styles/configbutton.styles";
import { getSeconds, secondsToDate } from "../../lib/modes.helpers";

var self;
class General extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      icon: "icon-alert",
      files: [],
      video: "",
      type: true,
      repeticiones: null,
      series: null,
      pausa_repeticiones: { hours: 0, minutes: 0, seconds: 0 },
      pausa_series: { hours: 0, minutes: 0, seconds: 0 },
      tiempo_trabajo: { hours: 0, minutes: 0, seconds: 0 },
      channels: [],
      config_channels: [],
      select: 0,

      time: "00:00:00",

      tab_active: "0",

      technical_name: "",
      public_name: "",
      description: "",
      work_intensity: 0,
    };

    this.currentName = React.createRef();
  }

  values() {
    if (this.props.values !== undefined) {
      this.props.values(this.state);
    }
  }

  componentDidMount() {
    self = this;
    if (this.props.data.params && Object.keys(this.props.data.params).length !== 0) {
      const {
        name,
        series,
        pausa_series,
        pausa_repeticiones,
        repeticiones,
        files,
        description,
        technical_name,
        public_name,
        work_intensity,
        tiempo_trabajo,
        time,
        select,
        // frecuencia,
        // ancho_pulso,
        tab_active,
      } = this.props.data.params;
      this.setState(
        {
          name,
          files,
          repeticiones,
          series,
          pausa_repeticiones,
          pausa_series,
          select: select || 0,
          tab_active: tab_active || "0",
          public_name: public_name || "",
          description: description || "",
          time: time || "00:00:00",
          work_intensity: work_intensity || 0,
        },
        () => this.getTotalTime()
      );

      if (name && !technical_name) {
        this.setState({
          technical_name: name,
        });
      }

      if (technical_name) {
        this.setState({
          technical_name,
        });
      }

      if (tiempo_trabajo) {
        this.setState({
          tiempo_trabajo,
        });
      }
    }
  }

  componentDidUpdate() {
    this.values();
  }

  setPublicName(e) {
    self.setState({ public_name: e });
  }

  setTechnicalName(e) {
    self.setState({ technical_name: e });
  }

  setDescription(e) {
    self.setState({ description: e });
  }

  setTabActive(val) {
    self.setState({ tab_active: val }, () => this.getTotalTime());
  }

  getTotalTime() {
    const totalSeconds =
      getSeconds(this.state.pausa_repeticiones) * this.state.repeticiones +
      getSeconds(this.state.pausa_series) * this.state.series;

    const totalSecondsWorkTime = getSeconds(this.state.tiempo_trabajo);

    const total = this.state.tab_active === "0" ? totalSeconds : totalSecondsWorkTime;
    self.setState({
      time: secondsToDate(total),
    });
  }

  render() {
    const { t } = this.props;

    return (
      <M.ScreenContainer>
        <M.GeneralContainer>
          <div>
            <TextField
              fullWidth
              required
              label={t("tech_name")}
              value={this.state.technical_name}
              onChange={({ target }) => this.setTechnicalName(target.value)}
            />
            <TextField
              fullWidth
              label={t("public_name")}
              value={this.state.public_name}
              onChange={({ target }) => this.setPublicName(target.value)}
            />
          </div>
          <TextField
            label={t("description")}
            fullWidth
            multiline
            rows={4}
            value={this.state.description}
            onChange={({ target }) => this.setDescription(target.value)}
          />
        </M.GeneralContainer>

        <Container>
          <Row cols={12}>
            <Col xs={6}>
              <C.ConfigTimeContainer style={{ marginBottom: "15px" }}>
                <M.TimeContainer>
                  <M.TimeTotal>{t("total_time")}:</M.TimeTotal>
                  <M.Box padding={"10px"}>
                    <M.TimeText>{this.state.time}</M.TimeText>
                  </M.Box>
                </M.TimeContainer>
                {/* <C.ConfigButton onClick={() => this.setShowConfigModal(true)}>
                  <ConfigIcon />
                </C.ConfigButton> */}
              </C.ConfigTimeContainer>

              <M.TabsContainer style={{ marginTop: 27 }}>
                <RadioTab
                  label={t("repeat")}
                  id="repeat"
                  value={"0"}
                  onChange={({ target }) => {
                    this.setTabActive(target.value);
                    this.setState({ select: 0 });
                  }}
                  checked={this.state.tab_active === "0"}
                />
                <RadioTab
                  label={t("time")}
                  id="time"
                  value={"1"}
                  onChange={({ target }) => {
                    this.setTabActive(target.value);
                    this.setState({ select: 1 });
                  }}
                  checked={this.state.tab_active === "1"}
                />
              </M.TabsContainer>

              <Row cols={12} gap={"30"}>
                <Col xs={6} style={{ paddingLeft: "0px" }}>
                  {this.state.tab_active === "0" ? (
                    <M.Box padding={"10px 15px 0px 15px"}>
                      <M.GridTitle>{t("series")}</M.GridTitle>
                      <M.SliderContainer margin="0">
                        <Counter
                          title="0 - 100"
                          min={0}
                          max={100}
                          data={this.state.series}
                          values={(value) =>
                            this.setState({ series: value }, () => this.getTotalTime())
                          }
                        />
                      </M.SliderContainer>
                    </M.Box>
                  ) : (
                    <M.Box padding={"10px 15px 0px 15px"}>
                      <M.GridTitle>{t("timeOfWork")}</M.GridTitle>
                      <M.SliderContainer margin="0">
                        <TimePicker
                          data={this.state.tiempo_trabajo}
                          seconds={true}
                          minutes={true}
                          values={(value) =>
                            this.setState({ tiempo_trabajo: value }, () => this.getTotalTime())
                          }
                        />
                      </M.SliderContainer>
                    </M.Box>
                  )}
                </Col>
                <Col xs={6} style={{ paddingRight: "0px" }}>
                  {this.state.tab_active === "0" && (
                    <M.Box padding={"10px 15px 0px 15px"}>
                      <M.GridTitle>{t("pauseSeries")}</M.GridTitle>
                      <M.SliderContainer margin="0">
                        <TimePicker
                          data={this.state.pausa_series}
                          seconds={true}
                          minutes={true}
                          values={(value) =>
                            this.setState({ pausa_series: value }, () => this.getTotalTime())
                          }
                        />
                      </M.SliderContainer>
                    </M.Box>
                  )}
                </Col>

                <Col xs={6} style={{ paddingLeft: "0px" }}>
                  {this.state.tab_active === "0" && (
                    <M.Box className="_marginY-7" padding={"10px 15px 0px 15px"}>
                      <M.GridTitle>{t("repeatSeries")}</M.GridTitle>
                      <M.SliderContainer margin="0">
                        <Counter
                          title="0 - 50"
                          min={0}
                          max={50}
                          data={this.state.repeticiones}
                          values={(value) =>
                            this.setState({ repeticiones: value }, () => this.getTotalTime())
                          }
                        />
                      </M.SliderContainer>
                    </M.Box>
                  )}
                </Col>

                <Col xs={6} style={{ paddingRight: "0px" }}>
                  {this.state.tab_active === "0" && (
                    <M.Box className="_marginY-7" padding={"10px 15px 0px 15px"}>
                      <M.GridTitle>{t("between_repeat")}</M.GridTitle>
                      <M.SliderContainer margin="0">
                        <TimePicker
                          data={this.state.pausa_repeticiones}
                          seconds={true}
                          minutes={true}
                          values={(value) =>
                            this.setState({ pausa_repeticiones: value }, () => this.getTotalTime())
                          }
                        />
                      </M.SliderContainer>
                    </M.Box>
                  )}
                </Col>
              </Row>
            </Col>

            <Col xs={6}>
              <M.Box padding={"10px 15px 0px 15px"} style={{ marginTop: 48 }}>
                <M.GridTitle>{t("work_intensity")} (0 - 100%)</M.GridTitle>
                <div className="box--tooltip">
                  <Tooltip
                    label={
                      <>
                        <strong>{t("by_FC")}</strong> {t("it_is_required_to_know_the_age")}
                      </>
                    }
                  />
                </div>

                <M.SliderIndicator>{this.state.work_intensity}</M.SliderIndicator>
                <M.SliderContainer>
                  <SliderMulticolor
                    title={t("work_intensity")}
                    min={0}
                    step={1}
                    max={100}
                    value={this.state.work_intensity}
                    onChange={(value) => this.setState({ work_intensity: value })}
                  />
                </M.SliderContainer>
              </M.Box>
            </Col>
          </Row>
        </Container>

        {/* <Modal
          isOpen={this.state.showConfigModal}
          buttons={
            <>
              <Button
                type="ghost"
                width="142px"
                label={t("cancel")}
                onClick={() => {
                  this.setFrecuencyTemp(this.state.frecuencia);
                  this.setPulseWidthTemp(this.state.ancho_pulso);
                  this.setShowConfigModal(false);
                }}
              />
              <Button width="142px" label={t("save")} onClick={this.handleConfigSubmit} />
            </>
          }
        >
          <C.ModalConfigContainer>
            <M.Box padding={"10px 15px 0px 15px"}>
              <M.GridTitle>{t("frecuency")} (HZ)</M.GridTitle>
              <M.SliderIndicator>{this.state.frecuenciaTemp || 0}</M.SliderIndicator>
              <M.SliderContainer>
                <Slider
                  step={1}
                  value={this.state.frecuenciaTemp}
                  min={5}
                  max={60}
                  onChange={(value) => this.setFrecuencyTemp(value)}
                />
              </M.SliderContainer>
            </M.Box>

            <M.Box padding={"10px 15px 0px 15px"}>
              <M.GridTitle>{t("pulse_width")} (US)</M.GridTitle>
              <M.SliderIndicator>{this.state.ancho_pulsoTemp || 0}</M.SliderIndicator>
              <M.SliderContainer>
                <Slider
                  step={1}
                  value={this.state.ancho_pulsoTemp}
                  min={20}
                  max={400}
                  onChange={(value) => this.setPulseWidthTemp(value)}
                />
              </M.SliderContainer>
            </M.Box>
          </C.ModalConfigContainer>
        </Modal> */}
      </M.ScreenContainer>
    );
  }
}

export default withTranslation("sessionStudio")(General);
