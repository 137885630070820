import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "../../assets/css/video.css";

import {
  Container,
  Row,
  Col,
  Tabs,
  SearchBar,
  Button,
  ButtonCircle,
} from "trainfes-components-library";
import CardLayoutPlatform from "../../components/CardLayoutPlatform";

import { FilterIcon } from "../../assets/icons";
import { HeaderStyled } from "./Library.styles";
import { tabsRender } from "./Library.helpers";
import FilterSidebar from "./components/FilterSidebar";

import { connect } from "../../store";

const INITIAL_STATE_SIDEBAR = {
  category: "",
  position: "",
  device: "",
};

const Library = () => {
  const { t } = useTranslation("library");

  // -> Mostrar Modal P.E
  const [showModal, setShowModal] = useState(false);

  // -> Mostrar Sidebar.
  const [showSidebar, setShowSidebar] = useState(false);

  // -> Tabs.
  const tabsObject = [
    { id: 0, title: t("videos") },
    { id: 1, title: t("images") },
    { id: 2, title: t("files") },
    { id: 3, title: t("electrode_positioning") },
  ];

  // -> Tab Activa.
  const [activeTab, setActiveTab] = useState(0);

  // -> Filtros sidebar.
  const [sidebarFilterState, setSidebarFilterState] = useState(INITIAL_STATE_SIDEBAR);

  // -> Barra Sidebar para filtrado de registros.
  const sidebarFilter = {
    title: (
      <>
        <FilterIcon /> {t("filters")}
      </>
    ),
    content: (
      <FilterSidebar
        activeTab={activeTab}
        handleClose={() => setShowSidebar(false)}
        sidebarFilterState={sidebarFilterState}
        setSidebarFilterState={setSidebarFilterState}
      />
    ),
  };

  // -> Filtro de busqueda electrodos.
  const [filter, setFilter] = useState("");

  const handleTabChange = () => {
    setFilter("");
    setSidebarFilterState(INITIAL_STATE_SIDEBAR);
  };

  return (
    <>
      <Container>
        <Row cols={12}>
          <Col xs={12}>
            <CardLayoutPlatform
              title={t("library")}
              showSidebar={showSidebar}
              handleCloseSidebar={() => setShowSidebar(false)}
              sidebarTitle={sidebarFilter.title}
              sidebarContent={sidebarFilter.content}
              floatButton={activeTab === 3 && <ButtonCircle onClick={() => setShowModal(true)} />}
              header={
                <>
                  <Tabs
                    data={tabsObject}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    onChange={handleTabChange}
                  />
                  <HeaderStyled>
                    <SearchBar
                      placeholder={t("search")}
                      onChange={(e) => setFilter(e.target.value)}
                      value={filter}
                    />
                    <Button
                      type="ghost"
                      icon={<FilterIcon />}
                      label={t("filters")}
                      width="102px"
                      onClick={() => setShowSidebar(true)}
                    />
                  </HeaderStyled>
                </>
              }
            >
              {tabsRender(activeTab, showModal, setShowModal, filter, sidebarFilterState)}
            </CardLayoutPlatform>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default connect(Library);

