import * as Action from "./actions";

export const initialState = {
  selectedId: "",
  idCurrent: null,

  // Fields
  name: "",
  desc: "",

  // Modals
  newDataModal: false,
  removeModal: false,

  // Filter
  filteredEvaluations: [],
  inputFilter: "",
  dropdownFilter: "name",
};

export const evaluationsReducer = (state, action) => {
  switch (action.type) {

    case Action.CLEAR_STATE:
      return {
        ...state,
        selectedId: "",
        idCurrent: null,

        // Fields
        name: "",
        desc: "",

        // Modals
        newDataModal: false,
        removeModal: false,

        // Filter
        filteredEvaluations: [],
        inputFilter: "",
        dropdownFilter: "name",
      };

    case Action.OPEN_MODAL:
      return {
        ...state,
        selectedId: action.payload || "",
        [action.modalType]: !state[action.modalType],
        varsData: action.payload || state.varsData,
      };

    case Action.CLOSE_MODAL:
      return {
        ...state,
        removeModal: false,
        newDataModal: false
      };

    case Action.SET_FIELDS:
      return {
          ...state,
          [action.payload.field]: action.payload.value,
      };

    default:
      return state;
  }
};
