import styled from "styled-components";
import { CrossIcon, UploadIcon } from "../../../../assets/icons";

export const Container = styled.div`
    display: flex;
`;

export const SelectorContainer = styled.div`
    background: #f3f6fd;
    border-radius: 10px;
    border: 1px dashed #ced4da;
    height: 200px;
    width: fit-content;

    &.__double {
        display: flex;
        margin-left: 8px;
    }

    & > .__one {
        width: 163px;

        & > label {
            margin: 0 auto;
        }
    }
`;

export const SelectorStyled = styled.div`
    position: relative;
    display: grid;
    grid-template-rows: 150px 1fr;
`;

export const UploadIconStyled = styled(UploadIcon)`
    place-self: center;
    cursor: pointer;
`;

export const Image = styled.div`
    background: url(${({ image }) => image});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
`;

export const ContentValidation = styled.div`
    background-color: white;
    width: 100%;
    height: 150px;
    font-weight: 400;
    font-size: 10px;
    text-align: center;
    letter-spacing: 1px;
    display: grid;
    place-items: center;
    cursor: pointer;

    border: 14px solid #f3f6fd;
    border-radius: 24px;
    color: #9aa2a8;
`;

export const CrossIconStyled = styled(CrossIcon)`
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;

    &:hover {
        opacity: 0.7;
    }
`;
