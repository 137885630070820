import React, { Component } from "react";

import TimePicker from "../../timePicker";
import Counter from "../../counter";
import { withTranslation } from "react-i18next";

import * as M from "../styles/Modos.styles";
import * as S from "../bike/bike.styles";
import * as C from "../styles/configbutton.styles";

import {
  Button,
  Slider,
  Container,
  Row,
  Col,
  RadioTabTwo,
  SliderMulticolor,
  Modal,
  Tooltip,
} from "trainfes-components-library";
import { TextField } from '@mui/material';

import { TableSpasms } from "../components/TableSpasms";
import { ConfigIcon, TableIcon } from "../../../assets/icons";

import { TableMuscles as WalkfesTable } from "../components/TableMuscles/rowing/Walkfes";
import { TableMuscles as FES4WearTable } from "../components/TableMuscles/rowing/FES4Wear";
import { TableMuscles as FES6AdvanceTable } from "../components/TableMuscles/rowing/FES6Advance";
import { TableMuscles as FES6PROTable } from "../components/TableMuscles/rowing/FES6PRO";
import { getSeconds, secondsToDate } from "../../../lib/modes.helpers";
import { MuscleSelector } from "../components/MuscleSelector";

import { musclesFES4, musclesFES6Advance, musclesFES6Pro, musclesWalkfes } from "./Rowing.helpers";
import { musclesFiltered } from "../helpers/musclesFilter.helper";

var self;

class Rowing extends Component {
  constructor() {
    super();
    this.state = {
      channels: [
        { ch: 1, name: "", index: 100 },
        { ch: 2, name: "", index: 100 },
        { ch: 3, name: "", index: 100 },
        { ch: 4, name: "", index: 100 },
        { ch: 5, name: "", index: 100 },
        { ch: 6, name: "", index: 100 },
      ],
      cpm: null,
      tiempo_trabajo: null,
      carga_trabajo: 0,
      deteccion_espasmos: 0,
      frecuencia_cardiaca_min: 60,
      frecuencia_cardiaca_max: 140,
      openTable: false,

      // Temp -> temporal para configuración de modal.
      frecuenciaTemp: 35,
      ancho_pulsoTemp: 200,

      frecuencia: 35,
      ancho_pulso: 200,

      time: "00:00:00",

      active_tab: "1",

      work_intensity: 0,
      public_name: "",
      technical_name: "",
      description: "",
      musclesSelected: [
        {
          ch: 1,
          img: null,
          showImage: false,
        },
        {
          ch: 2,
          img: null,
          showImage: false,
        },
        {
          ch: 3,
          img: null,
          showImage: false,
        },
        {
          ch: 4,
          img: null,
          showImage: false,
        },
        {
          ch: 5,
          img: null,
          showImage: false,
        },
        {
          ch: 6,
          img: null,
          showImage: false,
        },
      ],
    };
  }

  values() {
    if (this.props.values !== undefined) {
      this.props.values({
        ...this.state,
        channels: this.state.channels.map((e) => (e.name !== "" ? true : false)),
        config_channels: this.state.channels.filter((e) => e.name !== ""),
      });
    }
  }

  componentDidUpdate() {
    this.values();
  }

  componentDidMount() {
    self = this;

    this.setState({ musclesSelected: musclesWalkfes(this.props.t) });

    if (this.props.data.params && Object.keys(this.props.data.params).length !== 0) {
      const {
        carga_trabajo,
        tiempo_trabajo,
        rpm,
        deteccion_espasmos,
        frecuencia_cardiaca_min,
        frecuencia_cardiaca_max,
        musclesSelected,
        config_channels,
        work_intensity,
        technical_name,
        public_name,
        description,
        time,
        frecuencia,
        ancho_pulso,
        active_tab,
      } = this.props.data.params;
      this.setState(
        {
          carga_trabajo,
          tiempo_trabajo,
          rpm,
          deteccion_espasmos,
          frecuencia_cardiaca_min,
          frecuencia_cardiaca_max,
          config_channels,
          technical_name: technical_name || "",
          public_name: public_name || "",
          description: description || "",
          time: time || "00:00:00",
          work_intensity: work_intensity || 0,
        },
        () => this.getTotalTime()
      );

      if (active_tab) {
        this.setState({ active_tab });
      } else {
        this.setState({ musclesSelected: musclesWalkfes(this.props.t) });
      }

      if (frecuencia) {
        this.setState({ frecuencia, frecuenciaTemp: frecuencia });
      }

      if (ancho_pulso) {
        this.setState({ ancho_pulso, ancho_pulsoTemp: ancho_pulso });
      }

      if (musclesSelected) {
        if (active_tab === "1")
          return this.setState({ musclesSelected: musclesWalkfes(this.props.t) });
        if (active_tab === "2")
          return this.setState({ musclesSelected: musclesFES4(this.props.t) });
        if (active_tab === "3")
          return this.setState({ musclesSelected: musclesFES6Advance(this.props.t) });
        if (active_tab === "4")
          return this.setState({ musclesSelected: musclesFES6Pro(this.props.t) });

        return this.setState({ musclesSelected });
      }
    }
  }

  setMusclesSelected(data) {
    self.setState({ musclesSelected: data });
  }

  setConfigChannels(data) {
    self.setState({ config_channels: data });
  }

  setChannels(data) {
    self.setState({ channels: data });
  }

  setPublicName(e) {
    self.setState({ public_name: e });
  }

  setTechnicalName(e) {
    self.setState({ technical_name: e });
  }

  setDescription(e) {
    self.setState({ description: e });
  }

  getTotalTime() {
    self.setState({ time: secondsToDate(getSeconds(this.state.tiempo_trabajo)) });
  }

  setShowConfigModal(bool) {
    self.setState({ showConfigModal: bool });
  }

  setFrecuencyTemp(value) {
    self.setState({ frecuenciaTemp: value });
  }

  setPulseWidthTemp(value) {
    self.setState({ ancho_pulsoTemp: value });
  }

  handleConfigSubmit() {
    self.setState({
      ancho_pulso: self.state.ancho_pulsoTemp,
      frecuencia: self.state.frecuenciaTemp,
    });
    self.setShowConfigModal(false);
  }

  render() {
    const { t } = this.props;

    const tableRender = {
      1: <WalkfesTable t={t} />,
      2: <FES4WearTable t={t} />,
      3: <FES6AdvanceTable t={t} />,
      4: <FES6PROTable t={t} />,
    };

    return (
      <>
        <M.ScreenContainer>
          <Container>
            <Row cols={12} gap={"30"}>
              <Col xs={5}>
                <S.BikeForm>
                  <TextField
                    fullWidth
                    required
                    label={t("tech_name")}
                    value={this.state.technical_name}
                    onChange={({ target }) => this.setTechnicalName(target.value)}
                  />
                  <TextField
                    fullWidth
                    label={t("public_name")}
                    value={this.state.public_name}
                    onChange={({ target }) => this.setPublicName(target.value)}
                  />
                  <TextField
                    label={t("description")}
                    fullWidth
                    multiline
                    rows={4}
                    value={this.state.description}
                    onChange={({ target }) => this.setDescription(target.value)}
                  />
                </S.BikeForm>

                <S.TabsContainer>
                  <RadioTabTwo
                    label="Walkfes"
                    id="Walkfes"
                    value={"1"}
                    onChange={({ target }) => {
                      this.setState({ active_tab: target.value });
                      this.setState({ musclesSelected: musclesWalkfes(t) });
                    }}
                    checked={this.state.active_tab === "1"}
                  />
                  {/* <RadioTabTwo
                    label="FES-4 Wear"
                    id="FES-4 Wear"
                    value={"2"}
                    onChange={({ target }) => {
                      this.setState({ active_tab: target.value });
                      this.setState({ musclesSelected: musclesFES4(t) });
                    }}
                    checked={this.state.active_tab === "2"}
                  /> */}
                  <RadioTabTwo
                    label="FES-6 Advanced"
                    id="FES-6 Advanced"
                    value={"3"}
                    onChange={({ target }) => {
                      this.setState({ active_tab: target.value });
                      this.setState({ musclesSelected: musclesFES6Advance(t) });
                    }}
                    checked={this.state.active_tab === "3"}
                  />
                  {/* <RadioTabTwo
                    label="FES-6 Pro"
                    id="FES-6 Pro"
                    value={"4"}
                    onChange={({ target }) => {
                      this.setState({ active_tab: target.value });
                      this.setState({ musclesSelected: musclesFES6Pro(t) });
                    }}
                    checked={this.state.active_tab === "4"}
                  /> */}
                </S.TabsContainer>

                <S.TableContainer>{tableRender[this.state.active_tab]}</S.TableContainer>
              </Col>

              <Col xs={7}>
                <C.ConfigTimeContainer style={{ marginBottom: "15px" }}>
                  <M.TimeContainer>
                    <M.TimeTotal>{t("total_time")}:</M.TimeTotal>
                    <M.Box padding={"10px"}>
                      <M.TimeText>{this.state.time}</M.TimeText>
                    </M.Box>
                  </M.TimeContainer>
                  <C.ConfigButton onClick={() => this.setShowConfigModal(true)}>
                    <ConfigIcon />
                  </C.ConfigButton>
                </C.ConfigTimeContainer>

                <S.Params>
                  <M.Box padding={"10px 15px 0px 15px"}>
                    <M.GridTitle>{t("workload")}</M.GridTitle>
                    <M.SliderIndicator>{this.state.carga_trabajo}</M.SliderIndicator>
                    <M.SliderContainer>
                      <Slider
                        title={t("workload")}
                        min={0}
                        max={10}
                        defaultValue={this.state.carga_trabajo}
                        onChange={(value) => this.setState({ carga_trabajo: value })}
                      />
                    </M.SliderContainer>
                  </M.Box>

                  <M.Box padding={"10px 15px 0px 15px"}>
                    <M.GridTitle>{t("trainningsTime")}</M.GridTitle>
                    <M.SliderContainer margin="0">
                      <TimePicker
                        data={this.state.tiempo_trabajo}
                        hours={true}
                        minutes={true}
                        seconds={true}
                        values={(value) =>
                          this.setState({ tiempo_trabajo: value }, () => this.getTotalTime())
                        }
                      />
                    </M.SliderContainer>
                  </M.Box>

                  <M.Box padding={"10px 15px 0px 15px"}>
                    <M.GridTitle>{t("rpm")}</M.GridTitle>
                    <M.SliderContainer margin="0">
                      <Counter
                        data={this.state.rpm}
                        title="0 - 100"
                        min={0}
                        max={100}
                        values={(value) => this.setState({ rpm: value })}
                      />
                    </M.SliderContainer>
                  </M.Box>

                  <M.Box padding={"10px 15px 0px 15px"}>
                    <M.GridTitle>{t("spasm")}</M.GridTitle>

                    <div
                      className="box--icon"
                      onClick={() => {
                        this.setState({ openTable: true });
                      }}
                    >
                      <TableIcon />
                    </div>

                    <M.SliderIndicator>{this.state.deteccion_espasmos}</M.SliderIndicator>
                    <M.SliderContainer>
                      <Slider
                        title={t("spasm")}
                        min={0}
                        max={5}
                        value={this.state.deteccion_espasmos}
                        onChange={(value) => this.setState({ deteccion_espasmos: value })}
                      />
                    </M.SliderContainer>
                  </M.Box>

                  <M.Box padding={"10px 15px 0px 15px"}>
                    <M.GridTitle>{t("work_intensity")} (0 - 100%)</M.GridTitle>

                    <div className="box--tooltip">
                      <Tooltip
                        label={
                          <>
                            <strong>{t("by_FC")}</strong> {t("it_is_required_to_know_the_age")}
                          </>
                        }
                      />
                    </div>

                    <M.SliderIndicator>{this.state.work_intensity}</M.SliderIndicator>
                    <M.SliderContainer>
                      <SliderMulticolor
                        title={t("spasm")}
                        min={0}
                        step={1}
                        max={100}
                        value={this.state.work_intensity}
                        onChange={(value) => this.setState({ work_intensity: value })}
                      />
                    </M.SliderContainer>
                  </M.Box>
                </S.Params>
                <S.MusclesContainer>
                  <Row cols={12}>
                    <Col xs={6}>
                      <M.Title>{t("select_muscles")}</M.Title>

                      {musclesFiltered(this.state.active_tab, this.state.musclesSelected).map(
                        (channel, i) => (
                          <MuscleSelector
                            noModify
                            key={i}
                            index={i}
                            data={channel}
                            musclesSelected={this.state.musclesSelected}
                            setMusclesSelected={this.setMusclesSelected}
                            configChannels={this.state.config_channels || null}
                            setConfigChannels={this.setConfigChannels}
                            channels={this.state.channels}
                            setChannels={this.setChannels}
                          />
                        )
                      )}
                    </Col>
                    <Col xs={6}></Col>
                  </Row>
                </S.MusclesContainer>
              </Col>
            </Row>
          </Container>

          <Modal
            title={t("tableSpasms.title")}
            isOpen={this.state.openTable}
            width="fit-content"
            handleClose={() => this.setState({ openTable: false })}
            buttons={
              <Button label={t("close")} onClick={() => this.setState({ openTable: false })} />
            }
          >
            <TableSpasms t={t} />
          </Modal>

          <Modal
            isOpen={this.state.showConfigModal}
            buttons={
              <>
                <Button
                  type="ghost"
                  width="142px"
                  label={t("cancel")}
                  onClick={() => {
                    this.setFrecuencyTemp(this.state.frecuencia);
                    this.setPulseWidthTemp(this.state.ancho_pulso);
                    this.setShowConfigModal(false);
                  }}
                />
                <Button width="142px" label={t("save")} onClick={this.handleConfigSubmit} />
              </>
            }
          >
            <C.ModalConfigContainer>
              <M.Box padding={"10px 15px 0px 15px"}>
                <M.GridTitle>{t("frecuency")} (HZ)</M.GridTitle>
                <M.SliderIndicator>{this.state.frecuenciaTemp || 0}</M.SliderIndicator>
                <M.SliderContainer>
                  <Slider
                    step={1}
                    value={this.state.frecuenciaTemp}
                    min={5}
                    max={60}
                    onChange={(value) => this.setFrecuencyTemp(value)}
                  />
                </M.SliderContainer>
              </M.Box>

              <M.Box padding={"10px 15px 0px 15px"}>
                <M.GridTitle>{t("pulse_width")} (US)</M.GridTitle>
                <M.SliderIndicator>{this.state.ancho_pulsoTemp || 0}</M.SliderIndicator>
                <M.SliderContainer>
                  <Slider
                    step={1}
                    value={this.state.ancho_pulsoTemp}
                    min={20}
                    max={400}
                    onChange={(value) => this.setPulseWidthTemp(value)}
                  />
                </M.SliderContainer>
              </M.Box>
            </C.ModalConfigContainer>
          </Modal>
        </M.ScreenContainer>
      </>
    );
  }
}

export default withTranslation("sessionStudio")(Rowing);
