import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import API from "../services/api";

const api = new API();

export const useGetEvaluations = () => {
  // State para utilizar en los inputs tipo dropdown.
  const [evaluationsDropdown, setEvaluationsDropdown] = useState([]);

  const EvaluationsQuery = useQuery({
    queryKey: ["Evaluations"],
    queryFn: () =>
      api.getEvaluations().then((res) => {
        setEvaluationsDropdown(
          [...JSON.parse(JSON.stringify(res.data))].map((e) => {
            e["cat"] = e.cat._id;
            e["label"] = e.name;
            e["value"] = e.name;
            return e;
          })
        );

        return res.data;
      }),
    refetchOnWindowFocus: false,
  });

  const { mutate: addEvaluation, isLoading: postLoading } = useMutation(
    (newCategoriesEvaluations) => {
      return api.createEvaluation(newCategoriesEvaluations);
    },
    {
      onSuccess: async () => EvaluationsQuery.refetch(),
    }
  );

  const { mutate: updateEvaluation, isLoading: updateLoading } = useMutation(
    (newCategoriesEvaluations) => {
      return api.updateEvaluation(newCategoriesEvaluations);
    },
    {
      onSuccess: async () => EvaluationsQuery.refetch(),
    }
  );

  const { mutate: deleteEvaluation, isLoading: deleteLoading } = useMutation(
    (id) => {
      return api.deleteEvaluation(id);
    },
    {
      onSuccess: async () => EvaluationsQuery.refetch(),
    }
  );

  const customLoading = EvaluationsQuery.isLoading || postLoading || updateLoading || deleteLoading;

  return {
    evaluationsDropdown,
    EvaluationsQuery,
    addEvaluation,
    updateEvaluation,
    deleteEvaluation,
    customLoading,
  };
};
