import React, { Component } from 'react';
import { Grid, TextField } from '@material-ui/core';
import TimePicker from '../timePicker';
import Counter from '../counter';
import UploadFiles from '../uploadFiles/index';
import { withTranslation } from 'react-i18next'


class Flexibility extends Component {

    constructor() {
        super();
        this.state = {
            name: "",
            description: "",
            icon: "icon-alert",
            files: [],
            video: "",
            type: true,
            repeticiones: null,
            series: null,
            pausa_repeticiones: null,
            pausa_series: null,
            tiempo_trabajo: null,
            channels: [],
            config_channels: []
        }

        this.currentName = React.createRef();
    }

    values() {
        if (this.props.values !== undefined) {
            this.props.values(this.state);
        }
    }

    componentDidUpdate() {
        this.values();
    }

    componentDidMount() {
        if (this.props.data.params && Object.keys(this.props.data.params).length !== 0) {
            const { name, series, pausa_series, pausa_repeticiones, repeticiones, files, description } = this.props.data.params;
            this.setState({
                name,
                description,
                files,
                repeticiones,
                series,
                pausa_repeticiones,
                pausa_series
            })
        }
    }

    render() {
        const {t} = this.props;
        return (
            <Grid container direction="row" justifyContent="center">
                <Grid item xs={12} md={5} className="pl-4">
                    <p className="title-calendar mb-2">{t('name')}</p>
                    <Grid item xs={12}>
                        <TextField
                            className="w-100"
                            id="outlined-margin-dense1"
                            margin="dense"
                            variant="outlined"
                            value={this.state.name}
                            onChange={value => this.setState({ name: value.target.value })}
                        />
                    </Grid>
                    <p className="title-calendar mt-3 mb-2">{t('description')}</p>
                    <Grid item xs={12}>
                        <TextField
                            className="w-100"
                            id="outlined-margin-dense1"
                            margin="dense"
                            multiline
                            rows={7}
                            variant="outlined"
                            value={this.state.description}
                            onChange={value => this.setState({ description: value.target.value })}
                        />
                    </Grid>
                    <p className="title-calendar my-3">{t('adjustments')}</p>
                    <Grid container direction="row">
                        <Grid item xs={4} className="pr-1">
                            <div className="border-tf py-2 px-3">
                                <p className="fz-10 color-sub-text m-0">{t('series')}</p>
                                <Counter data={this.state.series} title="0 - 100" min={0} max={100} values={value => this.setState({ series: value })} />
                            </div>
                        </Grid>
                        <Grid item xs={8} className="pl-1">
                            <div className="border-tf py-2 px-3">
                                <p className="fz-10 color-sub-text m-0">{t('pauseSeries')}</p>
                                <TimePicker data={this.state.pausa_series} seconds={true} minutes={true} values={value => this.setState({ pausa_series: value })} />
                            </div>
                        </Grid>
                        <Grid item xs={4} className="pr-1 mt-2">
                            <div className="border-tf py-2 px-3">
                                <p className="fz-10 color-sub-text m-0">{t('repeat')}</p>
                                <Counter data={this.state.repeticiones} title="0 - 50" min={0} max={50} values={value => this.setState({ repeticiones: value })} />
                            </div>
                        </Grid>
                        <Grid item xs={8} className="pl-1 mt-2">
                            <div className="border-tf py-2 px-3">
                                <p className="fz-10 color-sub-text m-0">{t('between_repeat')}</p>
                                <TimePicker data={this.state.pausa_repeticiones} seconds={true} minutes={true} values={value => this.setState({ pausa_repeticiones: value })} />
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={7} className="px-4">
                    <UploadFiles value={this.state.files} onChange={f => this.setState({ files: f })} />
                </Grid>
            </Grid>
        )
    }
}


export default withTranslation('sessionStudio')(Flexibility);