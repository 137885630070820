export const musclesWalkfes = (t) => {
  return [
    {
      ch: 1,
      img: {
        name: t("quadriceps"),
        category: "60ec7d24449b17e986856ce8",
        position: "right",
        image:
          "https://dashboard.trainfes-cloud.com/assets/Remo/cuadriceps.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Remo/cuadriceps.png",
      },
      showImage: true,
    },
    {
      ch: 2,
      img: {
        name: t("quadriceps"),
        category: "60ec7d24449b17e986856ce8",
        position: "left",
        image:
          "https://dashboard.trainfes-cloud.com/assets/Remo/cuadriceps.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Remo/cuadriceps.png",
      },
      showImage: true,
    },
    {
      ch: 3,
      img: null,
      showImage: false,
    },
    {
      ch: 4,
      img: null,
      showImage: false,
    },
    {
      ch: 5,
      img: null,
      showImage: false,
    },
    {
      ch: 6,
      img: null,
      showImage: false,
    },
  ];
};

export const channelsWalkfes = (t) => {
  return [
    { ch: 1, index: 100, keyId: t("quadriceps"), name: t("quadriceps") },
    { ch: 2, index: 100, keyId: t("quadriceps"), name: t("quadriceps") },
  ];
};

export const musclesFES4 = (t) => {
  return [
    {
      ch: 1,
      img: {
        name: t("quadriceps"),
        category: "60ec7d24449b17e986856ce8",
        position: "right",
        image:
          "https://dashboard.trainfes-cloud.com/assets/Remo/cuadriceps.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Remo/cuadriceps.png",
      },
      showImage: true,
    },
    {
      ch: 2,
      img: {
        name: t("hamstring"),
        category: "60ec7d24449b17e986856ce8",
        position: "right",
        image:
          "https://dashboard.trainfes-cloud.com/assets/Remo/isquiotibiales.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Remo/isquiotibiales.png",
      },
      showImage: true,
    },
    {
      ch: 3,
      img: {
        name: t("hamstring"),
        category: "60ec7d24449b17e986856ce8",
        position: "left",
        image:
          "https://dashboard.trainfes-cloud.com/assets/Remo/isquiotibiales.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Remo/isquiotibiales.png",
      },
      showImage: true,
    },
    {
      ch: 4,
      img: {
        name: t("quadriceps"),
        category: "60ec7d24449b17e986856ce8",
        position: "left",
        image:
          "https://dashboard.trainfes-cloud.com/assets/Remo/cuadriceps.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Remo/cuadriceps.png",
      },
      showImage: true,
    },
    {
      ch: 5,
      img: null,
      showImage: false,
    },
    {
      ch: 6,
      img: null,
      showImage: false,
    },
  ];
};

export const channelsFES4 = (t) => {
  return [
    { ch: 1, index: 100, keyId: t("quadriceps"), name: t("quadriceps") },
    { ch: 2, index: 100, keyId: t("hamstring"), name: t("hamstring") },
    { ch: 3, index: 100, keyId: t("hamstring"), name: t("hamstring") },
    { ch: 4, index: 100, keyId: t("quadriceps"), name: t("hamstring") },
  ];
};

export const musclesFES6Advance = (t) => {
  return [
    {
      ch: 1,
      img: {
        name: t("quadriceps"),
        category: "60ec7d24449b17e986856ce8",
        position: "right",
        image:
          "https://dashboard.trainfes-cloud.com/assets/Remo/cuadriceps.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Remo/cuadriceps.png",
      },
      showImage: true,
    },
    {
      ch: 2,
      img: {
        name: t("quadriceps"),
        category: "60ec7d24449b17e986856ce8",
        position: "left",
        image:
          "https://dashboard.trainfes-cloud.com/assets/Remo/cuadriceps.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Remo/cuadriceps.png",
      },
      showImage: true,
    },
    {
      ch: 3,
      img: {
        name: t("hamstring"),
        category: "60ec7d24449b17e986856ce8",
        position: "right",
        image:
          "https://dashboard.trainfes-cloud.com/assets/Remo/isquiotibiales.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Remo/isquiotibiales.png",
      },
      showImage: true,
    },
    {
      ch: 4,
      img: {
        name: t("hamstring"),
        category: "60ec7d24449b17e986856ce8",
        position: "left",
        image:
          "https://dashboard.trainfes-cloud.com/assets/Remo/isquiotibiales.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Remo/isquiotibiales.png",
      },
      showImage: true,
    },
    {
      ch: 5,
      img: {
        name: t("tibialis_anterior"),
        category: "60ec7d24449b17e986856ce8",
        position: "right",
        image:
          "https://dashboard.trainfes-cloud.com/assets/Remo/tibial-anterior.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Remo/tibial-anterior.png",
      },
      showImage: true,
    },
    {
      ch: 6,
      img: {
        name: t("tibialis_anterior"),
        category: "60ec7d24449b17e986856ce8",
        position: "left",
        image:
          "https://dashboard.trainfes-cloud.com/assets/Remo/tibial-anterior.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Remo/tibial-anterior.png",
      },
      showImage: true,
    },
  ];
};

export const channelsFES6Advance = (t) => {
  return [
    { ch: 1, index: 100, keyId: t("quadriceps"), name: t("quadriceps") },
    { ch: 2, index: 100, keyId: t("quadriceps"), name: t("quadriceps") },
    { ch: 3, index: 100, keyId: t("hamstring"), name: t("hamstring") },
    { ch: 4, index: 100, keyId: t("hamstring"), name: t("hamstring") },
    {
      ch: 5,
      index: 100,
      keyId: t("tibialis_anterior"),
      name: t("tibialis_anterior"),
    },
    {
      ch: 6,
      index: 100,
      keyId: t("tibialis_anterior"),
      name: t("tibialis_anterior"),
    },
  ];
};

export const musclesFES6Pro = (t) => {
  return [
    {
      ch: 1,
      img: {
        name: t("quadriceps"),
        category: "60ec7d24449b17e986856ce8",
        position: "right",
        image:
          "https://dashboard.trainfes-cloud.com/assets/Remo/cuadriceps.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Remo/cuadriceps.png",
      },
      showImage: true,
    },
    {
      ch: 2,
      img: {
        name: t("hamstring"),
        category: "60ec7d24449b17e986856ce8",
        position: "right",
        image:
          "https://dashboard.trainfes-cloud.com/assets/Remo/isquiotibiales.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Remo/isquiotibiales.png",
      },
      showImage: true,
    },
    {
      ch: 3,
      img: {
        name: t("tibialis_anterior"),
        category: "60ec7d24449b17e986856ce8",
        position: "right",
        image:
          "https://dashboard.trainfes-cloud.com/assets/Remo/tibial-anterior.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Remo/tibial-anterior.png",
      },
      showImage: true,
    },
    {
      ch: 4,
      img: {
        name: t("quadriceps"),
        category: "60ec7d24449b17e986856ce8",
        position: "left",
        image:
          "https://dashboard.trainfes-cloud.com/assets/Remo/cuadriceps.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Remo/cuadriceps.png",
      },
      showImage: true,
    },
    {
      ch: 5,
      img: {
        name: t("hamstring"),
        category: "60ec7d24449b17e986856ce8",
        position: "left",
        image:
          "https://dashboard.trainfes-cloud.com/assets/Remo/isquiotibiales.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Remo/isquiotibiales.png",
      },
      showImage: true,
    },
    {
      ch: 6,
      img: {
        name: t("tibialis_anterior"),
        category: "60ec7d24449b17e986856ce8",
        position: "left",
        image:
          "https://dashboard.trainfes-cloud.com/assets/Remo/tibial-anterior.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Remo/tibial-anterior.png",
      },
      showImage: true,
    },
  ];
};

export const channelsFES6Pro = (t) => {
  return [
    { ch: 1, index: 100, keyId: t("quadriceps"), name: t("quadriceps") },
    { ch: 2, index: 100, keyId: t("hamstring"), name: t("hamstring") },
    {
      ch: 3,
      index: 100,
      keyId: t("tibialis_anterior"),
      name: t("tibialis_anterior"),
    },
    { ch: 4, index: 100, keyId: t("quadriceps"), name: t("quadriceps") },
    { ch: 5, index: 100, keyId: t("hamstring"), name: t("hamstring") },
    {
      ch: 6,
      index: 100,
      keyId: t("tibialis_anterior"),
      name: t("tibialis_anterior"),
    },
  ];
};
