import React from "react";
import { Video, Images, Records, Electrodes } from "./tabs";

export const tabsRender = (key, showModal, setShowModal, filter, sidebarFilterState) => {
  switch (key) {
    case 0:
      return <Video filter={filter} sidebarFilterState={sidebarFilterState} />;

    case 1:
      return <Images filter={filter} sidebarFilterState={sidebarFilterState} />;

    case 2:
      return <Records filter={filter} sidebarFilterState={sidebarFilterState} />;

    case 3:
      return (
        <Electrodes
          showModal={showModal}
          setShowModal={setShowModal}
          filter={filter}
          sidebarFilterState={sidebarFilterState}
        />
      );

    default:
      break;
  }
};
