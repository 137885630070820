import React from 'react';
import { Doughnut } from 'react-chartjs-2';

export const ChartDonut = (props) => {
    return (
        <div className="chart" style={{ position: "relative", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Doughnut
                width={170}
                height={170}
                data={{
                    labels: props.labels,
                    datasets: [{
                        data: props.data,
                        backgroundColor: ["#49C39E", "#4666DD", "#FF7F23", "#FD0202"],
                        fill: ['rgba(30, 202, 78, 1)'],
                        pointBackgroundColor: ['rgba(41, 51, 138, 1)', 'rgba(41, 51, 138, 1)'],
                        lineTension: 0.1,
                        borderWidth: 1,
                        hoverBackgroundColor: ["#49C39E", "#4666DD", "#FF7F23", "#FD0202"],
                    }]
                }}
                options={{
                    animation: { animateRotate: false, animateScale: false },
                    responsive: false,
                    cutout: 65,
                    plugins: {
                        legend: { display: false, position: 'top' },
                        tooltip: {
                            callbacks: {
                                label: (context) => ` ${context.label}: ${context.parsed}%`
                            }
                        }
                    },
                }}
            />
            {props.enableValue ? <div style={{ position: "absolute", marginTop: 5, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                <p style={{ fontSize: 16, margin: 0, fontWeight: "bold", color: "#281B65", lineHeight: "150%" }}><span style={{ fontSize: 32 }}>{props.value}</span>%</p>
            </div> : null}
        </div>
    )
}

ChartDonut.defaultProps = {
    labels: ['Realizadas', 'Próximas', 'Por recuperar', 'Perdidas'],
    data: [],
    value: 20,
    enableValue: true
}
