import React from "react";
import Select from "react-select";
import "./style.css";

import { filterValidation } from '../../lib/textValidation';

const orderlessFilter = (candidate, input) => {
  if (!input) {
    return true;
  }
  const filterWords = filterValidation(input).split(/\s+/);
  return filterWords.every((word) => filterValidation(candidate.label).includes(word))
}

const colourStyles = {
  control: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: "white",
    "&:hover": { borderColor: "#281B65" },
    borderColor: isFocused ? "#281B65" : "rgba(0, 0, 0, 0.23)",
    boxShadow: "none",
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? "#ccc"
        : isFocused
        ? "#4632A420"
        : isSelected
        ? "#281B65"
        : "#FAFAFA",
      color: isFocused ? "#3A3A3A" : isSelected ? "#FFF" : "#3A3A3A",
      cursor: isDisabled ? "not-allowed" : "default",
    };
  },
  multiValue: (styles) => {
    return {
      ...styles,
      padding: "2px 10px",
      backgroundColor: "#E5E7FA",
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: "#6979F8",
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ":hover": {
      backgroundColor: "transparent",
      color: "#281B65",
      cursor: "pointer",
    },
  }),
  menuPortal: (styles) => ({
    ...styles,
    zIndex: 9999999999999,
  }),
};

const DropdownSelect = ({
  options = [],
  placeholder = "",
  value = { value: "", label: "" },
  maxMenuHeight = 300,
  menuPlacement = "auto",
  menuPortalTarget = document.getElementById("root"),
  className = "",
  isMulti = false,
  disabled = false,
  onChange = () => {}
}) => {

  return (
    <div className="dropdown-select-tf">
      <Select
        isMulti={isMulti}
        className={className}
        styles={colourStyles}
        options={options}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        maxMenuHeight={maxMenuHeight}
        menuPlacement={menuPlacement}
        menuPortalTarget={menuPortalTarget}
        isDisabled={disabled}
        filterOption={orderlessFilter}
      />
    </div>
  );
};

export default DropdownSelect;
