import React from "react";
import * as S from "./Expand.styles";

import { Container } from "trainfes-components-library";
import { RowComponent } from "./RowComponent";

export const Expand = ({ data, t }) => {
  return (
    <S.ExpandStyled>
      <Container>
        <S.RowStyled cols={12}>
          <S.ColStyled xs={3}>
            <S.HeaderCol>{t("training")}</S.HeaderCol>
          </S.ColStyled>
          <S.ColStyled xs={3}>
            <S.HeaderCol className="align-center">{t("time")}</S.HeaderCol>
          </S.ColStyled>
          <S.ColStyled xs={3}>
            <S.HeaderCol className="align-center">{t("session")}</S.HeaderCol>
          </S.ColStyled>
          <S.ColStyled xs={3}></S.ColStyled>
        </S.RowStyled>

        {data.map((item, i) => (
          <RowComponent key={i} data={item} />
        ))}
      </Container>
    </S.ExpandStyled>
  );
};
