import React, { useRef } from "react";
import { ButtonUpload } from "trainfes-components-library";

import * as S from "./Selector.styles";

export const Selector = ({ positioning, setPositioning, t, notify }) => {
  const normalFileRef = useRef(null);
  const leftFileRef = useRef(null);
  const rightFileRef = useRef(null);
  const { left, normal, right } = positioning;

  const imageValidation = (image) => {
    if (!image) return null;
    if (typeof image === "string") return image;
    return URL.createObjectURL(image);
  };

  const validateAndSetImage = (file, position) => {
    if (!file.type.startsWith('image/')) {
        notify({
            type: "error",
            title: t("notify.error"),
            text: t("notify.invalid_image_format"),
        });
        return;
    }
    setPositioning(prevPositioning => ({ ...prevPositioning, [position]: file }));
  };

  const handleUpload = (position) => (event) => {
    const file = event.target.files[0];
    validateAndSetImage(file, position);
  };

  const handleClick = (fileRef) => () => {
    fileRef.current.click();
  };

  return (
    <S.Container>
      <S.SelectorContainer>
        <S.SelectorStyled className="__one">
          {normal && normal !== "null" ? (
            <>
              <S.Image image={() => imageValidation(normal)} />
              <S.CrossIconStyled
                onClick={() =>
                  setPositioning({
                    ...positioning,
                    normal: null,
                  })
                }
              />
            </>
          ) : null}
          {(!normal || normal === "null") && <S.UploadIconStyled onClick={handleClick(normalFileRef)} />}

          <input
            type="file"
            accept="image/jpeg, image/png, image/gif"
            style={{ display: 'none' }}
            ref={normalFileRef}
            onChange={handleUpload('normal')}
          />

          <ButtonUpload
            accept="image/jpeg, image/png, image/gif"
            label={t("normal_position")}
            name="Posición General"
            id="Posición General"
            onChange={(file) => validateAndSetImage(file, 'normal')}
          />
        </S.SelectorStyled>
      </S.SelectorContainer>

      <S.SelectorContainer className="__double">
        <S.SelectorStyled className="__one">
          {left && left !== "null" ? (
            <>
              <S.Image image={() => imageValidation(left)} />
              <S.CrossIconStyled
                onClick={() =>
                  setPositioning({
                    ...positioning,
                    left: null,
                  })
                }
              />
            </>
          ) : null}

          {(!left || left === "null") && right && right !== "null" ? (
            <S.ContentValidation onClick={handleClick(leftFileRef)}>{t("add_left_position")}</S.ContentValidation>
          ) : null}

          {(!left || left === "null") && (!right || right === "null") ? (
            <S.UploadIconStyled onClick={handleClick(leftFileRef)} />
          ) : null}

          <input
            type="file"
            accept="image/jpeg, image/png, image/gif"
            style={{ display: 'none' }}
            ref={leftFileRef}
            onChange={handleUpload('left')}
          />

          <ButtonUpload
            accept="image/jpeg, image/png, image/gif"
            label={t("left_position")}
            name="Posición Izquierdo"
            id="Posición Izquierdo"
            onChange={(file) => validateAndSetImage(file, 'left')}
          />
        </S.SelectorStyled>

        <S.SelectorStyled className="__one">
          {right && right !== "null" && (
            <>
              <S.Image image={() => imageValidation(right)} />
              <S.CrossIconStyled
                onClick={() =>
                  setPositioning({
                    ...positioning,
                    right: null,
                  })
                }
              />
            </>
          )}

          {left && left !== "null" && (!right || right === "null") ? (
            <S.ContentValidation onClick={handleClick(rightFileRef)}>{t("add_right_position")}</S.ContentValidation>
          ) : null}

          {(!left || left === "null") && (!right || right === "null") ? (
            <S.UploadIconStyled onClick={handleClick(rightFileRef)} />
          ) : null}

          <input
            type="file"
            accept="image/jpeg, image/png, image/gif"
            style={{ display: 'none' }}
            ref={rightFileRef}
            onChange={handleUpload('right')}
          />

          <ButtonUpload
            accept="image/jpeg, image/png, image/gif"
            label={t("right_position")}
            name="Posición Derecho"
            id="Posición Derecho"
            onChange={(file) => validateAndSetImage(file, 'right')}
          />
        </S.SelectorStyled>
      </S.SelectorContainer>
    </S.Container>
  );
};
