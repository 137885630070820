import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import moment from "moment";

i18n.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: "es",
		interpolation: {
			formatSeparator: ",",
			format: function (value, format) {
				if (format === "uppercase") return value.toUpperCase();
				if (value instanceof Date) return moment(value).format(format);
				return value;
			},
		},
		react: { useSuspense: true },
	});

i18n.on("languageChanged", function (lng) {
	moment.locale(lng);
});
