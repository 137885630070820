import React from "react";
import styled from "styled-components";
import { ThunderImg } from "./assets";

export const RolBlockedScreen = () => {
  return (
    <Container>
      <div>
        <Img />
        <Text1>Ups!</Text1>
        <Text2>No tiene acceso para ver a esta información</Text2>
        <Text3>Debes cambiar de rol para poder ingresar</Text3>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  place-items: center;
  height: calc(100vh - 135px);
  padding: 10px 0;
`;

const Img = styled(ThunderImg)`
  margin: auto;
  width: 100%;
`;

const Text1 = styled.h4`
  font-weight: 500;
  font-size: 32px;
  text-align: center;
  color: #281b65;
  margin: 18px 0 13px 0;
`;

const TextCommon = styled.p`
  margin: 0;
  text-align: center;
  font-weight: 400;
`;

const Text2 = styled(TextCommon)`
  font-size: 18px;
  color: #281b65;
`;

const Text3 = styled(TextCommon)`
  font-size: 14px;
  color: #9aa2a8;
`;
