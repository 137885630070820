import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { withTranslation } from 'react-i18next';


class TableBlocks extends React.Component {

    constructor() {
        super();
        this.state = {
            data: [],
            open: false,
            tiempo: "",
            duracion: "",
            fase: "",
            velocidad: "",
            inclinacion: ""
        }

    }

    handleClose() {
        this.setState({ open: false })
    }

    remove(id) {
        if (this.props.remove !== undefined) this.props.remove(id);
    }

    render() {
        const { t } = this.props;
        return (
            <TableContainer className="mt-4 mb-5 scroll-table-blocks">
                <Table>
                    <TableHead style={{background:"rgba(192, 181, 242, 0.25)"}}>
                        <TableRow align="center">
                            <TableCell>{'Pos'}</TableCell>
                            <TableCell>{t('table.time')} (mm:ss)</TableCell>
                            <TableCell>{t('table.phase')}</TableCell>
                            <TableCell>{t('table.velocity')} (km/h)</TableCell>
                            <TableCell>{t('table.inclination')} (%)</TableCell>
                            <TableCell>{t('table.action')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody style={{background:"#F8F8F8"}}>
                        {this.props.data !== undefined ? this.props.data.map( (e,i) => {
                            return (
                                <TableRow align="center" key={i}>
                                    <TableCell>{i+1}</TableCell>
                                    <TableCell>{e.tiempo}</TableCell>
                                    <TableCell>{e.fase}</TableCell>
                                    <TableCell>{e.velocidad}</TableCell>
                                    <TableCell>{e.inclinacion}</TableCell>
                                    <TableCell className='d-flex align-items-center justify-content-center'>{e.block ? null : <button className="btn-carrusel d-flex align-items-center justify-content-center"><span className="icon-trash pointer" onClick={() => this.remove(e.pos)}></span></button>} </TableCell>
                                </TableRow>
                            )
                        }) : null}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }
}

export default withTranslation('sessionStudio')(TableBlocks);