import styled from "styled-components";

export const VerticalLine = styled.div`
  width: 1px;
  height: 100%;
  border-radius: 50%;
  background-color: #cfdbff;
  margin: auto;
  /* margin-top: 5px; */
`;

export const IconMenuItem = styled.span`
  width: 80px;

  font-size: 22px;
  outline: none;
  text-decoration: none;
  border: 0px;
`;

export const UlStyled = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 30px;
  list-style: none;
  flex-direction: column;
  margin-top: 3rem;
`;

export const ScrollBox = styled.div`
  height: 90%;
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;

  &:hover {
    height: 90%;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }
`;

export const LogoStyled = styled.div`
  background: #281b64 !important;
  position: relative;

  & > .logo--text_version {
    position: absolute;
    right: 10px;
    bottom: 10px;

    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.850962px;

    color: #ffffff;
  }

  & > a > img {
    height: auto;
    max-width: 100%;

    display: block;
    margin: 0 auto;
  }
`;

export const NavStyled = styled.nav`
  display: block;
  position: fixed !important;
  color: #281b65;

  /* main-menu */
  &:hover,
  &.expanded {
    width: 250px;
    overflow: hidden;
    opacity: 1;
    transition-delay: 0.75s;

    .logo--text_version {
      opacity: 1;
      transition: opacity 0.2s linear;
      transition-delay: 1s;
    }
  }

  .logo--text_version {
    opacity: 0;
  }

  -webkit-transform: translateZ(0) scale(1, 1);
  -webkit-transition: width 0.2s linear;
  background: #fff;
  bottom: 0;
  height: 100%;
  left: 0;
  opacity: 1;
  overflow: hidden;
  position: absolute;
  top: 0;
  transition: width 0.2s linear;
  width: 74px;

  & > ul {
    margin: 7px 0;
  }

  & li {
    position: relative;
    display: block;
    width: 255px;
  }

  & li > a {
    position: relative;
    width: 255px;
    display: table;
    border-collapse: collapse;
    border-spacing: 0;
    color: #281b65;
    font-size: 14px;
    text-decoration: none;
    -webkit-transform: translateZ(0) scale(1, 1);
    -webkit-transition: all 0.14s linear;
    transition: all 0.14s linear;
    /* border-top: 1px solid #fdfdfd; */
  }

  & .nav-icon {
    position: relative;
    display: table-cell;
    width: 75px;
    height: 36px;
    text-align: center;
    vertical-align: middle;
    font-size: 18px;
  }

  & .nav-text {
    position: relative;
    display: table-cell;
    vertical-align: middle;
    width: 190px !important;
    font-size: 14px;
    color: #281b65;
  }

  & .fb-like {
    left: 180px;
    position: absolute;
    top: 15px;
  }

  & > ul.logout {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  & li:hover > a,
  & li:active {
    color: #281b65;
    background-color: #e3eaff;
  }
`;

export const DropdownMenuBody = styled.div``;

export const DropdownMenuItems = styled.div`
  cursor: pointer;
  background-color: #f3f6ff;

  
  .line-dropdown {
    top: 0;
    bottom: 0;
    left: -3px;
    min-width: 80px;
    max-width: 80px;
  }

  &:first-child {
    .line-dropdown {
      padding-top: 30px;
      bottom: 0;
      top: 0;
    }
  }
  &:last-child {
    .line-dropdown {
      padding-bottom: 30px;
      top: 0;
    }
  }

  &:hover {
    background-color: #e3eaff;
  }

  .__top-0 {
    top: 0 !important;
  }

`;

export const DropdownMenuHeader = styled.div`
  cursor: pointer !important;
  position: relative;

  & > svg {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translate(0, -50%) rotate(0deg);
    transition: 0.3s;
  }

  & > .arrow--active {
    transform: translate(0, -50%) rotate(180deg);
    transition: 0.3s;
  }

  &:hover {
    color: #281b65;
    background-color: #e3eaff;
  }
`;

export const LiStyled = styled.li`
  cursor: pointer;

  .active-menu {
    color: #281b65 !important;
    background-color: #e3eaff;
  }

  .fa {
    position: relative;
    display: table-cell;
    width: 80px;
    height: 65px;
    text-align: center;
    top: 22px;
    font-size: 20px;
  }
`;
