import React, { useRef, useState, useEffect } from "react";
import Vimeo from "@u-wave/react-vimeo";
import "./index.css";
import styled from "styled-components";
import { Controls } from "./Controls";

const PlayerVideo = (props) => {
  const [controlsVisible, setControlsVisible] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const video = useRef();

  const handleFullScreenChange = () => {
    setIsFullScreen(document.fullscreenElement !== null);
  };

  useEffect(() => {
    let animationFrameId;
  
    const handleTimeUpdate = () => {
      if (video.current) {
        setCurrentTime(video.current.currentTime);
        animationFrameId = requestAnimationFrame(handleTimeUpdate);
      }
    };
  
    if (video.current) {
      video.current.addEventListener("loadedmetadata", () => {
        setDuration(video.current.duration);
        handleTimeUpdate();
      });
      document.addEventListener("fullscreenchange", handleFullScreenChange); // Agregar el event listener
    }

    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
      document.removeEventListener("fullscreenchange", handleFullScreenChange); // Eliminar el event listener al desmontar el componente
    };
  }, []);

  const handlePlaybackRateChange = (rate) => {
    video.current.playbackRate = rate;
    setPlaybackRate(rate);
  };

  const handlePlay = () => {
    video.current.play();
    setIsPlaying(true);
  };

  const handlePause = () => {
    video.current.pause();
    setIsPlaying(false);
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      handlePause();
      setIsPlaying(false);
    } else {
      handlePlay();
      setIsPlaying(true);
      if (currentTime === duration) {
        handleRestart();
      }
    }
  };

  const handleMute = () => {
    video.current.muted = !video.current.muted;
  };

  const handleMouseEnter = () => {
    setControlsVisible(true);
  };

  const handleMouseLeave = () => {
    setControlsVisible(false);
  };

  const handleFullScreenToggle = () => {
    if (!isFullScreen) {
      // Entrar en modo de pantalla completa
      if (video.current.requestFullscreen) {
        video.current.requestFullscreen();
      } else if (video.current.mozRequestFullScreen) {
        /* Firefox */
        video.current.mozRequestFullScreen();
      } else if (video.current.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        video.current.webkitRequestFullscreen();
      } else if (video.current.msRequestFullscreen) {
        /* IE/Edge */
        video.current.msRequestFullscreen();
      }
    } else {
      // Salir del modo de pantalla completa
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        /* Firefox */
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE/Edge */
        document.msExitFullscreen();
      }
    }
    setIsFullScreen(!isFullScreen);
  };

  const handleRestart = () => {
    video.current.currentTime = 0;
    video.current.play();
    setIsPlaying(true);
  };

  const handleVideoClick = () => {
    if (isPlaying) {
      handlePause();
      setIsPlaying(false);
    } else {
      handlePlay();
      setIsPlaying(true);
      if (currentTime === duration) {
        handleRestart();
      }
    }
  };

  const getProvider = (src) => {
    return src.includes("http") ? "amazon" : "vimeo";
  };

  const getVimeoPayer = (src) => {
    return <Vimeo responsive={true} video={src} autoplay />;
  };
  
  const getTfPlayer = (src, cover) => {
    return (
      <VideoStyled ref={video} poster={cover} onClick={handleVideoClick} controls={false}>
        <source src={src} />
      </VideoStyled>
    );
  };

  return (
    <>
      <>
        {getProvider(props.src) === "vimeo" ? (
          getVimeoPayer(props.src)
        ) : (
          <VideoContainer
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {getTfPlayer(props.src, props.cover)}
            <Controls
              handlePlayPause={handlePlayPause}
              isPlaying={isPlaying}
              video={video}
              visible={controlsVisible}
              currentTime={currentTime}
              duration={duration}
              videoUrl={props.src}
              handleMute={handleMute}
              playbackRate={playbackRate}
              handlePlaybackRateChange={handlePlaybackRateChange}
              handleRestart={handleRestart}
              handleFullScreenToggle={handleFullScreenToggle}
              isFullScreen={isFullScreen}
            />
          </VideoContainer>
        )}
        {props.title && <h6 className="p-3 m-0">{props.title}</h6>}
      </>
    </>
  );
};

export default PlayerVideo;


const VideoStyled = styled.video`
  width: 100%;
  max-height: 600px;
`;

const VideoContainer = styled.div`
  position: relative;
`;