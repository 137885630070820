import React, { useState } from "react";
import PropTypes from "prop-types";

import { RatingStyled } from "./styles";
import { HeartIcon, HeartFilledIcon } from "./icons";

const activeColor = {
  yellow: "#FDAD34",
  blue: "#4632A4",
  inactive: "#E5E5E5",
};

export const Rating = ({ color, value = 0, clickable }) => {
  const [rating, setRating] = useState(value);

  const handleClick = (i) => {
    if (!clickable) return;

    setRating(i + 1);
  };

  return (
    <RatingStyled color={activeColor[color]}>
      {[1, 2, 3, 4, 5].map((heart, index) => (
        <div key={index} onClick={() => handleClick(index)}>
          {rating >= index + 1 ? <HeartFilledIcon /> : <HeartIcon />}
        </div>
      ))}
    </RatingStyled>
  );
};

Rating.propTypes = {
  /**
   * Type of Rating to use
   */
  color: PropTypes.oneOf(["blue", "yellow", "inactive"]).isRequired,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
};

Rating.defaultProps = {
  color: "blue",
  value: 3,
  clickable: false,
};
