export const musclesWalkfes = (t) => {
  return [
    {
      ch: 1,
      img: {
        name: t("quadriceps"),
        category: "60ec7d24449b17e986856ce8",
        position: "left",
        image:
          "https://dashboard.trainfes-cloud.com/assets/Bipe/cuadriceps.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Bipe/cuadriceps.png",
      },
      showImage: true,
    },
    {
      ch: 2,
      img: {
        name: t("quadriceps"),
        category: "60ec7d24449b17e986856ce8",
        position: "right",
        image:
          "https://dashboard.trainfes-cloud.com/assets/Bipe/cuadriceps.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Bipe/cuadriceps.png",
      },
      showImage: true,
    },
    {
      ch: 3,
      img: null,
      showImage: false,
    },
    {
      ch: 4,
      img: null,
      showImage: false,
    },
    {
      ch: 5,
      img: null,
      showImage: false,
    },
    {
      ch: 6,
      img: null,
      showImage: false,
    },
  ];
};

export const channelsWalkfes = (t) => {
  return [
    { ch: 1, index: 100, keyId: t("quadriceps"), name: t("quadriceps") },
    { ch: 2, index: 100, keyId: t("quadriceps"), name: t("quadriceps") },
  ];
};

export const musclesFES4 = (t) => {
  return [
    {
      ch: 1,
      img: {
        name: t("quadriceps"),
        category: "60ec7d24449b17e986856ce8",
        position: "left",
        image:
          "https://dashboard.trainfes-cloud.com/assets/Bipe/cuadriceps.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Bipe/cuadriceps.png",
      },
      showImage: true,
    },
    {
      ch: 2,
      img: {
        name: t("glute_maximus"),
        category: "60ec7d24449b17e986856ce8",
        position: "left",
        image: "https://dashboard.trainfes-cloud.com/assets/Bipe/gluteos.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Bipe/gluteos.png",
      },
      showImage: true,
    },
    {
      ch: 3,
      img: {
        name: t("glute_maximus"),
        category: "60ec7d24449b17e986856ce8",
        position: "right",
        image: "https://dashboard.trainfes-cloud.com/assets/Bipe/gluteos.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Bipe/gluteos.png",
      },
      showImage: true,
    },
    {
      ch: 4,
      img: {
        name: t("quadriceps"),
        category: "60ec7d24449b17e986856ce8",
        position: "right",
        image:
          "https://dashboard.trainfes-cloud.com/assets/Bipe/cuadriceps.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Bipe/cuadriceps.png",
      },
      showImage: true,
    },
    {
      ch: 5,
      img: null,
      showImage: false,
    },
    {
      ch: 6,
      img: null,
      showImage: false,
    },
  ];
};

export const channelsFES4 = (t) => {
  return [
    { ch: 1, index: 100, keyId: t("quadriceps"), name: t("quadriceps") },
    { ch: 2, index: 100, keyId: t("glute_maximus"), name: t("glute_maximus") },
    { ch: 3, index: 100, keyId: t("glute_maximus"), name: t("glute_maximus") },
    { ch: 4, index: 100, keyId: t("quadriceps"), name: t("quadriceps") },
  ];
};

export const musclesFES6Advance = (t) => {
  return [
    {
      ch: 1,
      img: {
        name: t("quadriceps"),
        category: "60ec7d24449b17e986856ce8",
        position: "left",
        image:
          "https://dashboard.trainfes-cloud.com/assets/Bipe/cuadriceps.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Bipe/cuadriceps.png",
      },
      showImage: true,
    },
    {
      ch: 2,
      img: {
        name: t("quadriceps"),
        category: "60ec7d24449b17e986856ce8",
        position: "right",
        image:
          "https://dashboard.trainfes-cloud.com/assets/Bipe/cuadriceps.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Bipe/cuadriceps.png",
      },
      showImage: true,
    },
    {
      ch: 3,
      img: {
        name: t("glute_maximus"),
        category: "60ec7d24449b17e986856ce8",
        position: "left",
        image: "https://dashboard.trainfes-cloud.com/assets/Bipe/gluteos.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Bipe/gluteos.png",
      },
      showImage: true,
    },
    {
      ch: 4,
      img: {
        name: t("glute_maximus"),
        category: "60ec7d24449b17e986856ce8",
        position: "right",
        image: "https://dashboard.trainfes-cloud.com/assets/Bipe/gluteos.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Bipe/gluteos.png",
      },
      showImage: true,
    },
    {
      ch: 5,
      img: {
        name: t("ABS"),
        category: "60ec7d24449b17e986856ce8",
        position: "left",
        image: "https://dashboard.trainfes-cloud.com/assets/Bipe/abdomen-1.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Bipe/abdomen-1.png",
      },
      showImage: true,
    },
    {
      ch: 6,
      img: {
        name: t("ABS"),
        category: "60ec7d24449b17e986856ce8",
        position: "right",
        image: "https://dashboard.trainfes-cloud.com/assets/Bipe/abdomen-1.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Bipe/abdomen-1.png",
      },
      showImage: true,
    },
  ];
};

export const channelsFES6Advance = (t) => {
  return [
    { ch: 1, index: 100, keyId: t("quadriceps"), name: t("quadriceps") },
    { ch: 2, index: 100, keyId: t("quadriceps"), name: t("quadriceps") },
    { ch: 3, index: 100, keyId: t("glute_maximus"), name: t("glute_maximus") },
    { ch: 4, index: 100, keyId: t("glute_maximus"), name: t("glute_maximus") },
    { ch: 5, index: 100, keyId: t("ABS"), name: t("ABS") },
    { ch: 6, index: 100, keyId: t("ABS"), name: t("ABS") },
  ];
};

export const musclesFES6Pro = (t) => {
  return [
    {
      ch: 1,
      img: {
        name: t("quadriceps"),
        category: "60ec7d24449b17e986856ce8",
        position: "left",
        image:
          "https://dashboard.trainfes-cloud.com/assets/Bipe/cuadriceps.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Bipe/cuadriceps.png",
      },
      showImage: true,
    },
    {
      ch: 2,
      img: {
        name: t("glute_maximus"),
        category: "60ec7d24449b17e986856ce8",
        position: "left",
        image: "https://dashboard.trainfes-cloud.com/assets/Bipe/gluteos.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Bipe/gluteos.png",
      },
      showImage: true,
    },
    {
      ch: 3,
      img: {
        name: t("ABS"),
        category: "60ec7d24449b17e986856ce8",
        position: "left",
        image: "https://dashboard.trainfes-cloud.com/assets/Bipe/abdomen-1.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Bipe/abdomen-1.png",
      },
      showImage: true,
    },
    {
      ch: 4,
      img: {
        name: t("quadriceps"),
        category: "60ec7d24449b17e986856ce8",
        position: "right",
        image:
          "https://dashboard.trainfes-cloud.com/assets/Bipe/cuadriceps.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Bipe/cuadriceps.png",
      },
      showImage: true,
    },
    {
      ch: 5,
      img: {
        name: t("glute_maximus"),
        category: "60ec7d24449b17e986856ce8",
        position: "right",
        image: "https://dashboard.trainfes-cloud.com/assets/Bipe/gluteos.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Bipe/gluteos.png",
      },
      showImage: true,
    },
    {
      ch: 6,
      img: {
        name: t("ABS"),
        category: "60ec7d24449b17e986856ce8",
        position: "right",
        image: "https://dashboard.trainfes-cloud.com/assets/Bipe/abdomen-1.png",
        url: "https://dashboard.trainfes-cloud.com/assets/Bipe/abdomen-1.png",
      },
      showImage: true,
    },
  ];
};

export const channelsFES6Pro = (t) => {
  return [
    { ch: 1, index: 100, keyId: t("quadriceps"), name: t("quadriceps") },
    { ch: 2, index: 100, keyId: t("glute_maximus"), name: t("glute_maximus") },
    { ch: 3, index: 100, keyId: t("ABS"), name: t("ABS") },
    { ch: 4, index: 100, keyId: t("quadriceps"), name: t("quadriceps") },
    { ch: 5, index: 100, keyId: t("glute_maximus"), name: t("glute_maximus") },
    { ch: 6, index: 100, keyId: t("ABS"), name: t("ABS") },
  ];
};
