import { useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import API from "../services/api";

const api = new API();

export const useGetProtocols = () => {
    const [protocolsDropdown, setProtocolsDropdown] = useState([])

    const protocolsQuery = useQuery({
        queryKey: ["protocols"],
        queryFn: () => api.getProtocols()
            .then((res) => {
                setProtocolsDropdown(
                    res.data.map((item) => {
                        return {
                          label: `${item.name} | ${item.fase}`,
                          value: item._id,
                        };
                    })
                )

                return res.data
            }),
        refetchOnWindowFocus: false,
    });

    const { mutate: addNewProtocol, isLoading: addNewProtocolLoading } = useMutation(
        ( newProtocol ) => {
            return api.createProtocol( newProtocol )
        },
        {
            onSuccess: async () => protocolsQuery.refetch()
        }
    );

    const { mutate: updateProtocol, isLoading: editProtocolLoading } = useMutation(
        ( { id, data } ) => {
            return api.editProtocol( id, data )
        },
        {
            onSuccess: async () => protocolsQuery.refetch()
        }
    );

    const { mutate: deleteProtocol, isLoading: deleteProtocolLoading } = useMutation(
        ( id ) => {
            return api.deleteProtocol( id )
        },
        {
            onSuccess: async () => protocolsQuery.refetch()
        }
    );

    const { mutate: cloneProtocol, isLoading: cloneProtocolLoading,  } = useMutation(
        ( id ) => {
            return api.cloneProtocol( id )
        },
        {
            onSuccess: async () => protocolsQuery.refetch()
        }
    );

    const protocolLoading = protocolsQuery.isLoading || addNewProtocolLoading || editProtocolLoading || deleteProtocolLoading || cloneProtocolLoading;

    return {
        protocolsDropdown,
        protocolsQuery,
        addNewProtocol,
        updateProtocol,
        deleteProtocol,
        cloneProtocol,
        protocolLoading
    }
}
