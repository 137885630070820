import React, { Component } from 'react';
import { Grid, TextField } from '@material-ui/core';
import TimePicker from '../timePicker';
import Counter from '../counter';
import { withTranslation } from 'react-i18next'

var currentFile = null; var currentName = null;
class Balance extends Component {

    constructor() {
        super();
        this.state = {
            name: "",
            description: "",
            icon: "icon-alert",
            files: [],
            video: "",
            type: true,
            repeticiones: null,
            series: null,
            pausa_repeticiones: null,
            pausa_series: null,
            tiempo_trabajo: null
        }

        this.currentName = React.createRef();
    }


    removeFile(item) {
        this.state.files.splice(this.state.files.findIndex(i => i.name === item.name && i.size === item.size && i.lastModified === item.lastModified), 1);
        this.setState({ files: this.state.files });
    }

    pushFile() {
        const item = {};
        let name = this.currentName.current.querySelector('input');
        if (currentFile) {
            item["_id"] = Math.random().toString(36).slice(-8);
            item['file'] = currentFile;
            item['name'] = currentName;
            name.value = "";
            this.setState({
                files: [...this.state.files, item]
            })
        }

    }

    values() {
        if (this.props.values !== undefined) {
            this.props.values(this.state);
        }
    }

    componentDidUpdate() {
        this.values();
    }

    render() {
        return (
            <Grid container direction="row" justifyContent="center"> 
                <Grid item xs={12} md={5} className="pl-4">
                    <p className="title-calendar mb-2">Nombre</p>
                    <Grid item xs={12}>
                        <TextField
                                className="w-100"
                                id="outlined-margin-dense1"
                                margin="dense"
                                variant="outlined"
                                onChange={value => this.setState({ name: value.target.value })}
                            />
                    </Grid>
                    <p className="title-calendar mt-3 mb-2">Descripción</p>
                    <Grid item xs={12}>
                        <TextField
                                className="w-100"
                                id="outlined-margin-dense1"
                                margin="dense"
                                multiline
                                rows={4}
                                variant="outlined"
                                onChange={value => this.setState({ description: value.target.value })}
                            />
                    </Grid>
                    <p className="title-calendar mt-4 mb-2">Archivos</p>
                    <Grid item xs={12} container direction="row" justifyContent="center" alignItems="center">
                        <Grid item xs={10}>
                            <TextField
                                className="w-100"
                                id="outlined-margin-dense1"
                                margin="dense"
                                variant="outlined"
                                name="Nombre"
                                ref={this.currentName}
                                onChange={value => currentName = value.target.value}
                            />
                        </Grid>
                        <Grid item xs={1} container direction="row" justifyContent="flex-end" alignItems="center" className="pt-1">
                            <input
                                type="file"
                                id="avatar"
                                name="files"
                                accept=".png,.jpg,.pdf,audio/*,video/*,.doc,.docx,.odt" style={{ display: "none" }} onChange={ev => { currentFile = ev.target.files[0] }} />
                            <label className="m-0">
                                <label
                                    htmlFor="avatar"
                                    className="btn-square"><span className="icon-folder_upload icon-display fz-21"></span>
                                </label>
                            </label>
                        </Grid>
                        <Grid item xs={1} container direction="row" justifyContent="flex-end" alignItems="center" className="pt-1">
                            <button className="btn-square" onClick={() => this.pushFile()}><span className="icon-plus"></span></button>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} md={10}>
                            <p className="fz-10 color-sub-text my-3">Nombre</p>
                        </Grid>
                        <Grid item xs={12} md={2} container direction="row" justifyContent="center" alignItems="center">
                            <p className="fz-10 color-sub-text my-3">Acción</p>
                        </Grid>
                    </Grid>
                    <Grid className="MuiGrid-root border-right-tf border-left-tf border-top-tf">
                        {this.state.files.map((e, fileIndex) => {
                            let icon = e.file.type.split('/')[1]
                            return (
                                <Grid key={fileIndex} container className="border-bottom-tf py-2">
                                    <Grid item xs={12} md={3} container direction="row" justifyContent="center" alignItems="center">
                                        <div className="icon-info-lg">
                                            <span style={{display: "table-cell", verticalAlign: "middle"}} className={'mimetypes-' + icon}></span>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={7} container direction="row" justifyContent="flex-start" alignItems="center">
                                        <p className="m-0 fz-14 color-sub-text">{e.name}</p>
                                    </Grid>
                                    <Grid item xs={12} md={2} container direction="row" justifyContent="center" alignItems="center">
                                        <button className="btn-carrusel" onClick={() => this.removeFile(e)}><span className="icon-trash"></span></button>
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
                <Grid item xs={12} md={7} className="px-4">
                    <p className="title-calendar mb-3">Ajustes</p>
                    <Grid container direction="row">
                        <Grid item xs={3} className="pr-1">
                            <div className="border-tf py-2 px-3">
                                <p className="fz-10 color-sub-text mb-2">Número de repeticiones</p>
                                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">                           
                                    <Grid item xs={12} md={7}>
                                        <Counter title="0 - 50" min={0} max={100} values={value => this.setState({ repeticiones: value })} />
                                    </Grid>                 
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={3} className="px-1">
                            <div className="border-tf py-2 px-3">
                                <p className="fz-10 color-sub-text mb-2">Tiempo entre repeticiones</p>
                                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">                           
                                    <Grid item xs={12} md={12}>
                                        <TimePicker seconds={true} minutes={true} values={value => this.setState({ pausa_repeticiones: value })} />
                                    </Grid>                  
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={3} className="px-1">
                            <div className="border-tf py-2 px-3">
                                <p className="fz-10 color-sub-text mb-2">Número de series</p>
                                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">                           
                                    <Grid item xs={12} md={7}>
                                        <Counter title="0 - 100" min={0} max={100} values={value => this.setState({ series: value })} />
                                    </Grid>                 
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={3} className="pl-1">
                            <div className="border-tf py-2 px-3">
                                <p className="fz-10 color-sub-text mb-2">Tiempo entre series</p>
                                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">                           
                                    <Grid item xs={12} md={12}>
                                        <TimePicker seconds={true} minutes={true} values={value => this.setState({ pausa_series: value })} />
                                    </Grid>                  
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" className="mt-4">
                        <Grid item xs={3} className="pr-1">
                            <div className="border-tf py-2 px-3">
                                <p className="fz-10 color-sub-text mb-2">Tiempo de trabajo</p>
                                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">                         
                                    <Grid item xs={12} md={12}>
                                        <TimePicker minutes={true} seconds={true} values={value => this.setState({ tiempo_trabajo: value })} />
                                    </Grid>               
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid> 
        )
    }
}


export default withTranslation('sessionStudio')(Balance);