import styled from "styled-components";
import { SequentialForm } from "../styles/Modos.styles";

export const SensorForm = styled(SequentialForm)`
  margin-bottom: 0;

  & > div {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 7px;
    }
  }
`;
