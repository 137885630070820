import React from "react";
import { Title } from "../../../../styles/Modos.styles";
import * as S from "../../TableMuscles.styles";

export const TableMuscles = ({ t }) => {
  const tableHeaders = [t("muscles"), 1, 2];
  const tableData = [
    {
      muscle: t("quadriceps"),
      1: <S.Check className="__blue" />,
      2: <S.Check className="__blue" />,
    },

    {
      muscle: t("glute_maximus"),
      1: <S.Check />,
      2: <S.Check />,
    },
    {
      muscle: t("ABS"),
      1: <S.Check />,
      2: <S.Check />,
    },
    {
      muscle: t("lumbar"),
      1: <S.Check />,
      2: <S.Check />,
    },
    {
      muscle: t("tibialis_anterior"),
      1: <S.Check />,
      2: <S.Check />,
    },
    {
      muscle: t("fibular"),
      1: <S.Check />,
      2: <S.Check />,
    },
    {
      muscle: t("gluteus_medius"),
      1: <S.Check />,
      2: <S.Check />,
    },
  ];

  return (
    <>
      <Title>
        {t("muscles_to_work")} <span className="__red">*</span>
      </Title>
      <S.Table>
        <S.RowHeader>
          {tableHeaders.map((header, i) => (
            <div key={i} className={`${header === 1 ? "__dark-bg" : null}`}>
              {header}
            </div>
          ))}
        </S.RowHeader>
        {tableData.map((row, i) => (
          <S.Row key={i}>
            <div>{row.muscle}</div>
            <div>{row[1]}</div>
            <div>{row[2]}</div>
          </S.Row>
        ))}
      </S.Table>
      <S.InfoText>
        <p>*{t("conf_suggested")}</p>
        <p>*{t("conf_optional")}</p>
      </S.InfoText>
      <S.ReferenceText>
        <strong>{t("this_table_is_not_intended")},</strong> {t("it_is_a_reference")}
      </S.ReferenceText>
    </>
  );
};
