import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input, Dropdown } from "trainfes-components-library";

import API from "../../../../services/api";
import { connect } from "../../../../store";
import LoaderContainer from "../../../loader/container";
import { ImageCard } from "./ImageCard";
import * as S from "./ModalMuscleSelector.styles";

const api = new API();

const ModalMuscleSelector = ({ img, handleChangeImage, store }) => {
  const { categories_media } = store;

  const { t } = useTranslation(["sessionStudio"]);

  const [images, setImages] = useState([]);

  const [loading, setLoading] = useState(true);

  const [selectedImage, setSelectedImage] = useState(img || null);

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const categoriesArr = [{ value: "", label: t("all") }];

    categories_media.forEach((category) => {
      categoriesArr.push({
        label: category.name,
        value: category._id,
      });
    });

    setCategories(categoriesArr);
  }, []);

  const imagesCollection = (data) => {
    const newArray = [];

    data.forEach((item) => {
      if (item.left !== "" && item.left !== "null") {
        newArray.push({
          name: item.name,
          title: item.name,
          category: item.category,
          position: "left",
          image: item.left,
          description: item.description || "",
        });
      }

      if (item.right !== "" && item.right !== "null") {
        newArray.push({
          name: item.name,
          title: item.name,
          category: item.category,
          position: "right",
          image: item.right,
          description: item.description || "",
        });
      }

      if (item.normal !== "" && item.normal !== "null") {
        newArray.push({
          name: item.name,
          title: item.name,
          category: item.category,
          position: "normal",
          image: item.normal,
          description: item.description || "",
        });
      }
    });

    setImages(newArray);
  };

  useEffect(() => {
    api.getAllPositioning().then((res) => {
      imagesCollection(res.data);
      setLoading(false);
    });
  }, []);

  const [filter, setFilter] = useState({
    name: "",
    category: "",
    position: "",
  });

  const positionsOptions = [
    { value: "", label: t("all") },
    { value: "left", label: t("left") },
    { value: "right", label: t("right") },
    { value: "normal", label: t("general") },
  ];

  const handleSelect = (image) => {
    if (selectedImage === image) {
      handleChangeImage(null);
      setSelectedImage(null);
      return;
    }

    if (img) {
      if (
        selectedImage.category === image.category &&
        selectedImage.description === image.description &&
        selectedImage.image === image.image &&
        selectedImage.position === image.position &&
        selectedImage.title === image.name
      ) {
        handleChangeImage(null);
        setSelectedImage(null);
        return;
      }
    }

    handleChangeImage({
      ...image,
      name: `${image.name} ${t(image.position)}`,
      url: image.image,
    });

    setSelectedImage(image);
  };

  return (
    <form style={{ width: "100%" }}>
      <S.FormStyled>
        <Input
          placeholder={t("search")}
          name="name"
          onChange={(e) => setFilter({ ...filter, name: e.target.value })}
          value={filter.name}
        />
        <div className="muscleselectr--dropdowns">
          <Dropdown
            type="line"
            name="position"
            placeholder={t("position")}
            portal
            options={positionsOptions}
            onChange={(option) => setFilter({ ...filter, position: option.value })}
            value={positionsOptions.filter((e) => e.value === filter.position)}
          />

          <Dropdown
            type="line"
            name="category"
            placeholder={t("category")}
            portal
            options={categories}
            onChange={(option) => setFilter({ ...filter, category: option.value })}
            value={categories.filter((e) => e.value === filter.category)}
          />
        </div>
      </S.FormStyled>

      <LoaderContainer active={loading} height={"250px"}>
        <S.Body>
          {images
            .filter(
              (f) =>
                f.name
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .includes(
                    filter.name
                      .toLowerCase()
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                  ) || filter.name === ""
            )
            .filter(
              (f) =>
                f.position.toLowerCase().includes(filter.position.toLowerCase()) ||
                filter.position === ""
            )
            .filter(
              (f) =>
                f.category.toLowerCase().includes(filter.category.toLowerCase()) ||
                filter.category === ""
            )
            .map((image, i) => (
              <ImageCard
                key={i}
                t={t}
                data={image}
                onClick={() => handleSelect(image)}
                selectedImage={selectedImage}
              />
            ))}
          {/* TODO: sin elementos para mostrar agregar */}
        </S.Body>
      </LoaderContainer>
    </form>
  );
};

export default connect(ModalMuscleSelector);
