import { useQuery, useMutation } from '@tanstack/react-query';
import axios from 'axios';

export const useGetDevices = () => {

    const devicesQuery = useQuery({
        queryKey: ["devicesQuery"],
        queryFn: () => axios.get('/device/media').then( (res) => res.data.data ),
        refetchOnWindowFocus: false,
    });

    const { mutate: addNewDevice, isLoading: addLoading } = useMutation(
        ( newDevice ) => axios.post( '/device/media', newDevice ),
        {
            onSuccess: async () => devicesQuery.refetch()
        }
    );
    
    const { mutate: deleteDevice, isLoading: deleteLoading } = useMutation(
        ( id ) => axios.delete(`/device/media/${id}`),
        {
            onSuccess: async () => devicesQuery.refetch()
        }
    );

    const customLoading = devicesQuery.isLoading || addLoading || deleteLoading;

    return {
        customLoading,
        devicesQuery,
        addNewDevice, 
        deleteDevice
    }
}
