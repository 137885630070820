import React from "react";
import ConfigSensor from "../../configSensor";
import TimePicker from "../../timePicker";
import Counter from "../../counter";
import { withTranslation } from "react-i18next";

import {
  Container,
  Col,
  Row,
  Modal,
  Button,
  Slider,
} from "trainfes-components-library";
import { TextField } from '@mui/material';

import * as S from "../styles/Modos.styles";
import * as C from "../styles/configbutton.styles";
import * as M from "./sensor.styles";

import { MuscleSelector } from "../components/MuscleSelector";
import { ConfigIcon } from "../../../assets/icons";

var limite_intensidad, self;
class Sensor extends React.Component {
  constructor() {
    super();
    this.state = {
      channels: [
        { ch: 1, name: "", index: 100, keyId: "" },
        { ch: 2, name: "", index: 100, keyId: "" },
        { ch: 3, name: "", index: 100, keyId: "" },
        { ch: 4, name: "", index: 100, keyId: "" },
        { ch: 5, name: "", index: 100, keyId: "" },
        { ch: 6, name: "", index: 100, keyId: "" },
      ],
      configs: new Array(24).fill(false),
      contraccion: { hours: 0, minutes: 0, seconds: 0 },
      series: 0,
      // repeticion_series: 0,
      pausa_contraccion: { hours: 0, minutes: 0, seconds: 0 },
      pausa_series: { hours: 0, minutes: 0, seconds: 0 },
      name: "",
      num_contraccion: 0,
      time: "00:00:00",

      frecuenciaTemp: 35,
      ancho_pulsoTemp: 200,
      frecuencia: 35,
      ancho_pulso: 200,
      public_name: "",
      technical_name: "",
      description: "",
      channelSelected: 0,
      musclesSelected: [
        {
          ch: 1,
          img: null,
          showImage: false,
        },
        {
          ch: 2,
          img: null,
          showImage: false,
        },
        {
          ch: 3,
          img: null,
          showImage: false,
        },
        {
          ch: 4,
          img: null,
          showImage: false,
        },
        {
          ch: 5,
          img: null,
          showImage: false,
        },
        {
          ch: 6,
          img: null,
          showImage: false,
        },
      ],
      showConfigModal: false,
    };
  }

  values() {
    const {
      num_contraccion,
      configs,
      name,
      contraccion,
      series,
      pausa_contraccion,
      pausa_series,
      frecuencia,
      ancho_pulso,
      time,
      musclesSelected,
      technical_name,
      public_name,
      description,
      channelSelected,
    } = this.state;
    if (this.props.values !== undefined) {
      const chs = this.state.channels.map((e) => (e.index !== 100 ? true : false));
      this.props.values({
        channels: chs,
        configs,
        name,
        contraccion,
        series,
        pausa_series,
        pausa_contraccion,
        frecuencia,
        ancho_pulso,
        frecuenciaTemp: frecuencia,
        ancho_pulsoTemp: ancho_pulso,
        limite_intensidad,
        num_contraccion,
        config_channels: this.state.channels.filter((e) => e.name !== ""),
        musclesSelected,
        technical_name: technical_name || "",
        public_name: public_name || "",
        description: description || "",
        time: time || "00:00:00",
        channelSelected: channelSelected || 0,
      });
    }
  }

  toInt(obj = { hours: 0, minutes: 0, seconds: 0 }) {
    let { hours, minutes, seconds } = obj;
    return hours * 3600 + minutes * 60 + seconds;
  }

  componentDidUpdate(props, state) {
    this.values();

    if (
      state.serie !== this.state.series ||
      state.contraccion !== this.state.contraccion ||
      state.num_contraccion !== this.state.num_contraccion ||
      state.pausa_series !== this.state.pausa_series ||
      state.pausa_contraccion !== this.state.pausa_contraccion
    ) {
      let tc = this.state.contraccion.seconds;
      let pec = this.toInt(this.state.pausa_contraccion); //this.hoursToSeconds(this.state.pausa_contraccion.hours) + this.minutesToSeconds(this.state.pausa_contraccion.minutes) + this.state.pausa_contraccion.seconds;
      let nc = this.state.num_contraccion;
      let ns = this.state.series;
      let pes =
        this.hoursToSeconds(this.state.pausa_series.hours) +
        this.minutesToSeconds(this.state.pausa_series.minutes) +
        this.state.pausa_series.seconds;
      let t = ns * (nc * (tc + pec) - pec) + pes * (ns - 1);
      let total = this.convertir(t);
      if (state.time !== total) this.setState({ time: total });
    }
  }

  hoursToSeconds(h) {
    if (h === 0) return 0;
    else return parseInt(h) * 3600;
  }

  minutesToSeconds(m) {
    if (m === 0) return 0;
    else return parseInt(m) * 60;
  }

  convertir(segundosP) {
    const segundos = Math.round(segundosP % 0x3c).toString();
    const s = segundos <= 0 ? "00" : segundos < 10 ? "0" + segundos : segundos.toString();
    const horas = Math.floor(segundosP / 0xe10);
    const h = horas <= 0 ? "00" : horas < 10 ? "0" + horas : horas.toString();
    const minutos = (Math.floor(segundosP / 0x3c) % 0x3c).toString();
    const m = minutos <= 0 ? "00" : minutos < 10 ? "0" + minutos : minutos.toString();
    return `${h}:${m}:${s}`;
  }

  componentDidMount() {
    self = this;

    if (this.props.data.params && Object.keys(this.props.data.params).length !== 0) {
      const {
        config_channels,
        configs,
        series,
        pausa_series,
        num_contraccion,
        contraccion,
        pausa_contraccion,
        musclesSelected,
        technical_name,
        public_name,
        description,
        frecuencia,
        ancho_pulso,
        time,
      } = this.props.data.params;
      this.setState({
        config_channels,
        configs,
        series,
        pausa_series,
        num_contraccion,
        contraccion,
        pausa_contraccion,
        frecuencia,
        ancho_pulso,
        frecuenciaTemp: frecuencia,
        ancho_pulsoTemp: ancho_pulso,
        technical_name: technical_name || "",
        public_name: public_name || "",
        description: description || "",
        time: time || "00:00:00",
      });

      if (musclesSelected) {
        this.setState({
          musclesSelected,
        });
      }
    }
  }

  refreshSelectedConfig() {
    let arrayDuplicate = JSON.parse(JSON.stringify([...this.state.configs]));

    if (!this.state.musclesSelected[0].img) {
      arrayDuplicate[0] = false;
      arrayDuplicate[6] = false;
      arrayDuplicate[12] = false;
      arrayDuplicate[18] = false;
    }
    if (!this.state.musclesSelected[1].img) {
      arrayDuplicate[1] = false;
      arrayDuplicate[7] = false;
      arrayDuplicate[13] = false;
      arrayDuplicate[19] = false;
    }
    if (!this.state.musclesSelected[2].img) {
      arrayDuplicate[2] = false;
      arrayDuplicate[8] = false;
      arrayDuplicate[14] = false;
      arrayDuplicate[20] = false;
    }
    if (!this.state.musclesSelected[3].img) {
      arrayDuplicate[3] = false;
      arrayDuplicate[9] = false;
      arrayDuplicate[15] = false;
      arrayDuplicate[21] = false;
    }
    if (!this.state.musclesSelected[4].img) {
      arrayDuplicate[4] = false;
      arrayDuplicate[10] = false;
      arrayDuplicate[16] = false;
      arrayDuplicate[22] = false;
    }
    if (!this.state.musclesSelected[5].img) {
      arrayDuplicate[5] = false;
      arrayDuplicate[11] = false;
      arrayDuplicate[17] = false;
      arrayDuplicate[23] = false;
    }
    this.setState({ configs: arrayDuplicate });
  }

  setMusclesSelected(data) {
    self.setState({ musclesSelected: data }, () => self.refreshSelectedConfig());
  }

  setConfigChannels(data) {
    self.setState({ config_channels: data });
  }

  setChannels(data) {
    self.setState({ channels: data });
  }

  setPublicName(e) {
    self.setState({ public_name: e });
  }

  setTechnicalName(e) {
    self.setState({ technical_name: e });
  }

  setDescription(e) {
    self.setState({ description: e });
  }

  setShowConfigModal(bool) {
    self.setState({ showConfigModal: bool });
  }

  setFrecuencyTemp(value) {
    self.setState({ frecuenciaTemp: value });
  }

  setPulseWidthTemp(value) {
    self.setState({ ancho_pulsoTemp: value });
  }

  handleConfigSubmit() {
    self.setState({
      ancho_pulso: self.state.ancho_pulsoTemp,
      frecuencia: self.state.frecuenciaTemp,
    });
    self.setShowConfigModal(false);
  }

  render() {
    const { t } = this.props;

    return (
      <S.ScreenContainer>
        <Container>
          <Row cols={12}>
            <Col xs={12} md={3}>
              <S.Title>{t("config")}</S.Title>
              {this.state.musclesSelected &&
                this.state.musclesSelected.map((channel, i) => (
                  <MuscleSelector
                    key={i}
                    index={i}
                    data={channel}
                    musclesSelected={this.state.musclesSelected}
                    setMusclesSelected={this.setMusclesSelected}
                    configChannels={this.state.config_channels || null}
                    setConfigChannels={this.setConfigChannels}
                    channels={this.state.channels}
                    setChannels={this.setChannels}
                  />
                ))}
            </Col>
            <Col xs={12} md={3}>
              <S.Title style={{ textAlign: "center", marginLeft: "-160px" }}>{t("sensor")}</S.Title>
              <ConfigSensor
                data={this.state.configs}
                channels={this.state.channels}
                value={(values) => {
                  this.setState({ configs: values });
                }}
              />
            </Col>

            <Col xs={12} md={6}>
              <Row cols={12}>
                <Col xs={12}>
                  <M.SensorForm>
                    <TextField
                      fullWidth
                      required
                      label={t("tech_name")}
                      value={this.state.technical_name}
                      onChange={({ target }) => this.setTechnicalName(target.value)}
                    />
                    <TextField
                      fullWidth
                      label={t("public_name")}
                      value={this.state.public_name}
                      onChange={({ target }) => this.setPublicName(target.value)}
                    />
                    <TextField
                      label={t("description")}
                      fullWidth
                      multiline
                      rows={4}
                      value={this.state.description}
                      onChange={({ target }) => this.setDescription(target.value)}
                    />
                  </M.SensorForm>
                </Col>
                <Col xs={12}>
                  <C.ConfigTimeContainer>
                    <S.TimeContainer style={{ margin: "7px 0" }}>
                      <S.TimeTotal>{t("total_time")}:</S.TimeTotal>
                      <S.Box padding={"10px"}>
                        <S.TimeText>{this.state.time}</S.TimeText>
                      </S.Box>
                    </S.TimeContainer>
                    <C.ConfigButton onClick={() => this.setShowConfigModal(true)}>
                      <ConfigIcon />
                    </C.ConfigButton>
                  </C.ConfigTimeContainer>
                </Col>

                <Col xs={6}>
                  <S.Box padding={"10px 15px 0px 15px"} className="_marginY-7">
                    <S.GridTitle>{t("series")}</S.GridTitle>
                    <Counter
                      data={this.state.series}
                      title="0 - 50"
                      min={0}
                      max={50}
                      values={(value) => this.setState({ series: value })}
                    />
                  </S.Box>
                </Col>
                <Col xs={6}>
                  <S.Box padding={"10px 15px 0px 15px"} className="_marginY-7">
                    <S.GridTitle>{t("pauseSeries")}</S.GridTitle>
                    <TimePicker
                      data={this.state.pausa_series}
                      min={0}
                      max={50}
                      seconds={true}
                      minutes={true}
                      values={(value) => this.setState({ pausa_series: value })}
                    />
                  </S.Box>
                </Col>
                <Col xs={4}>
                  <S.Box padding={"10px 15px 0px 15px"} className="_marginY-7">
                    <S.GridTitle>{t("num_repeats")}</S.GridTitle>
                    <Counter
                      data={this.state.num_contraccion}
                      title="0 - 50"
                      min={0}
                      max={50}
                      values={(e) => this.setState({ num_contraccion: e })}
                    />
                  </S.Box>
                </Col>
                <Col xs={4}>
                  <S.Box padding={"10px 15px 0px 15px"} className="_marginY-7">
                    <S.GridTitle>{t("trainningsTime")}</S.GridTitle>
                    <TimePicker
                      data={this.state.contraccion}
                      min={0}
                      max={50}
                      seconds={true}
                      values={(value) => this.setState({ contraccion: value })}
                    />
                  </S.Box>
                </Col>
                <Col xs={4}>
                  <S.Box padding={"10px 15px 0px 15px"} className="_marginY-7">
                    <S.GridTitle>{t("pause_between_repeat")} (0 - 60s)</S.GridTitle>
                    <TimePicker
                      data={this.state.pausa_contraccion}
                      min={0}
                      max={50}
                      seconds={true}
                      values={(value) => this.setState({ pausa_contraccion: value })}
                    />
                  </S.Box>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Modal
          isOpen={this.state.showConfigModal}
          buttons={
            <>
              <Button
                type="ghost"
                width="142px"
                label={t("cancel")}
                onClick={() => {
                  this.setFrecuencyTemp(this.state.frecuencia);
                  this.setPulseWidthTemp(this.state.ancho_pulso);
                  this.setShowConfigModal(false);
                }}
              />
              <Button width="142px" label={t("save")} onClick={this.handleConfigSubmit} />
            </>
          }
        >
          <C.ModalConfigContainer>
            <S.Box padding={"10px 15px 0px 15px"}>
              <S.GridTitle>{t("frecuency")} (HZ)</S.GridTitle>
              <S.SliderIndicator>{this.state.frecuenciaTemp || 0}</S.SliderIndicator>
              <S.SliderContainer>
                <Slider
                  step={1}
                  value={this.state.frecuenciaTemp}
                  min={5}
                  max={60}
                  onChange={(value) => this.setFrecuencyTemp(value)}
                />
              </S.SliderContainer>
            </S.Box>

            <S.Box padding={"10px 15px 0px 15px"}>
              <S.GridTitle>{t("pulse_width")} (US)</S.GridTitle>
              <S.SliderIndicator>{this.state.ancho_pulsoTemp || 0}</S.SliderIndicator>
              <S.SliderContainer>
                <Slider
                  step={1}
                  value={this.state.ancho_pulsoTemp}
                  min={20}
                  max={400}
                  onChange={(value) => this.setPulseWidthTemp(value)}
                />
              </S.SliderContainer>
            </S.Box>
          </C.ModalConfigContainer>
        </Modal>
      </S.ScreenContainer>
    );
  }
}

export default withTranslation("sessionStudio")(Sensor);
