import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Input, Dropdown } from "trainfes-components-library";
import { connect } from "../../../../store";
import { Selector } from "../Selector";

const FindElectrodes = (props) => {
  const { t } = useTranslation("library");
  const { positioning, setPositioning } = props;
  const { name, category, description } = positioning;

  const dropdownOptions = props.store.categories_media.map((category) => {
    return { value: category._id, label: category.name };
  });

  return (
    <>
      <InputStyled>
        <Input
          placeholder={t("name")}
          onChange={(e) => setPositioning({ ...positioning, name: e.target.value })}
          value={name}
        />
      </InputStyled>
      <InputStyled>
        <Dropdown
          placeholder={t("category")}
          onChange={(value) =>
            props.setPositioning({
              ...positioning,
              category: value,
            })
          }
          value={category}
          type="line"
          options={dropdownOptions}
          portal
        />
      </InputStyled>

      <InputStyled className="_description">
        <Label>{t("desc")}</Label>
        <TextArea
          value={description}
          onChange={(e) =>
            setPositioning({
              ...positioning,
              description: e.target.value,
            })
          }
        ></TextArea>
      </InputStyled>

      <SelectorStyled>
        <Selector t={t} positioning={positioning} setPositioning={setPositioning} notify={props.notify} />
      </SelectorStyled>
    </>
  );
};

export default connect(FindElectrodes);

const SelectorStyled = styled.div`
  margin: 17px 0 35px 0;
`;

const InputStyled = styled.div`
  margin: 7px 0;

  &._description {
    margin: 14px 0;
  }
`;

const TextArea = styled.textarea`
  border-radius: 4px;
  border: 1px solid #9aa2a8;
  outline: none;
  padding: 10px;
  resize: none;
  width: 100%;

  &:focus {
    border: 1px solid #5943c1;
  }
`;

const Label = styled.label`
  color: #281b65;
  font-size: 12px;
  font-weight: 500;
  margin: 0 0 4px 0;
`;
