export class DaD {
  static onDragStart(ev, data, type, cb) {
    data["id"] = Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
    data["type"] = type;
    ev.dataTransfer.effectAllowed = "copy";
    ev.dataTransfer.setData("text/plain", JSON.stringify(data));
    if (cb !== undefined) cb();
  }

  static onDragEnd(ev, cb) {
    if (cb !== undefined) cb();
  }

  static onDrop(ev, cb) {
    ev.preventDefault();
    const data = JSON.parse(ev.dataTransfer.getData("text"));
    let target = null;

    if (data.type === "move") {
      ev.dataTransfer.dropEffect = "move";
      ev.preventDefault();
      target = ev.target;
    }

    if (cb !== undefined) cb(data, target);
  }

  static onDragOver(ev) {
    ev.preventDefault();
  }
}
