import styled from "styled-components";
import { CheckIcon } from "../../../../assets/icons";

export const TableFlex = styled.div`
  display: flex;
`;

export const Table = styled.div``;

export const RowHeader = styled.div`
  place-items: center;
  color: #281b65;
  display: flex;
  font-family: "Roboto";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  text-align: center;

  & > div {
    background: #e3eaff;
    width: 50px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & > .__dark-bg {
    background: #c2d2ff;
  }

  & > div:first-child {
    width: 145px;

    background: #f8f8f8;
    color: #182024;
    font-weight: 500;
  }
`;

export const Row = styled(RowHeader)`
  & > div:first-child {
    color: #281b65;
    font-weight: 400;
    background: #e3eaff;
    border: 0.3px solid #e3eaff;
  }

  & > div {
    background: #f8f8f8;
    border: 0.3px solid rgba(241, 241, 241, 0.6);
  }
`;

export const Check = styled(CheckIcon)`
  fill: #fdad34;

  &.__blue {
    fill: #281b65;
  }
`;

export const InfoText = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  & > p {
    margin: 0 10px 0 0;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #281b65;

    &:last-child {
      color: #fdad34;
    }
  }
`;

export const ReferenceText = styled.p`
  max-width: 430px;
  font-weight: 400;
  font-size: 10px;

  color: #9aa2a8;
`;
