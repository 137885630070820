import styled from "styled-components";
import PhoneInput from "react-phone-input-2";

export const IconsContainer = styled.div`
  & > svg {
    cursor: pointer;

    &:first-child {
      margin-right: 10px;
    }

    &:last-child {
      margin-left: 10px;
    }

    &:hover {
      opacity: 0.7;
    }
  }
`;

export const Container = styled.div`
    & > div {
    margin: 15px 0;

    &:first-child {
      margin-top: 0;
    }
  }
`;

export const PhoneInputStyled = styled(PhoneInput)`
  & > .form-control {
    height: 46px;
    width: 100%;
    border-radius: 4px;
    border: 0.5px solid #9aa2a8;

    &:focus {
      box-shadow: none;
    }
  }

  & > .special-label {
    font-size: 14px;
    color: ${({ focus }) => (focus ? "#281b65" : "#9aa2a8")};
    font-weight: 300;
    left: 15px;
    top: -10px;
    padding: 0 5px;
    transition: color 0.2s;
    ${({ required }) =>
      required &&
      `
      &::after {
        content: "*";
        color: red;
      }
    `}
  }
`;