import React, { Component, Fragment } from "react";
import { Grid } from "@material-ui/core";
import TimePicker from "../timePicker";
import Counter from "../counter";
import Slider from "../slider";
import { withTranslation } from "react-i18next";
import TableCicloergometro from "../tableCicloergometro";
import Tab from "../../components/tab";
import ChartTF from "../chartTf/cicloergometro";
// import ImgBike from "../../assets/img/bloques/bici-cicloergometro.svg";
// import { param } from "jquery";

class Cicloergometro extends Component {
  constructor() {
    super();
    this.state = {
      duracion: { hours: 0, minutes: 0, seconds: 0 },
      potencia: 0,
      frecuencia_cardiaca_min: 60,
      frecuencia_cardiaca_max: 140,
      select: 0,
      type: 0,
      data: [],
    };
  }

  values() {
    if (this.props.values !== undefined) {
      this.props.values(this.state);
    }
  }

  componentDidUpdate() {
    this.values();
  }

  componentDidMount() {
    this.values();
    if (this.props.data.params && Object.keys(this.props.data.params).length !== 0) {
      let params = this.props.data.params;
      this.setState({
        data: params.data,
        duracion: params.duracion,
        frecuencia_cardiaca_max: params.frecuencia_cardiaca_max,
        frecuencia_cardiaca_min: params.frecuencia_cardiaca_min,
        potencia: params.potencia,
        select: params.select,
        type: params.type,
      });
    }
  }

  getData() {
    return this.state.data.map((e) => {
      return e.data;
    });
  }

  appendData() {
    let d = Object.assign([], this.state.data);
    let pos = d.length === 0 ? 1 : d.length - 1;
    let obj = {
      pos: pos,
      time: this.state.duracion,
      tiempo: this.getTimeToStr(this.state.duracion),
      fase: this.state.type === 0 ? "Paso" : "Rampa",
      type: this.state.type,
      potencia: this.state.potencia,
    };

    d.push(obj);
    this.setState({ data: d });
  }

  removeItem(pos) {
    let d = Object.assign([], this.state.data);
    d.splice(
      d.findIndex((e) => e.pos === pos),
      1
    );
    this.setState({
      data: d.sort(function (a, b) {
        return a.pos - b.pos;
      }),
    });
  }

  getTimeToStr(obj, h = false) {
    const { hours, minutes, seconds } = obj;
    return `${h ? (hours < 10 ? "0" + hours + ":" : hours + ":") : ""} ${
      minutes < 10 ? "0" + minutes : minutes
    }:${seconds < 10 ? "0" + seconds : seconds} `;
  }

  setCalentamientoTime(input) {
    const d = Object.assign([], this.state.data);
    d.map((e) => {
      if (e.id === "calentamiento") e["tiempo"] = this.getTimeToStr(input);
      return e;
    });

    this.setState({ calentamiento: input });
  }

  setCalentamientoPotency(input) {
    const d = Object.assign([], this.state.data);
    d.map((e) => {
      if (e.id === "calentamiento") e["potencia"] = input;
      return e;
    });

    this.setState({ potencia_calentamiento: input });
  }

  setRecuperacionTime(input) {
    const d = Object.assign([], this.state.data);
    d.map((e) => {
      if (e.id === "recuperacion") e["tiempo"] = this.getTimeToStr(input);
      return e;
    });

    this.setState({ recuperacion: input });
  }

  setRecuperacionPotency(input) {
    const d = Object.assign([], this.state.data);
    d.map((e) => {
      if (e.id === "recuperacion") e["potencia"] = input;
      return e;
    });

    this.setState({ potencia_recuperacion: input });
  }

  toInt(obj = { hours: 0, minutes: 0, seconds: 0 }) {
    let { hours, minutes, seconds } = obj;
    return hours * 3600 + minutes * 60 + seconds;
  }

  transformArrData(data) {
    return data.map((e) => {
      return [
        this.parseTimeString(e.time.minutes, e.time.seconds),
        this.toInt(e.time),
        e.potencia,
        { color: "#281B65", type: parseInt(e.type) },
      ];
    });
  }

  parseTimeString(m, s) {
    let str = "";
    if (m > 0) str += m + "m ";
    if (s > 0) str += s + "s";
    return str;
  }

  render() {
    const { t } = this.props;
    return (
      <Fragment>
        <Grid container direction="row" justifyContent="center">
          <Grid item xs={12} md={7} className="pl-4">
            <Tab
              value={this.state.select}
              onChange={(i) => this.setState({ select: i })}
              tabs={[
                {
                  title: t("graphic"),
                  content: (
                    <div className="grafic my-4">
                      <ChartTF
                        data={{
                          data: this.transformArrData(this.state.data),
                          yLabel: t("potency"), //t('threshold')
                          xLabel: t("duration"),
                        }}
                      />
                    </div>
                  ),
                },
                {
                  title: t("information"),
                  content: (
                    <div>
                      <TableCicloergometro
                        data={this.state.data}
                        remove={(v) => this.removeItem(v)}
                      ></TableCicloergometro>
                    </div>
                  ),
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={5} className="pl-4">
            <h6 className="title-calendar my-3">{t("adjustments")}</h6>
            <Grid
              item
              xs={12}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} md={6} className="pr-1">
                <div className="border-tf py-2 px-3">
                  <p className="m-0 fz-10 color-sub-text">{t("frecuecyCard")} (Mín. 60 - 160)</p>
                  <p className="m-0 color-primario fz-16">
                    <strong>{this.state.frecuencia_cardiaca_min}</strong>
                  </p>
                  <Slider
                    title={t("frecuecyCard")}
                    min={60}
                    max={160}
                    defaultValue={this.state.frecuencia_cardiaca_min}
                    onChange={(value) => this.setState({ frecuencia_cardiaca_min: value })}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6} className="pl-1">
                <div className="border-tf py-2 px-3">
                  <p className="m-0 fz-10 color-sub-text">{t("frecuecyCardMax")} (Máx. 90 - 220)</p>
                  <p className="m-0 color-primario fz-16">
                    <strong>{this.state.frecuencia_cardiaca_max}</strong>
                  </p>
                  <Slider
                    title={t("frecuecyCardMax")}
                    min={90}
                    max={220}
                    defaultValue={this.state.frecuencia_cardiaca_max}
                    onChange={(value) => this.setState({ frecuencia_cardiaca_max: value })}
                  />
                </div>
              </Grid>
            </Grid>
            <div className="bg-primario-300 px-2 pb-2 border-radius-4">
              <p className="title-calendar my-3 pt-2">{t("config")}</p>
              <Grid container direction="row" justifyContent="center">
                <Grid item xs={12} md={4} className="pr-1">
                  <div className="border-radius-3 py-2 px-3 bg-white" style={{ height: 101 }}>
                    <p className="fz-10 color-sub-text m-0">{t("type")}</p>
                    <select
                      style={{
                        height: 35,
                        padding: "0px 8px",
                        cursor: "pointer",
                      }}
                      className="form-control my-3 d-block w-100"
                      onChange={(v) => this.setState({ type: v.target.value })}
                    >
                      <option value={0}>{t("step")}</option>
                      <option value={1}>{t("ramp")}</option>
                    </select>
                  </div>
                </Grid>
                <Grid item xs={12} md={4} className="px-1">
                  <div className="border-radius-3 py-2 px-3 bg-white">
                    <p className="fz-10 color-sub-text m-0">{t("step_ramp_duration")}</p>
                    <TimePicker
                      data={this.state.entrenamiento_paso}
                      minutes={true}
                      seconds={true}
                      values={(value) => this.setState({ duracion: value })}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={4} className="pl-1">
                  <div className="border-radius-3 py-2 px-3 bg-white">
                    <p className="fz-10 color-sub-text m-0">{t("potency")}</p>
                    <Counter
                      data={this.state.potencia_paso}
                      title="0 - 100"
                      min={0}
                      max={100}
                      values={(value) => this.setState({ potencia: value })}
                    />
                  </div>
                </Grid>
                <button className="mt-2 btn btn-tf-xs w-100" onClick={() => this.appendData()}>
                  {t("add")}
                </button>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default withTranslation("sessionStudio")(Cicloergometro);
