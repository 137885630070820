import React, { useEffect } from 'react';
import ChartsTF from './cicloergometro_logic';
import './style.css';

const ChartTF = (props) => {

    useEffect(() => {
        ChartsTF(props.data);
    }, [props.data]);

    return (
        <div className="chart" id="chart">
            <div className="xLineContainer"></div>
            <div className="hidden" id="boxChart">
                <svg height="520" width="2000" id="contSvg" version="1.2" xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink" className="graph" aria-labelledby="title" role="img" style={{ border: 'none' }}>
                    {/* <g className="grid x-grid" id="xGrid"><line x1="0" x2="0" y1="0" y2="540" stroke="#281B65"></line></g> */}
                    <g className="grid y-grid" id="yGrid"><line id="lineY" x1="0" x2="705" y1="500" y2="500" stroke="#281B65"></line></g>
                    <g className="labels x-labels" id="y"></g>
                    <g className="labels y-labels" id="x"></g>
                    <g className="data" id="v" data-setname="Our first data set" transform="translate(0,500) scale(1,-1)"></g>
                    <use xlinkHref="#y" />
                    <use xlinkHref="#x" />
                </svg>
            </div>
            <p className="labelx">{props.data.yLabel}</p>
            <p className="labely">{props.data.xLabel}</p>
        </div>
    )
}

export default ChartTF;