import * as Action from "./actions";

export const initialState = {
  selectedId: "",
  idCurrent: null,
  activeTab: 0,

  // Fields
  name: "",
  desc: "",

  // Modals
  newDataModal: false,
  removeModal: false,

  // Filter
  filtered: [],
  inputFilter: "",
  dropdownFilter: "name",
};

export const reducer = (state, action) => {
  switch (action.type) {

    case Action.CLEAR_STATE:
      return {
        ...state,
        selectedId: "",
        idCurrent: null,

        // Fields
        name: "",
        desc: "",

        // Modals
        newDataModal: false,
        removeModal: false,
        inputFilter: "",
        dropdownFilter: "name",
      };

    case Action.SET_FIELDS:
      return {
          ...state,
          [action.payload.field]: action.payload.value,
      };

    default:
      return state;
  }
};
