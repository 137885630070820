import * as Action from "./actions";

export const initialState = {
  isPosting: false,

  isEditing: false,
  idToEdit: null,

  country: "",
  commune: "",
  region: "",
  address: "",
  stateAddress: "",

  genre: undefined,

  openModal: false,

  specialities: [],
  selectedSpecialities: [],

  currentSpeciality: null,

  currentRol: null,
  isUserActive: true,

  result: null,
  schedule: {},

  filter: "",

  defaultSelectOption: undefined,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case Action.POSTING_DATA:
      return {
        ...state,
        isPosting: action.payload,
      };

    case Action.ADD_SPECIALITY:
      return {
        ...state,
        selectedSpecialities: [...state.selectedSpecialities, action.payload],
      };

    case Action.REMOVE_SPECIALITY:
      return {
        ...state,
        selectedSpecialities: state.selectedSpecialities.filter(
          (speciality) => speciality !== action.payload
        ),
      };

    case Action.SET_FIELD:
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };

    case Action.OPEN_MODAL:
      return {
        ...state,
        openModal: true,
      };

    case Action.SET_EDIT:
      return {
        ...state,
        isEditing: true,
        openModal: true,
        idToEdit: action.payload._id,
        currentRol: action.payload.rol ? action.payload.rol : null,
        country: action.payload.country || "",
        commune: action.payload.commune || "",
        region: action.payload.region || "",
        address: action.payload.address || "",
        genre: action.payload.genre
          ? { label: action.payload.genre, value: action.payload.genre }
          : undefined,
        isUserActive: action.payload.is_available,
        stateAddress: `${action.payload.address || ""}`,
        defaultSelectOption: action.payload.genre
          ? { label: action.payload.genre, value: action.payload.genre }
          : {},
        selectedSpecialities: action.payload.specialities.map(({ _id }) => _id),
      };

    case Action.RESET_DATA:
      return {
        ...state,
        isPosting: false,
        isEditing: false,
        idToEdit: null,
        country: "",
        commune: "",
        region: "",
        address: "",
        stateAddress: "",
        openModal: false,
        selectedSpecialities: [],
        currentSpeciality: null,
        currentRol: null,
        isUserActive: true,
        result: null,
        schedule: {},
        defaultSelectOption: undefined,
      };

    case Action.GET_DATA_ADDRESS:
      return {
        ...state,
        country: action.payload.country,
        commune: action.payload.state,
        region: action.payload.region,
        address: `${action.payload.addressComplete || ""}`,
        stateAddress: action.payload.addressComplete,
      };
    default:
      return state;
  }
};
