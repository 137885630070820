export const formValidation = ( state, t, notify ) => {
  if (state.name === null || state.name.length === 0 || state.name === "") {
    notify({ type: "error", title: t("evaluations"), text: t("notify.name") });
    return false;
  }

  if (state.cat === null || state.cat.length === 0 || state.cat === "") {
    notify({ type: "error", title: t("evaluations"), text: t("notify.category") });
    return false;
  }

  if (state.type === null || state.type.length === 0 || state.type === "") {
    notify({ type: "error", title: t("evaluations"), text: t("notify.type") });
    return false;
  }

  if (state.varsData.length === 0) {
    notify({ type: "error", title: t("evaluations"), text: t("notify.variable") });
    return false;
  }

  if (state.instructions === null || state.instructions === undefined || state.instructions === "" || state.instructions.length === 0) {
    notify({
      type: "error",
      title: t("evaluations"),
      text: t("notify.instructions_required"),
    });
    return false;
  }

  return true
}

export function validateVarsData(varsData) {
  for (let i = 0; i < varsData.length; i++) {
    if (varsData[i].name === "" || varsData[i].name === undefined) {
      return { valid: false, error: "title_empty" };
    }

    if (varsData[i].type !== "paragraph" && varsData[i].type !== "number" && varsData[i].type !== "string" && varsData[i].type !== "textarea" && varsData[i].type !== "bool") {
      if (varsData[i].data.length === 0 || varsData[i].data[0].name === "") {
        return { valid: false, error: "options_empty" };
      }
    }
  }
  return { valid: true };
}
