import React from "react";
import * as I from "../../../assets/icons/modes-icons";
import AddSensor from "../../../assets/img/bloques/icon-addsensor.svg";

export const functionsData = (t, translateBlock, translateTraining) => {
  return [
    {
      name: t("secuential"),
      addsensor: (
        <div className="addsensor">
          <img src={AddSensor} alt=""></img>
        </div>
      ),
      desc: translateTraining("secuential"),
      icon: "icon-sequential",
      iconSVG: <I.SequentialIcon />,
      type: "function",
      mode: "graphic",
      params: {},
    },
    {
      name: t("sensor"),
      addsensor: (
        <div className="addsensor">
          <img src={AddSensor} alt=""></img>
        </div>
      ),
      desc: translateTraining("sensor"),
      icon: "icon-sensor",
      iconSVG: <I.SensorIcon />,
      type: "function",
      mode: "sensor",
      params: {},
    },
    {
      name: t("bike"),
      addsensor: (
        <div className="addsensor">
          <img src={AddSensor} alt=""></img>
        </div>
      ),
      desc: translateTraining("bike"),
      icon: "icon-bike",
      iconSVG: <I.BikeIcon />,
      type: "function",
      mode: "bike",
      params: {},
    },
    {
      name: t("rowing"),
      addsensor: (
        <div className="addsensor">
          <img src={AddSensor} alt=""></img>
        </div>
      ),
      desc: translateTraining("rowing"),
      icon: "icon-rowing",
      iconSVG: <I.RowingIcon />,
      type: "function",
      mode: "rowing",
      params: {},
    },
    {
      name: t("bipedestation"),
      addsensor: (
        <div className="addsensor">
          <img src={AddSensor} alt=""></img>
        </div>
      ),
      desc: translateTraining("bipedestation"),
      icon: "icon-bipedestation",
      iconSVG: <I.BipedestationIcon />,
      type: "function",
      mode: "bipedestacion",
      params: {},
    },
    {
      name: t("general"),
      desc: translateBlock("general"),
      icon: "icon-evaluation",
      iconSVG: <I.GeneralIcon />,
      type: "function",
      mode: "general",
      params: {},
    },
    {
      name: t("warning"),
      desc: translateBlock("warning"),
      icon: "icon-alert",
      iconSVG: <I.AdvertenciaIcon />,
      type: "function",
      mode: "warnings",
      params: {},
    },
    {
      name: t("pausa"),
      desc: translateBlock("pausa"),
      icon: "icon-pause",
      iconSVG: <I.DescansandoIcon />,
      type: "function",
      mode: "pause",
      params: {},
    },
    {
      name: t("pdf_files"),
      desc: translateBlock("pdf_files"),
      icon: "icon-training-therapist",
      iconSVG: <I.ArchivoIcon />,
      type: "function",
      mode: "records",
      params: {},
    },
    {
      name: t("video"),
      desc: translateBlock("video"),
      icon: "icon-video",
      iconSVG: <I.VideoIcon />,
      type: "function",
      mode: "video",
      params: {},
    },
    {
      name: t("evaluations"),
      desc: translateBlock("evaluations"),
      icon: "icon-form",
      iconSVG: <I.EvaluationIcon />,
      type: "function",
      mode: "evaluations",
      params: {},
    },
    {
      name: t("walkfes"),
      addsensor: (
        <div className="addsensor">
          <img src={AddSensor} alt=""></img>
        </div>
      ),
      desc: translateBlock("Walkfes"),
      icon: "icon-march",
      iconSVG: <I.MarchIcon />,
      type: "function",
      mode: "walkfes",
      params: {},
    },
    {
      name: t("treadmill"),
      desc: translateBlock("treadmill"),
      icon: "icon-treadmill",
      iconSVG: <I.TreadmillIcon />,
      type: "function",
      mode: "treadmill",
      params: {},
    },
    {
      name: t("biofeedback"),
      addsensor: (
        <div className="addsensor">
          <img src={AddSensor} alt=""></img>
        </div>
      ),
      desc: translateBlock("biofeedback"),
      icon: "icon-feedback",
      iconSVG: <I.FeedbackIcon />,
      type: "function",
      mode: "biofeedback",
      params: {},
    },
    {
      name: t("cicloergometro"),
      desc: translateBlock("cicloergometro"),
      icon: "icon-cicloergometro",
      iconSVG: <I.CicloergometroIcon />,
      type: "function",
      mode: "cicloergometro",
      params: {},
    },
  ];
};
