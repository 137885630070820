import React, { useEffect, useState } from "react";
import MediaManager from "./MediaManager/media";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import LoaderContainer from "../../../../components/loader/container";
import { Input, Button } from "trainfes-components-library";

import { SET_IMAGE_FILE, SET_QUESTION_IMAGE } from "../../reducer/evaluationsActions";

const mediaManager = new MediaManager();

export const ModalImage = ({ show, onClose, dispatch, questionId }) => {
  const { t } = useTranslation("evaluations");
  const [load, setLoad] = useState(true);
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageUrl, setSelectedImageUrl] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    getData(true);
  }, []);

  useEffect(() => {
    setSelectedImage(null);
    setSelectedImageUrl(null);
    setSearchValue("");
  }, [show]);

  const getData = (loader = false) => {
    setLoad(loader);
    mediaManager.getImages().then((res) => {
      setLoad(false);
      setImages(res);
    });
  };

  const handleImageClick = (image) => {
    if (selectedImage && selectedImage._id === image._id) {
      setSelectedImage(null);
      setSelectedImageUrl(null);
    } else {
      setSelectedImage(image);
      setSelectedImageUrl(image.src);
    }
  };

  const handleSelectClick = () => {
    if (selectedImage) {
      if (!questionId) {
        dispatch({ type: SET_IMAGE_FILE, payload: selectedImage });
      } else {
        dispatch({ type: SET_QUESTION_IMAGE, payload: { questionId: questionId, imageSrc: selectedImageUrl } });
      }
      onClose();
    }
  };
  

  const handleSearchChange = (value) => {
    setSearchValue(value);
  };

  const filteredImages = images.filter((image) => {
    return image.name.toLowerCase().includes(searchValue.toLowerCase());
  });

  return (
    <Modal show={show} onClose={onClose}>
      <ModalContentImage onClick={(e) => e.stopPropagation()}>
        <ModalHeader>
          <h5>{t("modal_image.title")}</h5>
        </ModalHeader>
        <ModalBody>
          <Input
            placeholder={t("modal_image.name")}
            onChange={(e) => {
              handleSearchChange(e.target.value);
            }}
            value={searchValue}
          />
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {load ? (
              <div style={{ height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <LoaderContainer />
              </div>
            ) : (
              <CardsContainer>
                {filteredImages.map((image) => (
                  <CardStyled
                    key={image._id}
                    onClick={() => handleImageClick(image)}
                    selected={selectedImage && selectedImage._id === image._id}
                    style={{ order: -1 }}
                  >
                    <ImageContainer>
                      <ImgStyled src={image.src} className="" alt="" />
                    </ImageContainer>
                    <ModalDescription>
                      {image.name.length > 20
                        ? image.name.substring(0, 20) + "..."
                        : image.name}
                    </ModalDescription>
                  </CardStyled>
                ))}
              </CardsContainer>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            type="ghost"
            label={t("modal_image.cancel")}
            onClick={ onClose }
          />
          <Button
              label={t("modal_image.select")}
              onClick={ handleSelectClick }
              disabled={ !selectedImage }
          />
        </ModalFooter>
      </ModalContentImage>
    </Modal>
  );
};

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1000;
  display: ${(props) => (props.show ? "flex" : "none")};
  justify-content: center;
  align-items: center;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ModalContentImage = styled.div`
  background-color: #fff;
  width: 553px;
  min-height: 533px;
  max-width: 700px;
  max-height: 600px;
  border-radius: 10px;
  padding: 27px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`;

const ModalBody = styled.div`
  width: 100%;
`;

const CardsContainer = styled.div`
  display: grid;
  background-color: #f3f6fd;
  grid-template-columns: repeat(4, 1fr);
  min-height: 300px;
  height: 300px;
  overflow-y: auto;
  box-sizing: border-box;
  margin: 0;
  margin-top: 20px;
  width: 100%;
  max-width: 600px;
  border: 1px dashed #ccc;
  border-radius: 10px;
  padding: 10px;
  & > div {
    height: 150px;
    width: 111px;
    overflow: hidden;
  }
`;

const CardStyled = styled.div`
  background-color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 180px;
  margin-top: 10px;
  ${(props) =>
    props.selected &&
    `
      background-color: #97b8ff;
  `}

  &:hover,
    &:active {
    background-color: #97b8ff;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImgStyled = styled.img`
  width: 100%;
  height: 92px;
  object-fit: contain;
  border-radius: 5px;
`;

const ModalDescription = styled.p`
  font-size: 12px;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 20px;

  button {
    margin-left: 10px;
    width: 150px;
  }
`;