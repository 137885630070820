import React from "react";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { CardExercise } from "../sessionTherapist.styles";

const getTimeStr = (timeObj) => {
    if ( timeObj === null ) return "00:00:00"
    if (timeObj !== undefined) {
        if (timeObj.hours !== undefined) {
            if (timeObj.minutes !== undefined) {
                if (timeObj.seconds !== undefined) {
                    return `${
                        timeObj.hours < 10 ? "0" + timeObj.hours : timeObj.hours
                    }:${
                        timeObj.minutes < 10
                            ? "0" + timeObj.minutes
                            : timeObj.minutes
                    }:${
                        timeObj.seconds < 10
                            ? "0" + timeObj.seconds
                            : timeObj.seconds
                    }`;
                } else return "-- : -- : --";
            } else return "-- : -- : --";
        } else return "-- : -- : --";
    } else {
        return "-- : -- : --";
    }
};

// const isUndefined = (d) => {
//     if (d === undefined) return " - ";
//     else return d;
// };

export const CardSensor = (props) => {
    const { t } = useTranslation("protocols");
    const { data } = props;
    return (
        <CardExercise>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className="card-records mb-3 px-3"
            >
                <Grid item xs={12}>
                    <h6 className="my-3 fz-16">{t("card.sensor_exercise")}</h6>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={4}>
                            <p className="m-0 color-sub-text fz-12">
                                {t("card.n_series")}
                            </p>
                        </Grid>
                        <Grid item xs={4}>
                            <p className="m-0 color-sub-text fz-12">
                                {t("card.n_contractions")}
                            </p>
                        </Grid>
                        <Grid item xs={4}>
                            <p className="m-0 color-sub-text fz-12">
                                {t("card.contraction_time")}
                            </p>
                        </Grid>
                    </Grid>
                    <hr className="my-1"></hr>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={4}>
                            <p className="m-0 fz-18 fw-500 mb-3 color-primario">
                                {data.params.series}
                            </p>
                        </Grid>
                        <Grid item xs={4}>
                            <p className="m-0 fz-18 fw-500 mb-3 color-secundario">
                                {data.params.num_contraccion !== undefined
                                    ? data.params.num_contraccion
                                    : "-"}
                            </p>
                        </Grid>
                        <Grid item xs={4}>
                            <p className="m-0 fz-18 fw-500 mb-3 color-primario">
                                {getTimeStr(data.params.contraccion)}
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </CardExercise>
    );
};

export const CardBike = (props) => {
    const { t } = useTranslation("protocols");
    const { data } = props;
    return (
        <CardExercise>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className="card-records mb-3 px-3"
            >
                <Grid item xs={12}>
                    <h6 className="my-3 fz-16">{t("card.bike_exercise")}</h6>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={4}>
                            <p className="m-0 color-sub-text fz-12">
                                {t("card.time")}
                            </p>
                        </Grid>
                        <Grid item xs={4}>
                            <p className="m-0 color-sub-text fz-12">
                                {t("card.workload")}
                            </p>
                        </Grid>
                        <Grid item xs={4}>
                            <p className="m-0 color-sub-text fz-12">
                                {t("card.desired_cadence_RPM")}
                            </p>
                        </Grid>
                    </Grid>
                    <hr className="my-1"></hr>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={4}>
                            <p className="m-0 fz-18 fw-500 mb-3 color-primario">
                                {getTimeStr(data.params.tiempo_trabajo)}
                            </p>
                        </Grid>
                        <Grid item xs={4}>
                            <p className="m-0 fz-18 fw-500 mb-3 color-secundario">
                                {data.params.carga_trabajo}
                            </p>
                        </Grid>
                        <Grid item xs={4}>
                            <p className="m-0 fz-18 fw-500 mb-3 color-primario">
                                {data.params.rpm}
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </CardExercise>
    );
};

export const CardRowing = (props) => {
    const { t } = useTranslation("protocols");
    const { data } = props;
    return (
        <CardExercise>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className="card-records mb-3 px-3"
            >
                <Grid item xs={12}>
                    <h6 className="my-3 fz-16">{t("card.rowing_exercise")}</h6>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={4}>
                            <p className="m-0 color-sub-text fz-12">
                                {t("card.time")}
                            </p>
                        </Grid>
                        <Grid item xs={4}>
                            <p className="m-0 color-sub-text fz-12">
                                {t("card.workload")}
                            </p>
                        </Grid>
                        <Grid item xs={4}>
                            <p className="m-0 color-sub-text fz-12">
                                {t("card.desired_cadence_RPM")}
                            </p>
                        </Grid>
                    </Grid>
                    <hr className="my-1"></hr>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={4}>
                            <p className="m-0 fz-18 fw-500 mb-3 color-primario">
                                {getTimeStr(data.params.tiempo_trabajo)}
                            </p>
                        </Grid>
                        <Grid item xs={4}>
                            <p className="m-0 fz-18 fw-500 mb-3 color-secundario">
                                {data.params.carga_trabajo}
                            </p>
                        </Grid>
                        <Grid item xs={4}>
                            <p className="m-0 fz-18 fw-500 mb-3 color-primario">
                                {data.params.rpm}
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </CardExercise>
    );
};

export const CardTens = (props) => {
    const { t } = useTranslation("protocols");
    const { data } = props;
    return (
        <CardExercise>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className="card-records mb-3 px-3"
            >
                <Grid item xs={12}>
                    <h6 className="my-3 fz-16">{t("card.tens_exercise")}</h6>
                    <h6 className="mb-3 fz-14 color-primario">{data.name}</h6>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={12}>
                            <p className="m-0 color-sub-text fz-12">
                                {t("card.time")}
                            </p>
                        </Grid>
                    </Grid>
                    <hr className="my-1"></hr>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={12}>
                            <p className="m-0 fz-18 fw-500 mb-3 color-secundario">
                                {getTimeStr(data.params.tiempo_trabajo)}
                            </p>
                        </Grid>
                    </Grid>
                    <h6 className="m-0 color-primario pb-1">
                        {t("card.desc")}
                    </h6>
                    <p className="m-0 fz-14 color-sub-text mb-3">{data.desc}</p>
                </Grid>
            </Grid>
        </CardExercise>
    );
};

export const CardNeuro = (props) => {
    const { t } = useTranslation("protocols");
    const { data } = props;
    return (
        <CardExercise>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className="card-records mb-3 px-3"
            >
                <Grid item xs={12}>
                    <h6 className="my-3 fz-16">
                        {t("card.neuromodulation_exercise")}
                    </h6>
                    <h6 className="mb-3 fz-14 color-primario">{data.name}</h6>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={12}>
                            <p className="m-0 color-sub-text fz-12">
                                {t("card.time")}
                            </p>
                        </Grid>
                    </Grid>
                    <hr className="my-1"></hr>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={12}>
                            <p className="m-0 fz-18 fw-500 mb-3 color-secundario">
                                {getTimeStr(data.params.tiempo_trabajo)}
                            </p>
                        </Grid>
                    </Grid>
                    <h6 className="m-0 color-primario pb-1">
                        {t("card.desc")}
                    </h6>
                    <p className="m-0 fz-14 color-sub-text mb-3">{data.desc}</p>
                </Grid>
            </Grid>
        </CardExercise>
    );
};

export const CardDescanso = (props) => {
    const { t } = useTranslation("protocols");
    const { data } = props;
    return (
        <CardExercise>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className="card-records mb-3 px-3"
            >
                <Grid item xs={12}>
                    <h6 className="my-3 fz-16">{t("card.break")}</h6>
                    <h6 className="mb-3 fz-14 color-primario">{data.name}</h6>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={12}>
                            <p className="m-0 color-sub-text fz-12">
                                {t("card.time")}
                            </p>
                        </Grid>
                    </Grid>
                    <hr className="my-1"></hr>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={12}>
                            <p className="m-0 fz-18 fw-500 mb-3 color-secundario">
                                {getTimeStr(data.params.tiempo_trabajo)}
                            </p>
                        </Grid>
                    </Grid>
                    <h6 className="m-0 color-primario pb-1">
                        {t("card.desc")}
                    </h6>
                    <p className="m-0 fz-14 color-sub-text mb-3">{data.desc}</p>
                </Grid>
            </Grid>
        </CardExercise>
    );
};

export const CardFlexibilidad = (props) => {
    const { t } = useTranslation("protocols");
    const { data } = props;
    return (
        <CardExercise>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className="card-records mb-3 px-3"
            >
                <Grid item xs={12}>
                    <h6 className="my-3 fz-16">
                        {t("card.flexibility_exercise")}
                    </h6>
                    <h6 className="mb-3 fz-14 color-primario">{data.name}</h6>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={6}>
                            <p className="m-0 color-sub-text fz-12">
                                {t("card.series")}
                            </p>
                        </Grid>
                        <Grid item xs={6}>
                            <p className="m-0 color-sub-text fz-12">
                                {t("card.repetitions")}
                            </p>
                        </Grid>
                    </Grid>
                    <hr className="my-1"></hr>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={6}>
                            <p className="m-0 fz-18 fw-500 mb-3 color-primario">
                                {data.params.series}
                            </p>
                        </Grid>
                        <Grid item xs={6}>
                            <p className="m-0 fz-18 fw-500 mb-3 color-secundario">
                                {data.params.repeticiones}
                            </p>
                        </Grid>
                    </Grid>
                    <h6 className="m-0 color-primario pb-1">
                        {t("card.desc")}
                    </h6>
                    <p className="m-0 fz-14 color-sub-text mb-3">
                        {data.desc || data.name}
                    </p>
                </Grid>
            </Grid>
        </CardExercise>
    );
};

export const CardCardio = (props) => {
    const { t } = useTranslation("protocols");
    const { data } = props;
    return (
        <CardExercise>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className="card-records mb-3 px-3"
            >
                <Grid item xs={12}>
                    <h6 className="my-3 fz-16">
                        {t("card.cardiovascular_exercise")}r
                    </h6>
                    <h6 className="mb-3 fz-14 color-primario">{data.name}</h6>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={4}>
                            <p className="m-0 color-sub-text fz-12">
                                {t("card.time")}
                            </p>
                        </Grid>
                        <Grid item xs={4}>
                            <p className="m-0 color-sub-text fz-12">
                                {t("card.series")}
                            </p>
                        </Grid>
                        <Grid item xs={4}>
                            <p className="m-0 color-sub-text fz-12">
                                {t("card.repetitions")}
                            </p>
                        </Grid>
                    </Grid>
                    <hr className="my-1"></hr>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={4}>
                            <p className="m-0 fz-18 fw-500 mb-3 color-primario">
                                {getTimeStr(data.params.tiempo_trabajo)}
                            </p>
                        </Grid>
                        <Grid item xs={4}>
                            <p className="m-0 fz-18 fw-500 mb-3 color-secundario">
                                {data.params.series}
                            </p>
                        </Grid>
                        <Grid item xs={4}>
                            <p className="m-0 fz-18 fw-500 mb-3 color-primario">
                                {data.params.repeticiones}
                            </p>
                        </Grid>
                    </Grid>
                    <h6 className="m-0 color-primario pb-1">
                        {t("card.desc")}
                    </h6>
                    <p className="m-0 fz-14 color-sub-text mb-3">{data.desc}</p>
                </Grid>
            </Grid>
        </CardExercise>
    );
};

export const CardGeneral = (props) => {
    const { t } = useTranslation("protocols");
    const { data } = props;
    return (
        <CardExercise>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className="card-records mb-3 px-3"
            >
                <Grid item xs={12}>
                    <h6 className="my-3 fz-16">{t("card.general_exercise")}</h6>
                    <h6 className="mb-3 fz-14 color-primario">{data.name}</h6>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={6}>
                            <p className="m-0 color-sub-text fz-12">
                                {t("card.series")}
                            </p>
                        </Grid>
                        <Grid item xs={6}>
                            <p className="m-0 color-sub-text fz-12">
                                {t("card.series_repetition")}
                            </p>
                        </Grid>
                    </Grid>
                    <hr className="my-1"></hr>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={6}>
                            <p className="m-0 fz-18 fw-500 mb-3 color-primario">
                                {data.params.series}
                            </p>
                        </Grid>
                        <Grid item xs={6}>
                            <p className="m-0 fz-18 fw-500 mb-3 color-secundario">
                                {data.params.repeticiones}
                            </p>
                        </Grid>
                    </Grid>
                    <h6 className="m-0 color-primario pb-1">
                        {t("card.desc")}
                    </h6>
                    <p className="m-0 fz-14 color-sub-text mb-3">{data.desc}</p>
                </Grid>
            </Grid>
        </CardExercise>
    );
};

export const CardAdvertencia = (props) => {
    const { t } = useTranslation("protocols");
    const { data } = props;
    return (
        <CardExercise>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className="card-records mb-3 px-3"
            >
                <Grid item xs={9}>
                    <h6 className="my-3 fz-16">{t("card.warning")}</h6>
                    <h6 className="mb-3 fz-14 color-primario">{data.name}</h6>
                    <h6 className="m-0 color-primario pb-1">
                        {t("card.desc")}
                    </h6>
                    <p className="m-0 fz-14 color-sub-text mb-3">{data.desc}</p>
                </Grid>
                <Grid
                    item
                    xs={3}
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <div className="icon mx-auto">
                        <span className="icon-help"></span>
                    </div>
                </Grid>
            </Grid>
        </CardExercise>
    );
};

export const CardFiles = (props) => {
    const { t } = useTranslation("protocols");
    return (
        <CardExercise>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className="card-records mb-3 px-3"
            >
                <Grid item xs={12}>
                    <h6 className="my-3 fz-16">{t("card.files")}</h6>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={6}>
                            <p className="m-0 color-sub-text fz-12">
                                {t("card.total_files")}
                            </p>
                        </Grid>
                    </Grid>
                    <hr className="my-1"></hr>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={6}>
                            <p className="m-0 fz-18 fw-500 mb-3 color-primario">
                                {props.data.params.files.length}
                            </p>
                        </Grid>
                    </Grid>
                    <h6 className="m-0 color-primario pb-1">
                        {t("card.desc")}
                    </h6>
                    <p className="m-0 fz-14 color-sub-text mb-3">
                        {props.data.name || props.data.desc}
                    </p>
                </Grid>
            </Grid>
        </CardExercise>
    );
};

export const CardVideos = (props) => {
    const { t } = useTranslation("protocols");
    return (
        <CardExercise>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className="card-records mb-3 px-3"
            >
                <Grid item xs={12}>
                    <h6 className="my-3 fz-16">{t("card.videos")}</h6>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={6}>
                            <p className="m-0 color-sub-text fz-12">
                                {t("card.total_videos")}
                            </p>
                        </Grid>
                    </Grid>
                    <hr className="my-1"></hr>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={6}>
                            <p className="m-0 fz-18 fw-500 mb-3 color-secundario">
                                {props.data.params.videos.length}
                            </p>
                        </Grid>
                    </Grid>
                    <h6 className="m-0 color-primario pb-1">
                        {t("card.desc")}
                    </h6>
                    <p className="m-0 fz-14 color-sub-text mb-3">
                        {props.data.desc != null
                            ? props.data.desc
                            : t("card.no_description_entered")}
                    </p>
                </Grid>
            </Grid>
        </CardExercise>
    );
};

export const CardSecuencial = (props) => {
    const { t } = useTranslation("protocols");
    const { data } = props;
    return (
        <CardExercise>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className="card-records mb-3 px-3"
            >
                <Grid item xs={12}>
                    <h6 className="my-3 fz-16">
                        {t("card.sequential_exercise")}
                    </h6>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={4}>
                            <p className="m-0 color-sub-text fz-12">
                                {t("card.time")}
                            </p>
                        </Grid>
                        <Grid item xs={4}>
                            <p className="m-0 color-sub-text fz-12">
                                {t("card.series")}
                            </p>
                        </Grid>
                        <Grid item xs={4}>
                            <p className="m-0 color-sub-text fz-12">
                                {t("card.n_contractions")}
                            </p>
                        </Grid>
                    </Grid>
                    <hr className="my-1"></hr>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={4}>
                            <p className="m-0 fz-18 fw-500 mb-3 color-primario">
                                { data.params.time 
                                  ? data.params.time
                                  : "00:00:00" }
                            </p>
                        </Grid>
                        <Grid item xs={4}>
                            <p className="m-0 fz-18 fw-500 mb-3 color-secundario">
                                {data.params.series}
                            </p>
                        </Grid>
                        <Grid item xs={4}>
                            <p className="m-0 fz-18 fw-500 mb-3 color-primario">
                                {data.params.num_contraccion}
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </CardExercise>
    );
};

export const CardMarcha = (props) => {
    const { t } = useTranslation("protocols");
    const { data } = props;
    return (
        <CardExercise>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className="card-records mb-3 px-3"
            >
                <Grid item xs={12}>
                    <h6 className="my-3 fz-16">
                        {t("card.marching_exercise")}
                    </h6>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={4}>
                            <p className="m-0 color-sub-text fz-12">
                                {t("card.series")}
                            </p>
                        </Grid>
                        <Grid item xs={4}>
                            <p className="m-0 color-sub-text fz-12">
                                {t("card.repetitions")}
                            </p>
                        </Grid>
                        <Grid item xs={4}>
                            <p className="m-0 color-sub-text fz-12">
                                {t("card.contraction_time")}
                            </p>
                        </Grid>
                    </Grid>
                    <hr className="my-1"></hr>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={4}>
                            <p className="m-0 fz-18 fw-500 mb-3 color-primario">
                                {data.params.series}
                            </p>
                        </Grid>
                        <Grid item xs={4}>
                            <p className="m-0 fz-18 fw-500 mb-3 color-secundario">
                                {data.params.repeticiones !== undefined
                                    ? data.params.repeticiones < 10
                                        ? "0" + data.params.repeticiones
                                        : data.params.repeticiones
                                    : "-"}
                            </p>
                        </Grid>
                        <Grid item xs={4}>
                            <p className="m-0 fz-18 fw-500 mb-3 color-primario">
                                {getTimeStr(data.params.contraccion)}
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </CardExercise>
    );
};

export const CardBipedestacion = (props) => {
    const { t } = useTranslation("protocols");
    const { data } = props;
    return (
        <CardExercise>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className="card-records mb-3 px-3"
            >
                <Grid item xs={12}>
                    <h6 className="my-3 fz-16">
                        {t("card.standing_exercise")}
                    </h6>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={4}>
                            <p className="m-0 color-sub-text fz-12">
                                {t("card.sitting")} - {t("card.stopped")}
                            </p>
                        </Grid>
                        <Grid item xs={4}>
                            <p className="m-0 color-sub-text fz-12">
                                {t("card.stopped")}
                            </p>
                        </Grid>
                        <Grid item xs={4}>
                            <p className="m-0 color-sub-text fz-12">
                                {t("card.stopped")} - {t("card.sitting")}
                            </p>
                        </Grid>
                    </Grid>
                    <hr className="my-1"></hr>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={4}>
                            <p className="m-0 fz-18 fw-500 mb-3 color-primario">
                                {getTimeStr(data.params.sentado_parado)}
                            </p>
                        </Grid>
                        <Grid item xs={4}>
                            <p className="m-0 fz-18 fw-500 mb-3 color-secundario">
                                {getTimeStr(data.params.parado)}
                            </p>
                        </Grid>
                        <Grid item xs={4}>
                            <p className="m-0 fz-18 fw-500 mb-3 color-primario">
                                {getTimeStr(data.params.parado_sentado)}
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </CardExercise>
    );
};

export const CardTreadmill = (props) => {
    const { t } = useTranslation("protocols");
    const { data } = props;
    const { params } = data;
    return (
        <CardExercise>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className="card-records mb-3 px-3"
            >
                <Grid item xs={12}>
                    <h6 className="my-3 fz-16">
                        {t("card.treadmill_exercise")}
                    </h6>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={6}>
                            <p className="m-0 color-sub-text fz-12">
                                {t("card.min_frequency")}
                            </p>
                        </Grid>
                        <Grid item xs={6}>
                            <p className="m-0 color-sub-text fz-12">
                                {t("card.max_frequency")}
                            </p>
                        </Grid>
                    </Grid>
                    <hr className="my-1"></hr>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={6}>
                            <p className="m-0 fz-18 fw-500 mb-3 color-primario">
                                {params.frecuencia_cardiaca_min}
                            </p>
                        </Grid>
                        <Grid item xs={6}>
                            <p className="m-0 fz-18 fw-500 mb-3 color-secundario">
                                {params.frecuencia_cardiaca_max}
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </CardExercise>
    );
};

export const CardWalkfes = (props) => {
    const { t } = useTranslation("protocols");
    const { data } = props;
    const { params } = data;
    return (
        <CardExercise>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className="card-records mb-3 px-3"
            >
                <Grid item xs={12}>
                    <h6 className="my-3 fz-16">{t("card.walkfes_exercise")}</h6>
                    {params.select === 0 ? (
                        <>
                            <h6 className="mb-3 fz-14 color-secundario">
                                {t("card.time")}
                            </h6>
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Grid item xs={4}>
                                    <p className="m-0 color-sub-text fz-12">
                                        {t("card.n_repetitions")}
                                    </p>
                                </Grid>
                                <Grid item xs={4}>
                                    <p className="m-0 color-sub-text fz-12">
                                        {t("card.pause_repeats")}
                                    </p>
                                </Grid>
                                <Grid item xs={4}>
                                    <p className="m-0 color-sub-text fz-12">
                                        {t("card.training_time")}
                                    </p>
                                </Grid>
                            </Grid>
                            <hr className="my-1"></hr>
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Grid item xs={4}>
                                    <p className="m-0 fz-18 fw-500 mb-3 color-primario">
                                        {params.repeticiones}
                                    </p>
                                </Grid>
                                <Grid item xs={4}>
                                    <p className="m-0 fz-18 fw-500 mb-3 color-secundario">
                                        {getTimeStr(params.pausa_repeticiones)}
                                    </p>
                                </Grid>
                                <Grid item xs={4}>
                                    <p className="m-0 fz-18 fw-500 mb-3 color-primario">
                                        {getTimeStr(params.tiempo_trabajo)}
                                    </p>
                                </Grid>
                            </Grid>
                        </>
                    ) : (
                        <>
                            <h6 className="mb-3 fz-14 color-secundario">
                                {t("card.steps")}
                            </h6>
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Grid item xs={4}>
                                    <p className="m-0 color-sub-text fz-12">
                                        {t("card.n_repetitions")}
                                    </p>
                                </Grid>
                                <Grid item xs={4}>
                                    <p className="m-0 color-sub-text fz-12">
                                        {t("card.pause_repeats")}
                                    </p>
                                </Grid>
                                <Grid item xs={4}>
                                    <p className="m-0 color-sub-text fz-12">
                                        {t("card.n_steps")}
                                    </p>
                                </Grid>
                            </Grid>
                            <hr className="my-1"></hr>
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Grid item xs={4}>
                                    <p className="m-0 fz-18 fw-500 mb-3 color-primario">
                                        {params.repeticiones}
                                    </p>
                                </Grid>
                                <Grid item xs={4}>
                                    <p className="m-0 fz-18 fw-500 mb-3 color-secundario">
                                        {getTimeStr(params.pausa_repeticiones)}
                                    </p>
                                </Grid>
                                <Grid item xs={4}>
                                    <p className="m-0 fz-18 fw-500 mb-3 color-primario">
                                        {params.num_steps}
                                    </p>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>
        </CardExercise>
    );
};

export const CardCicloergometro = (props) => {
    const { t } = useTranslation("protocols");
    const { data } = props;
    const { params } = data;
    return (
        <CardExercise>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className="card-records mb-3 px-3"
            >
                <Grid item xs={12}>
                    <h6 className="my-3 fz-16">
                        {t("card.cycloergometer_exercise")}
                    </h6>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={6}>
                            <p className="m-0 color-sub-text fz-12">
                                {t("card.min_frequency")}
                            </p>
                        </Grid>
                        <Grid item xs={6}>
                            <p className="m-0 color-sub-text fz-12">
                                {t("card.max_frequency")}
                            </p>
                        </Grid>
                    </Grid>
                    <hr className="my-1"></hr>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={6}>
                            <p className="m-0 fz-18 fw-500 mb-3 color-primario">
                                {params.frecuencia_cardiaca_min}
                            </p>
                        </Grid>
                        <Grid item xs={6}>
                            <p className="m-0 fz-18 fw-500 mb-3 color-secundario">
                                {params.frecuencia_cardiaca_max}
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </CardExercise>
    );
};

export const CardBiofeedback = (props) => {
    const { t } = useTranslation("protocols");
    const { data } = props;
    const { params } = data;
    return (
        <CardExercise>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className="card-records mb-3 px-3"
            >
                <Grid item xs={12}>
                    <h6 className="my-3 fz-16">
                        {t("card.biofeedback_exercise")}
                    </h6>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={4}>
                            <p className="m-0 color-sub-text fz-12">
                                {t("card.type")}
                            </p>
                        </Grid>
                        <Grid item xs={4}>
                            <p className="m-0 color-sub-text fz-12">
                                {t("card.mode")}
                            </p>
                        </Grid>
                        <Grid item xs={4}>
                            <p className="m-0 color-sub-text fz-12">
                                {t("card.sensor")}
                            </p>
                        </Grid>
                    </Grid>
                    <hr className="my-1"></hr>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={4}>
                            <p className="m-0 fz-18 fw-500 mb-3 color-primario">
                                {params.type && params.type.name}
                            </p>
                        </Grid>
                        <Grid item xs={4}>
                            <p className="m-0 fz-18 fw-500 mb-3 color-secundario">
                                {params.modo && params.modo.name}
                            </p>
                        </Grid>
                        <Grid item xs={4}>
                            <p className="m-0 fz-18 fw-500 mb-3 color-primario">
                                {params.sensor}
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </CardExercise>
    );
};

export const CardEvaluation = (props) => {
    const { t } = useTranslation("protocols");
    const { data } = props;
    const e = data.params;

    const getType = (str) => {
        switch (str) {
            case "string":
                return "Texto";
            case "number":
                return "Numero";
            case "textarea":
                return "Observación";
            case "bool":
                return "Si/No";
            default:
                return "Texto"
        }
    };

    return (
        <CardExercise>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className="card-records mb-3 px-3"
            >
                <Grid item xs={12}>
                    <h6 className="my-3 fz-16">{t("evaluations")}</h6>
                    <h6 className="mb-3 fz-14 color-primario">{data.name}</h6>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={12}>
                            <p className="m-0 color-sub-text fz-12">
                                {t("evaluations")}
                            </p>
                        </Grid>
                    </Grid>
                    <hr className="my-1"></hr>
                    <h6 className="mb-3 fz-14">
                        {t("evaluation")} : {e.evaluationCurrent.name}
                    </h6>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={12}>
                            {e.evaluationCurrent &&
                                e.evaluationCurrent.variables.map((u, i) => {
                                    return (
                                        <p key={i} className="color-primario fz-12">
                                            Variable:{" "}
                                            <strong className="color-secundario fz-16">
                                                {" "}
                                                {u.name}
                                            </strong>{" "}
                                            [{getType(u.type)}]
                                        </p>
                                    );
                                })}
                        </Grid>
                    </Grid>
                    <h6 className="m-0 color-primario pb-1">
                        {t("card.desc")}
                    </h6>
                    <p className="m-0 fz-14 color-sub-text mb-3">{data.desc}</p>
                </Grid>
            </Grid>
        </CardExercise>
    );
};
