import React from 'react';
import { Fab, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { withStyles } from '@material-ui/core/styles';


const useStyles = theme => ({
    absolute: {
        backgroundColor: '#281B65',
        color: '#FFF',
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(3),
        zIndex: '1',
        margin: '10px 5px',
        '&:focus': {
            outline: 'none',
        },
        '&:hover': {
            backgroundColor: '#281B65',
        },
    },
});


class ButtonAdd extends React.Component {
    render() {
        return (
            <Tooltip  title="Add" aria-label="add">
                <Fab className={this.props.classes.absolute} onClick={() => this.props.action()}>
                    <AddIcon />
                </Fab>
            </Tooltip>
        )
    }
}


export default withStyles(useStyles)(ButtonAdd);