import styled from "styled-components";

export const Block = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr 80px;
  place-items: center;
  margin: 10px 0;
  width: 274px;
  height: 42px;
  background: ${({ active }) => (active ? "#f6f6f6" : "#fff")};
  pointer-events: ${({ nomodify }) => (nomodify ? "none" : "auto")};
  border-radius: 4px;

  & > .MuscleSelector--icons {
    display: flex;
    & > svg {
      margin: 0 2px;
      cursor: pointer;
      fill: #9aa2a8;

      &.icon_active {
        fill: #281b65;
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }
`;

export const Channel = styled.div`
  align-items: center;
  background: ${({ blue }) => (blue ? "#281b65" : "#FDAD34")};
  border-radius: 50%;
  color: white;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: 400;
  height: 30px;
  justify-content: center;
  width: 30px;
  opacity: ${({ active }) => (active ? 1 : 0.6)};
`;

export const Name = styled.p`
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 14px;
  place-self: center flex-start;
`;
