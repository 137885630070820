export const LOADING = "[Evaluations] Loading";

export const SET_CATEGORIES = "[Evaluations] Set Categories";
export const SET_EVALUATIONS = "[Evaluations] Set Evaluations";

export const SET_VARS_DATA = "[Evaluations] Set Variables Data";
export const SET_IMAGE_FILE  = "[Evaluations] Set Image File";
export const SET_QUESTION_IMAGE = "[Evaluations] Set Question Image";
export const SET_SERVER_IMAGES = "[Evaluations] Set Server Images";

export const UPDATE_VARS_DATA = "[Evaluations] Update Variables Data";

export const OPEN_MODAL = "[Evaluations] Open Modals";

export const OPEN_EDIT = "[Evaluations] Open Edit";
export const OPEN_PREVIEW = "[Evaluations] Open Preview";
export const CLOSE_EDIT = "[Evaluations] Close Edit";

export const REMOVE = "[Evaluations] Delete Evaluation";

export const CLEAR_STATE = "[Evaluations] Clear state";
export const CLEAR_QUESTION_IMAGE = "[Evaluations] Update Question";
export const SET_INPUT_FIELD = "[Evaluations] Set Input fields";
export const SET_FIELDS = "[Evaluations] Set fields";
