import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { RadioInput } from "../RadioInputsComp/RadioInput";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { SET_VARS_DATA } from "../../reducer/evaluationsActions";
import { DropDown } from "../../../../components/DropDown";

export const LinearScaleComp = ({
  state,
  dispatch,
  type,
  editingId,
  questionId,
  setTypeRenderData,
  stored,
  typeRenderData,
}) => {
  const [num1, setNum1] = useState("1");
  const [num2, setNum2] = useState("5");
  const [etiqueta1, setEtiqueta1] = useState("");
  const [etiqueta2, setEtiqueta2] = useState("");

  const [options1] = useState(["0", "1"]);
  const [options2] = useState(["2", "3", "4", "5", "6", "7", "8", "9", "10"]);

  const { t } = useTranslation("evaluations");
  const { varsData } = state;

  useEffect(() => {
    if (typeRenderData[0] && typeRenderData[0].name) {
      setNum1(typeRenderData[0].name);
    } else {
      setNum1('1');
    }

    if (typeRenderData[1] && typeRenderData[1].name) {
      setNum2(typeRenderData[1].name);
    } else {
      setNum2('5');
    }

    if (typeRenderData[2] && typeRenderData[2].name) {
      setEtiqueta1(typeRenderData[2].name);
    } else {
      setEtiqueta1('');
    }

    if (typeRenderData[3] && typeRenderData[3].name) {
      setEtiqueta2(typeRenderData[3].name);
    } else {
      setEtiqueta2('');
    }
  }, [typeRenderData]);
  
  const onChangeInput = ({ value, type, index }) => {
    const newInput = {
      id: uuidv4(),
      name: value,
    };
  
    setTypeRenderData((prev) => {
      const newTypeRenderData = [...prev];
      newTypeRenderData[index - 1] = newInput;
      return newTypeRenderData;
    });
  
    if (type === 'select') {
      if (index === 1) {
        setNum1(value);
      } else {
        setNum2(value);
      }
    } else if (type === 'radio') {
      if (index === 3) {
        setEtiqueta1(value);
      } else {
        setEtiqueta2(value);
      }
    }
  };

  const onChangeEditing = (id, e) => {
    const indexToUpdate = typeRenderData.findIndex((el) => el.id === id);

    if (indexToUpdate === 0) {
      setNum1(e.target.value);
    } else if (indexToUpdate === 1) {
      setNum2(e.target.value);
    }

    const newDataArr = [...varsData];
    const index = newDataArr.findIndex((el) => el.id === questionId);
    const index2 = newDataArr[index] ? newDataArr[index].data.findIndex((el) => el.id === id) : -1;
    if (index2 >= 0) {
      newDataArr[index].data[index2] = {
        ...newDataArr[index].data[index2],
        name: e.target.value,
      };
    }

    dispatch({ type: SET_VARS_DATA, payload: newDataArr });
  };
  

  const onRemoveEditing = (id) => {
    if (!id) {
      return;
    }
    const newDataArr = [...varsData];
    const index = newDataArr.findIndex((el) => el.id === questionId);
    const index2 = newDataArr[index] ? newDataArr[index].data.findIndex((el) => el.id === id) : -1;
    if (index2 >= 0) {
      newDataArr[index].data[index2] = {
        ...newDataArr[index].data[index2],
        name: "",
      };
    }

    dispatch({ type: SET_VARS_DATA, payload: newDataArr });
  };

  if (stored && editingId === questionId) {
    return (
      <>
        <div className="__inputs">
          <ContainerSelect>
            <DropDown
              value={typeRenderData[0] ? typeRenderData[0].name : ""}
              options={options1}
              onChange={(e) => typeRenderData[0] && onChangeEditing(typeRenderData[0].id, e)}
            />
            <div>a</div>
            <DropDown
              value={typeRenderData[1] ? typeRenderData[1].name : ""}
              options={options2}
              onChange={(e) => typeRenderData[1] && onChangeEditing(typeRenderData[1].id, e)}
            />
          </ContainerSelect>
          <RadioInput
            key={1}
            index={1}
            type="scale_linear"
            id={uuidv4()}
            stored
            editingId={editingId}
            isActive
            placeholder={typeRenderData[2] ? typeRenderData[2].name : t("scale_linearOptions.label")}
            value={typeRenderData[2] ? typeRenderData[2].name : ""}
            onChange={(e) => { onChangeEditing(typeRenderData[2].id, e) }}
            onRemove={() => onRemoveEditing(typeRenderData[2] ? typeRenderData[2].id : undefined)}
            hideCheckmark
            numLabel={num1}
          />
          <RadioInput
            key={2}
            index={2}
            type="scale_linear"
            id={uuidv4()}
            stored
            editingId={editingId}
            isActive
            placeholder={typeRenderData[3] ? typeRenderData[3].name : t("scale_linearOptions.label")}
            value={typeRenderData[3] ? typeRenderData[3].name : ""}
            onChange={(e) => { onChangeEditing(typeRenderData[3].id, e) }}
            onRemove={() => onRemoveEditing(typeRenderData[3] ? typeRenderData[3].id : undefined)}
            hideCheckmark
            numLabel={num2}
          />
        </div>
      </>
    );
  }

  if (stored) {
    const min = typeRenderData[0] && typeRenderData[0].name ? parseInt(typeRenderData[0].name) : 1;
    const max = typeRenderData[1] && typeRenderData[1].name ? parseInt(typeRenderData[1].name) : 5;
    const range = Array.from(Array(max - min + 1), (_, i) => i + min);
    const etiq1 = typeRenderData[2] && typeRenderData[2].name ? typeRenderData[2].name : "";
    const etiq2 = typeRenderData[3] && typeRenderData[3].name ? typeRenderData[3].name : "";
  
    return (
      <ContainerHorizontal>
        {range.map((num, i) => (
          <ContainerVertical key={i}>
            <React.Fragment key={i}>
              {etiq1 === "" && etiq2 === "" ? (
                <>
                  <RadioInput
                    index={i + 1}
                    type={type}
                    stored
                    isActive
                    placeholder={`${t("option")} ${i + 1}`}
                  />
                  <NumberOption>{num}</NumberOption>
                </>
              ) : (
                <>
                  {i === 0 ? (
                    <>
                      {etiq1 !== "" ? <NumberOption>{etiq1}</NumberOption> : <NumberOptionBlank />}
                    </>
                  ) : i === range.length - 1 ? (
                    <>
                      {etiq2 !== "" ? <NumberOption>{etiq2}</NumberOption> : <NumberOptionBlank />}
                    </>
                  ) : (
                    <NumberOptionBlank></NumberOptionBlank>
                  )}
                  <RadioInput
                    index={i + 1}
                    type={type}
                    stored
                    isActive
                    placeholder={`${t("option")} ${i + 1}`}
                  />
                  <NumberOption>{num}</NumberOption>
                  {i < range.length - 1 && <span />}
                </>
              )}
            </React.Fragment>
          </ContainerVertical>
        ))}
      </ContainerHorizontal>
    );
  }
  
  
  return (
    <ContainerEval>
      <ContainerSelect>
        <DropDown
          value={num1}
          options={options1}
          onChange={(e) => {
            onChangeInput({ value: e.target.value, type: 'select', index: 1 });
          }}
        />
        <div>a</div>
        <DropDown
          value={num2}
          options={options2}
          onChange={(e) => {
            onChangeInput({ value: e.target.value, type: 'select', index: 2 });
          }}
        />
      </ContainerSelect>
      <RadioInput
        key={1}
        index={1}
        isActive
        placeholder={t("scale_linearOptions.label")}
        value={etiqueta1}
        onChange={(e) => onChangeInput({ value: e.target.value, type: 'radio', index: 3 })}
        onRemove={() => onChangeInput({ value: "", type: 'radio', index: 3 })}
        hideCheckmark
        type="scale_linear"
        numLabel={num1}
      />
      <RadioInput
        key={2}
        index={2}
        isActive
        placeholder={t("scale_linearOptions.label")}
        value={etiqueta2}
        onChange={(e) => onChangeInput({ value: e.target.value, type: 'radio', index: 4 })}
        onRemove={() => onChangeInput({ value: "", type: 'radio', index: 4 })}
        hideCheckmark
        type="scale_linear"
        numLabel={num2}
      />
    </ContainerEval>
  );
};

const ContainerEval = styled.div`
  margin-bottom: 20px;
`;

const ContainerHorizontal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  justify-content: center;

  span {
    /* width: 100%; */
    height: 1px;
    align-items: center;
    text-align: center;
    /* background-color: #fdad34; */
  }

  label {
    left: 16px;
  }

  div {
    display: grid;
    justify-items: center;
    min-width: 27px;
    align-content: center;
  }
`;

const ContainerVertical = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NumberOption = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 21px 0;
  max-width: 27px;
  max-height: 22px;
  justify-content: center;
  flex-grow: 1;
`;

const NumberOptionBlank = styled.div`
  width: 100%;
  height: 22px;
  display: flex;
  margin: 21px 0px;
  flex-grow: 1;
`;

const ContainerSelect = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;

  div {
    margin: 0 4px;
  }
`;