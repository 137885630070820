import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import TableFlow from '../../components/tableflow/tableflow.component';
import { getQueryStringParams } from '../../lib/router'
import { Row, Col } from '../../components/grilla/grid.component'
import axios from 'axios';
import { BoxUSerData, WrapperView, BoxLegendWrapper, BoxLegend, WrapperLegens } from './styles'
import Loading from '../../components/loader/container'

const ViewFlowTeleton = () => {
    const loc = useLocation();
    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(true);

    const getData = (id) => {
        axios.get(`/follow/teleton/user/${id}`)
            .then(res => {
                setData(res.data.data)
                setTimeout(() => setLoader(false), 1000)
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        const params = getQueryStringParams(loc.search)
        getData(params.ficha);
    }, [])

    return (
        <>
            <Loading active={loader} style={{background:"#f9f9f9"}}>
                <WrapperView>
                    <BoxUSerData>
                        <Row>
                            <Col md={6}>
                                <Row>
                                    <Col xs={6}>
                                        <span style={{ fontWeight: 700 }}>Ficha número:</span> {data && data.user && data.user.ficha}
                                    </Col>
                                    <Col xs={6}>
                                        <span style={{ fontWeight: 700 }}>Nombre paciente:</span> {data && data.user && data.user.name}
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={6} alignItems="center">
                                <WrapperLegens>
                                    <BoxLegendWrapper>
                                        <BoxLegend color="#39C4A5"></BoxLegend>
                                        <p>Realizadas</p>
                                    </BoxLegendWrapper>

                                    <BoxLegendWrapper>
                                        <BoxLegend color="#FF7F23"></BoxLegend>
                                        <p>Recuperadas</p>
                                    </BoxLegendWrapper>

                                    <BoxLegendWrapper>
                                        <BoxLegend color="#FD0202"></BoxLegend>
                                        <p>Perdidas</p>
                                    </BoxLegendWrapper>

                                    <BoxLegendWrapper>
                                        <BoxLegend color="#4666DD"></BoxLegend>
                                        <p>Pendientes</p>
                                    </BoxLegendWrapper>
                                </WrapperLegens>
                            </Col>
                        </Row>
                    </BoxUSerData>
                    <TableFlow data={data} />
                </WrapperView>
            </Loading>
        </>
    )
}


export default ViewFlowTeleton;