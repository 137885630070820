import React, { Component } from 'react';
import { Grid, TextField } from '@material-ui/core';
import TimePicker from '../timePicker';
import UploadFiles from '../uploadFiles/index';
import { withTranslation } from 'react-i18next'


class Neuromodulation extends Component {

    constructor() {
        super();
        this.state = {
            name: "",
            description: "",
            files: [],
            video: "",
            type: true,
            frecuencia: 35,
            ancho_pulso: 200,
            tiempo_canales: null,
            channels: []
        }

        this.currentName = React.createRef();
    }

    values() {
        let data = Object.assign({}, this.state)
        data['config_channels'] = this.state.channels.filter(e => e.index !== 100)
        if (this.props.values !== undefined) this.props.values(data)
    }

    componentDidUpdate() {
        this.values();
    }

    componentDidMount() {
        if (this.props.data.params && Object.keys(this.props.data.params).length !== 0) {
            const { name, tiempo_trabajo, files, description } = this.props.data.params;
            this.setState({
                name,
                description,
                files,
                tiempo_trabajo
            })
        }
    }

    render() {
        const { t } = this.props;
        return (
            <Grid container direction="row" justifyContent="center">
                <Grid item xs={12} md={5} className="pl-4">
                    <p className="title-calendar mb-2">{t('name')}</p>
                    <Grid item xs={12}>
                        <TextField
                            className="w-100"
                            id="outlined-margin-dense1"
                            margin="dense"
                            variant="outlined"
                            value={this.state.name}
                            onChange={value => this.setState({ name: value.target.value })}
                        />
                    </Grid>
                    <p className="title-calendar mt-3 mb-2">{t('description')}</p>
                    <Grid item xs={12}>
                        <TextField
                            className="w-100"
                            id="outlined-margin-dense1"
                            margin="dense"
                            multiline
                            rows={7}
                            variant="outlined"
                            value={this.state.description}
                            onChange={value => this.setState({ description: value.target.value })}
                        />
                    </Grid>
                    <Grid container direction="row">
                        <p className="title-calendar my-3">{t('adjustments')}</p>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item xs={12}>
                            <div className="border-tf py-2 px-3">
                                <p className="fz-10 color-sub-text m-0">{t('trainningsTime')}</p>
                                <TimePicker data={this.state.tiempo_trabajo} hours={true} minutes={true} seconds={true} values={value => this.setState({ tiempo_trabajo: value })} />
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={7} className="px-4">
                    <UploadFiles value={this.state.files} onChange={f => this.setState({ files: f })} />
                </Grid>
            </Grid>
        )
    }
}


export default withTranslation('sessionStudio')(Neuromodulation);