import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, Menu, MenuItem } from "@material-ui/core";
import "../assets/img/icomoon/style.css";
import { closeSession } from "../lib/router";
import { Link } from "react-router-dom";
import { connect } from "../store";
import { useTranslation } from "react-i18next";
import { getUrl } from "../lib/utils";

const useStyles = makeStyles({
  root: {
    width: "28px",
    height: "28px",
    display: "flex",
    overflow: "hidden",
    position: "relative",
    fontSize: "12px",
    alignItems: "center",
    lineHeight: 1,
    borderRadius: "50%",
    justifyContent: "center",
    backgroundColor: "#281B65",
    marginRight: "25px",
  },
  input: {
    color: "#FFF",
    fontWeight: "600",
    backgroundColor: "transparent",
    fontSize: "10px",
    margin: "0",
    "&:focus": {
      outline: "none",
    },
  },
  iconInfo: {
    width: "35px",
    height: "35px",
    background: "#EDEAFF",
    borderRadius: "100%",
    color: "#281B65",
    fontSize: "18px",
    textAlign: "center",
    padding: "0px",
    display: "table",
  },
  iconAvatar: {
    width: "50px",
    height: "50px",
    background: "#281B65",
    borderRadius: "100%",
    color: "#FFF",
    fontSize: "18px",
    textAlign: "center",
    padding: "6px",
    lineHeight: "37px",
  },
});

const SimpleMenu = (props) => {
  const max_height = 100;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [user, setUser] = React.useState({});

  React.useEffect(() => setUser(props.store.user), [props.store.user]);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const { t, i18n } = useTranslation("global");

  const getRolTranslate = (rol) => {
    return t(`roles.${rol}`);
  };

  return (
    <div className={classes.root}>
      <Button
        className={classes.input}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <img alt="" src={getUrl(user.avatar)} style={{ width: "60px", objectFit: "cover" }} />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: max_height * 4.5,
            width: "387px",
            paddingBottom: "7px",
            marginTop: "30px",
            background: "#FFF",
            borderRadius: "3px",
            zIndex: "999",
            color: "rgb(99, 98, 98)",
            textAlign: "left",
            marginLeft: "2px",
            fontWeight: "400",
          },
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className="border-bottom-tf py-3 not-focus"
        >
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid
              item
              xs={12}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              className="pr-3"
            >
              <img
                src={user.avatar}
                style={{ width: 60, height: 60, borderRadius: 100, marginBottom: 5 }}
                alt=""
              />
            </Grid>
            <Grid
              item
              xs={12}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              className="pr-3"
            >
              <p style={{ fontWeight: 600 }} className="m-0 pt-2 fz-14">
                {user.name} {user.lastname}{" "}
              </p>
            </Grid>
            <Grid
              item
              xs={12}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              className="pr-3"
            >
              <span style={{ fontSize: 12 }}>{getRolTranslate(user.rol)}</span>
            </Grid>
          </Grid>
        </Grid>
        <MenuItem>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className="border-bottom-tf py-3"
            onClick={handleClose}
          >
            <Link className="text-center" to={"/UserProfile/"}>
              <p
                className="m-0 fz-13 mx-auto border border-radius-30 px-4 py-2"
                style={{ width: "220px" }}
              >
                {t("manage_account")}
              </p>
            </Link>
          </Grid>
        </MenuItem>
        <MenuItem>
          <Grid container className="border-bottom-tf p-3">
            <Grid
              item
              xs={4}
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              className="pr-3"
            >
              <div className={classes.iconInfo}>
                <span className="icon-therapist icon-display"></span>
              </div>
            </Grid>
            <Grid
              item
              xs={8}
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <p className="m-0 fz-14">
                {t("language")}:
                <span
                  style={{ margin: "0 3px", fontWeight: i18n.language === "es" ? 600 : 400 }}
                  onClick={() => {
                    i18n.changeLanguage("es");
                  }}
                >
                  ES
                </span>{" "}
                /{" "}
                <span
                  style={{ margin: "0 3px", fontWeight: i18n.language === "en" ? 600 : 400 }}
                  onClick={() => {
                    i18n.changeLanguage("en");
                  }}
                >
                  EN
                </span>
              </p>
            </Grid>
          </Grid>
        </MenuItem>
        {/* <MenuItem>
                    <Grid container className="border-bottom-tf p-3">
                        <Grid item xs={4} container direction="row" justifyContent="flex-end" alignItems="center" className="pr-3">
                            <div className={classes.iconInfo}>
                                <span className="icon-therapist icon-display"></span>
                            </div>
                        </Grid>
                        <Grid item xs={8} container direction="row" justifyContent="flex-start" alignItems="center">
                            <p className="m-0 fz-14">{t('My_achievements')}</p>
                        </Grid>
                    </Grid>
                </MenuItem> */}
        <MenuItem onClick={() => closeSession()}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className="pt-3"
          >
            <p className="m-0 fz-12 border hover border-radius-3 px-4 py-2">{t("Sign_off")}</p>
          </Grid>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default connect(SimpleMenu);
