import React from 'react'
import IcoIsotipo from "../../../assets/img/iconMenu/icon-isotipo.svg";

const NavBarTeleton = () => {
    return (
        <>
            <nav className="navbar w-100">
                <img src={IcoIsotipo} style={{ width: 60, borderRadius: 100 }} alt="Isotipo" />
                <h4 style={{ marginRight: 5, fontSize:18 }}>Evaluación del paciente</h4>
            </nav>
        </>
    )
}

export default NavBarTeleton;