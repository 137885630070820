import styled from "styled-components";

export const IconsContainer = styled.div`
  & > svg {
    cursor: pointer;

    &:first-child {
      margin-right: 10px;
    }

    &:last-child {
      margin-left: 10px;
    }

    &:hover {
      opacity: 0.7;
    }
  }
`;

export const ColorDiv = styled.div`
    background-color: ${ ({ bg }) => bg ? bg : "#fff" };
    border-radius: 4px;
    height: 20px;
    width: 20px;
    margin: 0 auto;
`;