import styled from "styled-components";
import { CrossIcon } from "../../../assets/icons";

export const ScreenContainer = styled.div`
  padding: 15px 25px;

  height: calc(100vh - 45px);
  overflow: auto;

  & .__red {
    color: red;
  }
`;

export const Box = styled.div`
  position: relative;
  background: #ffffff;
  border: 1px solid #9aa2a8;
  border-radius: 4px;
  padding: ${({ padding }) => (padding ? padding : 0)};

  &._marginY-7 {
    margin: 7px 0;
  }

  & > .box--icon {
    position: absolute;
    top: 4px;
    right: 14px;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  & > .box--tooltip {
    position: absolute;
    top: 6px;
    right: 14px;
    cursor: pointer;
  }

  &.__centerCheckbox {
    height: 100%;
    display: grid;
    place-items: center;
  }
`;

export const BlueBg = styled.div`
  background-color: #c7d9ff;
  padding: 10px 7px;
  margin-top: 10px;
  border-radius: 4px;
`;

export const SliderContainer = styled.div`
  margin: ${({ margin = "14px 0" }) => margin};
`;

export const BoxImage = styled(SliderContainer)`
  display: grid;
  grid-template-columns: 80px 1fr;

  & > img {
    width: 65px;
  }
`;

export const ComponentContainer = styled.div``;

// -> Records & Video
export const ContainerBody = styled.div`
  display: grid;
  grid-template-columns: 350px 1fr;
`;

export const RecordsVideoForm = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  margin-bottom: 15px;

  & > div {
    & > div:first-child {
      margin-bottom: 15px;
    }
  }
`;

// -> General
export const GeneralContainer = styled(RecordsVideoForm)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  padding: 0 12px;
`;

export const TabsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 22px;

  & > div:first-child {
    margin-right: 9px;
  }
`;

export const CloseStyled = styled(CrossIcon)`
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

export const SidePanel = styled.div`
  background: #eef2ff;
  border-radius: 4px;
  width: 100%;
  height: calc(100vh - 200px);
  max-height: calc(100vh - 200px);
  overflow: auto;
  padding: 20px;

  & > .__file {
    background-color: white;
    border-radius: 4px;
    /* height: 64px; */
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 70px;
    place-items: center;
    margin-bottom: 20px;
    padding: 10px 0;

    & > span {
      place-self: center flex-start;
      padding-left: 20px;
    }
  }

  & > .__video {
    display: grid;
    grid-template-columns: 1fr 70px;
    place-items: center;
    background-color: white;
    border-radius: 4px;
    /* height: 118px; */
    width: 100%;
    margin-bottom: 20px;
    padding: 10px 0;

    & > .__info {
      padding-left: 20px;
      width: 100%;

      & > div {
        margin-top: 15px;
        & > hr {
          margin: 5px 0;
          border: 1px solid rgba(0, 0, 0, 0.1);
        }
      }
    }

    & > span {
      /* place-self: center flex-start; */
      padding-left: 20px;
      font-weight: 400;
      font-size: 14px;
      color: #495057;
      padding-top: 14px;
    }

    & .__serie {
      font-weight: 300;
      font-size: 12px;
      color: #495057;
    }

    & .__time {
      font-weight: 400;
      font-size: 16px;
      color: #fdad34;
    }
  }
`;

export const DataBody = styled.div`
  position: relative;

  & > .__header {
    display: grid;
    grid-template-columns: 1fr 115px;
    place-items: center;

    & > div:first-child {
      padding: 0 10px;
      width: 100%;
    }
  }

  & > .__body {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    margin-top: 20px;
    place-items: center;
    padding: 0 12px;

    max-height: calc(100vh - 260px);
    overflow-y: auto;
    padding-bottom: 30px;
  }
`;

// -> Graphic (Sequential)
export const Title = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #182024;

  & > .__red {
    color: red;
  }
`;

export const SequentialForm = styled.div`
  & > div {
    margin-bottom: 15px;

    &:last-child {
      height: 110px;
    }
  }

  margin-bottom: 55px;
`;

// Timeline
export const TimelineContainer = styled.div`
  position: relative;
  padding-left: 20px;
`;

export const TimelineTitle = styled.h6`
  font-weight: 500;
  font-size: 14px;
  color: #182024;
`;

export const Timeline = styled.div`
  border: 1px solid #d2d2d2;
  float: right;
  height: 242px;
  margin-bottom: 45px;
  margin-top: 8px;
  position: relative;
  width: 98%;
`;

export const TimelineSelect = styled.span`
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 50%;
  color: white;
  cursor: pointer;
  display: flex;
  height: 30px;
  justify-content: center;
  left: -40px;
  position: absolute;
  top: 5px;
  width: 30px;

  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.04em;

  opacity: ${({ active }) => (active ? 1 : 0.6)};
  pointer-events: ${({ active }) => (active ? "inherit" : "none")};

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const TimelineBg = styled.div`
  height: 40px;
  position: relative;
  width: 100%;

  &:nth-child(odd) {
    background-color: white;
  }
  &:nth-child(even) {
    background-color: #f7f7f7;
  }
`;

export const TrashIcon = styled.div`
  align-items: center;
  background-color: transparent;
  border-radius: 3px;
  border: 1px solid #c4c4c4;
  display: flex;
  height: 30px;
  justify-content: center;
  line-height: 45px;
  position: absolute;
  right: 0;
  text-align: center;
  top: -28px;
  width: 30px;

  & > span {
    color: #281b65;
  }

  &:hover {
    border: 1px solid #fdad34;
    background-color: #fdad34;

    & > span {
      color: white;
    }
  }
`;

// Sliders
export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 13px;
  width: 100%;
  margin-top: 15px;
`;

export const GridTitle = styled.p`
  color: #263238;
  font-size: 10px;
  margin: 0;
  white-space: nowrap;
`;

export const SliderIndicator = styled.p`
  color: #281b65;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
`;

export const TimeContainer = styled.div`
  align-items: center;
  display: flex;
  margin-right: 15px;
`;

export const TimeText = styled.p`
  color: #fdad34;
  font-size: 14px;
  font-weight: 700;
  margin: 0;
  padding: 0 16px;
  text-align: center;
`;
export const TimeTotal = styled.p`
  color: #182024;
  font-size: 12px;
  font-weight: 400;
  margin: 0 5px 0 0;
`;
