import React from "react";

// const channels = [];
class ConfigSensor extends React.Component {
  constructor() {
    super();
    this.state = {
      channels: [],
    };
  }

  value(ch, value) {
    // channels[ch] = value.target.checked;
    // this.setState((prevState, state) => {
    //     prevState.channels[ch] = value.target.checked;
    //     return prevState;
    // })

    let d = Object.assign([], this.state.channels);
    d[ch] = value.target.checked;

    this.setState({ channels: d });

    if (this.props.value !== undefined) this.props.value(d);
  }

  componentDidMount() {
    let chs = new Array(24).fill(false);

    this.setState((prevState) => {
      prevState.channels = chs;
      return prevState;
    });
  }

  componentDidUpdate(props) {
    if (this.props.data !== props.data) {
      this.setState({ channels: this.props.data });
    }
  }

  isDisabled(index) {
    if (this.props.channels[index].index !== 100) return false;
    else return true;
  }

  render() {
    return (
      <div className="block">
        <div className="configChannels horizontal">
          <div className="text-white">
            <input
              disabled={this.isDisabled(0)}
              type="checkbox"
              id="cs1"
              checked={this.state.channels[0]}
              onChange={(value) => this.value(0, value)}
            ></input>
            <label
              disabled={this.isDisabled(0)}
              htmlFor="cs1"
              className={this.isDisabled(0) ? "disabled" : "primario"}
            >
              1
            </label>
          </div>
          <div className="text-white">
            <input
              disabled={this.isDisabled(1)}
              type="checkbox"
              id="cs2"
              checked={this.state.channels[1]}
              onChange={(value) => this.value(1, value)}
            ></input>
            <label
              disabled={this.isDisabled(1)}
              htmlFor="cs2"
              className={this.isDisabled(1) ? "disabled" : "secundario"}
            >
              2
            </label>
          </div>
          <div className="text-white">
            <input
              disabled={this.isDisabled(2)}
              type="checkbox"
              id="cs3"
              checked={this.state.channels[2]}
              onChange={(value) => this.value(2, value)}
            ></input>
            <label
              disabled={this.isDisabled(2)}
              htmlFor="cs3"
              className={this.isDisabled(2) ? "disabled" : "primario"}
            >
              3
            </label>
          </div>
          <div className="text-white">
            <input
              disabled={this.isDisabled(3)}
              type="checkbox"
              id="cs4"
              checked={this.state.channels[3]}
              onChange={(value) => this.value(3, value)}
            ></input>
            <label
              disabled={this.isDisabled(3)}
              htmlFor="cs4"
              className={this.isDisabled(3) ? "disabled" : "secundario"}
            >
              4
            </label>
          </div>
          <div className="text-white">
            <input
              disabled={this.isDisabled(4)}
              type="checkbox"
              id="cs6"
              checked={this.state.channels[4]}
              onChange={(value) => this.value(4, value)}
            ></input>
            <label
              disabled={this.isDisabled(4)}
              htmlFor="cs6"
              className={this.isDisabled(4) ? "disabled" : "primario"}
            >
              5
            </label>
          </div>
          <div className="text-white">
            <input
              disabled={this.isDisabled(5)}
              type="checkbox"
              id="cs25"
              checked={this.state.channels[5]}
              onChange={(value) => this.value(5, value)}
            ></input>
            <label
              disabled={this.isDisabled(5)}
              htmlFor="cs25"
              className={this.isDisabled(5) ? "disabled" : "secundario"}
            >
              6
            </label>
          </div>
        </div>
        <div className="chorizontal">
          <div className="configChannels vertical ib">
            <div className="text-white">
              <input
                disabled={this.isDisabled(0)}
                type="checkbox"
                id="cs7"
                checked={this.state.channels[6]}
                onChange={(value) => this.value(6, value)}
              ></input>
              <label
                disabled={this.isDisabled(0)}
                htmlFor="cs7"
                className={this.isDisabled(0) ? "disabled" : "primario"}
              >
                1
              </label>
            </div>
            <div className="text-white">
              <input
                disabled={this.isDisabled(1)}
                type="checkbox"
                id="cs8"
                checked={this.state.channels[7]}
                onChange={(value) => this.value(7, value)}
              ></input>
              <label
                disabled={this.isDisabled(1)}
                htmlFor="cs8"
                className={this.isDisabled(1) ? "disabled" : "secundario"}
              >
                2
              </label>
            </div>
            <div className="text-white">
              <input
                disabled={this.isDisabled(2)}
                type="checkbox"
                id="cs9"
                checked={this.state.channels[8]}
                onChange={(value) => this.value(8, value)}
              ></input>
              <label
                disabled={this.isDisabled(2)}
                htmlFor="cs9"
                className={this.isDisabled(2) ? "disabled" : "primario"}
              >
                3
              </label>
            </div>
            <div className="text-white">
              <input
                disabled={this.isDisabled(3)}
                type="checkbox"
                id="cs10"
                checked={this.state.channels[9]}
                onChange={(value) => this.value(9, value)}
              ></input>
              <label
                disabled={this.isDisabled(3)}
                htmlFor="cs10"
                className={this.isDisabled(3) ? "disabled" : "secundario"}
              >
                4
              </label>
            </div>
            <div className="text-white">
              <input
                disabled={this.isDisabled(4)}
                type="checkbox"
                id="cs11"
                checked={this.state.channels[10]}
                onChange={(value) => this.value(10, value)}
              ></input>
              <label
                disabled={this.isDisabled(4)}
                htmlFor="cs11"
                className={this.isDisabled(4) ? "disabled" : "primario"}
              >
                5
              </label>
            </div>
            <div className="text-white">
              <input
                disabled={this.isDisabled(5)}
                type="checkbox"
                id="cs12"
                checked={this.state.channels[11]}
                onChange={(value) => this.value(11, value)}
              ></input>
              <label
                disabled={this.isDisabled(5)}
                htmlFor="cs12"
                className={this.isDisabled(5) ? "disabled" : "secundario"}
              >
                6
              </label>
            </div>
          </div>
          <div className="ib sensor-box">
            <div className="sensor">
              <div className="led"></div>
            </div>
          </div>
          <div className="configChannels vertical ib">
            <div className="text-white">
              <input
                disabled={this.isDisabled(0)}
                type="checkbox"
                id="cs13"
                checked={this.state.channels[12]}
                onChange={(value) => this.value(12, value)}
              ></input>
              <label
                disabled={this.isDisabled(0)}
                htmlFor="cs13"
                className={this.isDisabled(0) ? "disabled" : "primario"}
              >
                1
              </label>
            </div>
            <div className="text-white">
              <input
                disabled={this.isDisabled(1)}
                type="checkbox"
                id="cs14"
                checked={this.state.channels[13]}
                onChange={(value) => this.value(13, value)}
              ></input>
              <label
                disabled={this.isDisabled(1)}
                htmlFor="cs14"
                className={this.isDisabled(1) ? "disabled" : "secundario"}
              >
                2
              </label>
            </div>
            <div className="text-white">
              <input
                disabled={this.isDisabled(2)}
                type="checkbox"
                id="cs15"
                checked={this.state.channels[14]}
                onChange={(value) => this.value(14, value)}
              ></input>
              <label
                disabled={this.isDisabled(2)}
                htmlFor="cs15"
                className={this.isDisabled(2) ? "disabled" : "primario"}
              >
                3
              </label>
            </div>
            <div className="text-white">
              <input
                disabled={this.isDisabled(3)}
                type="checkbox"
                id="cs16"
                checked={this.state.channels[15]}
                onChange={(value) => this.value(15, value)}
              ></input>
              <label
                disabled={this.isDisabled(3)}
                htmlFor="cs16"
                className={this.isDisabled(3) ? "disabled" : "secundario"}
              >
                4
              </label>
            </div>
            <div className="text-white">
              <input
                disabled={this.isDisabled(4)}
                type="checkbox"
                id="cs17"
                checked={this.state.channels[16]}
                onChange={(value) => this.value(16, value)}
              ></input>
              <label
                disabled={this.isDisabled(4)}
                htmlFor="cs17"
                className={this.isDisabled(4) ? "disabled" : "primario"}
              >
                5
              </label>
            </div>
            <div className="text-white">
              <input
                disabled={this.isDisabled(5)}
                type="checkbox"
                id="cs18"
                checked={this.state.channels[17]}
                onChange={(value) => this.value(17, value)}
              ></input>
              <label
                disabled={this.isDisabled(5)}
                htmlFor="cs18"
                className={this.isDisabled(5) ? "disabled" : "secundario"}
              >
                6
              </label>
            </div>
          </div>
        </div>
        <div className="configChannels horizontal">
          <div className="text-white">
            <input
              disabled={this.isDisabled(0)}
              type="checkbox"
              id="cs19"
              checked={this.state.channels[18]}
              onChange={(value) => this.value(18, value)}
            ></input>
            <label
              disabled={this.isDisabled(0)}
              htmlFor="cs19"
              className={this.isDisabled(0) ? "disabled" : "primario"}
            >
              1
            </label>
          </div>
          <div className="text-white">
            <input
              disabled={this.isDisabled(1)}
              type="checkbox"
              id="cs20"
              checked={this.state.channels[19]}
              onChange={(value) => this.value(19, value)}
            ></input>
            <label
              disabled={this.isDisabled(1)}
              htmlFor="cs20"
              className={this.isDisabled(1) ? "disabled" : "secundario"}
            >
              2
            </label>
          </div>
          <div className="text-white">
            <input
              disabled={this.isDisabled(2)}
              type="checkbox"
              id="cs21"
              checked={this.state.channels[20]}
              onChange={(value) => this.value(20, value)}
            ></input>
            <label
              disabled={this.isDisabled(2)}
              htmlFor="cs21"
              className={this.isDisabled(2) ? "disabled" : "primario"}
            >
              3
            </label>
          </div>
          <div className="text-white">
            <input
              disabled={this.isDisabled(3)}
              type="checkbox"
              id="cs22"
              checked={this.state.channels[21]}
              onChange={(value) => this.value(21, value)}
            ></input>
            <label
              disabled={this.isDisabled(3)}
              htmlFor="cs22"
              className={this.isDisabled(3) ? "disabled" : "secundario"}
            >
              4
            </label>
          </div>
          <div className="text-white">
            <input
              disabled={this.isDisabled(4)}
              type="checkbox"
              id="cs23"
              checked={this.state.channels[22]}
              onChange={(value) => this.value(22, value)}
            ></input>
            <label
              disabled={this.isDisabled(4)}
              htmlFor="cs23"
              className={this.isDisabled(4) ? "disabled" : "primario"}
            >
              5
            </label>
          </div>
          <div className="text-white">
            <input
              disabled={this.isDisabled(5)}
              type="checkbox"
              id="cs24"
              checked={this.state.channels[23]}
              onChange={(value) => this.value(23, value)}
            ></input>
            <label
              disabled={this.isDisabled(5)}
              htmlFor="cs24"
              className={this.isDisabled(5) ? "disabled" : "secundario"}
            >
              6
            </label>
          </div>
        </div>
      </div>
    );
  }
}

export default ConfigSensor;
